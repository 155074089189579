import { useNavigate } from "react-router-dom";
import "./YieldNavbar.scss";
import themeIcon from "../../../assets/icons/theme.svg";
import languageIcon from "../../../assets/icons/language.svg";
import settingsIcon from "../../../assets/icons/profile.svg";
import walletIcon from "../../../assets/icons/wallet.svg";
import walletFilledIcon from "../../../assets/icons/walletFilled.svg";
import homeIcon from "../../../assets/icons/home.svg";
import homeFilledIcon from "../../../assets/icons/homeFilled.svg";
import plusIcon from "../../../assets/icons/plus.svg";
import plusFilledIcon from "../../../assets/icons/plusFilled.svg";
import yieldIcon from "../../../assets/icons/yield.svg";
import yieldFilledIcon from "../../../assets/icons/yieldFilled.svg";
import optionicon from "../../../assets/images/optionicon.png";
import stakingicon from "../../../assets/images/stakingicon.png";
import liqicon from "../../../assets/images/liqicon.png";

import {
  Button,
  Container,
  Nav,
  Navbar,
  NavDropdown,
  Offcanvas,
} from "react-bootstrap";
import { useState } from "react";
import YieldConnectWallet from "./YieldConnectWallet/YieldConnectWallet";
import { useTranslation } from "react-i18next";
import i18next from "../../../utils/i18n";
import i18n from "../../../utils/i18n";

function YieldNavbar({ switchTheme, theme, switchLanguage }) {
  let navigate = useNavigate();
  const [show, setShow] = useState(false);

  const { t } = useTranslation();

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const changeLanguage = (lng) => {
    localStorage.setItem("i18nextLng", lng);

    i18n.changeLanguage(lng);
  };

  const getLanguage = i18next.language || window.localStorage.i18nextLng;

  return (
    <>
      <Navbar expand={"lg"} sticky="top">
        <Container fluid className="navbar_container">
          <Navbar.Brand
            onClick={() => {
              navigate("/");
              handleClose();
            }}
          >
            <div className="navbar_logo">
              <h1>DOW</h1> <h2>Digital Capital</h2>
            </div>
          </Navbar.Brand>
          <Navbar.Toggle
            onClick={handleShow}
            aria-controls={"offcanvasNavbar-expand-lg"}
          />
          <Navbar.Offcanvas
            show={show}
            onHide={handleClose}
            id={"offcanvasNavbar-expand-lg"}
            aria-labelledby={"offcanvasNavbarLabel-expand-lg"}
            placement="end"
          >
            <Offcanvas.Header>
              <Offcanvas.Title id={"offcanvasNavbarLabel-expand-lg"}>
                <div className="navbar_logo">
                  <h1>DOW</h1> <h2>Digital Capital</h2>
                </div>
              </Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
              <Nav className="justify-content-start ml-4 flex-grow-1 pe-3 gap-4">
                <NavDropdown
                  title={t("yield_navbar")}
                  id={"offcanvasNavbarDropdown-expand-lg"}
                >
                  <NavDropdown.Item
                    onClick={() => {
                      navigate("/yields/staking");
                      handleClose();
                    }}
                  >
                    {t("flanding_leveraged")}
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    onClick={() => {
                      navigate("/yields/liquidity");
                      handleClose();
                    }}
                  >
                    {t("flanding_liquidity")}
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    onClick={() => {
                      navigate("/yields/option");
                      handleClose();
                    }}
                  >
                    {t("flanding_option")}
                  </NavDropdown.Item>
                </NavDropdown>
              </Nav>
              <div className="right_side">
                <div
                  onClick={() =>
                    changeLanguage(getLanguage === "tr" ? "en" : "tr")
                  }
                  style={{
                    fontSize: "16px",
                    fontWeight: "300",
                    lineHeight: "19px",
                    textTransform: "uppercase",
                    cursor: "pointer",
                    borderBottom: "2px solid #4169e1",
                    marginTop: "2px",
                    marginLeft: "25px",
                    marginRight: "25px",
                  }}
                >
                  {getLanguage === "tr" ? "EN" : "TR"}
                </div>
                <YieldConnectWallet />
              </div>
            </Offcanvas.Body>
          </Navbar.Offcanvas>
        </Container>
      </Navbar>
    </>
  );
}

export default YieldNavbar;
