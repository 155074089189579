import React, { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import "./FTable.scss";

const FTable = ({
  columns,
  data,
  rowKey,
  sortedColumn,
  setSortedColumn,
  sortDirection,
  setSortDirection,
  pagination,
  padding,
  type,
  total,
  tmanagement,
  colored,
  mfee_per,
  pfee_per,
  removeBorderBottom,
  verticalAlign,
  height,
}) => {
  const ASC = "asc";
  const DESC = "desc";
  const directions = [ASC, DESC, null];

  const [localSortDirection, setLocalSortDirection] = useState(null);
  const [localSortedColumn, setLocalSortedColumn] = useState(null);

  const handleClickColumnHead = (sortable, selector) => {
    if (sortable) {
      const direction =
        directions[
          (directions.findIndex((d) => d === getSortDirection()) + 1) %
            directions.length
        ];
      if (pagination) {
        // const direction = sortDirection === ASC ? DESC : ASC;
        setSortedColumn(selector);
        setSortDirection(direction);
        // handleFetchData(activePage);
      } else {
        setLocalSortedColumn(selector);
        setLocalSortDirection(direction);
      }
    }
  };

  const getSortDirection = () => {
    if (pagination) {
      return sortDirection;
    } else {
      return localSortDirection;
    }
  };

  const getSortedColumn = () => {
    if (pagination) {
      return sortedColumn;
    } else {
      return localSortedColumn;
    }
  };

  return (
    <div className="f_mini_table">
      <div className="f_mini_table-container">
        <Table responsive style={{ margin: "0" }}>
          <thead>
            <tr>
              {columns.map((column, index) => (
                <th
                  className={"table-color"}
                  key={`${column.selector}-${index}`}
                  data-sortable={column.sortable}
                  data-sort-direction={
                    column.selector === getSortedColumn()
                      ? getSortDirection()
                      : ""
                  }
                  onClick={() =>
                    handleClickColumnHead(column.sortable, column.selector)
                  }
                  width={column.width}
                  style={
                    type && {
                      verticalAlign: verticalAlign,
                      fontSize:
                        index == 0 && !removeBorderBottom ? "20px" : "14px",
                      color:
                        index == 0 && !removeBorderBottom
                          ? "#252525"
                          : "#5E718D",
                      borderBottom: removeBorderBottom
                        ? "0px"
                        : "0.5px solid #5E718D",
                      textAlign: column.headerAlign
                        ? column.headerAlign
                        : type && index != 0 && "center",
                      borderBottomWidth: tmanagement && "0px !important",
                      height: height,
                    }
                  }
                >
                  {column.name}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {data.map((dataItem, index, row) => (
              <tr
                key={`${dataItem[rowKey]}-${index}`}
                style={{
                  background:
                    colored &&
                    (dataItem.status === "R" ? "#fee9e9" : "#e7f7eb"),
                  borderBottom:
                    total && index + 2 === row.length
                      ? "0.5px solid #5E718D"
                      : "",
                }}
              >
                {columns.map((column, cIndex) => (
                  <td
                    key={`${dataItem[rowKey]}-${column.selector}-${cIndex}`}
                    title={column.showTitle && dataItem[column.selector]}
                    style={
                      {
                        textAlign: column.align
                          ? column.align
                          : type && cIndex != 0 && "center",
                        fontWeight: type && "400",
                        padding: padding ? padding : "",
                      }
                      /*dataItem[column.selector] === "ACTIVE"
                        ? { color: "#008C00" }
                        : dataItem[column.selector] === "PASSIVE"
                        ? { color: "#406882" }
                        : dataItem[column.selector] === "CANCELED"
                        ? { color: "#F05454" }
                        : dataItem[column.selector] === "WFA"
                        ? { color: "#F0BB62" }
                        : { color: "#8c959d" }*/
                    }
                  >
                    {column.cell
                      ? column.cell(dataItem)
                      : dataItem[column.selector]}
                  </td>
                ))}
              </tr>
            ))}
            {tmanagement && (
              <>
                <td></td>
                <td>
                  Total Management Fees: {parseFloat(mfee_per).toFixed(2) + "$"}
                </td>
                <td>
                  Total Performance Fees:{" "}
                  {parseFloat(pfee_per).toFixed(2) + "$"}
                </td>
              </>
            )}
          </tbody>
        </Table>
      </div>
    </div>
  );
};

export default FTable;

FTable.defaultProps = {
  verticalAlign: "middle",
  columns: [],
  data: [],
  rowKey: "_id",
  sortedColumn: "",
  setSortedColumn: () => {},
  sortDirection: "",
  setSortDirection: () => {},
  pagination: true,
  activePage: 0,
  totalPages: 0,
  handleFetchData: () => {},
};
