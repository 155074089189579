import React, { useEffect, useState } from "react";
import { Form, Spinner } from "react-bootstrap";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { useTranslation } from "react-i18next";
import "./DFundHistory.scss";
import { FChart } from "../../FChart/FChart";
import { useDispatch, useSelector } from "react-redux";
import { getGeneralChartDataRequest } from "../../../services/admin/adminService";
import { addDays } from "../../../utils/util";
import c5icon from "../../../assets/images/c5icon.png";
import btcicon from "../../../assets/images/btcicon.png";
import trqicon from "../../../assets/images/tradeicon.png";
import arbitrageicon from "../../../assets/images/arbitrageicon.png";

import optionicon from "../../../assets/images/optionicon.png";
import stakingicon from "../../../assets/images/stakingicon.png";
import liqicon from "../../../assets/images/liqicon.png";
const DFundHistory = ({ user }) => {
  const [key, setKey] = useState("C5");
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const { generalChartData, loadingGeneral } = useSelector(
    (state) => state.admin
  );

  const [time, setTime] = useState("DAY");

  const [startDate, setStartDate] = useState(addDays(new Date(), 7));
  const [endDate, setEndDate] = useState(addDays(new Date(), 0));

  useEffect(() => {
    if (time == "WEEK") {
      dispatch(
        getGeneralChartDataRequest({
          type: key,
          time: time,
          start: addDays(new Date(), 7),
          end: addDays(new Date(), 0),
        })
      );
    } else if (time == "MONTH") {
      dispatch(
        getGeneralChartDataRequest({
          type: key,
          time: time,
          start: addDays(new Date(), 30),
          end: addDays(new Date(), 0),
        })
      );
    } else {
      dispatch(
        getGeneralChartDataRequest({
          type: key,
          time: time,
          start: addDays(new Date(), 1),
          end: addDays(new Date(), 0),
        })
      );
    }
  }, [key, time]);

  return (
    <div>
      <h3
        style={{
          fontSize: "20px",
          fontWeight: 700,
        }}
      >
        {t("history")}
      </h3>
      <Tabs
        id="controlled-tab-example"
        activeKey={key}
        onSelect={(k) => setKey(k)}
        className=" d_fundhistory"
      >
        <Tab eventKey="C5" title={<img src={c5icon} width="27" />}>
          <div className="d_fundhistory-tab" style={{ minHeight: "300px" }}>
            <div>
              <div className="d_fundprice-header">
                <div></div>
                <Form.Select
                  value={time}
                  onChange={(e) => setTime(e.target.value)}
                >
                  <option value={"DAY"}>{t("daily")}</option>
                  <option value={"WEEK"}>{t("weekly")}</option>
                  <option value={"MONTH"}>{t("monthly")}</option>
                </Form.Select>
              </div>
            </div>
            {loadingGeneral ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginTop: "64px",
                }}
              >
                <Spinner
                  animation="grow"
                  style={{ width: "2rem", height: "2rem" }}
                />
              </div>
            ) : (
              generalChartData && <FChart data={generalChartData} token="C5" />
            )}
          </div>
        </Tab>
        <Tab eventKey="BTCp" title={<img src={btcicon} width="27" />}>
          <div className="d_fundhistory-tab" style={{ minHeight: "300px" }}>
            <div>
              <div className="d_fundprice-header">
                <h4></h4>
                <Form.Select
                  value={time}
                  onChange={(e) => setTime(e.target.value)}
                >
                  <option value={"DAY"}>{t("daily")}</option>
                  <option value={"WEEK"}>{t("weekly")}</option>
                  <option value={"MONTH"}>{t("monthly")}</option>
                </Form.Select>
              </div>
            </div>
            {loadingGeneral ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginTop: "64px",
                }}
              >
                <Spinner
                  animation="grow"
                  style={{ width: "2rem", height: "2rem" }}
                />
              </div>
            ) : (
              generalChartData && (
                <FChart data={generalChartData} token="BTCp" />
              )
            )}
          </div>
        </Tab>
        <Tab eventKey="TRQ" title={<img src={trqicon} width="27" />}>
          <div className="d_fundhistory-tab" style={{ minHeight: "300px" }}>
            <div>
              <div className="d_fundprice-header">
                <h4></h4>
                <Form.Select
                  value={time}
                  onChange={(e) => setTime(e.target.value)}
                >
                  <option value={"DAY"}>{t("daily")}</option>
                  <option value={"WEEK"}>{t("weekly")}</option>
                  <option value={"MONTH"}>{t("monthly")}</option>
                </Form.Select>
              </div>
            </div>
            {loadingGeneral ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginTop: "64px",
                }}
              >
                <Spinner
                  animation="grow"
                  style={{ width: "2rem", height: "2rem" }}
                />
              </div>
            ) : (
              generalChartData && <FChart data={generalChartData} token="TRQ" />
            )}
          </div>
        </Tab>
        <Tab eventKey="QNT" title={<img src={arbitrageicon} width="27" />}>
          <div className="d_fundhistory-tab" style={{ minHeight: "300px" }}>
            <div>
              <div className="d_fundprice-header">
                <h4></h4>
                <Form.Select
                  value={time}
                  onChange={(e) => setTime(e.target.value)}
                >
                  <option value={"DAY"}>{t("daily")}</option>
                  <option value={"WEEK"}>{t("weekly")}</option>
                  <option value={"MONTH"}>{t("monthly")}</option>
                </Form.Select>
              </div>
            </div>
            {loadingGeneral ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginTop: "64px",
                }}
              >
                <Spinner
                  animation="grow"
                  style={{ width: "2rem", height: "2rem" }}
                />
              </div>
            ) : (
              generalChartData && <FChart data={generalChartData} token="QNT" />
            )}
          </div>
        </Tab>
        <Tab eventKey="staking" title={<img src={stakingicon} width="27" />}>
          <div className="d_fundhistory-tab" style={{ minHeight: "300px" }}>
            <div>
              <div className="d_fundprice-header">
                <h4></h4>
                <Form.Select
                  value={time}
                  onChange={(e) => setTime(e.target.value)}
                >
                  <option value={"DAY"}>{t("daily")}</option>
                  <option value={"WEEK"}>{t("weekly")}</option>
                  <option value={"MONTH"}>{t("monthly")}</option>
                </Form.Select>
              </div>
            </div>
            <h5>{t("no_data")}</h5>
          </div>
        </Tab>
        <Tab eventKey="leveraged" title={<img src={liqicon} width="27" />}>
          <div className="d_fundhistory-tab" style={{ minHeight: "300px" }}>
            <div>
              <div className="d_fundprice-header">
                <h4></h4>
                <Form.Select
                  value={time}
                  onChange={(e) => setTime(e.target.value)}
                >
                  <option value={"DAY"}>{t("daily")}</option>
                  <option value={"WEEK"}>{t("weekly")}</option>
                  <option value={"MONTH"}>{t("monthly")}</option>
                </Form.Select>
              </div>
            </div>
            <h5>{t("no_data")}</h5>
          </div>
        </Tab>
        <Tab eventKey="strategy" title={<img src={optionicon} width="27" />}>
          <div className="d_fundhistory-tab" style={{ minHeight: "300px" }}>
            <div>
              <div className="d_fundprice-header">
                <h4></h4>
                <Form.Select
                  value={time}
                  onChange={(e) => setTime(e.target.value)}
                >
                  <option value={"DAY"}>{t("daily")}</option>
                  <option value={"WEEK"}>{t("weekly")}</option>
                  <option value={"MONTH"}>{t("monthly")}</option>
                </Form.Select>
              </div>
            </div>
            <h5>{t("no_data")}</h5>
          </div>
        </Tab>
      </Tabs>
    </div>
  );
};

export default DFundHistory;
