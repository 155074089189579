import React, { useEffect, useState } from "react";
import YieldCoinCard from "../../../components/YieldComponents/YieldCoinCard/YieldCoinCard";
import YieldModal from "../../../components/YieldComponents/YieldModal/YieldModal";

const YieldOptionPage = ({ language, theme, fund }) => {
  const [fundsModalShow, setFundsModalShow] = useState(false);
  const [id, setId] = useState(null);
  const [innerData, setInnerData] = useState(null);

  useEffect(() => {
    const found = fakeData.find((element) => element.id == id);
    setInnerData(found);
  }, [id]);
  const fakeData = [
    {
      id: 1,
      type: "STAKING",
      name: "SOL",
      logo: "https://upload.wikimedia.org/wikipedia/en/b/b9/Solana_logo.png",
      apy: 45.6,
      type: "Covered Call",
    },
    {
      id: 2,
      type: "STAKING",
      name: "ETH",
      logo: "https://icons.iconarchive.com/icons/cjdowner/cryptocurrency-flat/1024/Ethereum-ETH-icon.png",
      apy: 16.4,
      type: "Cash Secured Put",
    },
    {
      id: 3,
      type: "STAKING",
      name: "AVAX",
      logo: "https://cryptologos.cc/logos/avalanche-avax-logo.png",
      apy: 32.2,
      type: "Capital Protection",
    },

    {
      id: 4,
      type: "STAKING",
      name: "BTC",
      logo: "https://cdn-icons-png.flaticon.com/512/5968/5968260.png",
      apy: 18.9,
      type: "Capital Protection",
    },
  ];

  return (
    <>
      <YieldModal
        show={fundsModalShow}
        onHide={() => setFundsModalShow(false)}
        animation={false}
        data={innerData}
      />
      <div className="text-center details_wrapper">
        <div className="f_funddetails text-center">
          <div className="f_funddetails-head">
            <img src="https://dow.capital/static/media/stakingicon.f82205d14240d22c0019.png"></img>
            <div className="f_funddetails-head-text">
              <h1>STAKING</h1>
              <h2>Opsiyon Stratejileri</h2>
              <h3>By Dow Digital Capital</h3>
            </div>
          </div>
          <div className="f_funddetails-body">
            <div className="f_funddetails-body-text">
              <div className="f_funddetails-body-text-content">
                <h1>Opsiyonlarla getiri kazanın</h1>
                <h2>
                  Opsiyon piyasasından faydalanarak getirileri yaratın. Başlıca
                  3 çeşit strateji şu an uygulanabilir durumda: Karşılıklı Call
                  Satma, Nakit Teminatlı Put satma, Anapara korumalı Strateji
                </h2>
              </div>
              <div
                style={{
                  backgroundColor: "var(--background-darkroyalblue)",
                  borderRadius: "24px",
                  width: "474px",
                  height: "270px",
                }}
              >
                <iframe
                  style={{ borderRadius: "24px" }}
                  width={"100%"}
                  height={"100%"}
                  src="https://www.youtube.com/embed/qu44jOGy1J0"
                  title="YouTube video player"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="ysp-wrapper">
        <div className="ysp">
          <h4>Assets</h4>
          <div className="ysp-assets">
            {fakeData?.map((item, i) => {
              return (
                <YieldCoinCard
                  key={i}
                  data={item}
                  showModal={setFundsModalShow}
                  onClick={() => setId(item.id)}
                />
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};

export default YieldOptionPage;
