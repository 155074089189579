// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.f_mini_table thead th {
  color: var(--text-primary);
  font-size: 14px;
  font-weight: 700;
}
.f_mini_table tbody td {
  color: var(--text-primary) !important;
  font-size: 14px;
  font-weight: 500;
  display: table-cell;
  vertical-align: middle;
  border: none;
}
.f_mini_table table {
  width: 100%;
  text-align: start;
}`, "",{"version":3,"sources":["webpack://./src/components/FTable/FTable.scss"],"names":[],"mappings":"AAEI;EACE,0BAAA;EACA,eAAA;EACA,gBAAA;AADN;AAMI;EACE,qCAAA;EACA,eAAA;EACA,gBAAA;EACA,mBAAA;EACA,sBAAA;EACA,YAAA;AAJN;AAOE;EACE,WAAA;EACA,iBAAA;AALJ","sourcesContent":[".f_mini_table {\n  thead {\n    th {\n      color: var(--text-primary);\n      font-size: 14px;\n      font-weight: 700;\n      //border-bottom: 1px solid rgba(0, 0, 0, 0.327);\n    }\n  }\n  tbody {\n    td {\n      color: var(--text-primary) !important;\n      font-size: 14px;\n      font-weight: 500;\n      display: table-cell;\n      vertical-align: middle;\n      border: none;\n    }\n  }\n  table {\n    width: 100%;\n    text-align: start;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
