import React from "react";
import { useTranslation } from "react-i18next";
import { yieldWalletColumns } from "../../../constants/columnConfigurations";
import FTable from "../../FTable/FTable";

const WYield = () => {
  const { t } = useTranslation();

  const yieldWalletConfigs = yieldWalletColumns({ translateFunction: t });
  function createData(name, logo, qty, return_d, pl, mark_to_market) {
    return {
      assets: { logo, name },
      qty,
      return_d,
      pl,
      mark_to_market,
    };
  }

  const data = [
    createData(
      t("footer_staking"),
      "https://dow.capital/static/media/stakingicon.f82205d14240d22c0019.png",
      "-",
      "-",
      "-",
      "-"
    ),
    createData(
      t("footer_liq"),
      "http://localhost:8010/static/media/liqicon.4a3d28ab4fbf88d7146f.png",
      "-",
      "-",
      "-",
      "-"
    ),
    createData(
      t("footer_option"),
      "http://localhost:8010/static/media/optionicon.5f939fd9e9a63012d64d.png",
      "-",
      "-",
      "-",
      "-"
    ),
    createData("TOTAL", "", "", "", "", "-"),
  ];
  return (
    <div className="f_wallet-bg">
      <div>
        <FTable
          columns={yieldWalletConfigs}
          data={data}
          type="fund"
          total={true}
        />
      </div>
    </div>
  );
};

export default WYield;
