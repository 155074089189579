import React, { useEffect, useState } from "react";
import { Dropdown, Form, Spinner } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getFundPriceMovementsService } from "../../../services/fund/fundService";
import "./DFundPriceMovement.scss";
import {
  getAllGeneralChartDataRequest,
  getGeneralChartDataRequest,
} from "../../../services/admin/adminService";
import { Line } from "react-chartjs-2";
import { addDays } from "../../../utils/util";
const DFundPriceMovement = () => {
  const { loadingMovements, fundPriceMovements } = useSelector(
    (state) => state.fund
  );
  const { generalChartDataAll, loadingGeneralAll } = useSelector(
    (state) => state.admin
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [time, setTime] = useState("DAY");

  const options = {
    elements: {
      point: {
        radius: 0,
      },
    },
    tooltips: { enabled: false },
    hover: { mode: null },
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: false,
      },
    },
    events: [],

    scales: {
      x: {
        border: {
          display: false,
        },
        grid: {
          display: false,
        },
        ticks: {
          display: false, //this will remove only the label
        },
      },
      y: {
        grid: {
          display: false,
        },
        ticks: {
          display: false, //this will remove only the label
        },
        border: {
          display: false,
        },
      },
    },
  };

  const labels = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
  ];

  const data = {};

  const { t } = useTranslation();

  const logos = [
    "https://dow.capital/static/media/c5icon.a4fc3d13b355bfc93b0f.png",
    "https://dow.capital/static/media/btcicon.f369eb135dd8bed28e0d.png",
    "https://dow.capital/static/media/tradeicon.300070758f7cd253ff5f.png",
    "https://dow.capital/static/media/arbitrageicon.b534faf6f8c6f6c48dd5.png",
  ];

  const links = [
    "/invest/c5-index?id=1",
    "/invest/bitcoin-protected?id=2",
    "/invest/crypto-trading?id=3",
    "/invest/algorithmic?id=4",
  ];

  const coins = ["C5", "BTCp", "TRQ", "QNT"];

  const [startDate, setStartDate] = useState(addDays(new Date(), 7));
  const [endDate, setEndDate] = useState(addDays(new Date(), 0));

  useEffect(() => {
    dispatch(
      getAllGeneralChartDataRequest({
        time: time,
        start: startDate,
        end: endDate,
      })
    );
  }, [time, startDate, endDate]);
  return (
    <div className="d_fundprice">
      <div className="d_fundprice-header">
        <h3>{t("ffundlist_price")}</h3>
        <Form.Select value={time} onChange={(e) => setTime(e.target.value)}>
          <option value={"DAY"}>{t("daily")}</option>
          <option value={"WEEK"}>{t("weekly")}</option>
          <option value={"MONTH"}>{t("monthly")}</option>
        </Form.Select>
      </div>
      <div className="d_fundprice-date">
        <Form.Control
          name="wallet"
          type="date"
          value={startDate}
          onChange={(e) =>
            setStartDate(
              new Date(e.target.value).toLocaleDateString("fr-CA", {
                year: "numeric",
                month: "numeric",
                day: "numeric",
              })
            )
          }
        />
        <span> - </span>
        <Form.Control
          name="wallet"
          type="date"
          value={endDate}
          onChange={(e) =>
            setEndDate(
              new Date(e.target.value).toLocaleDateString("fr-CA", {
                year: "numeric",
                month: "numeric",
                day: "numeric",
              })
            )
          }
        />
      </div>
      {loadingGeneralAll ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "64px",
          }}
        >
          <Spinner animation="grow" style={{ width: "2rem", height: "2rem" }} />
        </div>
      ) : (
        <div className="d_fundprice-tokens">
          {generalChartDataAll &&
            coins?.map((value, index) => {
              const label = generalChartDataAll[value]?.graph[0]?.map(
                (obj) => obj?.time
              );
              const values = generalChartDataAll[value]?.graph[0]?.map((obj) =>
                parseFloat(obj.value)
              );
              return (
                <div className="d_fundprice-card">
                  <div className="d_fundprice-card-l">
                    <div className="d_fundprice-logo">
                      <img src={logos[index]} height="35" />
                    </div>
                    <h4
                      style={{ cursor: "pointer" }}
                      onClick={() => navigate(links[index])}
                    >
                      {value}
                    </h4>
                  </div>
                  <div className="line-canvas" style={{ width: "60px" }}>
                    <Line
                      options={options}
                      data={{
                        labels: label,
                        datasets: [
                          {
                            label: "Dataset 1",
                            data: values,
                            borderWidth: 1,
                            borderColor:
                              generalChartDataAll[value]?.ratio > 0
                                ? "#00B42D"
                                : "#F81506",
                            backgroundColor: "rgba(255, 99, 132, 0.5)",
                          },
                        ],
                      }}
                    />
                  </div>
                  <div className="d_fundprice-card-r">
                    <h4>
                      $
                      {generalChartDataAll[value]?.unitPrice != null
                        ? parseFloat(
                            generalChartDataAll[value]?.unitPrice
                          ).toFixed(2)
                        : "0"}
                    </h4>
                    <h4
                      style={{
                        color:
                          generalChartDataAll[value]?.ratio >= 0
                            ? "#00B42D"
                            : "#F81506",
                      }}
                    >
                      %
                      {generalChartDataAll[value]?.ratio != null
                        ? parseFloat(generalChartDataAll[value]?.ratio).toFixed(
                            2
                          )
                        : "0"}
                    </h4>
                  </div>
                </div>
              );
            })}
        </div>
      )}
    </div>
  );
};

export default DFundPriceMovement;
