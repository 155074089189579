// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.aum {
  background: white;
  border-radius: 10px;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

@media (max-width: 608px) {
  .aum-inner {
    flex: 1 1;
  }
}

@media (max-width: 608px) {
  canvas {
    margin-bottom: -15px;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/AdminComponents/AUM/AUM.scss"],"names":[],"mappings":"AAAA;EACE,iBAAA;EACA,mBAAA;EACA,mBAAA;EACA,aAAA;EACA,8BAAA;EACA,eAAA;AACF;;AAEE;EADF;IAEI,SAAA;EAEF;AACF;;AACE;EADF;IAEI,oBAAA;EAGF;AACF","sourcesContent":[".aum {\n  background: white;\n  border-radius: 10px;\n  margin-bottom: 20px;\n  display: flex;\n  justify-content: space-between;\n  flex-wrap: wrap;\n}\n.aum-inner {\n  @media (max-width: 608px) {\n    flex: 1;\n  }\n}\ncanvas {\n  @media (max-width: 608px) {\n    margin-bottom: -15px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
