// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.wolf_image {
  width: 100vh !important;
  height: calc(100vh - 80px);
  top: 80px;
}`, "",{"version":3,"sources":["webpack://./src/pages/MainLayout/SignUp/SignUp.scss"],"names":[],"mappings":"AAAA;EACE,uBAAA;EACA,0BAAA;EACA,SAAA;AACF","sourcesContent":[".wolf_image {\n  width: 100vh !important;\n  height: calc(100vh - 80px);\n  top: 80px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
