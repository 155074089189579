import React, { useEffect } from "react";
import "./FundAccordion.scss";
import { Accordion, Spinner } from "react-bootstrap";
import FTable from "../../FTable/FTable";
import { C5ReportColumns } from "../../../constants/columnConfigurations";
import { useDispatch, useSelector } from "react-redux";
import { getFundReportsRequest } from "../../../services/admin/adminService";

const FundAccordion = () => {
  const logos = [
    "https://dow.capital/static/media/c5icon.a4fc3d13b355bfc93b0f.png",
    "https://dow.capital/static/media/btcicon.f369eb135dd8bed28e0d.png",
    "https://dow.capital/static/media/tradeicon.300070758f7cd253ff5f.png",
    "https://dow.capital/static/media/arbitrageicon.b534faf6f8c6f6c48dd5.png",
  ];

  function createData(name, amount, price, ratio, total, rebalance) {
    return {
      name,
      amount,
      price,
      ratio,
      total,
      rebalance,
    };
  }

  const rows = [
    createData("BTC", "0.008006452", "24800", "20%", "200", "0%"),
    createData("BTC", "0.008006452", "24800", "20%", "200", "0%"),
    createData("BTC", "0.008006452", "24800", "20%", "200", "0%"),
    createData("BTC", "0.008006452", "24800", "20%", "200", "0%"),
    createData("BTC", "0.008006452", "24800", "20%", "200", "0%"),
    createData("BTC", "0.008006452", "24800", "20%", "200", "0%"),
    createData("TOTAL", "", "", "20%", "200", ""),
  ];

  const C5ReportConfig = C5ReportColumns();

  const { loading, fundReports } = useSelector((state) => state.admin);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getFundReportsRequest());
  }, []);

  return (
    <div className="fund_accordion">
      <Accordion>
        <Accordion.Item eventKey="0">
          <Accordion.Header>
            <div className="d-flex w-100 justify-content-between align-items-center flex-wrap">
              <div className="d-flex align-items-center">
                <img src={logos[0]} width={40} />
                <div>C5 FUND REPORT</div>
              </div>
              <div className="mx-4">
                Number of Tokens:{" "}
                {loading ? (
                  <Spinner animation="grow" size="sm" />
                ) : (
                  <>{fundReports?.C5?.qty}</>
                )}
              </div>
            </div>
          </Accordion.Header>
          <Accordion.Body>
            <div className="fund_accordion-top">
              {fundReports && (
                <FTable
                  wrapperClasses="table-responsive"
                  columns={C5ReportConfig}
                  data={fundReports?.C5?.data}
                  type="all"
                  total="asd"
                />
              )}
            </div>
            <div className="fund_accordion-bottom">
              <h3>C5 Fund Price $ : {fundReports?.C5?.price} </h3>
              <h3>Estimated Man Fee (2%) $ : 20</h3>
              <h3>Number of Customers : {fundReports?.C5?.userCount}</h3>
              <h3>Estimated Rebalance Commission: 20$</h3>
            </div>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="1">
          <Accordion.Header>
            <div className="d-flex w-100 justify-content-between align-items-center flex-wrap">
              <div className="d-flex align-items-center">
                <img src={logos[1]} width={40} />
                <div>BTCp FUND REPORT</div>
              </div>
              <div className="mx-4">
                Number of Tokens:{" "}
                {loading ? (
                  <Spinner animation="grow" size="sm" />
                ) : (
                  <>{fundReports?.BTC?.qty}</>
                )}
              </div>
            </div>
          </Accordion.Header>
          <Accordion.Body>
            <div className="fund_accordion-top">
              {fundReports && (
                <FTable
                  wrapperClasses="table-responsive"
                  columns={C5ReportConfig}
                  data={fundReports?.BTC?.data}
                  type="all"
                  total="asd"
                />
              )}
            </div>
            <div className="fund_accordion-bottom">
              <h3>BTCp Fund Price $ : {fundReports?.BTC?.price} </h3>
              <h3>Estimated Man Fee (2%) $ : 20</h3>
              <h3>Number of Customers : {fundReports?.BTC?.userCount}</h3>
              <h3>Estimated Rebalance Commission: 20$</h3>
            </div>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="2">
          <Accordion.Header>
            <div className="d-flex w-100 justify-content-between align-items-center flex-wrap">
              <div className="d-flex align-items-center">
                <img src={logos[2]} width={40} />
                <div>TRQ FUND REPORT</div>
              </div>
              <div className="mx-4">
                Number of Tokens:{" "}
                {loading ? (
                  <Spinner animation="grow" size="sm" />
                ) : (
                  <>{fundReports?.TRQ?.qty}</>
                )}
              </div>
            </div>
          </Accordion.Header>
          <Accordion.Body>
            <div className="fund_accordion-top">
              {fundReports && (
                <FTable
                  wrapperClasses="table-responsive"
                  columns={C5ReportConfig}
                  data={fundReports?.TRQ?.data}
                  type="all"
                  total="asd"
                />
              )}
            </div>
            <div className="fund_accordion-bottom">
              <h3>TRQ Fund Price $ : {fundReports?.TRQ?.price} </h3>
              <h3>Estimated Man Fee (2%) $ : 20</h3>
              <h3>Number of Customers : {fundReports?.TRQ?.userCount}</h3>
              <h3>Estimated Rebalance Commission: 20$</h3>
            </div>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="3">
          <Accordion.Header>
            <div className="d-flex w-100 justify-content-between align-items-center flex-wrap">
              <div className="d-flex align-items-center">
                <img src={logos[3]} width={40} />
                <div>QNT FUND REPORT</div>
              </div>
              <div className="mx-4">
                Number of Tokens:{" "}
                {loading ? (
                  <Spinner animation="grow" size="sm" />
                ) : (
                  <>{fundReports?.QNT?.qty ? fundReports?.QNT?.qty : 0}</>
                )}
              </div>
            </div>
          </Accordion.Header>
          <Accordion.Body>
            <div className="fund_accordion-top">
              {fundReports && (
                <FTable
                  wrapperClasses="table-responsive"
                  columns={C5ReportConfig}
                  data={fundReports?.QNT?.data}
                  type="all"
                  total="asd"
                />
              )}
            </div>
            <div className="fund_accordion-bottom">
              <h3>QNT Fund Price $ : {fundReports?.QNT?.price} </h3>
              <h3>Estimated Man Fee (2%) $ : 20</h3>
              <h3>Number of Customers : {fundReports?.QNT?.userCount}</h3>
              <h3>Estimated Rebalance Commission: 20$</h3>
            </div>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </div>
  );
};

export default FundAccordion;
