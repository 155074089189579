// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.f_checkbox {
  font-size: 13.88px;
  color: var(--text-status);
  font-weight: 500;
  line-height: 16.8px;
}
.f_checkbox span {
  border-bottom: 1px solid #898989;
}`, "",{"version":3,"sources":["webpack://./src/components/FCheckbox/FCheckbox.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,yBAAA;EACA,gBAAA;EACA,mBAAA;AACF;AAAE;EACE,gCAAA;AAEJ","sourcesContent":[".f_checkbox {\n  font-size: 13.88px;\n  color: var(--text-status);\n  font-weight: 500;\n  line-height: 16.8px;\n  span {\n    border-bottom: 1px solid #898989;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
