import React, { useEffect } from 'react';
import './MainLayout.scss';
import { Outlet } from 'react-router-dom';
import FLandingNavbar from '../../components/FLandingNavbar/FLandingNavbar';
import FFooter from '../../components/FFooter/FFooter';
import { Container } from 'react-bootstrap';

const MainLayout = ({ switchTheme, theme }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div
      style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}
    >
      <div style={{ flex: 1 }}>
        <FLandingNavbar switchTheme={switchTheme} theme={theme} />
        <Outlet />
      </div>
      <FFooter />
    </div>
  );
};

export default MainLayout;
