import { createChart, ColorType } from "lightweight-charts";
import React, { useEffect, useRef } from "react";

export const FChart = (props) => {
  let primaryColor;
  let secondaryColor;

  if (props.token == "C5") {
    primaryColor = "rgb(116,125,128)";
    secondaryColor = "rgba(116,125,128, 0.28)";
  } else if (props.token == "BTCp") {
    primaryColor = "rgb(242,169,0)";
    secondaryColor = "rgba(242,169,0, 0.28)";
  } else if (props.token == "TRQ") {
    primaryColor = "rgb(105,194,231)";
    secondaryColor = "rgba(105,194,231, 0.28)";
  } else if (props.token == "QNT") {
    primaryColor = "rgb(228,55,150)";
    secondaryColor = "rgba(228,55,150, 0.28)";
  }

  const {
    data,
    colors: {
      backgroundColor = "white",
      lineColor = primaryColor,
      textColor = "black",
      areaTopColor = primaryColor,
      areaBottomColor = secondaryColor,
    } = {},
  } = props;

  const chartContainerRef = useRef();

  useEffect(() => {
    const handleResize = () => {
      chart.applyOptions({ width: chartContainerRef.current.clientWidth });
    };

    const chart = createChart(chartContainerRef.current, {
      layout: {
        background: { type: ColorType.Solid, color: backgroundColor },
        textColor,
      },
      width: chartContainerRef.current.clientWidth,
      height: 300,
    });
    chart.timeScale().fitContent();

    const newSeries = chart.addAreaSeries({
      lineColor,
      topColor: areaTopColor,
      bottomColor: areaBottomColor,
      priceFormat: {
        type: "custom",
        formatter: (price) => {
          return price.toFixed(4);
        },
      },
    });
    newSeries.setData(data);

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);

      chart.remove();
    };
  }, [
    data,
    backgroundColor,
    lineColor,
    textColor,
    areaTopColor,
    areaBottomColor,
  ]);

  return <div ref={chartContainerRef} />;
};
