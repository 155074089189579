import React, { useEffect, useState } from "react";
import "./LandingPage.scss";
import { useTranslation } from "react-i18next";
import heroimage from "../../../assets/images/hero.png";
import fundsbg from "../../../assets/images/fundsbg.png";
import bluebg from "../../../assets/images/bluebg.png";
import bluebg2 from "../../../assets/images/bluebg2.png";
import c5landing from "../../../assets/images/c5landing.png";

import cryptolanding from "../../../assets/images/cryptolanding.png";
import btclanding from "../../../assets/images/btclanding.png";
import stakinglanding from "../../../assets/images/stakinglanding.png";
import arbitragelanding from "../../../assets/images/arbitragelanding.png";
import landingmockup1 from "../../../assets/images/landingmockup1.png";
import landingmockup2 from "../../../assets/images/landingmockup2.png";
import landingmockup3 from "../../../assets/images/landingmockup3.png";
import barissozen from "../../../assets/images/barissozen.png";
import team1 from "../../../assets/images/team1.png";
import custody from "../../../assets/images/custody.png";
import mpc from "../../../assets/images/mpc.png";
import proof from "../../../assets/images/proof.png";
import ozan from "../../../assets/images/ozan.jpg";
import sabrina from "../../../assets/images/sabrina.jpeg";
import utku from "../../../assets/images/utku.jpeg";
import mustafa from "../../../assets/images/mustafa.jpg";
import ercan from "../../../assets/images/ercan.png";

import { Button, Carousel, Container, Modal } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import i18next from "i18next";
const LandingPage = () => {
  const [index, setIndex] = useState(0);
  const [width, setWidth] = useState(window.innerWidth);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [show2, setShow2] = useState(false);
  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);
  const [show3, setShow3] = useState(false);
  const handleClose3 = () => setShow3(false);
  const handleShow3 = () => setShow3(true);
  const [show4, setShow4] = useState(false);
  const handleClose4 = () => setShow4(false);
  const handleShow4 = () => setShow4(true);
  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };
  const { t } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    const handleWindowResize = () => {
      setWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);
  const getLanguage = i18next.language || window.localStorage.i18nextLng;

  return (
    <div>
      <div className="landing-hero">
        <div className="landing-hero-left container">
          <h1>{t("landing_welcome")}</h1>
          <h2>{t("landing_title_first")}</h2>
          <h2>{t("landing_title_second")}</h2>
          <Button
            className="right_side-launch"
            onClick={() => {
              navigate("/dashboard");
            }}
          >
            {t("flanding_start_invest")}
          </Button>
          <h3>{t("landing_title_third")}</h3>
        </div>
        <div className="landing-hero-right">
          <img src={heroimage} height="410" />
        </div>
      </div>
      <div
        className="landing-funds"
        style={{
          backgroundImage: `url(${fundsbg})`,
          backgroundSize: "600px auto",
          backgroundRepeat: "no-repeat",
          backgroundPositionX: "center",
          backgroundPositionY: "100px",
        }}
      >
        {getLanguage === "tr" ? (
          <h1>FONLAR</h1>
        ) : (
          <h1>
            OUR <span>FUNDS</span>
          </h1>
        )}
        <div className="landing-funds-details">
          <div className="landing-funds-details-left">
            <div>
              <img src={c5landing} width={160} />
              <h1>{t("flanding_c5")}</h1>
              <h2>{t("flanding_c5_d")}</h2>
              <button
                className="right_side-launch"
                onClick={() => {
                  navigate("/funds/c5-index?id=1");
                }}
              >
                {t("flanding_start_now")}
              </button>
            </div>
            <div>
              <img src={btclanding} width={140} />
              <h1 style={{ whiteSpace: "pre-line" }}>
                {t("flanding_bitcoin")}
              </h1>
              <h2>{t("flanding_bitcoin_d")}</h2>
              <button
                className="right_side-launch"
                onClick={() => {
                  navigate("/funds/bitcoin-protected?id=2");
                }}
              >
                {t("flanding_start_now")}
              </button>
            </div>
          </div>
          <div className="landing-funds-details-right">
            <div>
              <img src={cryptolanding} width={140} />
              <h1>{t("flanding_trading")}</h1>
              <h2>{t("flanding_trading_d")}</h2>
              <button
                className="right_side-launch"
                onClick={() => {
                  navigate("/funds/crypto-trading?id=3");
                }}
              >
                {t("flanding_start_now")}
              </button>
            </div>
            <div>
              <img src={arbitragelanding} width={140} />
              <h1>{t("flanding_arbitrage")}</h1>
              <h2>{t("flanding_arbitrage_d")}</h2>
              <button
                className="right_side-launch"
                onClick={() => {
                  navigate("/funds/algorithmic?id=4");
                }}
              >
                {t("flanding_start_now")}
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="landing-hub">
        {width >= 767 && (
          <>
            <div className="landing-hub-title container text-uppercase">
              {t("flanding_yield")}
            </div>
            <div
              className="landing-hub-itemleft"
              style={{
                backgroundImage: `url(${bluebg})`,
                backgroundSize: "70% 100%",
                backgroundRepeat: "no-repeat",
              }}
            >
              <div className="container">
                <div className="landing-hub-itemleft-l">
                  <h1>{t("flanding_leveraged")}</h1>
                  <h2>{t("flanding_leveraged_d")}</h2>
                  <Button
                    className="right_side-launch"
                    onClick={() => {
                      navigate("/yield-hub/leveraged-staking?id=5");
                    }}
                  >
                    {t("flanding_start_now")}
                  </Button>
                </div>
                <div className="landing-hub-itemleft-r">
                  <svg
                    width="147"
                    height="147"
                    viewBox="0 0 147 147"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M73.5 128.625C103.945 128.625 128.625 103.945 128.625 73.5C128.625 43.0553 103.945 18.375 73.5 18.375C43.0553 18.375 18.375 43.0553 18.375 73.5C18.375 103.945 43.0553 128.625 73.5 128.625Z"
                      stroke="#4169E1"
                      stroke-width="3"
                    />
                    <path
                      d="M73.5 87.2812C81.1112 87.2812 87.2812 81.1112 87.2812 73.5C87.2812 65.8888 81.1112 59.7188 73.5 59.7188C65.8888 59.7188 59.7188 65.8888 59.7188 73.5C59.7188 81.1112 65.8888 87.2812 73.5 87.2812Z"
                      stroke="#4169E1"
                      stroke-width="3"
                    />
                    <path
                      d="M114.844 73.5H87.2812"
                      stroke="#4169E1"
                      stroke-width="3"
                    />
                  </svg>
                </div>
              </div>
            </div>
            <div
              className="landing-hub-itemleft"
              style={{
                backgroundImage: `url(${bluebg2})`,
                backgroundSize: "70% 100%",
                backgroundRepeat: "no-repeat",
              }}
            >
              <div className="container">
                <div className="landing-hub-itemleft-l">
                  <h1>{t("flanding_liquidity")}</h1>
                  <h2>{t("flanding_liquidity_d")}</h2>
                  <Button
                    className="right_side-launch"
                    onClick={() => {
                      navigate("/yield-hub/liquidty-providing?id=6");
                    }}
                  >
                    {t("flanding_start_now")}
                  </Button>
                </div>
                <div className="landing-hub-itemleft-r">
                  <svg
                    width="167"
                    height="167"
                    viewBox="0 0 167 167"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M69.5833 76.5416L97.4167 104.375L69.5833 132.208"
                      stroke="#4169E1"
                      stroke-width="3"
                    />
                    <path
                      d="M67.2915 42.8169C51.7437 44.8999 38.397 49.8962 30.0213 56.7689C21.6456 63.6416 18.884 71.8629 22.3097 79.7265C25.7355 87.5901 35.0854 94.4927 48.4191 99.001C61.7528 103.509 78.046 105.277 93.918 103.939"
                      stroke="#4169E1"
                      stroke-width="3"
                      stroke-linecap="round"
                    />
                    <path
                      d="M137.735 88.7187C142.538 84.559 145.362 79.9032 145.991 75.1103C146.619 70.3174 145.034 65.5171 141.358 61.0797C137.682 56.6423 132.013 52.6873 124.791 49.5205C117.569 46.3537 108.987 44.06 99.7087 42.8169"
                      stroke="#4169E1"
                      stroke-width="3"
                      stroke-linecap="round"
                    />
                  </svg>
                </div>
              </div>
            </div>
            <div
              className="landing-hub-itemleft"
              style={{
                backgroundImage: `url(${bluebg})`,
                backgroundSize: "70% 100%",
                backgroundRepeat: "no-repeat",
              }}
            >
              <div className="container">
                <div className="landing-hub-itemleft-l">
                  <h1>{t("flanding_option")}</h1>
                  <h2>{t("flanding_option_d")}</h2>
                  <Button
                    className="right_side-launch"
                    onClick={() => {
                      navigate("/yield-hub/option-strategies?id=7");
                    }}
                  >
                    {t("flanding_start_now")}
                  </Button>
                </div>
                <div className="landing-hub-itemleft-r">
                  <svg
                    width="126"
                    height="126"
                    viewBox="0 0 126 126"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clip-path="url(#clip0_3_1188)">
                      <path
                        d="M102.375 123.48H27.5625C26.7789 123.48 26.145 122.846 26.145 122.063V115.172C26.145 105.163 34.2878 97.02 44.2969 97.02H44.9544L38.1071 83.3214C37.8788 82.8686 37.9142 82.3253 38.1938 81.9039L59.9918 49.203L28.7831 54.5501C28.1649 54.6564 27.5546 54.3454 27.2751 53.7863L22.3532 43.9425C22.0579 43.3558 22.2075 42.6392 22.7115 42.2179L52.2428 17.6085C52.4948 17.3959 52.8176 17.2817 53.1484 17.2817H61.5746V3.93357C61.5746 3.40988 61.8621 2.93344 62.3188 2.68538C62.7756 2.44125 63.3347 2.46488 63.7718 2.75232L82.0063 14.8247C88.3221 21.1011 95.9057 28.6886 95.9057 45.5451V82.6875C95.9057 82.9946 95.8073 83.2899 95.6222 83.538L85.5225 97.02H85.6406C95.6497 97.02 103.793 105.163 103.793 115.172V122.063C103.793 122.846 103.159 123.48 102.375 123.48ZM28.98 120.645H100.957V115.172C100.957 106.726 94.0866 99.855 85.6406 99.855H44.2969C35.8509 99.855 28.98 106.726 28.98 115.172V120.645ZM48.1281 97.02H81.9827L93.0825 82.215V76.23H82.6875V73.395H93.0825V60.48H82.6875V57.645H93.0825V45.549C93.0825 45.2734 93.0825 44.9978 93.0786 44.73H82.6875V41.895H92.9486C91.9761 28.7595 85.7627 22.5461 80.2305 17.0139L64.4175 6.57169V18.7031C64.4175 19.4867 63.7836 20.1206 63 20.1206H53.6681L25.3969 43.6826L29.3462 51.5813L62.7598 45.8522C63.3308 45.7616 63.882 46.0058 64.1891 46.4783C64.5002 46.9547 64.4963 47.565 64.1813 48.0375L41.0169 82.7899L48.1281 97.02Z"
                        fill="#4169E1"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_3_1188">
                        <rect width="126" height="126" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                </div>
              </div>
            </div>
          </>
        )}
        {width < 767 && (
          <div className="landing-hub-mobile container">
            <div className="landing-hub-title container">
              {t("flanding_yield")}
            </div>
            <div className="landing-hub-mobile-item">
              <svg
                width="101"
                height="102"
                viewBox="0 0 101 102"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M50.5 88.875C71.4178 88.875 88.375 71.9178 88.375 51C88.375 30.0822 71.4178 13.125 50.5 13.125C29.5822 13.125 12.625 30.0822 12.625 51C12.625 71.9178 29.5822 88.875 50.5 88.875Z"
                  stroke="#4169E1"
                  stroke-width="3"
                />
                <path
                  d="M50.5 60.4688C55.7294 60.4688 59.9688 56.2294 59.9688 51C59.9688 45.7706 55.7294 41.5312 50.5 41.5312C45.2706 41.5312 41.0312 45.7706 41.0312 51C41.0312 56.2294 45.2706 60.4688 50.5 60.4688Z"
                  stroke="#4169E1"
                  stroke-width="3"
                />
                <path
                  d="M78.9062 51H59.9688"
                  stroke="#4169E1"
                  stroke-width="3"
                />
              </svg>

              <h1>{t("flanding_leveraged")}</h1>
              <h2>{t("flanding_leveraged_d")}</h2>
              <Button
                className="right_side-launch"
                style={{ width: "125px" }}
                onClick={() => {
                  navigate("/yield-hub/leveraged-staking?id=5");
                }}
              >
                {t("flanding_start_now")}
              </Button>
            </div>
            <div className="landing-hub-mobile-item">
              <svg
                width="133"
                height="132"
                viewBox="0 0 133 132"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M55.5 60.5L77.5 82.5L55.5 104.5"
                  stroke="#4169E1"
                  stroke-width="3"
                />
                <path
                  d="M53.6885 33.8433C41.3993 35.4897 30.8498 39.4389 24.2295 44.8712C17.6091 50.3035 15.4263 56.8017 18.1341 63.0173C20.8418 69.2328 28.2322 74.6888 38.7715 78.2523C49.3107 81.8157 62.1891 83.2133 74.7346 82.1551"
                  stroke="#4169E1"
                  stroke-width="3"
                  stroke-linecap="round"
                />
                <path
                  d="M109.368 70.1249C113.165 66.837 115.397 63.157 115.894 59.3686C116.39 55.5802 115.138 51.7859 112.232 48.2785C109.326 44.7712 104.846 41.645 99.1375 39.1419C93.429 36.6388 86.6453 34.8258 79.3116 33.8433"
                  stroke="#4169E1"
                  stroke-width="3"
                  stroke-linecap="round"
                />
              </svg>

              <h1>{t("flanding_liquidity")}</h1>
              <h2>{t("flanding_liquidity_d")}</h2>
              <Button
                className="right_side-launch"
                onClick={() => {
                  navigate("/yield-hub/liquidty-providing?id=6");
                }}
                style={{ width: "125px" }}
              >
                {t("flanding_start_now")}
              </Button>
            </div>
            <div className="landing-hub-mobile-item">
              <svg
                width="67"
                height="98"
                viewBox="0 0 67 98"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M65.3533 98H4.83568C4.20184 98 3.68903 97.4864 3.68903 96.8516V91.269C3.68903 83.1599 10.2759 76.5628 18.3725 76.5628H18.9044L13.3655 65.4646C13.1807 65.0978 13.2094 64.6576 13.4356 64.3162L31.0685 37.8228L5.82307 42.1549C5.323 42.2411 4.82931 41.989 4.60316 41.5361L0.621737 33.5609C0.382852 33.0856 0.503887 32.505 0.911585 32.1637L24.8001 12.2259C25.004 12.0536 25.2652 11.9611 25.5327 11.9611H32.3489V1.1468C32.3489 0.722522 32.5814 0.336526 32.9509 0.135553C33.3204 -0.0622307 33.7727 -0.0430903 34.1262 0.189784L48.8766 9.97049C53.9856 15.0554 60.1202 21.2027 60.1202 34.8593V64.951C60.1202 65.1999 60.0405 65.4391 59.8908 65.6401L51.7209 76.5628H51.8165C59.9131 76.5628 66.5 83.1599 66.5 91.269V96.8516C66.5 97.4864 65.9872 98 65.3533 98ZM5.98233 95.7032H64.2067V91.269C64.2067 84.4263 58.6486 78.8597 51.8165 78.8597H18.3725C11.5404 78.8597 5.98233 84.4263 5.98233 91.269V95.7032ZM21.4717 76.5628H48.8575L57.8364 64.5682V59.7194H49.4276V57.4225H57.8364V46.9592H49.4276V44.6623H57.8364V34.8625C57.8364 34.6392 57.8364 34.4159 57.8332 34.1989H49.4276V31.9021H57.7281C56.9414 21.2601 51.9152 16.2262 47.4401 11.7442L34.6486 3.28414V13.1127C34.6486 13.7475 34.1358 14.2611 33.5019 14.2611H25.9532L3.08385 33.3504L6.27855 39.7496L33.3077 35.1081C33.7695 35.0347 34.2154 35.2325 34.4639 35.6153C34.7155 36.0013 34.7123 36.4958 34.4575 36.8786L15.7193 65.034L21.4717 76.5628Z"
                  fill="#4169E1"
                />
              </svg>

              <h1>{t("flanding_option")}</h1>
              <h2>{t("flanding_option_d")}</h2>
              <Button
                className="right_side-launch"
                onClick={() => {
                  navigate("/yield-hub/option-strategies?id=7");
                }}
                style={{ width: "125px" }}
              >
                {t("flanding_start_now")}
              </Button>
            </div>
          </div>
        )}
      </div>
      <div className="landing-works">
        <div className="container">
          <h1>{t("flanding_how")}</h1>
          <Carousel
            activeIndex={index}
            onSelect={handleSelect}
            indicators={false}
            interval={null}
          >
            <Carousel.Item>
              <h2>1</h2>
              <div className="carousel-content">
                <img src={landingmockup1} />
                <div>
                  <h3>{t("flanding_register")}</h3>
                  <h4>{t("flanding_register_d")}</h4>
                  <div className="carousel_button">
                    <Button
                      className="right_side-launch"
                      onClick={() => {
                        navigate("/dashboard");
                      }}
                    >
                      {t("flanding_start_invest")}
                    </Button>
                  </div>
                </div>
              </div>
            </Carousel.Item>
            <Carousel.Item>
              <h2>2</h2>
              <div className="carousel-content">
                <img src={landingmockup2} />
                <div>
                  <h3>{t("flanding_deposit")}</h3>
                  <h4>{t("flanding_deposit_d")}</h4>
                  <div className="carousel_button">
                    <Button
                      className="right_side-launch"
                      onClick={() => {
                        navigate("/dashboard");
                      }}
                    >
                      {t("flanding_start_invest")}
                    </Button>
                  </div>
                </div>
              </div>
            </Carousel.Item>
            <Carousel.Item>
              <h2>3</h2>
              <div className="carousel-content">
                <img src={landingmockup3} />
                <div>
                  <h3>{t("flanding_invest")}</h3>
                  <h4>{t("flanding_invest_d")}</h4>
                  <div className="carousel_button">
                    <Button
                      className="right_side-launch"
                      onClick={() => {
                        navigate("/dashboard");
                      }}
                    >
                      {t("flanding_start_invest")}
                    </Button>
                  </div>
                </div>
              </div>
            </Carousel.Item>
          </Carousel>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          background: "#fff",
        }}
      >
        <div
          style={{
            fontSize: "24px",
            fontWeight: 500,
            color: "#4169E1",
            marginTop: "32px",
            marginBottom: "32px",
          }}
        >
          {t("flanding_who")}
        </div>
      </div>
      <div className="landing-who container">
        <div className="landing-who-hero ">
          <img src={barissozen} width={310} height={400} />
          <div className="landing-who-hero-desc">
            <h3>"{t("flanding_bs")}"</h3>
            <h4>
              Barış Sözen
              <br />
              CEO
            </h4>
          </div>
        </div>
        <div className="landing-who-team">
          <div
            className="landing-who-team-item"
            onClick={handleShow}
            style={{ cursor: "pointer" }}
          >
            <img
              src={ozan}
              width="100"
              height="100"
              style={{ borderRadius: "100%", filter: "grayscale(100%)" }}
            />
            <h3>Ozan İmamoğlu</h3>
            <h4>Fund Manager</h4>
          </div>

          <div
            className="landing-who-team-item"
            onClick={handleShow2}
            style={{ cursor: "pointer" }}
          >
            <img
              src={ercan}
              width="100"
              height="100"
              style={{ borderRadius: "100%", filter: "grayscale(100%)" }}
            />
            <h3>Ercan Işık</h3>
            <h4>CTO</h4>
          </div>

          <div
            className="landing-who-team-item"
            onClick={handleShow3}
            style={{ cursor: "pointer" }}
          >
            <img
              src={utku}
              width="100"
              height="100"
              style={{ borderRadius: "100%", filter: "grayscale(100%)" }}
            />
            <h3>Utku Fırat Ertürk</h3>
            <h4>Quantitative Researcher</h4>
          </div>

          <div
            className="landing-who-team-item"
            onClick={handleShow4}
            style={{ cursor: "pointer" }}
          >
            <img
              src={mustafa}
              width="100"
              height="100"
              style={{ borderRadius: "100%", filter: "grayscale(100%)" }}
            />
            <h3>Mustafa Ünal</h3>
            <h4>Advisor</h4>
          </div>
        </div>
      </div>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <img
            src={ozan}
            width="70"
            height="70"
            style={{
              borderRadius: "100%",
              filter: "grayscale(100%)",
              marginRight: "20px",
            }}
          />
          <Modal.Title>Ozan İmamoğlu</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ fontStyle: "italic" }}>
          {t("flanding_oi")}
          <a
            href="https://www.linkedin.com/in/ozan-imamoglu-1323255"
            target="_blank"
            style={{ display: "block", marginTop: "20px" }}
          >
            <img
              width={20}
              height={20}
              src="https://cdn-icons-png.flaticon.com/512/174/174857.png"
            ></img>
          </a>
        </Modal.Body>
      </Modal>
      <Modal show={show2} onHide={handleClose2}>
        <Modal.Header closeButton>
          <img
            src={ercan}
            width="70"
            height="70"
            style={{
              borderRadius: "100%",
              filter: "grayscale(100%)",
              marginRight: "20px",
            }}
          />
          <Modal.Title>Ercan Işık</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ fontStyle: "italic" }}>
          I am a highly experienced and respected professional with over 15
          years of experience in the field of blockchain, system architecture,
          and start-up leadership. I have a strong technical background and a
          proven track record as a serial entrepreneur, having successfully
          founded and led several start-ups in the past. In my current role as
          CEO of a blockchain-based start-up, I am responsible for overseeing
          all aspects of the business, including product development, strategy,
          and team management. As a team lead with excellent communication and
          leadership skills, I have a talent for building and motivating
          high-performing teams, and have a reputation for driving results and
          delivering on business objectives. Throughout my career, I have
          consistently demonstrated the ability to successfully navigate complex
          technical challenges and deliver innovative solutions that drive
          business growth and success.
          <a
            href="https://www.linkedin.com/in/ercan-isik/"
            target="_blank"
            style={{ display: "block", marginTop: "20px" }}
          >
            <img
              width={20}
              height={20}
              src="https://cdn-icons-png.flaticon.com/512/174/174857.png"
            ></img>
          </a>
        </Modal.Body>
      </Modal>
      <Modal show={show3} onHide={handleClose3}>
        <Modal.Header closeButton>
          <img
            src={utku}
            width="70"
            height="70"
            style={{
              borderRadius: "100%",
              filter: "grayscale(100%)",
              marginRight: "20px",
            }}
          />
          <Modal.Title>Utku Fırat Ertürk</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ fontStyle: "italic" }}>
          Utku Fırat Ertürk holds a Bachelor of Science degree in Mechanical
          Engineering. Since 2017, he has been researching the cryptocurrency
          ecosystem with a primary focus on liquidity and cost of carry. Since
          2022, he has been actively engaged in research and development
          activities related to perpetual contracts. At Dow Capital, he is
          involved in implementing the cash & carry arbitrage strategy, as well
          as data procurement and organization for Option Vaults and Constant
          Function Market Maker applications. He provides support for the
          development of products in these areas. Utku is fluent in Turkish
          (native), proficient in English, and has a basic understanding of
          German (A2 level).
          <a
            href="https://www.linkedin.com/in/utkufiraterturk/"
            target="_blank"
            style={{ display: "block", marginTop: "20px" }}
          >
            <img
              width={20}
              height={20}
              src="https://cdn-icons-png.flaticon.com/512/174/174857.png"
            ></img>
          </a>
        </Modal.Body>
      </Modal>
      <Modal show={show4} onHide={handleClose4}>
        <Modal.Header closeButton>
          <img
            src={mustafa}
            width="70"
            height="70"
            style={{
              borderRadius: "100%",
              filter: "grayscale(100%)",
              marginRight: "20px",
            }}
          />
          <Modal.Title>Mustafa Ünal</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ fontStyle: "italic" }}>
          "TBD"
          {/*<a
            href="https://www.linkedin.com/in/sabrina-kihm-43b6533a"
            target="_blank"
            style={{ display: "block", marginTop: "20px" }}
          >
            <img
              width={20}
              height={20}
              src="https://cdn-icons-png.flaticon.com/512/174/174857.png"
            ></img>
          </a>*/}
        </Modal.Body>
      </Modal>
      <div className="landing-info container">
        <div className="landing-info-item">
          <img src={custody} height={60} />
          <h3>{t("flanding_q_one_t")}</h3>
          <h4>{t("flanding_q_one_d")}</h4>
        </div>
        <div className="landing-info-item">
          <img src={mpc} height={60} /> <h3>{t("flanding_q_two_t")}</h3>
          <h4>{t("flanding_q_two_d")}</h4>
        </div>
        <div className="landing-info-item">
          <img src={proof} height={60} /> <h3>{t("flanding_q_three_t")}</h3>
          <h4>{t("flanding_q_three_d")}</h4>
        </div>
      </div>
      <div className="landing-learn">
        <h2
          onClick={() => navigate("/news/newsDetails?id=65")}
          style={{ cursor: "pointer" }}
        >
          {t("flanding_q_button")}{" "}
          <svg
            width="21"
            height="16"
            viewBox="0 0 21 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M20.7071 8.70711C21.0976 8.31658 21.0976 7.68342 20.7071 7.29289L14.3431 0.928932C13.9526 0.538408 13.3195 0.538408 12.9289 0.928932C12.5384 1.31946 12.5384 1.95262 12.9289 2.34315L18.5858 8L12.9289 13.6569C12.5384 14.0474 12.5384 14.6805 12.9289 15.0711C13.3195 15.4616 13.9526 15.4616 14.3431 15.0711L20.7071 8.70711ZM0 9H20V7H0V9Z"
              fill="#4169E1"
            />
          </svg>
        </h2>
      </div>
      <div className="landing-ref">
        <div className="landing-ref-inner">
          <img
            src="https://cdn.cookielaw.org/logos/99f99939-5a0d-4feb-aef3-ba45f16b6907/9a108bbb-e66a-476f-baba-dacc1c961fcf/ffadb3dd-5165-4b26-8b19-4081b671aec7/ceffu-blk@2x.png"
            height="37"
          />
          <img
            src="https://upload.wikimedia.org/wikipedia/commons/thumb/1/12/Binance_logo.svg/1280px-Binance_logo.svg.png"
            height="37"
          ></img>
        </div>
      </div>
    </div>
  );
};

export default LandingPage;

/*
<div className="landing_container">
          <div className="landing_container_text">
            <div className="landing_container_text-headline">
              <>
                {t("landing_title_first") + " "}
                <span style={{ color: "var(--text-secondary)" }}>
                  {t("landing_title_second")}
                </span>
              </>
            </div>
            <div className="landing_container_text-desc">
              {t("landing_title_third")}
            </div>
            <div className="landing_container_text-subline">
              <>
                <b>{t("landing_hero_title")}</b>
                <div>
                  <h4>{t("landing_tokenised")}</h4>
                  <h5>{t("landing_c5")}</h5>

                  <h5>{t("landing_bitcoin")}</h5>

                  <h5>{t("landing_hedge")}</h5>

                  <h5>{t("landing_arbitrage")}</h5>
                  <br></br>
                  <h4>{t("landing_yield_hub")}</h4>

                  <h5>{t("landing_leveraged")}</h5>

                  <h5>{t("landing_liquidity")}</h5>

                  <h5>{t("landing_option")}</h5>
                </div>
              </>
            </div>
          </div>
          <img
            className="landing_container_text-headline_image"
            src={landingpage1}
          ></img>
        </div>
        <div className="landing_second_container">
          <img src={landingpage2}></img>
          <div className="landing_second_container_text">
            <div className="landing_second_container_text-headline">
              <span
                style={{
                  color: "var(--text-primary)",
                  fontWeight: 700,
                  fontSize: "24px",
                }}
              >
                {t("landing_team_title")}
              </span>
            </div>
            <div className="landing_second_container_text-subline">
              <>
                <h5> {t("landing_team_item1")}</h5>

                <h5> {t("landing_team_item2")}</h5>

                <h5> {t("landing_team_item3")}</h5>
              </>
            </div>
            <div style={{ fontWeight: "normal" }}>{t("landing_team_desc")}</div>
          </div>
        </div>
        <div className="landing_third_container">
          <Container>
            <h1> {t("landing_faq")}</h1>
            <Accordion>
              <Accordion.Item eventKey="0">
                <Accordion.Header>{t("landing_faq_title1")}</Accordion.Header>
                <Accordion.Body>{t("landing_faq_item1")}</Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="1">
                <Accordion.Header>{t("landing_faq_title2")}</Accordion.Header>
                <Accordion.Body>
                  {t("landing_faq_item2")}{" "}
                  <a
                    href="https://www.qredo.com/blog/what-is-multi-party-computation-mpc"
                    target="blank"
                    rel="noopener noreferrer"
                  >
                    dMPC
                  </a>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="2">
                <Accordion.Header>{t("landing_faq_title3")}</Accordion.Header>
                <Accordion.Body>{t("landing_faq_item3")}</Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="3">
                <Accordion.Header>{t("landing_faq_title4")}</Accordion.Header>
                <Accordion.Body>{t("landing_faq_item4")}</Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="4">
                <Accordion.Header>{t("landing_faq_title5")}</Accordion.Header>
                <Accordion.Body>{t("landing_faq_item5")}</Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="5">
                <Accordion.Header>{t("landing_faq_title6")}</Accordion.Header>
                <Accordion.Body>{t("landing_faq_item6")} </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="6">
                <Accordion.Header>{t("landing_faq_title7")} </Accordion.Header>
                <Accordion.Body>{t("landing_faq_item7")}</Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </Container>
        </div>
*/
