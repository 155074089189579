import * as actions from "../../actions";

const initialState = {
  fundList: [],
  fundFee: 0,
  postFundResult: [],
  buyFundResult: null,
  buyFundStatus: null,
  withdrawFundResult: null,
  withdrawFundStatus: null,
  redeemFundResult: null,
  redeemFundStatus: null,
  loading: false,
  loadingFunds: false,
  count: 0,
  updateFundResult: [],
  fundPrice: 0,
  error: null,
  lastPrices: {},
  txList: [],
  loadingTxList: false,
  countFunds: 0,
  fund: {},
  fundPriceMovements: [],
  loadingMovements: false,
};

const fund = (state = initialState, action) => {
  switch (action.type) {
    case actions.GET_FUNDS_SERVICE:
      return {
        ...state,
        loadingFunds: true,
        fundList: [],
        error: null,
        postFundResult: [],
        updateFundResult: [],
        countFunds: 0,
      };
    case actions.GET_FUNDS_SERVICE_SUCCEED:
      return {
        ...state,
        fundList: [...action.payload.data.data],
        countFunds: action.payload.data._meta.countTotal,
        loadingFunds: false,
        error: null,
        postFundResult: [],
        updateFundResult: [],
      };
    case actions.GET_FUNDS_SERVICE_FAILED:
      return {
        ...state,
        loadingFunds: false,
        fundList: [],
        error: action.payload,
        postFundResult: [],
        updateFundResult: [],
        countFunds: 0,
      };
    case actions.GET_FUND_BY_ID_SERVICE:
      return {
        ...state,
        loading: true,
        fund: {},
        error: null,
      };
    case actions.GET_FUND_BY_ID_SERVICE_SUCCEED:
      return {
        ...state,
        fund: action.payload.data[0],
        loading: false,
        error: null,
      };
    case actions.GET_FUND_BY_ID_SERVICE_FAILED:
      return {
        ...state,
        loading: false,
        fund: {},
        error: action.payload,
      };

    case actions.GET_FUND_FEE_BY_ID_SERVICE:
      return {
        ...state,
        loading: true,
        fundFee: 0,
        error: null,
      };
    case actions.GET_FUND_FEE_BY_ID_SERVICE_SUCCEED:
      return {
        ...state,
        fundFee: action.payload.data.fee,
        loading: false,
        error: null,
      };
    case actions.GET_FUND_FEE_BY_ID_SERVICE_FAILED:
      return {
        ...state,
        loading: false,
        fundFee: 0,
        error: action.payload,
      };
    case actions.GET_FUND_PRICE_BY_ID_SERVICE:
      return {
        ...state,
        loading: true,
        fundPrice: 0,
        error: null,
      };
    case actions.GET_FUND_PRICE_BY_ID_SERVICE_SUCCEED:
      return {
        ...state,
        fundPrice: action.payload.data,
        loading: false,
        error: null,
      };
    case actions.GET_FUND_PRICE_BY_ID_SERVICE_FAILED:
      return {
        ...state,
        loading: false,
        fundList: 0,
        error: action.payload,
      };

    case actions.GET_USER_TRANSACTIONS_BY_ID_REQUEST:
      return {
        ...state,
        loadingTxList: true,
        txList: [],
        error: null,
        count: 0

      };
    case actions.GET_USER_TRANSACTIONS_BY_ID_SUCCESS:
      return {
        ...state,
        txList: [...action.payload.data.data],
        loadingTxList: false,
        error: null,
        count: action.payload.data.count
      };
    case actions.GET_USER_TRANSACTIONS_BY_ID_FAIL:
      return {
        ...state,
        loadingTxList: false,
        txList: [],
        error: action.payload,
        count: 0

      };

    case actions.GET_FUND_PRICE_MOVEMENTS_REQUEST:
      return {
        ...state,
        loadingMovements: true,
        fundPriceMovements: [],
        error: null,
      };
    case actions.GET_FUND_PRICE_MOVEMENTS_SUCCESS:
      return {
        ...state,
        fundPriceMovements: [...action.payload.data],
        loadingMovements: false,
        error: null,
      };
    case actions.GET_FUND_PRICE_MOVEMENTS_FAIL:
      return {
        ...state,
        loadingMovements: false,
        fundPriceMovements: [],
        error: action.payload,
      };

    case actions.GET_LAST_PRICES_SERVICE:
      return {
        ...state,
        loading: true,
        lastPrices: {},
        error: null,
      };
    case actions.GET_LAST_PRICES_SERVICE_SUCCEED:
      return {
        ...state,
        lastPrices: action.payload.data,
        loading: false,
        error: null,
      };
    case actions.GET_LAST_PRICES_SERVICE_FAILED:
      return {
        ...state,
        loading: false,
        lastPrices: {},
        error: action.payload,
      };
    case actions.POST_FUND_SERVICE:
      return {
        ...state,
        loading: true,
        postFundResult: [],
        error: null,
      };
    case actions.POST_FUND_SERVICE_SUCCEED:
      return {
        ...state,
        loading: false,
        postFundResult: action.payload,
        error: null,
      };
    case actions.POST_FUND_SERVICE_FAILED:
      return {
        ...state,
        loading: false,
        postFundResult: [],
        error: action.payload,
      };
    case actions.UPDATE_FUND_SERVICE:
      return {
        ...state,
        loading: true,
        updateFundResult: [],
        error: null,
      };
    case actions.UPDATE_FUND_SERVICE_SUCCEED:
      return {
        ...state,
        updateFundResult: action.payload,
        loading: false,
        error: null,
      };
    case actions.UPDATE_FUND_SERVICE_FAILED:
      return {
        ...state,
        loading: false,
        updateFundResult: [],
        error: action.payload,
      };
    case actions.BUY_FUND_BY_ID_SERVICE:
      return {
        ...state,
        loading: true,
        buyFundResult: null,
        buyFundStatus: null,
        error: null,
      };
    case actions.BUY_FUND_BY_ID_SERVICE_SUCCEED:
      return {
        ...state,
        buyFundResult: action.payload.data,
        buyFundStatus: action.payload.status,
        loading: false,
        error: null,
      };
    case actions.BUY_FUND_BY_ID_SERVICE_FAILED:
      return {
        ...state,
        loading: false,
        buyFundResult: action.payload.message,
        buyFundStatus: 400,
        error: action.payload,
      };
    case actions.WITHDRAW_FUND_BY_ID_SERVICE:
      return {
        ...state,
        loading: true,
        withdrawFundResult: null,
        withdrawFundStatus: null,
        error: null,
      };
    case actions.WITHDRAW_FUND_BY_ID_SERVICE_SUCCEED:
      return {
        ...state,
        withdrawFundResult: action.payload.data,
        withdrawFundStatus: action.payload.status,
        loading: false,
        error: null,
      };
    case actions.WITHDRAW_FUND_BY_ID_SERVICE_FAILED:
      return {
        ...state,
        loading: false,
        withdrawFundResult: action.payload.response.data.message,
        withdrawFundStatus: action.payload.response.data.status,
        error: action.payload,
      };
    case actions.REDEEM_FUND_BY_ID_SERVICE:
      return {
        ...state,
        loading: true,
        redeemFundResult: null,
        redeemFundStatus: null,
        error: null,
      };
    case actions.REDEEM_FUND_BY_ID_SERVICE_SUCCEED:
      return {
        ...state,
        redeemFundResult: action.payload.data,
        redeemFundStatus: action.payload.status,
        loading: false,
        error: null,
      };
    case actions.REDEEM_FUND_BY_ID_SERVICE_FAILED:
      return {
        ...state,
        loading: false,
        redeemFundResult: action.payload.message,
        redeemFundStatus: 400,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default fund;
