import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  Col,
  Container,
  Modal,
  ProgressBar,
  Row,
} from "react-bootstrap";
import "./YieldDashboardPage.scss";
import UserLabel from "../../../components/UserLabel/UserLabel";
import { useTranslation } from "react-i18next";
import FTable from "../../../components/FTable/FTable";
import {
  AssetsToBorrowColumns,
  AssetsToSupplyColumns,
} from "../../../constants/columnConfigurations";
import BootstrapTable from "react-bootstrap-table-next";
import FCheckbox from "../../../components/FCheckbox/FCheckbox";

const YieldDashboardPage = () => {
  const ref = useRef(null);
  const [width, setWidth] = useState();
  const { t } = useTranslation();
  let limit = document.querySelector(".ydashboard-stats_container-limit");

  const apyDatas = {
    netApy: "0.00",
    supplyBalance: "0.00",
    borrowBalance: "0.00",
    borrowLimitUsed: "0.00",
    ratio: 80,
  };

  useEffect(() => {
    if (apyDatas.ratio > 0 && limit) {
      const innerRatio = apyDatas.ratio / 100;
      const actualRatio = innerRatio * width;
      limit.style.left = actualRatio + "px";
      console.log(width);
    }
  }, [apyDatas.ratio, width]);

  useEffect(() => {
    const handleResize = () => {
      setWidth(ref.current.offsetWidth);
    };
    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const [show, setShow] = useState(false);
  const [selectedAsset, setSelectedAsset] = useState(null);

  const handleSupplyOnClick = (row) => {
    setSelectedAsset(row);
    setShow(true);
  };

  const assetsToSupplyConfig = AssetsToSupplyColumns({ handleSupplyOnClick });
  const assetsToBorrowConfig = AssetsToBorrowColumns();

  const assetsToSupplyData = [
    {
      asset: "WETH",
      apy: "5.62",
      wallet_balance: "0.00",
      can_be_collateral: true,
      logo: "https://seeklogo.com/images/W/wrapped-ether-weth-logo-D553125721-seeklogo.com.png",
    },
    {
      asset: "USDT",
      apy: "5.62",
      wallet_balance: "0.00",
      can_be_collateral: true,
      logo: "https://seeklogo.com/images/T/tether-usdt-logo-FA55C7F397-seeklogo.com.png",
    },
    {
      asset: "USDC",
      apy: "5.62",
      wallet_balance: "0.00",
      can_be_collateral: true,
      logo: "https://logowik.com/content/uploads/images/usd-coin-usdc4695.jpg",
    },
    {
      asset: "DAI",
      apy: "5.62",
      wallet_balance: "0.00",
      can_be_collateral: true,
      logo: "https://cryptologos.cc/logos/multi-collateral-dai-dai-logo.png",
    },
    {
      asset: "wstETH",
      apy: "5.62",
      wallet_balance: "0.00",
      can_be_collateral: true,
    },
    {
      asset: "WBTC",
      apy: "5.62",
      wallet_balance: "0.00",
      can_be_collateral: true,
      logo: "https://cryptologos.cc/logos/wrapped-bitcoin-wbtc-logo.png",
    },
    {
      asset: "AAVE",
      apy: "5.62",
      wallet_balance: "0.00",
      can_be_collateral: true,
      logo: "https://cryptologos.cc/logos/aave-aave-logo.png",
    },
    {
      asset: "LINK",
      apy: "5.62",
      wallet_balance: "0.00",
      can_be_collateral: true,
      logo: "https://cryptologos.cc/logos/chainlink-link-logo.png",
    },
    {
      asset: "CVX",
      apy: "5.62",
      wallet_balance: "0.00",
      can_be_collateral: true,
      logo: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAOEAAADhCAMAAAAJbSJIAAABC1BMVEX///86Ojpg2KT0uzsWgv7/WlotLS03Nzf5+flISEhiYmIkJCQeHh4qKiq7u7uZmZklLjp83bI3KjH1xWLWpTtZvpKoqKja2toyOTk7Ny0tOTk7ODLX5f8Aef4yUX85NDY7NSYgODgyNTr/3t7/TEyAQ0Pj9+1T1p5GdWAtXJ4qif7/ZGTuV1fV1dWAaTr98d7ztyXE79soZ7o9SUOfwv4Ad/7/srL/UVH647uZSEi0yneoSkoVFRVSUlLb6f/z/Pe8ysOfqbo1SGVk5q4gNi1ZvZIuKR6jss4zY6cgFwC/0Y2fsmwvLDbtRUUAGxvp6en/6+u/qanSnh4AAxvztAy4sKOZiIhZPj6GhoZD376HAAACxklEQVR4nO3d2VITQRSAYSIhCQ4BREFFoyh7VIwLIhqC+77v7/8kWpIq+6TaHk7SU30m9f/3s3yZvjrVlZ6YiN7FVtXt0oXKscvOxn+dAkKI0H4IEdoPIUL7IURoP4QI7YcQof0Qll/YE8J2OYSHt0SPZkM9vi16cnxgpbYUvPNAjYjCjU3RVtYMVDsvUgD/EEM3Hqgb84NvbJ5w27qseu2CirqkESYJIUKE6UOIEGH6ECJEmD6ECBGmD6FS+NRNJazXgsmxlWLyWMk+DmU5JYZqi88O/jZ9R6T5pZuzwef1Wm23bc1oLpvx1D3IE4rl+HyhfpTGpBIODMhVQm/1kyrh4sKoD0SIECFChAgRIkSIECFChAgRIrQn9M7SZnKEuy2n6vYL3wROwy5UuOQtPNXs3RW97F/0SqYYMhYorE/n3FpTW6zd9sq4C6sIESJEiBAhQoQIESJEiBAhQoQIEwmru26t174dbP6U+9qSCRsjVA5hgSFEaD+ECO2HEKH9ECK0H0KE9kOI0H4Iyy9888Dt7bvxE57uTP6rc2Z+DIWTbggRWgwhQvshRGg/hAjthxCh/RAitB9ChJEqcF+bEeHUmtv7DxH3JhoRXptyu35F8Rp5+0sRIkSIECFChAgRIkSIECFChAgRll34v//cC5+z8eme6HP/ooei/WUjQu//JnZvBJ+3urbntt48umpZpniJQoXesjyhWI/r50Z9HkKECBEiRIgQIUKECBEiRIgQ4bgIv3Wcvnzt7xabF13VCMPnW6zuDS8c7ixZfzc74tPuaIg55wHLoZoKONx5wPGFhRX1TGeESUKIEGH6ECJEmD6ECBGmDyFChOlDqBOKCVxnp6kYLqneOmdsJfeTxRQ27ou+z4X6sS/6qQH+Ct55oJizNlUjDHmjLrziQojQfggR2g8hQvshRGg/hAjthxCh/RAijNxvU8F6VHLGLwUAAAAASUVORK5CYII=",
    },
    {
      asset: "CVR",
      apy: "5.62",
      wallet_balance: "0.00",
      can_be_collateral: true,
    },
    {
      asset: "YFI",
      apy: "5.62",
      wallet_balance: "0.00",
      can_be_collateral: true,
      logo: "https://cryptologos.cc/logos/yearn-finance-yfi-logo.png",
    },
    {
      asset: "SNX",
      apy: "5.62",
      wallet_balance: "0.00",
      can_be_collateral: true,
      logo: "https://seeklogo.com/images/S/synthetix-snx-logo-2E4D0D8BED-seeklogo.com.png",
    },
    {
      asset: "UNI",
      apy: "5.62",
      wallet_balance: "0.00",
      can_be_collateral: true,
      logo: "https://seeklogo.com/images/U/uniswap-uni-logo-7B6173C76E-seeklogo.com.png",
    },
    {
      asset: "SUSHI",
      apy: "5.62",
      wallet_balance: "0.00",
      can_be_collateral: true,
      logo: "https://cryptologos.cc/logos/sushiswap-sushi-logo.png",
    },
    {
      asset: "DPI",
      apy: "5.62",
      wallet_balance: "0.00",
      can_be_collateral: true,
      logo: "https://s2.coinmarketcap.com/static/img/coins/64x64/7055.png",
    },
    {
      asset: "sUSD",
      apy: "5.62",
      wallet_balance: "0.00",
      can_be_collateral: true,
      logo: "https://s2.coinmarketcap.com/static/img/coins/200x200/2927.png",
    },
    {
      asset: "MIM",
      apy: "5.62",
      wallet_balance: "0.00",
      can_be_collateral: false,
      logo: "https://seeklogo.com/images/M/magic-internet-money-mim-logo-8D7E7E2642-seeklogo.com.png",
    },
    {
      asset: "ibEUR",
      apy: "5.62",
      wallet_balance: "0.00",
      can_be_collateral: false,
    },
    {
      asset: "ibGBP",
      apy: "5.62",
      wallet_balance: "0.00",
      can_be_collateral: false,
    },
    {
      asset: "ibJPY",
      apy: "5.62",
      wallet_balance: "0.00",
      can_be_collateral: false,
    },
    {
      asset: "ibAUD",
      apy: "5.62",
      wallet_balance: "0.00",
      can_be_collateral: false,
    },
    {
      asset: "ibKRW",
      apy: "5.62",
      wallet_balance: "0.00",
      can_be_collateral: false,
    },
    {
      asset: "ibCHF",
      apy: "5.62",
      wallet_balance: "0.00",
      can_be_collateral: false,
    },
  ];
  const assetsToBorrowData = [
    {
      asset: "USDT",
      apy: "5.62",
      wallet_balance: "0.00",
      can_be_collateral: true,
      logo: "https://seeklogo.com/images/T/tether-usdt-logo-FA55C7F397-seeklogo.com.png",
    },
    {
      asset: "USDC",
      apy: "5.62",
      wallet_balance: "0.00",
      can_be_collateral: true,
      logo: "https://logowik.com/content/uploads/images/usd-coin-usdc4695.jpg",
    },
    {
      asset: "DAI",
      apy: "5.62",
      wallet_balance: "0.00",
      can_be_collateral: true,
      logo: "https://cryptologos.cc/logos/multi-collateral-dai-dai-logo.png",
    },
  ];

  const [checkbox, setCheckbox] = useState(true);

  return (
    <div>
      <Container className="">
        <UserLabel text={t("flanding_yield")} />
      </Container>
      <div className="ydashboard-stats_container">
        <Container className="py-4">
          <Row className="mx-4">
            <Col>
              <h4>Net APY</h4>
              <span>{apyDatas?.netApy} %</span>
            </Col>
            <Col>
              <h4>Supply Balance</h4>
              <span>{apyDatas?.supplyBalance} %</span>
            </Col>
            <Col>
              <h4>Borrow Balance</h4>
              <span>{apyDatas?.borrowBalance} %</span>
            </Col>
            <Col>
              <h4>Borrow Limit Used</h4>
              <span>{apyDatas?.borrowLimitUsed} %</span>
              <div className="position-relative mt-4">
                <div className="ydashboard-stats_container-limit">
                  <h3>Borrow Limit</h3>
                  <h3>Safe Limit</h3>
                </div>
                <ProgressBar now={60} ref={ref}></ProgressBar>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <div className="ydashboard-stats_tables">
        <Container className="mt-4">
          <Row>
            <Col>
              <div className="f_admin-balances-container-item px-4 py-4">
                <div className="mb-4">Assets to Supply</div>
                <FTable
                  columns={assetsToSupplyConfig}
                  data={assetsToSupplyData}
                  type="fund"
                  removeBorderBottom={true}
                  verticalAlign={"top"}
                />
              </div>
            </Col>
            <Col>
              <div className="f_admin-balances-container-item px-4 py-4">
                <div className="mb-4">Assets to Borrow</div>
                <FTable
                  columns={assetsToBorrowConfig}
                  data={assetsToBorrowData}
                  type="fund"
                  removeBorderBottom={true}
                  verticalAlign={"top"}
                  height={"59px"}
                />
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <Modal
        show={show}
        onHide={() => setShow(false)}
        animation={false}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="apymodal"
      >
        <Modal.Header
          closeButton
          style={{
            border: 0,
            padding: "30px 50px",
          }}
        >
          <Modal.Title
            id="contained-modal-title-vcenter"
            style={{
              fontSize: "18px",
              fontWeight: 700,
              color: "#333333",
            }}
          >
            Supply
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ padding: "0 50px 30px 50px" }}>
          <div className="inputholder">
            <div className="dollar">$0.00</div>
            <div className="assetinfo">
              <img src={selectedAsset?.logo} height={24} />
              <h4>{selectedAsset?.asset}</h4>
            </div>
            <input placeholder="0.00" type="number" />
          </div>
          <div className="apymodal-balance">
            <h3>Balance: 0.0000 {selectedAsset?.asset}</h3>
            <h4>Max Amount</h4>
          </div>
          <div className="apymodal-stats">
            <div className="apymodal-stats-title">
              <h3>Supply Stats</h3>
            </div>
            <div className="apymodal-stats-content">
              <h4>Supply APY</h4>
              <span>5.65%</span>
            </div>
            <div className="apymodal-stats-content">
              <h4>Reward APY</h4>
              <span>5.65%</span>
            </div>
          </div>
          <div className="apymodal-stats">
            <div className="apymodal-stats-title">
              <h3>Collateral</h3>
              <FCheckbox
                value={checkbox}
                handleChange={(e) => setCheckbox(checkbox)}
              />
            </div>
            <div className="apymodal-stats-content">
              <h4>Collateral Factor</h4>
              {console.log(checkbox)}
              <span>5.65%</span>
            </div>
            <div className="apymodal-stats-content">
              <h4>Borrow Limit Used</h4>
              <span>0% → 0%</span>
            </div>
          </div>
          <div
            className="apymodal-stats-buttons"
            style={{ display: "flex", gap: "5px" }}
          >
            <Button
              style={{
                flex: 2,
                background: "#4169E1",
                fontSize: "14px",
                fontWeight: "500",
                border: "none",
                height: "40px",
              }}
            >
              Approve
            </Button>
            <Button
              disabled
              style={{
                flex: 1,
                background: "#B3B3B3",
                fontSize: "14px",
                fontWeight: "500",
                border: "none",
                height: "40px",
                opacity: 1,
              }}
            >
              → Supply
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default YieldDashboardPage;
