import React from 'react';
import { Container } from 'react-bootstrap';
import './AboutDowTokens.scss';
import about4 from '../../../assets/images/about4.jpeg';
import { useTranslation } from 'react-i18next';

const AboutDowTokens = () => {
  const { t } = useTranslation();

  return (
    <>
      <div className="about">
        <div className="about-head">
          <img src={about4}></img>
          <div className="about-head-text">{t('about_dow_tokens_main')}</div>
        </div>
        <Container>
          <div className="about-body">
            <div className="about-body-title">
              {t('about_dow_tokens_security')}
            </div>
            <div className="about-body-content">
              {t('about_dow_tokens_body')}
            </div>
            <div className="about-body-title">
              {t('about_dow_tokens_sectitle')}
            </div>
            <div className="about-body-content">
              {t('about_dow_tokens_seccontent')}
            </div>
            <div className="about-body-title">
              {t('about_dow_tokens_howeytitle')}
            </div>
            <div className="about-body-content">
              {t('about_dow_tokens_howeycontent')}
            </div>
            <div className="about-body-title">
              {t('about_dow_tokens_ehoweytitle')}
            </div>
            <div className="about-body-content">
              {t('about_dow_tokens_ehoweycontent')}
            </div>
            <div className="about-body-title">
              {t('about_dow_tokens_abouttitle')}
            </div>
            <div className="about-body-content">
              {t('about_dow_tokens_aboutcontent')}
            </div>
          </div>
        </Container>
      </div>
    </>
  );
};

export default AboutDowTokens;
