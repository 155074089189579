import * as services from '../actions';
export const getFundsService = (reqParams) => ({
  type: services.GET_FUNDS_SERVICE,
  payload: reqParams,
});

export const getFundsServiceSucceed = (reqParams) => ({
  type: services.GET_FUNDS_SERVICE_SUCCEED,
  payload: reqParams,
});

export const getFundsServiceFailed = (error) => ({
  type: services.GET_FUNDS_SERVICE_FAILED,
  payload: error,
});

export const getFundByIdService = (reqParams) => ({
  type: services.GET_FUND_BY_ID_SERVICE,
  payload: reqParams,
});

export const getFundByIdServiceSucceed = ({ id, data }) => ({
  type: services.GET_FUND_BY_ID_SERVICE_SUCCEED,
  payload: {
    id,
    data,
  },
});

export const getFundByIdServiceFailed = (error) => ({
  type: services.GET_FUND_BY_ID_SERVICE_FAILED,
  payload: error,
});

export const getFundFeeByIdService = (reqParams) => ({
  type: services.GET_FUND_FEE_BY_ID_SERVICE,
  payload: reqParams,
});

export const getFundFeeByIdServiceSucceed = ({ id, data }) => ({
  type: services.GET_FUND_FEE_BY_ID_SERVICE_SUCCEED,
  payload: {
    id,
    data,
  },
});

export const getFundFeeByIdServiceFailed = (error) => ({
  type: services.GET_FUND_FEE_BY_ID_SERVICE_FAILED,
  payload: error,
});

export const getFundPriceByIdService = (reqParams) => ({
  type: services.GET_FUND_PRICE_BY_ID_SERVICE,
  payload: reqParams,
});

export const getFundPriceByIdServiceSucceed = ({ id, data }) => ({
  type: services.GET_FUND_PRICE_BY_ID_SERVICE_SUCCEED,
  payload: {
    id,
    data,
  },
});

export const getFundPriceByIdServiceFailed = (error) => ({
  type: services.GET_FUND_PRICE_BY_ID_SERVICE_FAILED,
  payload: error,
});

export const getLastPricesService = (reqParams) => ({
  type: services.GET_LAST_PRICES_SERVICE,
  payload: reqParams,
});

export const getLastPricesServiceSucceed = (reqParams) => ({
  type: services.GET_LAST_PRICES_SERVICE_SUCCEED,
  payload: reqParams,
});

export const getLastPricesServiceFailed = (error) => ({
  type: services.GET_LAST_PRICES_SERVICE_FAILED,
  payload: error,
});

export const postFundService = (reqParams) => ({
  type: services.POST_FUND_SERVICE,
  payload: reqParams,
});

export const postFundServiceSucceed = (reqParams) => ({
  type: services.POST_FUND_SERVICE_SUCCEED,
  payload: reqParams,
});

export const postFundServiceFailed = (error) => ({
  type: services.POST_FUND_SERVICE_FAILED,
  payload: error,
});

export const updateFundService = (reqParams) => ({
  type: services.UPDATE_FUND_SERVICE,
  payload: reqParams,
});

export const updateFundSucceed = ({ id, data }) => ({
  type: services.UPDATE_FUND_SERVICE_SUCCEED,
  payload: { id, data },
});

export const updateFundFailed = (error) => ({
  type: services.UPDATE_FUND_SERVICE_FAILED,
  payload: error,
});

export const buyFundByIdService = (reqParams) => ({
  type: services.BUY_FUND_BY_ID_SERVICE,
  payload: reqParams,
});

export const buyFundByIdServiceSucceed = ({ id, data, status }) => ({
  type: services.BUY_FUND_BY_ID_SERVICE_SUCCEED,
  payload: {
    id,
    data,
    status,
  },
});

export const buyFundByIdServiceFailed = (error) => ({
  type: services.BUY_FUND_BY_ID_SERVICE_FAILED,
  payload: error,
});

export const withdrawFundByIdService = (reqParams) => ({
  type: services.WITHDRAW_FUND_BY_ID_SERVICE,
  payload: reqParams,
});

export const withdrawFundByIdServiceSucceed = ({ id, data, status }) => ({
  type: services.WITHDRAW_FUND_BY_ID_SERVICE_SUCCEED,
  payload: {
    id,
    data,
    status,
  },
});

export const withdrawFundByIdServiceFailed = (error) => ({
  type: services.WITHDRAW_FUND_BY_ID_SERVICE_FAILED,
  payload: error,
});

export const redeemFundByIdService = (reqParams) => ({
  type: services.REDEEM_FUND_BY_ID_SERVICE,
  payload: reqParams,
});

export const redeemFundByIdServiceSucceed = ({ id, data, status }) => ({
  type: services.REDEEM_FUND_BY_ID_SERVICE_SUCCEED,
  payload: {
    id,
    data,
    status,
  },
});

export const redeemFundByIdServiceFailed = (error) => ({
  type: services.REDEEM_FUND_BY_ID_SERVICE_FAILED,
  payload: error,
});

export const getUserTransactionsByIdService = (reqParams) => ({
  type: services.GET_USER_TRANSACTIONS_BY_ID_REQUEST,
  payload: reqParams,
});

export const getUserTransactionsByIdServiceSucceed = ({ id, data }) => ({
  type: services.GET_USER_TRANSACTIONS_BY_ID_SUCCESS,
  payload: {
    id,
    data,
  },
});

export const getUserTransactionsByIdServiceFailed = (error) => ({
  type: services.GET_USER_TRANSACTIONS_BY_ID_FAIL,
  payload: error,
});

export const getFundPriceMovementsService = (reqParams) => ({
  type: services.GET_FUND_PRICE_MOVEMENTS_REQUEST,
  payload: reqParams,
});

export const getFundPriceMovementsServiceSucceed = ({ id, data }) => ({
  type: services.GET_FUND_PRICE_MOVEMENTS_SUCCESS,
  payload: {
    id,
    data,
  },
});

export const getFundPriceMovementsServiceFailed = (error) => ({
  type: services.GET_FUND_PRICE_MOVEMENTS_FAIL,
  payload: error,
});
