import React from 'react';
import './FIcon.scss';

const FIcon = ({ className, name, size, color, onClick, title, style }) => {
  return (
    <i
      className={`icon icon-${name} ${className} `}
      style={{
        width: `${size}px`,
        height: `${size}px`,
        backgroundColor: color,
        ...style,
      }}
      onClick={onClick}
      title={title}
    ></i>
  );
};

export default FIcon;

FIcon.defaultProps = {
  className: '',
  size: '24',
  backgroundColor: 'none',
  onClick: () => {},
  title: '',
  style: {},
};
