// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.w_transaction span {
  font-family: Helvetica Neue;
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
}
.w_transaction .accordion-button:not(.collapsed) {
  color: black;
  background: none;
  border: none;
}
.w_transaction .accordion-button::after {
  scale: 0.7;
  margin-top: -16px;
  filter: invert(38%) sepia(90%) saturate(3660%) hue-rotate(217deg) brightness(95%) contrast(84%);
}
.w_transaction .accordion-item:first-of-type {
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
}
.w_transaction .accordion-button {
  padding: 0 !important;
}
.w_transaction .accordion-item {
  border: 0 !important;
  border-top: 0.5px solid #5e718d !important;
  padding-top: 12px;
  padding-bottom: 12px;
}
.w_transaction h2 {
  margin: 0 !important;
  font-size: 14px;
  font-weight: 700 !important;
}
.w_transaction-acc-desc div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 7px;
  margin-bottom: 7px;
}
.w_transaction-acc-desc div h4 {
  font-size: 14px;
  font-weight: 700;
  color: #5e718d;
}
.w_transaction-acc-desc div span {
  font-size: 14px;
  font-weight: 400;
}`, "",{"version":3,"sources":["webpack://./src/components/WalletComponents/WTransaction/WTransaction.scss"],"names":[],"mappings":"AACE;EACE,2BAAA;EACA,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,mBAAA;EACA,gBAAA;AAAJ;AAEE;EACE,YAAA;EACA,gBAAA;EACA,YAAA;AAAJ;AAEE;EACE,UAAA;EACA,iBAAA;EACA,+FAAA;AAAJ;AAGE;EACE,2BAAA;EACA,4BAAA;AADJ;AAGE;EACE,qBAAA;AADJ;AAGE;EACE,oBAAA;EACA,0CAAA;EACA,iBAAA;EACA,oBAAA;AADJ;AAGE;EACE,oBAAA;EACA,eAAA;EACA,2BAAA;AADJ;AAGE;EACE,aAAA;EACA,8BAAA;EACA,mBAAA;EACA,eAAA;EACA,kBAAA;AADJ;AAEI;EACE,eAAA;EACA,gBAAA;EACA,cAAA;AAAN;AAEI;EACE,eAAA;EACA,gBAAA;AAAN","sourcesContent":[".w_transaction {\n  span {\n    font-family: Helvetica Neue;\n    font-size: 14px;\n    font-weight: 500;\n    line-height: 17px;\n    letter-spacing: 0em;\n    text-align: left;\n  }\n  .accordion-button:not(.collapsed) {\n    color: black;\n    background: none;\n    border: none;\n  }\n  .accordion-button::after {\n    scale: 0.7;\n    margin-top: -16px;\n    filter: invert(38%) sepia(90%) saturate(3660%) hue-rotate(217deg)\n      brightness(95%) contrast(84%);\n  }\n  .accordion-item:first-of-type {\n    border-top-left-radius: 0px;\n    border-top-right-radius: 0px;\n  }\n  .accordion-button {\n    padding: 0 !important;\n  }\n  .accordion-item {\n    border: 0 !important;\n    border-top: 0.5px solid #5e718d !important;\n    padding-top: 12px;\n    padding-bottom: 12px;\n  }\n  h2 {\n    margin: 0 !important;\n    font-size: 14px;\n    font-weight: 700 !important;\n  }\n  &-acc-desc div {\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n    margin-top: 7px;\n    margin-bottom: 7px;\n    h4 {\n      font-size: 14px;\n      font-weight: 700;\n      color: #5e718d;\n    }\n    span {\n      font-size: 14px;\n      font-weight: 400;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
