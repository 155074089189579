import { Navigate, useRoutes } from "react-router-dom";
import { MainLayout, PublicLayout, YieldLayout } from "../layouts";
import {
  Dashboard,
  Login,
  Error,
  Invest,
  Performance,
  Wallets,
  LandingPage,
  SignUp,
  About,
  Funds,
  News,
  FundDetails,
  Confirmation,
  Terms,
  C5Index,
  BitcoinProtected,
  Arbitrage,
  CryptoTrading,
  Yield,
  AboutDowTokens,
  AccountSettings,
  NewsDetails,
  Buy,
  Redeem,
  LeveragedStaking,
  LiquidtyProviding,
  OptionStrategies,
  YieldLandingPage,
  YieldListPage,
  YieldStakingPage,
  YieldLiquidityPage,
  YieldOptionPage,
  FAQ,
  Withdraw,
  ForgotPassword,
  ResetPassword,
  OTP,
  YieldDashboardPage,
} from "../pages";
import AboutDMPC from "../pages/MainLayout/AboutDMPC/AboutDMPC";
import Transactions from "../pages/PublicLayout/Transactions/Transactions";

export default function Router({
  auth,
  switchTheme,
  theme,
  switchLanguage,
  language,
}) {
  let element = useRoutes([
    {
      children: [
        { path: "*", element: auth ? <Error /> : <Navigate to="/login" /> },
      ],
    },
    {
      element: (
        <PublicLayout
          switchTheme={switchTheme}
          theme={theme}
          switchLanguage={switchLanguage}
          language={language}
        />
      ),
      children: [
        {
          path: "/dashboard",
          element: auth ? (
            <Dashboard language={language} theme={theme} />
          ) : (
            <Navigate to="/login" language={language} />
          ),
        },
        {
          path: "/borrow",
          element: <YieldDashboardPage /*language={language} theme={theme}*/ />,
        },
        {
          path: "/transactions",
          element: auth ? (
            <Transactions language={language} theme={theme} />
          ) : (
            <Navigate to="/login" language={language} />
          ),
        },
        {
          path: "invest",
          element: auth ? (
            <Invest language={language} />
          ) : (
            <Navigate to="/login" language={language} />
          ),
        },
        {
          path: "performance",
          element: auth ? (
            <Performance language={language} />
          ) : (
            <Navigate to="/login" language={language} />
          ),
        },
        {
          path: "/yield",
          element: auth ? (
            <Yield language={language} theme={theme} />
          ) : (
            <Navigate to="/login" language={language} />
          ),
        },
        {
          path: "invest/buy",
          element: auth ? (
            <Buy language={language} theme={theme} />
          ) : (
            <Navigate to="/login" language={language} />
          ),
        },
        {
          path: "invest/redeem",
          element: auth ? (
            <Redeem language={language} theme={theme} />
          ) : (
            <Navigate to="/login" language={language} />
          ),
        },
        {
          path: "wallets",
          element: auth ? (
            <Wallets language={language} theme={theme} />
          ) : (
            <Navigate to="/login" language={language} />
          ),
        },
        {
          path: "wallets/withdraw",
          element: auth ? (
            <Withdraw language={language} theme={theme} />
          ) : (
            <Navigate to="/login" language={language} />
          ),
        },
        {
          path: "settings",
          element: auth ? (
            <AccountSettings language={language} theme={theme} />
          ) : (
            <Navigate to="/login" language={language} />
          ),
        },
        {
          path: "invest/c5-index",
          element: auth ? (
            <C5Index from={"invest"} language={language} theme={theme} />
          ) : (
            <Navigate to="/login" language={language} />
          ),
        },
        {
          path: "invest/bitcoin-protected",
          element: auth ? (
            <BitcoinProtected
              from={"invest"}
              language={language}
              theme={theme}
            />
          ) : (
            <Navigate to="/login" language={language} />
          ),
        },
        {
          path: "invest/algorithmic",
          element: auth ? (
            <Arbitrage from={"invest"} language={language} theme={theme} />
          ) : (
            <Navigate to="/login" language={language} />
          ),
        },
        {
          path: "invest/crypto-trading",
          element: auth ? (
            <CryptoTrading from={"invest"} language={language} theme={theme} />
          ) : (
            <Navigate to="/login" language={language} />
          ),
        },
      ],
    },
    {
      element: (
        <MainLayout
          switchTheme={switchTheme}
          theme={theme}
          switchLanguage={switchLanguage}
          language={language}
        />
      ),
      children: [
        {
          path: "/",
          element: <LandingPage language={language} />,
        },

        {
          path: "/login",
          element: <Login language={language} />,
        },
        {
          path: "/forgot-password",
          element: <ForgotPassword language={language} />,
        },
        {
          path: "/password-reset/:token",
          element: <ResetPassword language={language} />,
        },
        {
          path: "/otp",
          element: <OTP language={language} />,
        },
        {
          path: "/signup",
          element: <SignUp language={language} />,
        },

        {
          path: "/about",
          element: <About language={language} />,
        },
        {
          path: "/about-dow-tokens",
          element: <AboutDowTokens language={language} />,
        },
        {
          path: "/about-dmpc",
          element: <AboutDMPC language={language} />,
        },
        {
          path: "/news",
          element: <News language={language} />,
        },
        {
          path: "/news/newsDetails",
          element: <NewsDetails theme={theme} language={language} />,
        },

        {
          path: "/yield-hub/leveraged-staking",
          element: <LeveragedStaking language={language} theme={theme} />,
        },
        {
          path: "/yield-hub/liquidty-providing",
          element: <LiquidtyProviding language={language} theme={theme} />,
        },
        {
          path: "/yield-hub/option-strategies",
          element: <OptionStrategies language={language} theme={theme} />,
        },
        {
          path: "/funds",
          element: <Funds language={language} />,
        },
        {
          path: "funds/c5-index",
          element: <C5Index from={"funds"} language={language} theme={theme} />,
        },
        {
          path: "funds/bitcoin-protected",
          element: (
            <BitcoinProtected
              from={"funds"}
              language={language}
              theme={theme}
            />
          ),
        },
        {
          path: "funds/algorithmic",
          element: (
            <Arbitrage from={"funds"} language={language} theme={theme} />
          ),
        },
        {
          path: "funds/crypto-trading",
          element: (
            <CryptoTrading from={"funds"} language={language} theme={theme} />
          ),
        },
        {
          path: "/confirmation",
          element: <Confirmation language={language} />,
        },
        {
          path: "/terms",
          element: <Terms language={language} />,
        },
        {
          path: "/faq",
          element: <FAQ language={language} />,
        },
      ],
    },
    {
      element: (
        <YieldLayout
          switchTheme={switchTheme}
          theme={theme}
          switchLanguage={switchLanguage}
          language={language}
        />
      ),
      children: [
        {
          path: "yields",
          element: <YieldLandingPage /*language={language} theme={theme}*/ />,
        },
        {
          path: "yields/yield-list",
          element: <YieldListPage /*language={language} theme={theme}*/ />,
        },
        {
          path: "yields/staking",
          element: <YieldStakingPage /*language={language} theme={theme}*/ />,
        },
        {
          path: "yields/liquidity",
          element: <YieldLiquidityPage /*language={language} theme={theme}*/ />,
        },
        {
          path: "yields/option",
          element: <YieldOptionPage /*language={language} theme={theme}*/ />,
        },
      ],
    },
  ]);
  return element;
}
