import React, { useEffect } from "react";
import { Doughnut } from "react-chartjs-2";
import "./AUM.scss";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { getAumDataRequest } from "../../../services/admin/adminService";
import { numberWithCommas } from "../../../utils/util";
import { useNavigate } from "react-router-dom";

const AUM = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { aumData, totalUsers, totalUserCount } = useSelector(
    (state) => state.admin
  );

  const data = {
    labels: aumData && aumData.map((value) => value.name),
    datasets: [
      {
        data: aumData,
        backgroundColor: [
          "#2672C4",
          "rgb(116,125,128)",
          "rgb(242,169,0)",
          "rgb(105,194,231)",
          "rgb(228,55,150)",
        ],
        borderWidth: 1,
      },
    ],
  };
  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: true,
        position: "bottom",
      },
    },
    cutout: 70,
  };
  const logos = [
    "https://dow.capital/static/media/c5icon.a4fc3d13b355bfc93b0f.png",
    "https://dow.capital/static/media/btcicon.f369eb135dd8bed28e0d.png",
    "https://dow.capital/static/media/tradeicon.300070758f7cd253ff5f.png",
    "https://dow.capital/static/media/arbitrageicon.b534faf6f8c6f6c48dd5.png",
    "https://dow.capital/static/media/stakingicon.f82205d14240d22c0019.png",
    "https://dow.capital/static/media/liqicon.4a3d28ab4fbf88d7146f.png",
    "https://dow.capital/static/media/optionicon.5f939fd9e9a63012d64d.png",
    "https://logowik.com/content/uploads/images/usd-coin-usdc4695.jpg",
  ];
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAumDataRequest());
  }, []);

  return (
    <div className="aum">
      <div className="aum-inner">
        <h1
          style={{
            fontSize: "16px",
            fontWeight: "700",
            paddingTop: "13px",
            paddingLeft: "32px",
          }}
        >
          {t("aum")}
        </h1>
        <div className="w-100 h-100 d-flex justify-content-center align-items-center">
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              padding: "18px 27px",
              alignItems: "center",
              height: "100%",
              width: "260px",
              flexDirection: "column",
            }}
          >
            <div style={{ marginBottom: "30px", fontSize: "36px" }}>
              $
              {aumData &&
                numberWithCommas(
                  (
                    aumData[0]?.value +
                    aumData[1]?.value +
                    aumData[2]?.value +
                    aumData[3]?.value +
                    aumData[4]?.value
                  ).toFixed(2)
                )}
            </div>
            <Doughnut data={data} options={options} />
          </div>
        </div>
      </div>
      <div style={{ display: "flex", flex: 1, padding: "0px 10px" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            flex: 1,
          }}
        >
          <div style={{ width: "33%" }}>
            <div
              style={{
                padding: "20px  0",
                fontSize: "14px",
                fontWeight: 700,
                color: "#77838F",
                visibility: "hidden",
              }}
            >
              a
            </div>

            <div
              style={{
                padding: "8px  0",
                fontSize: "14px",
                fontWeight: 700,
                color: "#77838F",
              }}
            >
              <div
                style={{ display: "flex", alignItems: "center", gap: "7px" }}
              >
                <img src={logos[7]} width="20" />
                <span>USDC</span>
              </div>
            </div>
            <div
              style={{
                padding: "8px  0",
                fontSize: "14px",
                fontWeight: 700,
                color: "#77838F",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "7px",
                  cursor: "pointer",
                }}
                onClick={() => navigate("/invest/c5-index?id=1")}
              >
                <img src={logos[0]} width="20" />
                <span>C5</span>
              </div>
            </div>

            <div
              style={{
                padding: "8px  0",
                fontSize: "14px",
                fontWeight: 700,
                color: "#77838F",
                cursor: "pointer",
              }}
            >
              <div
                style={{ display: "flex", alignItems: "center", gap: "7px" }}
                onClick={() => navigate("/invest/bitcoin-protected?id=2")}
              >
                <img src={logos[1]} width="20" />
                <span>BTCp</span>
              </div>
            </div>
            <div
              style={{
                padding: "8px  0",
                fontSize: "14px",
                fontWeight: 700,
                color: "#77838F",
                cursor: "pointer",
              }}
            >
              <div
                style={{ display: "flex", alignItems: "center", gap: "7px" }}
                onClick={() => navigate("/invest/crypto-trading?id=3")}
              >
                <img src={logos[2]} width="20" />
                <span>TRQ</span>
              </div>
            </div>
            <div
              style={{
                padding: "8px  0",
                fontSize: "14px",
                fontWeight: 700,
                color: "#77838F",
                cursor: "pointer",
              }}
            >
              <div
                style={{ display: "flex", alignItems: "center", gap: "7px" }}
                onClick={() => navigate("/invest/algorithmic?id=4")}
              >
                <img src={logos[3]} width="20" />
                <span>QNT</span>
              </div>
            </div>
            <div
              style={{
                padding: "8px  0",
                fontSize: "14px",
                fontWeight: 700,
                color: "#77838F",
              }}
            >
              <div
                style={{ display: "flex", alignItems: "center", gap: "7px" }}
              >
                <img src={logos[4]} width="20" />
                <span>STAKING</span>
              </div>
            </div>
            <div
              style={{
                padding: "8px  0",
                fontSize: "14px",
                fontWeight: 700,
                color: "#77838F",
              }}
            >
              <div
                style={{ display: "flex", alignItems: "center", gap: "7px" }}
              >
                <img src={logos[5]} width="20" />
                <span>LIQUIDITY</span>
              </div>
            </div>
            <div
              style={{
                padding: "8px  0",
                fontSize: "14px",
                fontWeight: 700,
                color: "#77838F",
              }}
            >
              <div
                style={{ display: "flex", alignItems: "center", gap: "7px" }}
              >
                <img src={logos[6]} width="20" />
                <span>OPTION</span>
              </div>
            </div>
          </div>
          <div style={{ width: "33%", background: "#4169E129" }}>
            <div
              style={{
                padding: "20px  0",
                fontSize: "14px",
                fontWeight: 700,
                color: "#77838F",
                textAlign: "center",
              }}
            >
              AUM
            </div>
            <div
              style={{
                padding: "8px  0",
                fontSize: "14px",
                fontWeight: 400,
                color: "#77838F",
                textAlign: "center",
              }}
            >
              ${aumData && numberWithCommas(aumData[0]?.value.toFixed(2))}
            </div>
            <div
              style={{
                padding: "8px  0",
                fontSize: "14px",
                fontWeight: 400,
                color: "#77838F",
                textAlign: "center",
              }}
            >
              ${aumData && numberWithCommas(aumData[1]?.value.toFixed(2))}
            </div>
            <div
              style={{
                padding: "8px  0",
                fontSize: "14px",
                fontWeight: 400,
                color: "#77838F",
                textAlign: "center",
              }}
            >
              ${aumData && numberWithCommas(aumData[2]?.value.toFixed(2))}
            </div>
            <div
              style={{
                padding: "8px  0",
                fontSize: "14px",
                fontWeight: 400,
                color: "#77838F",
                textAlign: "center",
              }}
            >
              ${aumData && numberWithCommas(aumData[3]?.value.toFixed(2))}
            </div>
            <div
              style={{
                padding: "8px  0",
                fontSize: "14px",
                fontWeight: 400,
                color: "#77838F",
                textAlign: "center",
              }}
            >
              ${aumData && numberWithCommas(aumData[4]?.value.toFixed(2))}
            </div>
            <div
              style={{
                padding: "8px  0",
                fontSize: "14px",
                fontWeight: 400,
                color: "#77838F",
                textAlign: "center",
              }}
            >
              $0
            </div>
            <div
              style={{
                padding: "8px  0",
                fontSize: "14px",
                fontWeight: 400,
                color: "#77838F",
                textAlign: "center",
              }}
            >
              $0
            </div>
            <div
              style={{
                padding: "8px  0",
                fontSize: "14px",
                fontWeight: 400,
                color: "#77838F",
                textAlign: "center",
              }}
            >
              $0
            </div>
          </div>
          <div style={{ width: "33%" }}>
            <div
              style={{
                padding: "20px  0",
                fontSize: "14px",
                fontWeight: 700,
                color: "#77838F",
                textAlign: "center",
              }}
            >
              {t("aum_users")}
            </div>
            <div
              style={{
                padding: "8px 0px",
                fontSize: "14px",
                fontWeight: 400,
                color: "#77838F",
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <div
                style={{
                  display: "flex",
                  gap: " 10px",
                  justifyContent: "space-between",
                }}
              >
                <span>0/{totalUserCount}</span>
                <span style={{ color: "#4169E1" }}>%0</span>
              </div>
            </div>
            <div
              style={{
                padding: "8px 0px",
                fontSize: "14px",
                fontWeight: 400,
                color: "#77838F",
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <div
                style={{
                  display: "flex",
                  gap: " 10px",
                  justifyContent: "space-between",
                }}
              >
                <span>
                  {
                    totalUsers?.filter((element) => element?.token === "C5")[0]
                      ?.count
                  }
                  /{totalUserCount}
                </span>
                <span style={{ color: "#4169E1" }}>
                  %
                  {parseFloat(
                    (totalUsers?.filter((element) => element?.token === "C5")[0]
                      ?.count /
                      totalUserCount) *
                      100
                  ).toFixed(0)}
                </span>
              </div>
            </div>
            <div
              style={{
                padding: "8px 0px",
                fontSize: "14px",
                fontWeight: 400,
                color: "#77838F",
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <div
                style={{
                  display: "flex",
                  gap: " 10px",
                  justifyContent: "space-between",
                }}
              >
                <span>
                  {totalUsers?.filter((element) => element?.token === "BTCp")[0]
                    ?.count
                    ? totalUsers?.filter(
                        (element) => element?.token === "BTCp"
                      )[0]?.count
                    : 0}
                  /{totalUserCount}
                </span>
                <span style={{ color: "#4169E1" }}>
                  %
                  {parseFloat(
                    (totalUsers?.filter(
                      (element) => element?.token === "BTCp"
                    )[0]?.count /
                      totalUserCount) *
                      100
                  ).toFixed(2) !== "NaN"
                    ? parseFloat(
                        (totalUsers?.filter(
                          (element) => element?.token === "BTCp"
                        )[0]?.count /
                          totalUserCount) *
                          100
                      ).toFixed(0)
                    : "0"}
                </span>
              </div>
            </div>
            <div
              style={{
                padding: "8px 0px",
                fontSize: "14px",
                fontWeight: 400,
                color: "#77838F",
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <div
                style={{
                  display: "flex",
                  gap: " 10px",
                  justifyContent: "space-between",
                }}
              >
                <span>
                  {
                    totalUsers?.filter((element) => element?.token === "TRQ")[0]
                      ?.count
                  }
                  /{totalUserCount}
                </span>
                <span style={{ color: "#4169E1" }}>
                  %
                  {parseFloat(
                    (totalUsers?.filter(
                      (element) => element?.token === "TRQ"
                    )[0]?.count /
                      totalUserCount) *
                      100
                  ).toFixed(0)}
                </span>
              </div>
            </div>
            <div
              style={{
                padding: "8px 0px",
                fontSize: "14px",
                fontWeight: 400,
                color: "#77838F",
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <div
                style={{
                  display: "flex",
                  gap: " 10px",
                  justifyContent: "space-between",
                }}
              >
                <span>
                  {
                    totalUsers?.filter((element) => element?.token === "QNT")[0]
                      ?.count
                  }
                  /{totalUserCount}
                </span>
                <span style={{ color: "#4169E1" }}>
                  %
                  {parseFloat(
                    (totalUsers?.filter(
                      (element) => element?.token === "QNT"
                    )[0]?.count /
                      totalUserCount) *
                      100
                  ).toFixed(0)}
                </span>
              </div>
            </div>
            <div
              style={{
                padding: "8px 0px",
                fontSize: "14px",
                fontWeight: 400,
                color: "#77838F",
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <div
                style={{
                  display: "flex",
                  gap: " 10px",
                  justifyContent: "space-between",
                }}
              >
                <span>0/{totalUserCount}</span>
                <span style={{ color: "#4169E1" }}>%0</span>
              </div>
            </div>
            <div
              style={{
                padding: "8px 0px",
                fontSize: "14px",
                fontWeight: 400,
                color: "#77838F",
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <div
                style={{
                  display: "flex",
                  gap: " 10px",
                  justifyContent: "space-between",
                }}
              >
                <span>0/{totalUserCount}</span>
                <span style={{ color: "#4169E1" }}>%0</span>
              </div>
            </div>
            <div
              style={{
                padding: "8px 0px",
                fontSize: "14px",
                fontWeight: 400,
                color: "#77838F",
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <div
                style={{
                  display: "flex",
                  gap: " 10px",
                  justifyContent: "space-between",
                }}
              >
                <span>0/{totalUserCount}</span>
                <span style={{ color: "#4169E1" }}>%0</span>
              </div>
            </div>
          </div>
        </div>
        <div></div>
        <div></div>
      </div>
    </div>
  );
};

export default AUM;
