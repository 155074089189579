import { useNavigate } from "react-router-dom";
import "./FNavbar.scss";
import themeIcon from "../../assets/icons/theme.svg";
import languageIcon from "../../assets/icons/language.svg";
import settingsIcon from "../../assets/icons/profile.svg";
import walletIcon from "../../assets/icons/wallet.svg";
import walletFilledIcon from "../../assets/icons/walletFilled.svg";
import homeIcon from "../../assets/icons/home.svg";
import homeFilledIcon from "../../assets/icons/homeFilled.svg";
import plusIcon from "../../assets/icons/plus.svg";
import plusFilledIcon from "../../assets/icons/plusFilled.svg";
import graphIcon from "../../assets/icons/graph.svg";
import yieldIcon from "../../assets/icons/yield.svg";
import yieldFilledIcon from "../../assets/icons/yieldFilled.svg";
import borrowFilledIcon from "../../assets/icons/borrowfilled.svg";
import borrowIcon from "../../assets/icons/borrow.svg";

import {
  Button,
  Container,
  Dropdown,
  DropdownButton,
  Form,
  Nav,
  Navbar,
  Offcanvas,
} from "react-bootstrap";
import { useLayoutEffect, useRef, useState } from "react";
import i18n from "../../utils/i18n";
import { useTranslation } from "react-i18next";
import i18next from "../../utils/i18n";
import FIcon from "../FIcon/FIcon";
import { useGlobalContext } from "../../contexts/sidebarContext";
function FNavbar({ switchTheme, theme, isAdmin }) {
  const { t } = useTranslation();
  let navigate = useNavigate();
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const changeLanguage = (lng) => {
    localStorage.setItem("i18nextLng", lng);

    i18n.changeLanguage(lng);
  };
  const getLanguage = i18next.language || window.localStorage.i18nextLng;

  const [width, setWidth] = useState(0);
  const ref = useRef(null);

  useLayoutEffect(() => {
    setWidth(ref.current.offsetWidth);
  }, []);
  const { isSidebarOpen, showSidebar } = useGlobalContext();

  return (
    <>
      <Navbar expand={"lg"} className="f_navbar" ref={ref}>
        <Container fluid="sm">
          <Navbar.Brand>
            <div
              className="navbar_logo"
              style={{
                justifyContent: "start",
                textAlign: "start",
                alignItems: "start",
                visibility: isAdmin == 1 ? (width > 992 ? "hidden" : "") : "",
              }}
            >
              {isAdmin == 1 && window.location.pathname == "/dashboard" && (
                <div onClick={showSidebar} style={{ display: "flex" }}>
                  <svg
                    width="21"
                    height="17"
                    viewBox="0 0 21 17"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <line
                      x1="1"
                      y1="1"
                      x2="20"
                      y2="1"
                      stroke="#4169E1"
                      stroke-width="2"
                      stroke-linecap="round"
                    />
                    <line
                      x1="1"
                      y1="8.5"
                      x2="20"
                      y2="8.5"
                      stroke="#4169E1"
                      stroke-width="2"
                      stroke-linecap="round"
                    />
                    <line
                      x1="1"
                      y1="16"
                      x2="20"
                      y2="16"
                      stroke="#4169E1"
                      stroke-width="2"
                      stroke-linecap="round"
                    />
                  </svg>
                </div>
              )}

              <h1
                onClick={() => {
                  navigate("/");
                  handleClose();
                }}
              >
                DOW
              </h1>
            </div>
          </Navbar.Brand>
          <div className="navbar-toggler">
            <div
              className="navbar-language"
              onClick={() => changeLanguage(getLanguage === "tr" ? "en" : "tr")}
            >
              {getLanguage === "tr" ? "EN" : "TR"}
            </div>
            <Navbar.Toggle
              onClick={handleShow}
              aria-controls={"offcanvasNavbar-expand-lg"}
            />
          </div>
          <Navbar.Offcanvas
            show={show}
            onHide={handleClose}
            id={"offcanvasNavbar-expand-lg"}
            aria-labelledby={"offcanvasNavbarLabel-expand-lg"}
            placement="end"
          >
            <Offcanvas.Header>
              <Offcanvas.Title id={"offcanvasNavbarLabel-expand-lg"}>
                <div className="navbar_logo">
                  <h1>DOW</h1> <h2>Digital Capital</h2>
                </div>
              </Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body style={{ alignItems: "center" }}>
              <Nav className="justify-content-center flex-grow-1 pe-3 gap-4">
                <div className="nav_icon_buttons">
                  <Nav.Link
                    className={
                      theme === "dark" &&
                      window.location.pathname !== "/dashboard"
                        ? "nav_icon_buttons-item dark_icon"
                        : "nav_icon_buttons-item"
                    }
                    onClick={() => {
                      navigate("/dashboard");
                      handleClose();
                    }}
                    active={window.location.pathname === "/dashboard"}
                  >
                    <img
                      alt=""
                      src={
                        window.location.pathname === "/dashboard"
                          ? homeFilledIcon
                          : homeIcon
                      }
                      width="14"
                    ></img>
                    <h1>{t("fnavbar_panel")}</h1>
                  </Nav.Link>
                  <Nav.Link
                    className={
                      theme === "dark" &&
                      window.location.pathname !== "/wallets"
                        ? "nav_icon_buttons-item dark_icon"
                        : "nav_icon_buttons-item"
                    }
                    onClick={() => {
                      navigate("/wallets");
                      handleClose();
                    }}
                    active={window.location.pathname === "/wallets"}
                  >
                    <img
                      alt=""
                      src={
                        window.location.pathname === "/wallets"
                          ? walletFilledIcon
                          : walletIcon
                      }
                      width="14"
                    ></img>
                    <h1>{t("fnavbar_wallets")}</h1>
                  </Nav.Link>
                  <Nav.Link
                    className={
                      theme === "dark" && window.location.pathname !== "/invest"
                        ? "nav_icon_buttons-item dark_icon"
                        : "nav_icon_buttons-item"
                    }
                    onClick={() => {
                      navigate("/invest");
                      handleClose();
                    }}
                    active={window.location.pathname === "/invest"}
                  >
                    <img
                      alt=""
                      src={
                        window.location.pathname === "/invest"
                          ? plusFilledIcon
                          : plusIcon
                      }
                      width="14"
                    ></img>{" "}
                    <h1>{t("fnavbar_invest")}</h1>
                  </Nav.Link>
                  <Nav.Link
                    className={
                      theme === "dark" && window.location.pathname !== "/yield"
                        ? "nav_icon_buttons-item dark_icon"
                        : "nav_icon_buttons-item"
                    }
                    onClick={() => {
                      navigate("/yield");
                      handleClose();
                    }}
                    active={window.location.pathname === "/yield"}
                  >
                    <img
                      alt=""
                      src={
                        window.location.pathname === "/yield"
                          ? yieldFilledIcon
                          : yieldIcon
                      }
                      width="14"
                    ></img>
                    <h1>{t("fnavbar_hub")}</h1>
                  </Nav.Link>
                  <Nav.Link
                    className={
                      theme === "dark" && window.location.pathname !== "/borrow"
                        ? "nav_icon_buttons-item dark_icon"
                        : "nav_icon_buttons-item"
                    }
                    onClick={() => {
                      navigate("/borrow");
                      handleClose();
                    }}
                    active={window.location.pathname === "/borrow"}
                  >
                    <img
                      alt=""
                      src={
                        window.location.pathname === "/borrow"
                          ? borrowFilledIcon
                          : borrowIcon
                      }
                      width="14"
                    ></img>
                    <h1>{t("fnavbar_borrow")}</h1>
                  </Nav.Link>
                  {/* 
                  <Nav.Link
                    className={
                      theme === 'dark' &&
                      window.location.pathname !== '/performance'
                        ? 'nav_icon_buttons-item dark_icon'
                        : 'nav_icon_buttons-item'
                    }
                    onClick={() => navigate('/performance')}
                    active={window.location.pathname === '/performance'}
                  >
                    <img alt="" src={graphIcon}></img>{' '}
                  </Nav.Link>
                  */}
                </div>
              </Nav>
              <Form.Group style={{ display: "flex", position: "relative" }}>
                <Form.Control
                  name="wallet"
                  type="text"
                  placeholder="search"
                  style={{
                    width: "180px",
                    height: "30px",
                    borderRadius: "10px",
                    border: "0",
                    fontSize: "10px",
                  }}
                />
                <div style={{ position: "absolute", top: "0", right: "10px" }}>
                  <svg
                    width="8"
                    height="10"
                    viewBox="0 0 8 10"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M6.45277 3.5235C6.45277 5.30539 5.00827 6.74989 3.22638 6.74989C1.4445 6.74989 0 5.30539 0 3.5235C0 1.74162 1.4445 0.297119 3.22638 0.297119C5.00827 0.297119 6.45277 1.74162 6.45277 3.5235ZM0.432875 3.5235C0.432875 5.06632 1.68357 6.31701 3.22638 6.31701C4.7692 6.31701 6.01989 5.06632 6.01989 3.5235C6.01989 1.98069 4.7692 0.729994 3.22638 0.729994C1.68357 0.729994 0.432875 1.98069 0.432875 3.5235Z"
                      fill="#252525"
                    />
                    <path
                      d="M4.96387 6.04733L5.3666 5.75718L8.0002 9.41276L7.59746 9.70291L4.96387 6.04733Z"
                      fill="#252525"
                    />
                  </svg>
                </div>
              </Form.Group>
              <div className="right_side">
                <div
                  onClick={() =>
                    changeLanguage(getLanguage === "tr" ? "en" : "tr")
                  }
                  style={{
                    fontSize: "16px",
                    fontWeight: "300",
                    lineHeight: "19px",
                    textTransform: "uppercase",
                    cursor: "pointer",
                    borderBottom: "2px solid #4169e1",
                    marginTop: "2px",
                    marginLeft: "25px",
                    marginRight: "25px",
                  }}
                >
                  {getLanguage === "tr" ? "EN" : "TR"}
                </div>
                <Button
                  className={
                    theme === "dark"
                      ? "right_side-icons dark_icon"
                      : "right_side-icons"
                  }
                  onClick={() => {
                    navigate("/settings");
                    handleClose();
                  }}
                >
                  <img alt="" src={settingsIcon}></img>
                </Button>
              </div>
            </Offcanvas.Body>
          </Navbar.Offcanvas>
        </Container>
      </Navbar>
    </>
  );
}

export default FNavbar;
