import React, { useEffect, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";

import "./DInternalModal.scss";
import {
  getUserByIdService,
  getUsersRequest,
  internalTransferRequest,
  updateUserAdminRequest,
} from "../../../services/admin/adminService";
import FTextInput from "../../FTextInput/FTextInput";
import {
  Autocomplete,
  Chip,
  FormLabel,
  Grid,
  LinearProgress,
  MenuItem,
  TextField,
} from "@mui/material";

const DInternalModal = (props) => {
  const dispatch = useDispatch();
  const { userList, loadingAll } = useSelector((state) => state.admin);
  const [buttonDisabled, setButtonDisabled] = useState(true);

  const initialFormData = {
    fromAccountId: "",
    toAccountId: "",
    amount: 0,
    token: "",
  };

  const [formData, setFormData] = useState({ ...initialFormData });
  useEffect(() => {
    if (props.show) {
      dispatch(
        getUsersRequest({
          filter: {},
          sort: "DESC",
          limit: 1000,
          page: 1,
          search: "",
        })
      );
    }
  }, [props.show]);

  const handleOnChange = (name, value) => {
    setButtonDisabled(false);
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  function handleSubmit(e) {
    e.preventDefault();
    //dispatch(internalTransferRequest(formData));
    // Swal.fire("User updated!", "User updated successfully.", "success").then(
    //   () => window.location.reload()
    // );

    Swal.fire({
      title: "Are you sure?",
      showCancelButton: true,
      confirmButtonText: "Yes",
      denyButtonText: `Don't save`,
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        Swal.fire("Success!", "", "success");
        dispatch(internalTransferRequest(formData));
      } else if (result.isDenied) {
        Swal.fire("Canceled", "", "info");
      }
    });
  }

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Internal Transaction
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Grid container spacing={3}>
          <Grid item sm={12} xs={12}>
            <TextField
              select
              fullWidth
              color="info"
              size="medium"
              name="token"
              placeholder="Select Fund"
              onChange={(_, value) => {
                handleOnChange("token", value?.props?.value);
              }}
              label="Select Fund"
              SelectProps={{
                multiple: false,
              }}
            >
              <MenuItem value={"BTCp"}>BTCp</MenuItem>
              <MenuItem value={"TRQ"}>TRQ</MenuItem>
              <MenuItem value={"QNT"}>QNT</MenuItem>
              <MenuItem value={"C5"}>C5</MenuItem>
              <MenuItem value={"TUSD"}>USD</MenuItem>
            </TextField>
          </Grid>
          {loadingAll == false ? (
            <Grid item sm={6} xs={12}>
              <Autocomplete
                fullWidth
                disablePortal
                options={userList}
                getOptionLabel={(option) => option.name + " " + option.surname}
                onChange={(_, value) => {
                  handleOnChange("fromAccountId", value?.account_id);
                }}
                renderInput={(params) => (
                  <TextField
                    color="info"
                    label="From"
                    variant="outlined"
                    placeholder="From"
                    {...params}
                    size="medium"
                  />
                )}
              />
            </Grid>
          ) : (
            <Grid item sm={6} xs={12}>
              <LinearProgress />
            </Grid>
          )}
          {loadingAll == false ? (
            <Grid item sm={6} xs={12}>
              <Autocomplete
                fullWidth
                disablePortal
                options={userList}
                getOptionLabel={(option) => option.name + " " + option.surname}
                onChange={(_, value) => {
                  handleOnChange("toAccountId", value?.account_id);
                }}
                renderInput={(params) => (
                  <TextField
                    color="info"
                    label="To"
                    variant="outlined"
                    placeholder="To"
                    {...params}
                    size="medium"
                  />
                )}
              />
            </Grid>
          ) : (
            <Grid item sm={6} xs={12}>
              <LinearProgress />
            </Grid>
          )}

          <Grid item sm={6} xs={12}>
            {userList && formData?.fromAccountId && (
              <Grid container spacing={1}>
                <Grid item sm={12} xs={12}>
                  <Chip
                    label={
                      "C5 Balance: " +
                      userList
                        .filter(
                          (user) => user.account_id == formData?.fromAccountId
                        )[0]
                        ?.c5_balance?.toFixed(2)
                    }
                    variant="outlined"
                    color="error"
                  />
                </Grid>
                <Grid item sm={12} xs={12}>
                  <Chip
                    label={
                      "BTCp Balance: " +
                      userList
                        .filter(
                          (user) => user.account_id == formData?.fromAccountId
                        )[0]
                        ?.btc_balance?.toFixed(2)
                    }
                    variant="outlined"
                    color="error"
                  />
                </Grid>
                <Grid item sm={12} xs={12}>
                  <Chip
                    label={
                      "QNT Balance: " +
                      userList
                        .filter(
                          (user) => user.account_id == formData?.fromAccountId
                        )[0]
                        ?.qnt_balance?.toFixed(2)
                    }
                    variant="outlined"
                    color="error"
                  />
                </Grid>
                <Grid item sm={12} xs={12}>
                  <Chip
                    label={
                      "TRQ Balance: " +
                      userList
                        .filter(
                          (user) => user.account_id == formData?.fromAccountId
                        )[0]
                        ?.trq_balance?.toFixed(2)
                    }
                    variant="outlined"
                    color="error"
                  />
                </Grid>
                <Grid item sm={12} xs={12}>
                  <Chip
                    label={
                      "USD Balance: " +
                      userList
                        .filter(
                          (user) => user.account_id == formData?.fromAccountId
                        )[0]
                        ?.tusd_balance?.toFixed(2)
                    }
                    variant="outlined"
                    color="error"
                  />
                </Grid>
              </Grid>
            )}
          </Grid>
          <Grid item sm={6} xs={12}>
            {userList && formData?.toAccountId && (
              <Grid container spacing={1}>
                <Grid item sm={12} xs={12}>
                  <Chip
                    label={
                      "C5 Balance: " +
                      userList
                        .filter(
                          (user) => user.account_id == formData?.toAccountId
                        )[0]
                        ?.c5_balance?.toFixed(2)
                    }
                    variant="outlined"
                    color="success"
                  />
                </Grid>
                <Grid item sm={12} xs={12}>
                  <Chip
                    label={
                      "BTCp Balance: " +
                      userList
                        .filter(
                          (user) => user.account_id == formData?.toAccountId
                        )[0]
                        ?.btc_balance?.toFixed(2)
                    }
                    variant="outlined"
                    color="success"
                  />
                </Grid>
                <Grid item sm={12} xs={12}>
                  <Chip
                    label={
                      "QNT Balance: " +
                      userList
                        .filter(
                          (user) => user.account_id == formData?.toAccountId
                        )[0]
                        ?.qnt_balance?.toFixed(2)
                    }
                    variant="outlined"
                    color="success"
                  />
                </Grid>
                <Grid item sm={12} xs={12}>
                  <Chip
                    label={
                      "TRQ Balance: " +
                      userList
                        .filter(
                          (user) => user.account_id == formData?.toAccountId
                        )[0]
                        ?.trq_balance?.toFixed(2)
                    }
                    variant="outlined"
                    color="success"
                  />
                </Grid>
                <Grid item sm={12} xs={12}>
                  <Chip
                    label={
                      "USD Balance: " +
                      userList
                        .filter(
                          (user) => user.account_id == formData?.toAccountId
                        )[0]
                        ?.tusd_balance?.toFixed(2)
                    }
                    variant="outlined"
                    color="success"
                  />
                </Grid>
              </Grid>
            )}
          </Grid>
          <Grid item sm={12} xs={12}>
            <TextField
              fullWidth
              name="amount"
              label="Amount"
              color="info"
              size="medium"
              placeholder="Amount"
              type="number"
              onChange={(e) => {
                handleOnChange(e.target.name, e.target.value);
              }}
            />
          </Grid>
        </Grid>
      </Modal.Body>
      <Modal.Footer>
        <Button
          disabled={buttonDisabled}
          variant="primary"
          onClick={handleSubmit}
        >
          Send Transaction
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default DInternalModal;
