import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import FIcon from "../FIcon/FIcon";
import FTextInput from "../FTextInput/FTextInput";
import "./FFooter.scss";

function FFooter({}) {
  let navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <>
      <div className="newsletter">
        <div className="container newsletter-inner">
          <div>
            <h1>{t("newsletter_t")}</h1>
            <h2>{t("newsletter_d_one")}</h2>
          </div>
          <div className="d-flex newsletter-email">
            <div className="newsletter-desc">{t("newsletter_d_two")}</div>
            <div>
              <FTextInput label="E-mail" id="name">
                <Form.Control
                  name="name"
                  type="text"
                  placeholder="name@example.com"
                />
              </FTextInput>
              <Button className="right_side-launch ml-0 w-full mt-2">
                {t("newsletter_button")}
              </Button>
            </div>
          </div>
        </div>
      </div>
      <div className="footer">
        <div className="container">
          <div className="footer-logos">
            <div
              className="footer-logo"
              style={{
                marginTop: "-5px",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <h1>DOW</h1> <h2>Digital Capital</h2>
            </div>
            <div className="footer-links">
              <div className="link heading">{t("footer_investment")}</div>
              <div
                className="link"
                onClick={() => navigate("funds/c5-index?id=1")}
              >
                {t("footer_c5")}
              </div>
              <div
                className="link"
                onClick={() => navigate("funds/bitcoin-protected?id=2")}
              >
                {t("footer_bitcoin")}
              </div>
              <div
                className="link"
                onClick={() => navigate("funds/crypto-trading?id=3")}
              >
                {t("footer_trading")}
              </div>
              <div
                className="link"
                onClick={() => navigate("funds/arbitrage?id=4")}
              >
                {t("footer_arbitrage")}
              </div>
            </div>
            <div className="footer-links">
              <div className="link heading">{t("footer_yield")}</div>
              <div
                className="link"
                onClick={() => navigate("yield-hub/leveraged-staking?id=5")}
              >
                {t("footer_staking")}
              </div>
              <div
                className="link"
                onClick={() => navigate("yield-hub/liquidty-providing?id=6")}
              >
                {t("footer_liq")}
              </div>
              <div
                className="link"
                onClick={() => navigate("yield-hub/option-strategies?id=7")}
              >
                {t("footer_option")}
              </div>
            </div>
            <div className="footer-links">
              <div className="link heading">{t("footer_resource")}</div>
              <div className="link" onClick={() => navigate("login")}>
                {t("footer_investor")}
              </div>
              <div className="link" onClick={() => navigate("news")}>
                {t("footer_news")}
              </div>
              <div className="link">
                <a className="link" href="mailto:dev@tubu.io">
                  {t("footer_contact")}
                </a>
              </div>
            </div>
            <div className="footer-links">
              <div className="link heading">{t("footer_company")}</div>
              <div className="link">{t("footer_feedback")}</div>
            </div>
            <div className="footer-links">
              <div className="link heading">{t("footer_legal")}</div>
              <div className="link" onClick={() => navigate("terms")}>
                {t("footer_terms")}
              </div>
              <div className="link">{t("footer_cookie")}</div>
              <div className="link">{t("footer_privacy")}</div>
            </div>
          </div>
        </div>
      </div>
      <div className="footer-social">
        <div className="container">
          <div className="footer-social-inner">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="#ffffff"
              style={{ cursor: "pointer" }}
            >
              <path d="M12 2.163c3.204 0 3.584.012 4.85.07 3.252.148 4.771 1.691 4.919 4.919.058 1.265.069 1.645.069 4.849 0 3.205-.012 3.584-.069 4.849-.149 3.225-1.664 4.771-4.919 4.919-1.266.058-1.644.07-4.85.07-3.204 0-3.584-.012-4.849-.07-3.26-.149-4.771-1.699-4.919-4.92-.058-1.265-.07-1.644-.07-4.849 0-3.204.013-3.583.07-4.849.149-3.227 1.664-4.771 4.919-4.919 1.266-.057 1.645-.069 4.849-.069zm0-2.163c-3.259 0-3.667.014-4.947.072-4.358.2-6.78 2.618-6.98 6.98-.059 1.281-.073 1.689-.073 4.948 0 3.259.014 3.668.072 4.948.2 4.358 2.618 6.78 6.98 6.98 1.281.058 1.689.072 4.948.072 3.259 0 3.668-.014 4.948-.072 4.354-.2 6.782-2.618 6.979-6.98.059-1.28.073-1.689.073-4.948 0-3.259-.014-3.667-.072-4.947-.196-4.354-2.617-6.78-6.979-6.98-1.281-.059-1.69-.073-4.949-.073zm0 5.838c-3.403 0-6.162 2.759-6.162 6.162s2.759 6.163 6.162 6.163 6.162-2.759 6.162-6.163c0-3.403-2.759-6.162-6.162-6.162zm0 10.162c-2.209 0-4-1.79-4-4 0-2.209 1.791-4 4-4s4 1.791 4 4c0 2.21-1.791 4-4 4zm6.406-11.845c-.796 0-1.441.645-1.441 1.44s.645 1.44 1.441 1.44c.795 0 1.439-.645 1.439-1.44s-.644-1.44-1.439-1.44z" />
            </svg>
            <FIcon name="telegram" size="25" color="white" />
            <FIcon name="youtube" size="25" color="white" />
            <FIcon name="twitter" size="25" color="white" />
          </div>
        </div>
      </div>
    </>
  );
}

export default FFooter;

/* 
            <div className="footer-logos">
              <FIcon name="twitter" color="white" size="20" />
              <a
                href="https://www.linkedin.com/company/dow-digital-capital/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FIcon name="in" color="white" size="20" />
              </a>

              <a
                href="https://www.youtube.com/channel/UCnzB3kk_cuMRiUCrUwu4DDw"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FIcon name="youtube" color="white" size="20" />
              </a>
              <FIcon name="telegram" color="white" size="20" />
            </div> */
