import React, { useEffect } from "react";
import { Button, Container } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { getFundByIdService } from "../../../../services/fund/fundService";
import Spinner from "react-bootstrap/Spinner";
import "./NewsDetails.scss";
import { getNewsByIdRequest } from "../../../../services/news/newsService";
import FIcon from "../../../../components/FIcon/FIcon";
import { useNavigate } from "react-router-dom";
import { convertFromRaw, EditorState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import { useTranslation } from "react-i18next";
import i18next from "i18next";

const NewsDetails = ({ theme }) => {
  let navigate = useNavigate();
  const newsId = window.location.href.split("newsDetails?id=")[1];

  const dispatch = useDispatch();
  const { news, loading } = useSelector((state) => state.news);

  const initialFilters = {
    id: newsId,
  };

  useEffect(() => {
    dispatch(getNewsByIdRequest(initialFilters));
  }, [newsId]);
  const { t } = useTranslation();

  const getLanguage = i18next.language || window.localStorage.i18nextLng;

  return (
    <div className="f_newsdetails">
      <Container className="f_newsdetails_container">
        {loading ? (
          <Spinner animation="grow" />
        ) : (
          <>
            <div className="f_newsdetails">
              <div className="f_newsdetails-content">
                {news[0]?.image && (
                  <img
                    src={
                      process.env.REACT_APP_DOWNLOAD_IMAGE_BASE_URL +
                      news[0]?.image
                    }
                  ></img>
                )}

                <div className="f_newsdetails-title">
                  <h1>{news[0]?.title}</h1>
                  <div className="f_newsdetails-title-share">
                    <h2>
                      {getLanguage === "tr"
                        ? new Date(news[0]?.c_date).toLocaleDateString(
                            "tr-TR",
                            {
                              year: "numeric",
                              month: "long",
                              day: "numeric",
                            }
                          )
                        : new Date(news[0]?.c_date).toLocaleDateString(
                            "en-EN",
                            {
                              year: "numeric",
                              month: "long",
                              day: "numeric",
                            }
                          )}
                    </h2>
                    <h2>Dow Digital Capital</h2>
                  </div>
                </div>

                {news[0]?.content && (
                  <Editor
                    toolbarHidden
                    readOnly
                    toolbarClassName="toolbarClassName"
                    wrapperClassName="wrapperClassName"
                    editorClassName="editorClassName"
                    editorState={EditorState.createWithContent(
                      convertFromRaw(JSON.parse(news[0]?.content))
                    )}
                  />
                )}
              </div>
            </div>
          </>
        )}
      </Container>
    </div>
  );
};

export default NewsDetails;
