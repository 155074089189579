import React, { useEffect } from "react";
import "./Yield.scss";
import Card from "react-bootstrap/Card";
import { useDispatch, useSelector } from "react-redux";
import { getFundsService } from "../../../services/fund/fundService";
import Spinner from "react-bootstrap/Spinner";
import { useNavigate } from "react-router-dom";
import { Container } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import WYield from "../../../components/WalletComponents/WYield/WYield";
import UserLabel from "../../../components/UserLabel/UserLabel";

const Yield = () => {
  const dispatch = useDispatch();
  let navigate = useNavigate();

  const { fundList, loading } = useSelector((state) => state.fund);
  const initialFilters = {
    filter: {},
  };

  useEffect(() => {
    dispatch(getFundsService(initialFilters));
  }, []);

  const { t } = useTranslation();
  const getLanguage = i18next.language || window.localStorage.i18nextLng;
  return (
    <Container>
      <div className="f_yield">
        <UserLabel text={t("yield_hub")} />
        <div className="f_invest text-center">
          <div
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <div
              style={{
                maxWidth: "900px",
                width: "100%",
              }}
            >
              <div>
                <WYield />
              </div>
              <div className="f_invest-funds">
                {loading ? (
                  <Spinner animation="grow" />
                ) : (
                  fundList?.map(function (item, index) {
                    return (
                      index > 3 && (
                        <Card
                          key={index}
                          onClick={() =>
                            index == 4
                              ? navigate(
                                  "/yield-hub/leveraged-staking?id=" + item?.id
                                )
                              : index == 5
                              ? navigate(
                                  "/yield-hub/liquidty-providing?id=" + item?.id
                                )
                              : index == 6
                              ? navigate(
                                  "/yield-hub/option-strategies?id=" + item?.id
                                )
                              : ""
                          }
                        >
                          <Card.Body>
                            <div className="card-inner">
                              {item?.id == 5 && (
                                <svg
                                  style={{ width: "131px", height: "93px" }}
                                  width="92"
                                  height="92"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M46 90.25C70.4386 90.25 90.25 70.4386 90.25 46C90.25 21.5614 70.4386 1.75 46 1.75C21.5614 1.75 1.75 21.5614 1.75 46C1.75 70.4386 21.5614 90.25 46 90.25Z"
                                    stroke="#4169E1"
                                    stroke-width="3"
                                  />
                                  <path
                                    d="M46 57.0625C52.1096 57.0625 57.0625 52.1096 57.0625 46C57.0625 39.8903 52.1096 34.9375 46 34.9375C39.8903 34.9375 34.9375 39.8903 34.9375 46C34.9375 52.1096 39.8903 57.0625 46 57.0625Z"
                                    stroke="#4169E1"
                                    stroke-width="3"
                                  />
                                  <path
                                    d="M79.1875 46H57.0625"
                                    stroke="#4169E1"
                                    stroke-width="3"
                                  />
                                </svg>
                              )}
                              {item?.id == 6 && (
                                <svg
                                  style={{
                                    width: "131px",
                                    height: "70px",
                                    marginTop: "30px",
                                  }}
                                  width="97"
                                  height="69"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M38.25 26.375L58.75 46.875L38.25 67.375"
                                    stroke="#4169E1"
                                    stroke-width="3"
                                  />
                                  <path
                                    d="M36.562 1.53583C25.1106 3.07005 15.2805 6.74995 9.1115 11.8119C2.94254 16.8738 0.908585 22.929 3.43172 28.7207C5.95486 34.5125 12.8413 39.5965 22.662 42.917C32.4826 46.2375 44.483 47.5397 56.1731 46.5537"
                                    stroke="#4169E1"
                                    stroke-width="3"
                                    stroke-linecap="round"
                                  />
                                  <path
                                    d="M88.4453 35.3438C91.9831 32.28 94.0633 28.8509 94.5261 25.3208C94.9889 21.7907 93.8214 18.2551 91.1139 14.9869C88.4063 11.7186 84.2315 8.80564 78.9123 6.4732C73.593 4.14076 67.2718 2.45141 60.4382 1.53583"
                                    stroke="#4169E1"
                                    stroke-width="3"
                                    stroke-linecap="round"
                                  />
                                </svg>
                              )}
                              {item?.id == 7 && (
                                <svg
                                  style={{ width: "132px", height: "93px" }}
                                  width="63"
                                  height="95"
                                  viewBox="0 0 63 95"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M61.8125 94.06H4.21876C3.61554 94.06 3.12751 93.5719 3.12751 92.9687V87.664C3.12751 79.9586 9.39613 73.69 17.1016 73.69H17.6078L12.3364 63.1442C12.1606 62.7957 12.1879 62.3773 12.4031 62.053L29.1841 36.8785L5.15845 40.9949C4.68254 41.0768 4.2127 40.8373 3.99748 40.4068L0.208415 32.8287C-0.0189285 32.3771 0.0962593 31.8254 0.484259 31.501L23.2186 12.5557C23.4126 12.392 23.6612 12.3041 23.9158 12.3041H30.4027V2.02819C30.4027 1.62503 30.624 1.25825 30.9756 1.06728C31.3272 0.879344 31.7577 0.897531 32.0941 1.11881L46.1319 10.4126C50.994 15.2444 56.8322 21.0857 56.8322 34.0624V62.6562C56.8322 62.8927 56.7564 63.12 56.6139 63.311L48.8388 73.69H48.9297C56.6351 73.69 62.9038 79.9586 62.9038 87.664V92.9687C62.9038 93.5719 62.4157 94.06 61.8125 94.06ZM5.31001 91.8775H60.7213V87.664C60.7213 81.162 55.4317 75.8725 48.9297 75.8725H17.1016C10.5995 75.8725 5.31001 81.162 5.31001 87.664V91.8775ZM20.051 73.69H46.1137L54.6588 62.2925V57.685H46.6563V55.5025H54.6588V45.56H46.6563V43.3775H54.6588V34.0655C54.6588 33.8533 54.6588 33.6411 54.6557 33.435H46.6563V31.2525H54.5557C53.807 21.1402 49.0237 16.3569 44.7648 12.098L32.5913 4.05913V13.3984C32.5913 14.0016 32.1032 14.4897 31.5 14.4897H24.3159L2.55157 32.6287L5.59192 38.7093L31.3151 34.2989C31.7546 34.2292 32.179 34.4171 32.4155 34.7808C32.6549 35.1476 32.6519 35.6175 32.4094 35.9812L14.5765 62.735L20.051 73.69Z"
                                    fill="#4169E1"
                                  />
                                </svg>
                              )}

                              <div className="card_subhead">
                                <Card.Title>
                                  {item?.id != 4
                                    ? getLanguage === "tr"
                                      ? item?.token + " " + item?.name
                                      : item?.token + " " + item?.name_en
                                    : getLanguage === "tr"
                                    ? item?.name
                                    : item?.name_en}
                                </Card.Title>

                                <Card.Text>
                                  {item?.id == 5 && <>{t("yield_staking_s")}</>}
                                  {item?.id == 6 && <>{t("yield_staking_s")}</>}
                                  {item?.id == 7 && <>{t("yield_option_s")}</>}
                                </Card.Text>
                              </div>
                            </div>
                          </Card.Body>
                          <div className="card_arrow">
                            <svg
                              width="13"
                              height="24"
                              viewBox="0 0 13 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M2.65999 23.2603C2.26224 23.7073 1.56354 23.7073 1.16578 23.2603L0.591401 22.6147C0.254205 22.2357 0.254205 21.6643 0.591401 21.2853L8.21678 12.7147C8.55397 12.3357 8.55397 11.7643 8.21678 11.3853L0.591401 2.81471C0.254205 2.43572 0.254205 1.86429 0.591401 1.48529L1.16578 0.83971C1.56354 0.392655 2.26224 0.392655 2.65999 0.83971L12.0426 11.3853C12.3797 11.7643 12.3797 12.3357 12.0426 12.7147L2.65999 23.2603Z"
                                fill="#4169E1"
                              />
                            </svg>
                          </div>
                        </Card>
                      )
                    );
                  })
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default Yield;
