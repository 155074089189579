// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.twoFa_modal .modal-content {
  background-color: var(--background-secondary) !important;
}
.twoFa_modal .modal-body h1 {
  font-size: 20px;
  font-weight: 700;
  color: var(--background-royalblue) !important;
}
.twoFa_modal .modal-body h2 {
  font-size: 16px;
  font-weight: 500;
}
.twoFa_modal .modal-footer .verify_button {
  background-color: var(--background-royalblue) !important;
  border: none !important;
  font-size: 16px !important;
  font-weight: 700 !important;
  border-radius: 8px !important;
}
.twoFa_modal .modal-footer .verify_button:hover {
  background-color: var(--background-darkroyalblue) !important;
}`, "",{"version":3,"sources":["webpack://./src/components/F2FAModal/F2FAModal.scss"],"names":[],"mappings":"AACE;EACE,wDAAA;AAAJ;AAII;EACE,eAAA;EACA,gBAAA;EACA,6CAAA;AAFN;AAKI;EACE,eAAA;EACA,gBAAA;AAHN;AAQI;EACE,wDAAA;EACA,uBAAA;EACA,0BAAA;EACA,2BAAA;EACA,6BAAA;AANN;AAQM;EACE,4DAAA;AANR","sourcesContent":[".twoFa_modal {\n  .modal-content {\n    background-color: var(--background-secondary) !important;\n  }\n\n  .modal-body {\n    h1 {\n      font-size: 20px;\n      font-weight: 700;\n      color: var(--background-royalblue) !important;\n    }\n\n    h2 {\n      font-size: 16px;\n      font-weight: 500;\n    }\n  }\n\n  .modal-footer {\n    .verify_button {\n      background-color: var(--background-royalblue) !important;\n      border: none !important;\n      font-size: 16px !important;\n      font-weight: 700 !important;\n      border-radius: 8px !important;\n\n      &:hover {\n        background-color: var(--background-darkroyalblue) !important;\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
