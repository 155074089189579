import React, { useState } from "react";
import { Button, Form } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import FCheckbox from "../../../components/FCheckbox/FCheckbox";
import FTextInput from "../../../components/FTextInput/FTextInput";
import { signupRequest } from "../../../services/authentication/authenticationService";
import "./SignUp.scss";
import { validate } from "../../../utils/validate";
import wolf from "../../../assets/images/model.png";
import { useTranslation } from "react-i18next";
import loginhero from "../../../assets/images/loginhero.png";

const SignUp = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const refCode = window.location.href.split("signup?ref=")[1];

  const initialFormData = {
    name: "",
    surname: "",
    email: "",
    password: "",
    confirm_password: "",
    referral: refCode,
  };

  const [formData, setFormData] = useState({ ...initialFormData });
  const [formDataErrors, setFormDataErrors] = useState({});

  const [checkboxTop, setCheckboxTop] = useState(false);
  const [checkboxBottom, setCheckboxBottom] = useState(false);

  const formDataValidate = {
    email: {
      isEmail: true,
    },
    password: {
      minLength: 6,
      isCapital: true,
    },
    confirm_password: {
      isPassword: formData.password,
    },
  };

  const handleOnChange = (name, value) => {
    formDataErrors[name] &&
      setFormDataErrors((prevState) => ({
        ...prevState,
        [name]: "",
      }));
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleCheckBoxTop = (value) => {
    setCheckboxTop(!value.checkboxTop);
  };

  const handleCheckBoxBottom = (value) => {
    setCheckboxBottom(!value.checkboxBottom);
  };

  const checkValidation = () => {
    const errorMsgs = validate(formData, formDataValidate);
    if (Object.keys(errorMsgs).length > 0) {
      setFormDataErrors({ ...errorMsgs });
      return false;
    }
    return true;
  };

  function handleSubmit(e) {
    const check = checkValidation();
    if (check) {
      e.preventDefault();
      dispatch(signupRequest(formData));
      navigate("/confirmation");
    }
  }
  const { t } = useTranslation();

  return (
    <div className="flogin">
      <div className="flogin-hero">
        <div className="flogin-hero-container">
          <h1>
            THE FUTURE OF <br />
            DIGITAL INVESTING
          </h1>
          <img src={loginhero} />
        </div>
      </div>
      <div className="flogin-login">
        <h1 className=" text-start mb-3">
          <svg
            width="22"
            height="24"
            viewBox="0 0 22 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M21.0607 13.0607C21.6464 12.4749 21.6464 11.5251 21.0607 10.9393L11.5147 1.3934C10.9289 0.807612 9.97918 0.807612 9.3934 1.3934C8.80761 1.97918 8.80761 2.92893 9.3934 3.51472L17.8787 12L9.3934 20.4853C8.80761 21.0711 8.80761 22.0208 9.3934 22.6066C9.97918 23.1924 10.9289 23.1924 11.5147 22.6066L21.0607 13.0607ZM0 13.5H20V10.5H0V13.5Z"
              fill="#4169E1"
            />
          </svg>
          {t("signup_title")}
        </h1>
        <Form>
          <div className="d-flex gap-3 mb-3 justify-content-between">
            <FTextInput label={t("signup_name")} id="name">
              <Form.Control
                name="name"
                value={formData.name || ""}
                onChange={(e) => handleOnChange(e.target.name, e.target.value)}
                type="text"
                autocomplete="new-password"
                placeholder="name@example.com"
              />
            </FTextInput>
            <FTextInput label={t("signup_surname")} id="surname">
              <Form.Control
                value={formData.surname || ""}
                name="surname"
                onChange={(e) => handleOnChange(e.target.name, e.target.value)}
                type="text"
                autocomplete="new-password"
                placeholder="name@example.com"
              />
            </FTextInput>
          </div>
          <div className=" mb-3" id="signupemail">
            <FTextInput label={t("signup_email")}>
              <Form.Control
                name="email"
                value={formData.email || ""}
                onChange={(e) => handleOnChange(e.target.name, e.target.value)}
                type="email"
                autocomplete="new-password"
                placeholder="name@example.com"
                isInvalid={formDataErrors.email}
              />
            </FTextInput>
            <Form.Text className="text-danger">
              {formDataErrors?.email}
            </Form.Text>
          </div>
          <div className="mb-3" id="signuppassword" n>
            <FTextInput label={t("signup_pass")}>
              <Form.Control
                name="password"
                value={formData.password || ""}
                onChange={(e) => handleOnChange(e.target.name, e.target.value)}
                type="password"
                placeholder="name@example.com"
                isInvalid={formDataErrors.password}
              />{" "}
            </FTextInput>
            <Form.Text className="text-danger">
              {formDataErrors?.password}
            </Form.Text>
          </div>
          <div className="mb-3" id="confirm_password">
            <FTextInput label={t("signup_confirm")}>
              <Form.Control
                name="confirm_password"
                value={formData.confirm_password || ""}
                onChange={(e) => handleOnChange(e.target.name, e.target.value)}
                type="password"
                placeholder="name@example.com"
                isInvalid={formDataErrors.confirm_password}
              />{" "}
            </FTextInput>
            <Form.Text className="text-danger">
              {formDataErrors?.confirm_password}
            </Form.Text>
          </div>
          <div className=" mb-3" id="referral">
            <FTextInput label={t("signup_referral")}>
              <Form.Control
                name="referral"
                value={formData.referral || ""}
                placeholder="name@example.com"
                onChange={(e) => handleOnChange(e.target.name, e.target.value)}
                type="text"
                autocomplete="new-password"
              />
            </FTextInput>
          </div>
          <div className="mb-3 text-start">
            <FCheckbox
              name="termsTop"
              value={checkboxTop}
              handleChange={(e) => handleCheckBoxTop({ checkboxTop })}
            >
              <>
                <span onClick={() => {}}>{t("signup_terms1")}</span>
                {t("signup_terms2")}
              </>
            </FCheckbox>
          </div>

          <div className="mb-3 text-start">
            <FCheckbox
              name="termsBottom"
              value={checkboxBottom}
              handleChange={(e) => handleCheckBoxBottom({ checkboxBottom })}
            >
              {t("signup_privacy")}
            </FCheckbox>
          </div>

          <div className="mb-3 text-center f_login-loginText">
            <Button
              disabled={
                formData.name == "" ||
                formData.surname == "" ||
                formData.email == "" ||
                formData.password == "" ||
                checkboxTop == false ||
                checkboxBottom == false
              }
              onClick={handleSubmit}
              className="login-button"
            >
              {t("signup_create")}
            </Button>
          </div>
        </Form>
        {/* <div className="mb-3 text-center f_login-loginText">
            Are you a US citizen? <FIcon name="out" color="red" size="16" />
          </div> */}
      </div>
    </div>
  );
};

export default SignUp;

/*
    <div className="f_login-container">
      <div className="f_login">
        <div className="f_login-wrap">
          <h1 className="f_login-title text-start mb-3">{t('signup_title')}</h1>
          <Form>
            <div className="d-flex gap-3 mb-3 justify-content-between">
              <FTextInput label={t('signup_name')} id="name">
                <Form.Control
                  name="name"
                  value={formData.name || ''}
                  onChange={(e) =>
                    handleOnChange(e.target.name, e.target.value)
                  }
                  type="text"
                  autocomplete="new-password"
                  placeholder="name@example.com"
                />
              </FTextInput>
              <FTextInput label={t('signup_surname')} id="surname">
                <Form.Control
                  value={formData.surname || ''}
                  name="surname"
                  onChange={(e) =>
                    handleOnChange(e.target.name, e.target.value)
                  }
                  type="text"
                  autocomplete="new-password"
                  placeholder="name@example.com"
                />
              </FTextInput>
            </div>
            <div className=" mb-3" id="signupemail">
              <FTextInput label={t('signup_email')}>
                <Form.Control
                  name="email"
                  value={formData.email || ''}
                  onChange={(e) =>
                    handleOnChange(e.target.name, e.target.value)
                  }
                  type="email"
                  autocomplete="new-password"
                  placeholder="name@example.com"
                  isInvalid={formDataErrors.email}
                />
              </FTextInput>
              <Form.Text className="text-danger">
                {formDataErrors?.email}
              </Form.Text>
            </div>
            <div className="mb-3" id="signuppassword" n>
              <FTextInput label={t('signup_pass')}>
                <Form.Control
                  name="password"
                  value={formData.password || ''}
                  onChange={(e) =>
                    handleOnChange(e.target.name, e.target.value)
                  }
                  type="password"
                  placeholder="name@example.com"
                  isInvalid={formDataErrors.password}
                />{' '}
              </FTextInput>
              <Form.Text className="text-danger">
                {formDataErrors?.password}
              </Form.Text>
            </div>
            <div className="mb-3" id="confirm_password">
              <FTextInput label={t('signup_confirm')}>
                <Form.Control
                  name="confirm_password"
                  value={formData.confirm_password || ''}
                  onChange={(e) =>
                    handleOnChange(e.target.name, e.target.value)
                  }
                  type="password"
                  placeholder="name@example.com"
                  isInvalid={formDataErrors.confirm_password}
                />{' '}
              </FTextInput>
              <Form.Text className="text-danger">
                {formDataErrors?.confirm_password}
              </Form.Text>
            </div>
            <div className=" mb-3" id="referral">
              <FTextInput label={t('signup_referral')}>
                <Form.Control
                  name="referral"
                  value={formData.referral || ''}
                  placeholder="name@example.com"
                  onChange={(e) =>
                    handleOnChange(e.target.name, e.target.value)
                  }
                  type="text"
                  autocomplete="new-password"
                />
              </FTextInput>
            </div>
            <div className="mb-3 text-start">
              <FCheckbox
                name="termsTop"
                value={checkboxTop}
                handleChange={(e) => handleCheckBoxTop({ checkboxTop })}
              >
                <>
                  <span onClick={() => {}}>{t('signup_terms1')}</span>
                  {t('signup_terms2')}
                </>
              </FCheckbox>
            </div>

            <div className="mb-3 text-start">
              <FCheckbox
                name="termsBottom"
                value={checkboxBottom}
                handleChange={(e) => handleCheckBoxBottom({ checkboxBottom })}
              >
                {t('signup_privacy')}
              </FCheckbox>
            </div>

            <div className="mb-3 text-center f_login-loginText">
              <Button
                disabled={
                  formData.name == '' ||
                  formData.surname == '' ||
                  formData.email == '' ||
                  formData.password == '' ||
                  checkboxTop == false ||
                  checkboxBottom == false
                }
                onClick={handleSubmit}
                className="login-button"
              >
                {t('signup_create')}
              </Button>
            </div>
          </Form>
          <div className="mb-3 text-center f_login-loginText">
            {t('signup_already')}{' '}
            <span onClick={() => navigate('/login')}>{t('signup_button')}</span>
          </div>
          </div>
          </div>
          <img className="wolf_image" src={wolf} alt="wolf_image"></img>
        </div> */
