import { convertToRaw } from "draft-js";
import { all, call, fork, put, takeLatest } from "redux-saga/effects";
import { apiCall } from "../../../utils/util";
import {
  DELETE_NEWS_REQUEST,
  GET_NEWS_BY_ID_REQUEST,
  GET_NEWS_REQUEST,
  POST_NEWS_REQUEST,
  UPDATE_NEWS_BY_ID_REQUEST,
  UPLOAD_FILE_REQUEST,
} from "../../actions";
import {
  deleteNewsFail,
  deleteNewsSuccess,
  getNewsByIdFail,
  getNewsByIdSuccess,
  getNewsFail,
  getNewsSuccess,
  postNewsFail,
  postNewsSuccess,
  updateNewsByIdFail,
  updateNewsByIdSuccess,
  uploadFileFail,
  uploadFileSuccess,
} from "../newsService";

function* watchGetNews() {
  yield takeLatest(GET_NEWS_REQUEST, getNews);
}

function* getNews(action) {
  try {
    let filters = action.payload;

    const res = yield call(apiCall, "post", "news/all", filters);
    let result = res.data;

    yield put(
      getNewsSuccess({
        data: result,
      })
    );
  } catch (err) {
    console.log(err);
    yield put(getNewsFail(err));
  }
}

function* watchGetNewsById() {
  yield takeLatest(GET_NEWS_BY_ID_REQUEST, getNewsById);
}

function* getNewsById(action) {
  try {
    const { id } = action.payload;

    const res = yield call(apiCall, "get", "news/" + id);
    let result = res.data.data;
    yield put(
      getNewsByIdSuccess({
        id,
        data: result,
      })
    );
  } catch (err) {
    console.log(err);
    yield put(getNewsByIdFail(err));
  }
}

function* watchPostNews() {
  yield takeLatest(POST_NEWS_REQUEST, postNews);
}

function* postNews(action) {
  try {
    const data = action.payload;

    const innerData = {
      title: data.title,
      content: JSON.stringify(convertToRaw(data.content.getCurrentContent())),
      type: data.type,
    };

    const res = yield call(apiCall, "post", "news", innerData);
    let result = res.data.data;
    yield put(
      postNewsSuccess({
        data: result,
      })
    );
  } catch (err) {
    console.log(err);
    yield put(postNewsFail(err));
  }
}

function* watchUpdateNewsById() {
  yield takeLatest(UPDATE_NEWS_BY_ID_REQUEST, updateNewsById);
}

function* updateNewsById(action) {
  try {
    const { id, data } = action.payload;

    const innerData = {
      title: data.title,
      content: JSON.stringify(convertToRaw(data.content.getCurrentContent())),
      type: data.type,
    };

    const res = yield call(apiCall, "put", "news/" + id, innerData);
    let result = res.data.data;
    yield put(
      updateNewsByIdSuccess({
        id,
        data: result,
      })
    );
  } catch (err) {
    console.log(err);
    yield put(updateNewsByIdFail(err));
  }
}

function* watchUploadFile() {
  yield takeLatest(UPLOAD_FILE_REQUEST, uploadFile);
}

function* uploadFile(action) {
  const formData = new FormData();

  formData.append("file", action.payload.image);

  try {
    const res = yield call(
      apiCall,
      "post",
      "news/file/upload/" + action.payload.id,
      formData
    );
    let result = res.data.data;
    yield put(
      uploadFileSuccess({
        data: result,
      })
    );
  } catch (err) {
    console.log(err);
    yield put(uploadFileFail(err));
  }
}

function* watchDeleteNews() {
  yield takeLatest(DELETE_NEWS_REQUEST, deleteNews);
}

function* deleteNews(action) {
  try {
    const res = yield call(apiCall, "delete", "news/" + action.payload.id);
    let result = res.data.data;
    yield put(
      deleteNewsSuccess({
        data: result,
      })
    );
  } catch (err) {
    console.log(err);
    yield put(deleteNewsFail(err));
  }
}

export default function* newsSaga() {
  yield all([
    fork(watchGetNews),
    fork(watchGetNewsById),
    fork(watchPostNews),
    fork(watchUpdateNewsById),
    fork(watchUploadFile),
    fork(watchDeleteNews),
  ]);
}
