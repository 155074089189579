import { all, call, fork, put, takeLatest } from "redux-saga/effects";
import { apiCall } from "../../../utils/util";
import {
  EMERGENCY_CONTACT_CREATE_BY_ID_REQUEST,
  EMERGENCY_CONTACT_DELETE_BY_ID_REQUEST,
  EMERGENCY_CONTACT_READ_BY_ID_REQUEST,
  EMERGENCY_CONTACT_UPDATE_BY_ID_REQUEST,
  FORGOT_PASSWORD_REQUEST,
  LOGIN_REQUEST,
  RESET_PASSWORD_REQUEST,
  SIGNUP_REQUEST,
  TWOFA_ACTIVATE_REQUEST,
  TWOFA_GENERATE_REQUEST,
  TWOFA_LOGIN_REQUEST,
  UPDATE_USER_BY_ID_REQUEST,
  USER_INFO_REQUEST,
} from "../../actions";
import {
  createEmergencyFail,
  createEmergencySuccess,
  deleteEmergencyFail,
  deleteEmergencySuccess,
  forgotPasswordFailed,
  forgotPasswordSucceeded,
  loginFailed,
  loginSucceeded,
  navigateToOtp,
  readEmergencyFail,
  readEmergencySuccess,
  resetPasswordFailed,
  resetPasswordSucceeded,
  signupFailed,
  signupSucceeded,
  twoFaActivateFailed,
  twoFaActivateSucceeded,
  twoFaGenerateFailed,
  twoFaGenerateSucceeded,
  twoFaLoginFailed,
  twoFaLoginSucceeded,
  updateEmergencyFail,
  updateEmergencySuccess,
  updateUserFailed,
  updateUserRequest,
  updateUserSucceed,
  userInfo,
  userInfoFailed,
  userInfoSucceeded,
} from "../authenticationService";

function* watchLogin() {
  yield takeLatest(LOGIN_REQUEST, login);
}

function* login(action) {
  try {
    const res = yield call(apiCall, "post", "login", action.payload);
    console.log(res);
    if (res?.data?.message === "login successfull") {
      yield localStorage.setItem("user", JSON.stringify(res.data.data));
      yield localStorage.setItem("token", res.data.data.token);
      yield localStorage.setItem("userRole", res.data.data.is_admin);
      yield localStorage.setItem("isLogged", true);
      yield localStorage.setItem("otp_enabled", res.data.data.otp_enabled);
      yield localStorage.setItem("activeTab", 0);

      //yield put(userInfo({ ...res.data.data }));
      yield put(loginSucceeded({ ...res.data.data }));
    } else if (res?.data?.message == "otp required") {
      yield localStorage.setItem("user", JSON.stringify(res.data.data));
      yield localStorage.setItem("token", res.data.data.token);

      yield put(navigateToOtp({ ...res.data.data }));
    } else {
      yield put(loginFailed({ ...res.data.data }));
    }
  } catch (err) {
    console.log(err);
    yield put(loginFailed());
  }
}

function* watchForgotPassword() {
  yield takeLatest(FORGOT_PASSWORD_REQUEST, forgotPassword);
}

function* forgotPassword(action) {
  try {
    const res = yield call(apiCall, "post", "forgot-password", action.payload);
    console.log(res);
    if (res?.data?.message === "Success") {
      //yield put(userInfo({ ...res.data.data }));
      yield put(forgotPasswordSucceeded({ ...res.data.data }));
    } else {
      yield put(forgotPasswordFailed({ ...res.data.data }));
    }
  } catch (err) {
    console.log(err);
    yield put(forgotPasswordFailed());
  }
}

function* watchResetPassword() {
  yield takeLatest(RESET_PASSWORD_REQUEST, resetPassword);
}

function* resetPassword(action) {
  try {
    //yield localStorage.setItem("tempToken", action.payload.tempToken);
    const res = yield call(
      apiCall,
      "post",
      "password-reset/" + action.payload.tempToken,
      {
        password: action.payload.password,
      }
    );
    console.log(res);
    if (res?.data?.message === "Success") {
      //yield put(userInfo({ ...res.data.data }));
      yield put(resetPasswordSucceeded({ ...res.data.data }));
    } else {
      yield put(resetPasswordFailed({ ...res.data.data }));
    }
  } catch (err) {
    console.log(err);
    yield put(resetPasswordFailed());
  }
}

function* watchTwoFAActivate() {
  yield takeLatest(TWOFA_ACTIVATE_REQUEST, TwoFAActivate);
}

function* TwoFAActivate(action) {
  try {
    //yield localStorage.setItem("tempToken", action.payload.tempToken);
    const res = yield call(apiCall, "post", "auth/otp/verify", {
      user_id: action.payload.user_id,
      token: action.payload.token,
    });
    console.log(res);
    if (res?.data?.message === "Operation Success") {
      //yield put(userInfo({ ...res.data.data }));
      localStorage.setItem("otp_enabled", true);
      yield put(twoFaActivateSucceeded({ ...res.data.data }));
    } else {
      yield put(twoFaActivateFailed({ ...res.data.data }));
    }
  } catch (err) {
    console.log(err);
    yield put(twoFaActivateFailed());
  }
}

function* watchTwoFALogin() {
  yield takeLatest(TWOFA_LOGIN_REQUEST, TwoFALogin);
}

function* TwoFALogin(action) {
  try {
    //yield localStorage.setItem("tempToken", action.payload.tempToken);
    const res = yield call(apiCall, "post", "auth/otp/validate", {
      user_id: action.payload.user_id,
      token: action.payload.token,
    });
    console.log(res);
    if (res?.data?.message === "Operation Success") {
      yield localStorage.setItem("user", JSON.stringify(res.data.data));
      yield localStorage.setItem("token", res.data.data.token);
      yield localStorage.setItem("userRole", res.data.data.is_admin);
      yield localStorage.setItem("isLogged", true);
      yield localStorage.setItem("otp_enabled", res.data.data.otp_enabled);
      yield localStorage.setItem("activeTab", 0);

      //yield put(userInfo({ ...res.data.data }));
      //yield put(loginSucceeded({ ...res.data.data }));
      yield put(twoFaLoginSucceeded({ ...res.data.data }));
    } else {
      yield put(twoFaLoginFailed({ ...res.data.data }));
    }
  } catch (err) {
    console.log(err);
    yield put(twoFaLoginFailed());
  }
}

function* watchTwoFAGenerate() {
  yield takeLatest(TWOFA_GENERATE_REQUEST, TwoFAGenerate);
}

function* TwoFAGenerate(action) {
  try {
    //yield localStorage.setItem("tempToken", action.payload.tempToken);
    const res = yield call(apiCall, "post", "auth/otp/generate", {
      id: action.payload.id,
    });
    console.log(res);
    if (res?.data?.message === "Operation Success") {
      let localstore = JSON.parse(localStorage.getItem("user"));
      localstore.otp_auth_url = res.data.data.otp_auth_url;
      localstore.otp_base32 = res.data.data.otp_base32;
      localStorage.setItem("user", JSON.stringify(localstore));
      yield put(twoFaGenerateSucceeded({ ...res.data.data }));
    } else {
      yield put(twoFaGenerateFailed({ ...res.data.data }));
    }
  } catch (err) {
    console.log(err);
    yield put(twoFaGenerateFailed());
  }
}

function* watchUserInfo() {
  yield takeLatest(USER_INFO_REQUEST, getUserInfo);
}

function* getUserInfo(action) {
  try {
    const userRes = yield call(apiCall, "get", "account/" + action.payload.id);
    yield localStorage.setItem("userInfo", JSON.stringify(userRes.data.data));
    yield put(userInfoSucceeded({ ...userRes.data.data }));
  } catch (err) {
    console.log(err);
    yield put(userInfoFailed());
  }
}

function* watchSignup() {
  yield takeLatest(SIGNUP_REQUEST, signup);
}

function* signup(action) {
  try {
    const res = yield call(apiCall, "post", "register", action.payload);
    /*yield localStorage.setItem('user', JSON.stringify(res.data.data));
    yield localStorage.setItem('token', res.data.data.token);
    yield localStorage.setItem('userId', res.data.data.id);
    */
    //yield put(userInfo({ ...res.data.data }));

    yield put(signupSucceeded({ ...res.data.data }));
  } catch (err) {
    console.log(err);
    yield put(signupFailed());
  }
}

function* watchUpdateUser() {
  yield takeLatest(UPDATE_USER_BY_ID_REQUEST, updateUser);
}

function* updateUser(action) {
  try {
    const res = yield call(
      apiCall,
      "put",
      "account/" + action.payload.id,
      action.payload.formData
    );
    console.log(res);
    if (res?.status === 200) {
      const userRes = yield call(
        apiCall,
        "get",
        "account/" + action.payload.id
      );
      yield localStorage.setItem("user", JSON.stringify(userRes.data.data));
      yield put(updateUserSucceed({ data: res.status }));
    } else {
      yield put(updateUserFailed({ ...res.message }));
    }
  } catch (err) {
    console.log(err);
    yield put(updateUserFailed(err));
  }
}

function* watchCreateEmergency() {
  yield takeLatest(EMERGENCY_CONTACT_CREATE_BY_ID_REQUEST, createEmergency);
}

function* createEmergency(action) {
  try {
    const res = yield call(
      apiCall,
      "post",
      "emergency-contact/",
      action.payload
    );
    let result = res.data.data;
    yield put(
      createEmergencySuccess({
        data: result,
      })
    );
  } catch (err) {
    console.log(err);
    yield put(createEmergencyFail(err));
  }
}

function* watchUpdateEmergency() {
  yield takeLatest(EMERGENCY_CONTACT_UPDATE_BY_ID_REQUEST, updateEmergency);
}

function* updateEmergency(action) {
  try {
    const res = yield call(
      apiCall,
      "put",
      "emergency-contact/" + action.payload.user_id,
      action.payload
    );
    let result = res.data.data;
    yield put(
      updateEmergencySuccess({
        data: result,
      })
    );
  } catch (err) {
    console.log(err);
    yield put(updateEmergencyFail(err));
  }
}

function* watchReadEmergency() {
  yield takeLatest(EMERGENCY_CONTACT_READ_BY_ID_REQUEST, readEmergency);
}

function* readEmergency(action) {
  try {
    const res = yield call(
      apiCall,
      "get",
      "emergency-contact/" + action.payload.id
    );
    let result = res.data.data;
    yield put(
      readEmergencySuccess({
        data: result,
      })
    );
  } catch (err) {
    console.log(err);
    yield put(readEmergencyFail(err));
  }
}

function* watchDeleteEmergency() {
  yield takeLatest(EMERGENCY_CONTACT_DELETE_BY_ID_REQUEST, deleteEmergency);
}

function* deleteEmergency(action) {
  try {
    const res = yield call(
      apiCall,
      "delete",
      "emergency-contact/" + action.payload.id
    );
    let result = res.data.data;
    yield put(
      deleteEmergencySuccess({
        data: result,
      })
    );
  } catch (err) {
    console.log(err);
    yield put(deleteEmergencyFail(err));
  }
}

export default function* authenticationSaga() {
  yield all([
    fork(watchLogin),
    fork(watchUserInfo),
    fork(watchSignup),
    fork(watchUpdateUser),
    fork(watchForgotPassword),
    fork(watchResetPassword),
    fork(watchTwoFAActivate),
    fork(watchTwoFALogin),
    fork(watchTwoFAGenerate),
    fork(watchCreateEmergency),
    fork(watchUpdateEmergency),
    fork(watchReadEmergency),
    fork(watchDeleteEmergency),
  ]);
}
