import * as services from '../actions';

export const getTotalUserCountRequest = (reqParams) => ({
  type: services.GET_TOTAL_USER_COUNT_REQUEST,
  payload: reqParams,
});

export const getTotalUserCountSuccess = (reqParams) => ({
  type: services.GET_TOTAL_USER_COUNT_SUCCESS,
  payload: reqParams,
});

export const getTotalUserCountFail = (error) => ({
  type: services.GET_TOTAL_USER_COUNT_FAIL,
  payload: error,
});

export const getTotalTransactionCountRequest = (reqParams) => ({
  type: services.GET_TOTAL_TRANSACTION_COUNT_REQUEST,
  payload: reqParams,
});

export const getTotalTransactionCountSuccess = (reqParams) => ({
  type: services.GET_TOTAL_TRANSACTION_COUNT_SUCCESS,
  payload: reqParams,
});

export const getTotalTransactionCountFail = (error) => ({
  type: services.GET_TOTAL_TRANSACTION_COUNT_FAIL,
  payload: error,
});

export const getTotalDepositCountRequest = (reqParams) => ({
  type: services.GET_TOTAL_DEPOSIT_COUNT_REQUEST,
  payload: reqParams,
});

export const getTotalDepositCountSuccess = (reqParams) => ({
  type: services.GET_TOTAL_DEPOSIT_COUNT_SUCCESS,
  payload: reqParams,
});

export const getTotalDepositCountFail = (error) => ({
  type: services.GET_TOTAL_DEPOSIT_COUNT_FAIL,
  payload: error,
});

export const getTotalProfitCountRequest = (reqParams) => ({
  type: services.GET_TOTAL_PROFIT_COUNT_REQUEST,
  payload: reqParams,
});

export const getTotalProfitCountSuccess = (reqParams) => ({
  type: services.GET_TOTAL_PROFIT_COUNT_SUCCESS,
  payload: reqParams,
});

export const getTotalProfitCountFail = (error) => ({
  type: services.GET_TOTAL_PROFIT_COUNT_FAIL,
  payload: error,
});

export const getTotalFundCountRequest = (reqParams) => ({
  type: services.GET_TOTAL_FUND_COUNT_REQUEST,
  payload: reqParams,
});

export const getTotalFundCountSuccess = (reqParams) => ({
  type: services.GET_TOTAL_FUND_COUNT_SUCCESS,
  payload: reqParams,
});

export const getTotalFundCountFail = (error) => ({
  type: services.GET_TOTAL_FUND_COUNT_FAIL,
  payload: error,
});
