import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { getLastPricesService } from "../../../../services/fund/fundService";
import FundDetails from "../../../PublicLayout/Invest/FundDetails/FundDetails";
import "./LeveragedStaking.scss";

const LeveragedStaking = ({ from, theme }) => {
  const dispatch = useDispatch();
  const { lastPrices, loading } = useSelector((state) => state.fund);
  const { t } = useTranslation();

  const initialFilters = {
    filter: {},
  };

  useEffect(() => {
    dispatch(getLastPricesService(initialFilters));
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <FundDetails location={"leveraged-staking"} from={from} theme={theme} />

      {loading ? (
        ""
      ) : (
        <div className="f_funddetailswrapper">
          <div className="f_funddetails-advantages">
            <div className="f_funddetails-advantages-content">
              <div className="f_funddetails-advantages-content-section">
                <svg
                  width="89"
                  height="89"
                  viewBox="0 0 89 89"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clip-path="url(#clip0_3_419)">
                    <path
                      d="M86.2188 57.0156H77.875V44.5C77.875 43.7313 77.253 43.1094 76.4844 43.1094H45.8906V31.9844H54.2344C55.7703 31.9844 57.0156 30.7391 57.0156 29.2031V9.73438C57.0156 8.19829 55.7703 6.95312 54.2344 6.95312H34.7656C33.2295 6.95312 31.9844 8.19829 31.9844 9.73438V29.2031C31.9844 30.7391 33.2295 31.9844 34.7656 31.9844H43.1094V43.1094H12.5156C11.7469 43.1094 11.125 43.7313 11.125 44.5V57.0156H2.78125C1.24517 57.0156 0 58.2608 0 59.7969V79.2656C0 80.8016 1.24517 82.0469 2.78125 82.0469H22.25C23.7859 82.0469 25.0312 80.8016 25.0312 79.2656V59.7969C25.0312 58.2608 23.7859 57.0156 22.25 57.0156H13.9063V45.8906H43.1094V57.0156H34.7656C33.2295 57.0156 31.9844 58.2608 31.9844 59.7969V79.2656C31.9844 80.8016 33.2295 82.0469 34.7656 82.0469H54.2344C55.7703 82.0469 57.0156 80.8016 57.0156 79.2656V59.7969C57.0156 58.2608 55.7703 57.0156 54.2344 57.0156H45.8906V45.8906H75.0938V57.0156H66.75C65.2139 57.0156 63.9688 58.2608 63.9688 59.7969V79.2656C63.9688 80.8016 65.2139 82.0469 66.75 82.0469H86.2188C87.7547 82.0469 89 80.8016 89 79.2656V59.7969C89 58.2608 87.7547 57.0156 86.2188 57.0156ZM34.7656 9.73438H54.2344V29.2031H34.7656V9.73438ZM22.25 59.7969V79.2656H2.78125V59.7969H22.25ZM54.2344 79.2656H34.7656V59.7969H54.2344V79.2656ZM86.2188 79.2656H66.75V59.7969H86.2188V79.2656Z"
                      fill="#4169E1"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_3_419">
                      <rect width="89" height="89" fill="white" />
                    </clipPath>
                  </defs>
                </svg>

                <h1>{t("leveraged_title1")}</h1>
                <h2>{t("leveraged_item1")}</h2>
              </div>
              <div className="f_funddetails-advantages-content-section">
                <svg
                  width="73"
                  height="73"
                  viewBox="0 0 73 73"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clip-path="url(#clip0_3_454)">
                    <path
                      d="M57.0312 71.5399H15.9687V69.8974H28.8737C29.2319 66.2131 31.9922 63.4528 35.6787 63.0947V28.1345C33.0986 27.7376 31.1162 25.502 31.1162 22.8124C31.1162 20.1228 33.0986 17.8871 35.6787 17.4902V9.8868L15.6858 15.599C20.2757 16.9564 23.6337 21.2109 23.6337 26.2342C23.6337 32.348 18.6606 37.3211 12.5468 37.3211C6.43309 37.3211 1.45996 32.348 1.45996 26.2342C1.45996 20.579 5.71677 15.8979 11.1941 15.2295L11.1804 15.1793L59.0843 1.4918C59.4242 1.39599 59.8166 1.53971 60.0105 1.84539L71.4168 20.0954C71.5742 20.3486 71.581 20.668 71.4373 20.928C71.2913 21.1904 71.0176 21.3524 70.7187 21.3524H47.9062C47.6074 21.3524 47.3336 21.1904 47.1899 20.928C47.0462 20.668 47.0507 20.3486 47.2104 20.0954L57.4852 3.65671L37.3212 9.41914V17.4925C39.9013 17.8894 41.8837 20.125 41.8837 22.8146C41.8837 25.5042 39.9013 27.7399 37.3212 28.1368V63.097C41.0077 63.4551 43.768 66.2154 44.1262 69.8996H57.0312V71.5399ZM30.5276 69.8974H42.4723C42.0936 66.8793 39.6549 64.6961 36.5 64.6961C33.3473 64.6961 30.9041 66.8793 30.5276 69.8974ZM12.5468 16.7899C7.33874 16.7899 3.10246 21.0261 3.10246 26.2342C3.10246 31.4423 7.33874 35.6786 12.5468 35.6786C17.7549 35.6786 21.9912 31.4423 21.9912 26.2342C21.9912 21.0261 17.7549 16.7899 12.5468 16.7899ZM36.5 19.0711C34.4377 19.0711 32.7587 20.7501 32.7587 22.8124C32.7587 24.8746 34.4377 26.5536 36.5 26.5536C38.5622 26.5536 40.2412 24.8746 40.2412 22.8124C40.2412 20.7501 38.5622 19.0711 36.5 19.0711ZM49.3867 19.7099H69.2359L59.3125 3.83008L49.3867 19.7099Z"
                      fill="#4169E1"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_3_454">
                      <rect width="73" height="73" fill="white" />
                    </clipPath>
                  </defs>
                </svg>

                <h1>{t("leveraged_title2")}</h1>
                <h2>{t("leveraged_item2")}</h2>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default LeveragedStaking;
