import React from "react";
import { Accordion, Container } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import "./FAQ.scss";

const FAQ = () => {
  const { t } = useTranslation();
  return (
    <div className="faq-wrapper">
      <div className="container pt-5 pb-5 faq">
        <h1 className="faq-title">{t("faq")}</h1>
        <Accordion>
          <Accordion.Item eventKey="0">
            <Accordion.Header>{t("landing_faq_title1")}</Accordion.Header>
            <Accordion.Body>
              {t("landing_faq_item1_1")}
              <br />
              {t("landing_faq_item1_2")}
              <br />
              {t("landing_faq_item1_3")}
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="1">
            <Accordion.Header>{t("landing_faq_title2")}</Accordion.Header>
            <Accordion.Body>{t("landing_faq_item2")}</Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="2">
            <Accordion.Header>{t("landing_faq_title3")}</Accordion.Header>
            <Accordion.Body>{t("landing_faq_item3")}</Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="3">
            <Accordion.Header>{t("landing_faq_title4")}</Accordion.Header>
            <Accordion.Body>{t("landing_faq_item4")}</Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="4">
            <Accordion.Header>{t("landing_faq_title5")}</Accordion.Header>
            <Accordion.Body>{t("landing_faq_item5")}</Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="5">
            <Accordion.Header>{t("landing_faq_title6")}</Accordion.Header>
            <Accordion.Body>{t("landing_faq_item6")} </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </div>
    </div>
  );
};

export default FAQ;
