// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.soon {
  text-align: center;
  padding-top: 100px;
  padding-bottom: 100px;
  font-size: 50px;
}

.f_yield .card {
  height: auto !important;
}
.f_yield .f_invest-funds {
  flex-direction: column;
}
.f_yield .f_invest .card {
  width: 100%;
  max-width: initial;
}`, "",{"version":3,"sources":["webpack://./src/pages/MainLayout/Yield/Yield.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,kBAAA;EACA,qBAAA;EAEA,eAAA;AAAF;;AAIE;EACE,uBAAA;AADJ;AAGE;EACE,sBAAA;AADJ;AAGE;EACE,WAAA;EACA,kBAAA;AADJ","sourcesContent":[".soon {\n  text-align: center;\n  padding-top: 100px;\n  padding-bottom: 100px;\n\n  font-size: 50px;\n}\n\n.f_yield {\n  .card {\n    height: auto !important;\n  }\n  .f_invest-funds {\n    flex-direction: column;\n  }\n  .f_invest .card {\n    width: 100%;\n    max-width: initial;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
