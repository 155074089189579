// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.f_textInput {
  background: var(--background-navbar);
  width: 100%;
  border-radius: 8px;
}
.f_textInput label {
  color: var(--text-primary) !important;
  font-weight: 500;
  font-size: 16px;
}
.f_textInput input {
  border-radius: 8px;
  border: 0;
  background: var(--background-navbar);
  color: var(--text-primary) !important;
}
.f_textInput input:focus {
  background: var(--background-navbar);
}`, "",{"version":3,"sources":["webpack://./src/components/FTextInput/FTextInput.scss"],"names":[],"mappings":"AAAA;EACE,oCAAA;EACA,WAAA;EACA,kBAAA;AACF;AAAE;EACE,qCAAA;EACA,gBAAA;EACA,eAAA;AAEJ;AAAE;EACE,kBAAA;EACA,SAAA;EACA,oCAAA;EACA,qCAAA;AAEJ;AAAI;EACE,oCAAA;AAEN","sourcesContent":[".f_textInput {\n  background: var(--background-navbar);\n  width: 100%;\n  border-radius: 8px;\n  label {\n    color: var(--text-primary) !important;\n    font-weight: 500;\n    font-size: 16px;\n  }\n  input {\n    border-radius: 8px;\n    border: 0;\n    background: var(--background-navbar);\n    color: var(--text-primary) !important;\n\n    &:focus {\n      background: var(--background-navbar);\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
