// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.about-head-text {
  display: flex;
  align-items: center;
  height: 300px;
  font-size: 36px;
  font-weight: 700;
  line-height: 44px;
}
.about-head img {
  width: 100%;
  height: 500px;
  opacity: 0.7;
}
.about-body {
  display: flex;
}
@media (max-width: 767px) {
  .about-body {
    flex-wrap: wrap-reverse;
  }
}
.about-body-title {
  color: #4169e1 !important;
  font-size: 24px !important;
  font-weight: 500 !important;
  line-height: 29px !important;
  text-transform: uppercase;
  margin-bottom: 50px !important;
}
.about-body-content {
  color: #353535 !important;
  font-size: 16px !important;
  font-weight: 400 !important;
  line-height: 20px !important;
}
.about-body div {
  flex: 1 1 100%;
}
.about-body svg {
  margin-top: 70px !important;
  flex: 1 0 300px;
}
.about-who {
  padding-bottom: 64px;
}
.about-who h2 {
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  color: #353535;
}
.about-wrapper {
  background-color: white;
}`, "",{"version":3,"sources":["webpack://./src/pages/MainLayout/About/About.scss"],"names":[],"mappings":"AAEI;EACE,aAAA;EACA,mBAAA;EACA,aAAA;EACA,eAAA;EACA,gBAAA;EACA,iBAAA;AADN;AAII;EACE,WAAA;EACA,aAAA;EACA,YAAA;AAFN;AAME;EACE,aAAA;AAJJ;AAKI;EAFF;IAGI,uBAAA;EAFJ;AACF;AAGI;EACE,yBAAA;EACA,0BAAA;EACA,2BAAA;EACA,4BAAA;EACA,yBAAA;EACA,8BAAA;AADN;AAII;EACE,yBAAA;EACA,0BAAA;EACA,2BAAA;EACA,4BAAA;AAFN;AAII;EACE,cAAA;AAFN;AAII;EACE,2BAAA;EACA,eAAA;AAFN;AAKE;EAOE,oBAAA;AATJ;AAGI;EACE,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,cAAA;AADN;AAKE;EACE,uBAAA;AAHJ","sourcesContent":[".about {\n  &-head {\n    &-text {\n      display: flex;\n      align-items: center;\n      height: 300px;\n      font-size: 36px;\n      font-weight: 700;\n      line-height: 44px;\n    }\n\n    img {\n      width: 100%;\n      height: 500px;\n      opacity: 0.7;\n    }\n  }\n\n  &-body {\n    display: flex;\n    @media (max-width: 767px) {\n      flex-wrap: wrap-reverse;\n    }\n    &-title {\n      color: #4169e1 !important;\n      font-size: 24px !important;\n      font-weight: 500 !important;\n      line-height: 29px !important;\n      text-transform: uppercase;\n      margin-bottom: 50px !important;\n    }\n\n    &-content {\n      color: #353535 !important;\n      font-size: 16px !important;\n      font-weight: 400 !important;\n      line-height: 20px !important;\n    }\n    div {\n      flex: 1 1 100%;\n    }\n    svg {\n      margin-top: 70px !important;\n      flex: 1 0 300px;\n    }\n  }\n  &-who {\n    h2 {\n      font-size: 16px;\n      font-weight: 400;\n      line-height: 19px;\n      color: #353535;\n    }\n    padding-bottom: 64px;\n  }\n  &-wrapper {\n    background-color: white;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
