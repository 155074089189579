// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.news {
  padding-bottom: 100px;
}
.news h1 {
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 20px !important;
}
.news-cards {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 20px;
}
@media (max-width: 767px) {
  .news-cards {
    justify-content: center;
  }
}
.news-card {
  cursor: pointer;
  width: 335px;
  background-color: #fff;
  border-radius: 5px;
  overflow: hidden;
}
.news-card-desc {
  padding: 20px 30px;
}
.news-card-desc-title {
  font-size: 20px;
  font-weight: 700;
}
.news-card-desc-content {
  font-size: 13px;
  font-weight: 400;
  color: #5e718d;
}
.news-card img {
  object-fit: cover;
  width: 100%;
  height: 143px;
}

.new_container {
  margin-top: 50px;
}`, "",{"version":3,"sources":["webpack://./src/pages/MainLayout/News/News.scss"],"names":[],"mappings":"AAAA;EACE,qBAAA;AACF;AAAE;EACE,eAAA;EACA,gBAAA;EACA,8BAAA;AAEJ;AAAE;EACE,aAAA;EACA,eAAA;EACA,8BAAA;EACA,SAAA;AAEJ;AADI;EALF;IAMI,uBAAA;EAIJ;AACF;AAFE;EACE,eAAA;EACA,YAAA;EACA,sBAAA;EACA,kBAAA;EACA,gBAAA;AAIJ;AAHI;EACE,kBAAA;AAKN;AAJM;EACE,eAAA;EACA,gBAAA;AAMR;AAJM;EACE,eAAA;EACA,gBAAA;EACA,cAAA;AAMR;AAHI;EACE,iBAAA;EACA,WAAA;EACA,aAAA;AAKN;;AAAA;EACE,gBAAA;AAGF","sourcesContent":[".news {\n  padding-bottom: 100px;\n  h1 {\n    font-size: 24px;\n    font-weight: 700;\n    margin-bottom: 20px !important;\n  }\n  &-cards {\n    display: flex;\n    flex-wrap: wrap;\n    justify-content: space-between;\n    gap: 20px;\n    @media (max-width: 767px) {\n      justify-content: center;\n    }\n  }\n  &-card {\n    cursor: pointer;\n    width: 335px;\n    background-color: #fff;\n    border-radius: 5px;\n    overflow: hidden;\n    &-desc {\n      padding: 20px 30px;\n      &-title {\n        font-size: 20px;\n        font-weight: 700;\n      }\n      &-content {\n        font-size: 13px;\n        font-weight: 400;\n        color: #5e718d;\n      }\n    }\n    img {\n      object-fit: cover;\n      width: 100%;\n      height: 143px;\n    }\n  }\n}\n\n.new_container {\n  margin-top: 50px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
