import React from "react";
import { Button, Container } from "react-bootstrap";
import "./YieldListPage.scss";
import likidite2 from "../../../assets/images/likidite2.png";
import { useNavigate } from "react-router-dom";

const YieldListPage = () => {
  const navigate = useNavigate();

  return (
    <Container>
      <div className="ylp">
        <div className="ylp-item">
          <div className="ylp-item-left">
            <div className="ylp-item-left-t">
              <h5>Staking</h5>
              <div>
                <img
                  width="50px"
                  height="50px"
                  src="https://dow.capital/static/media/stakingicon.f82205d14240d22c0019.png"
                ></img>
                <h4>Kaldıraçlı Staking</h4>
              </div>
              <p>
                Birçok blok zinciri, Proof of Stake konsensüs mekanizmasını
                kullanmaktadır. Staking, bir blok zincirinin çalışmasını
                desteklemeye yardımcı olmak için, kripto varlıklarınızı belirli
                bir süre boyunca bahis konusu networke kilitlemenizdir.
                Kriptonuzu staking karşılığında, daha fazla kripto para
                kazanırsınız.
              </p>
            </div>
            <div className="ylp-item-left-b">
              <Button onClick={() => navigate("/yields/staking")}>
                Explore
              </Button>
            </div>
          </div>
          <div className="ylp-item-right">
            <div className="ylp-item-right-t">
              <h5>Strategy For Bearish Markets</h5>
            </div>

            <img src={likidite2} />
            <div className="ylp-item-right-b">
              <div className="ylp-item-right-b-l">
                26 <span>% AVG APY</span>
              </div>
              <div className="ylp-item-right-b-r">
                <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/4/46/Bitcoin.svg/800px-Bitcoin.svg.png"></img>
              </div>
            </div>
          </div>
        </div>
        <div className="ylp-item">
          <div className="ylp-item-left">
            <div className="ylp-item-left-t">
              <h5>Staking</h5>
              <div>
                <img
                  width="50px"
                  height="50px"
                  src="https://dow.capital/static/media/stakingicon.f82205d14240d22c0019.png"
                ></img>
                <h4>Kaldıraçlı Staking</h4>
              </div>
              <p>
                Birçok blok zinciri, Proof of Stake konsensüs mekanizmasını
                kullanmaktadır. Staking, bir blok zincirinin çalışmasını
                desteklemeye yardımcı olmak için, kripto varlıklarınızı belirli
                bir süre boyunca bahis konusu networke kilitlemenizdir.
                Kriptonuzu staking karşılığında, daha fazla kripto para
                kazanırsınız.
              </p>
            </div>
            <div className="ylp-item-left-b">
              <Button>Explore</Button>
            </div>
          </div>
          <div className="ylp-item-right">
            <div className="ylp-item-right-t">
              <h5>Strategy For Bearish Markets</h5>
            </div>

            <img src={likidite2} />
            <div className="ylp-item-right-b">
              <div className="ylp-item-right-b-l">
                26 <span>% AVG APY</span>
              </div>
              <div className="ylp-item-right-b-r">
                <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/4/46/Bitcoin.svg/800px-Bitcoin.svg.png"></img>
              </div>
            </div>
          </div>
        </div>
        <div className="ylp-item">
          <div className="ylp-item-left">
            <div className="ylp-item-left-t">
              <h5>Staking</h5>
              <div>
                <img
                  width="50px"
                  height="50px"
                  src="https://dow.capital/static/media/stakingicon.f82205d14240d22c0019.png"
                ></img>
                <h4>Kaldıraçlı Staking</h4>
              </div>
              <p>
                Birçok blok zinciri, Proof of Stake konsensüs mekanizmasını
                kullanmaktadır. Staking, bir blok zincirinin çalışmasını
                desteklemeye yardımcı olmak için, kripto varlıklarınızı belirli
                bir süre boyunca bahis konusu networke kilitlemenizdir.
                Kriptonuzu staking karşılığında, daha fazla kripto para
                kazanırsınız.
              </p>
            </div>
            <div className="ylp-item-left-b">
              <Button>Explore</Button>
            </div>
          </div>
          <div className="ylp-item-right">
            <div className="ylp-item-right-t">
              <h5>Strategy For Bearish Markets</h5>
            </div>

            <img src={likidite2} />
            <div className="ylp-item-right-b">
              <div className="ylp-item-right-b-l">
                26 <span>% AVG APY</span>
              </div>
              <div className="ylp-item-right-b-r">
                <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/4/46/Bitcoin.svg/800px-Bitcoin.svg.png"></img>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default YieldListPage;
