// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.d_portfolio {
  display: flex;
  flex-direction: column;
  gap: 15px;
}
.d_portfolio h3 {
  font-size: 20px;
  font-weight: 700;
}`, "",{"version":3,"sources":["webpack://./src/components/DashboardComponents/DPortfolio/DPortfolio.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EACA,SAAA;AACF;AAAE;EACE,eAAA;EACA,gBAAA;AAEJ","sourcesContent":[".d_portfolio {\n  display: flex;\n  flex-direction: column;\n  gap: 15px;\n  h3 {\n    font-size: 20px;\n    font-weight: 700;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
