import { all, fork } from 'redux-saga/effects';
import adminSaga from './admin/sagas/adminSaga';
import authenticationSaga from './authentication/sagas/authenticationSaga';
import fundSaga from './fund/sagas/fundSaga';
import newsSaga from './news/sagas/newsSaga';
import walletSaga from './wallet/sagas/walletSaga';
import metricSaga from './metric/sagas/metricSaga';

export default function* sagas() {
  yield all([
    fork(authenticationSaga),
    fork(fundSaga),
    fork(walletSaga),
    fork(newsSaga),
    fork(adminSaga),
    fork(metricSaga),
  ]);
}
