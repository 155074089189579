import React, { useEffect } from 'react';
import { Spinner } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { miniUserTransactionColumns } from '../../constants/columnConfigurations';
import { getUserTransactionsByIdService } from '../../services/fund/fundService';
import FTable from '../FTable/FTable';
import './FTransactions.scss';

const FTransactions = () => {
  const { t } = useTranslation();
  const user = JSON.parse(localStorage.getItem('user'));
  const dispatch = useDispatch();
  let navigate = useNavigate();

  const { txList, loadingTxList } = useSelector((state) => state.fund);

  const txListFilters = {
    id: user.id,
    limit: '20',
    page: 1,
    sort: 'DESC',
  };

  useEffect(() => {
    dispatch(getUserTransactionsByIdService(txListFilters));
  }, []);

  const transactionColumnsConfig = miniUserTransactionColumns();

  return (
    <div className="f_transactions">
      <div className="f_wallets-title">{t('transaction_latest')}</div>
      {loadingTxList ? (
        <Spinner animation="grow" />
      ) : txList?.length !== 0 ? (
        <FTable data={txList?.slice(0, 5)} columns={transactionColumnsConfig} />
      ) : (
        <div className="f_transactions-noinfo">{t('transaction_noinfo')}</div>
      )}
      <div
        className="f_news-footer mt-3 text-center"
        onClick={() => navigate('/transactions')}
      >
        {t('transaction_more')}
      </div>
    </div>
  );
};

export default FTransactions;
