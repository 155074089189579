import React from "react";
import { useTranslation } from "react-i18next";
import FTransactions from "../../FTransactions/FTransactions";
import WAssets from "../../WalletComponents/WAssets/WAssets";
import WTransaction from "../../WalletComponents/WTransaction/WTransaction";
import "./DPortfolio.scss";

const DPortfolio = () => {
  const { t } = useTranslation();

  return (
    <div className="d_portfolio">
      <h3>{t("portfolio")}</h3>
      <WAssets isDashboard={true} />
      <WTransaction homepage={true} />
    </div>
  );
};

export default DPortfolio;
