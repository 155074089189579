import React, { useEffect, useRef, useState } from "react";
import "./Wallets.scss";
import Spinner from "react-bootstrap/Spinner";
import { Button } from "react-bootstrap";
import FIcon from "../../../components/FIcon/FIcon";
import {
  getBalanceByIdRequest,
  getWalletByIdRequest,
} from "../../../services/wallet/walletService";
import { useDispatch, useSelector } from "react-redux";
import {
  checkUserDepositWallet,
  checkUserTubuWallet,
  checkUserWithdrawWallet,
  checkUserWithdrawWalletAndGetLastWallet,
} from "../../../utils/util";
import editIcon from "../../../assets/icons/edit.svg";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import {
  getFundFeeByIdService,
  getUserTransactionsByIdService,
} from "../../../services/fund/fundService";
import c5icon from "../../../assets/images/c5icon.png";
import btcicon from "../../../assets/images/btcicon.png";
import tradeicon from "../../../assets/images/tradeicon.png";
import usdticon from "../../../assets/images/usdticon.png";
import arbitrageicon from "../../../assets/images/arbitrageicon.png";
import FTable from "../../../components/FTable/FTable";
import { miniUserTransactionColumns } from "../../../constants/columnConfigurations";
import { useTranslation } from "react-i18next";
import WAssets from "../../../components/WalletComponents/WAssets/WAssets";
import UserLabel from "../../../components/UserLabel/UserLabel";
import WFunds from "../../../components/WalletComponents/WFunds/WFunds";
import WYield from "../../../components/WalletComponents/WYield/WYield";
import WTransaction from "../../../components/WalletComponents/WTransaction/WTransaction";
import DFundHistory from "../../../components/DashboardComponents/DFundHistory/DFundHistory";

const Wallets = ({ theme }) => {
  const { t } = useTranslation();

  const user = JSON.parse(localStorage.getItem("user"));
  const [submitted, setSubmitted] = useState(false);
  const [submittedWithdrawValue, setSubmittedWithdrawValue] = useState(0);
  const [show, setShow] = useState(false);
  const target = useRef(null);
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const { wallet, loading, loadingBalance, balances } = useSelector(
    (state) => state.wallet
  );
  const {
    txList,
    loadingTxList,
    fundFee,
    withdrawFundResult,
    withdrawFundStatus,
  } = useSelector((state) => state.fund);

  const walletFilters = {
    id: user.id,
  };

  const balanceFilters = {
    wallet: checkUserTubuWallet(wallet),
    id: user.id,
  };

  const initialFeeFilters = {
    id: 1,
    amount: 0,
  };

  useEffect(() => {
    dispatch(getWalletByIdRequest(walletFilters));
  }, []);

  useEffect(() => {
    if (wallet.length !== 0) {
      dispatch(getBalanceByIdRequest(balanceFilters));
    }
  }, [wallet]);

  const refreshBalance = () => {
    dispatch(getBalanceByIdRequest(balanceFilters));
  };

  useEffect(() => {
    dispatch(getFundFeeByIdService(initialFeeFilters));
  }, []);
  /*
  useEffect(() => {
    if (
      (withdrawFundResult != null || withdrawFundResult != undefined) &&
      submitted
    ) {
      if (withdrawFundStatus < 400) {
        Swal.fire({
          title: "Withdraw transaction request has been added.",
          icon: "success",
          text: `${withdrawFundResult}`,
        }).then((result) => {
          setSubmitted(false);
        });
      } else {
        Swal.fire({
          title: "Error.",
          icon: "error",
          text: `${withdrawFundResult}`,
        }).then((result) => {
          setSubmitted(false);
        });
      }
    }
  }, [withdrawFundResult, submitted]);

  useEffect(() => {
    let formData = {};

    if (fundFee != null && fundFee != undefined && fundFee != 0) {
      Swal.fire({
        title: "Do you confirm?",
        text: `The following network commission will be charged for withdrawal: $${parseFloat(
          fundFee
        )?.toFixed(2)}`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
      }).then((result) => {
        if (result.isConfirmed) {
          formData.amount = submittedWithdrawValue - fundFee;
          formData.amount_usd = submittedWithdrawValue - fundFee;
          formData.transaction_fee = parseFloat(fundFee)?.toFixed(2);
          formData.walletId = checkWithdrawWalletId(wallet);
          formData.account_id = user?.id;

          dispatch(withdrawFundByIdService(formData));
          Swal.fire(
            "Success!",
            "Withdraw transaction request has been added.",
            "success"
          );
        }
      });
    }
  }, [fundFee]);

  const withdrawMoney = (userId) => {
    let formData = { account_id: userId };
    Swal.fire({
      title: "Withdraw USD",
      input: "text",
      html: `<div style="display:flex;justify-content:space-between;margin-top: 24px;"><h6>Your USD Balance: ${balances?.TUSD?.amount_usd?.toFixed(
        4
      )}`,
      //footer: `Ap. Network Fee: ${parseFloat(fundFee)?.toFixed(2)}`,
      inputPlaceholder: "100",
      inputAttributes: {
        autocapitalize: "off",
      },
      showClass: {
        backdrop: "swal2-noanimation", // disable backdrop animation
        popup: "", // disable popup animation
        icon: "", // disable icon animation
      },
      hideClass: {
        popup: "", // disable popup fade-out animation
      },
      showCancelButton: true,
      confirmButtonText: "Submit",
      confirmButtonColor: "royalblue",
      showLoaderOnConfirm: true,
      preConfirm: (login) => {
        setSubmittedWithdrawValue(login);
        dispatch(
          getFundFeeByIdService({
            amount: login,
            walletId: checkWithdrawWalletId(wallet),
          })
        );
      },
      allowOutsideClick: () => !Swal.isLoading(),
    }).then((result) => {
      if (result.isConfirmed) {
        setSubmitted(true);
      }
    });
  };
  */
  const copyInfo = (text) => {
    const Toast = Swal.mixin({
      toast: true,
      position: "top-end",
      showConfirmButton: false,
      timer: 2000,
      timerProgressBar: true,
      didOpen: (toast) => {
        toast.addEventListener("mouseenter", Swal.stopTimer);
        toast.addEventListener("mouseleave", Swal.resumeTimer);
      },
    });

    Toast.fire({
      icon: "success",
      title: text + " copied to clipboard.",
    });
  };

  const txListFilters = {
    id: user.id,
    limit: "20",
    page: 1,
    sort: "DESC",
  };

  useEffect(() => {
    dispatch(getUserTransactionsByIdService(txListFilters));
  }, []);

  const transactionColumnsConfig = miniUserTransactionColumns();

  return (
    <>
      <div className="container mb-4">
        <UserLabel text={t("fnavbar_wallets")} />
        <div className="row g-2">
          <div className="col-sm-3">
            <WAssets />
          </div>
          <div className="col-sm-9">
            <div className="row g-2 mb-2">
              <div className="col-sm-4 h-100">
                <div className="f_wallet-bg">
                  <h4>{t("wallet_deposit")}</h4>
                  <h5>{t("wallet_deposit_wallet")}</h5>
                  <div className="f_wallet-bg-w">
                    <FIcon
                      ref={target}
                      onClick={() => {
                        navigator.clipboard.writeText(
                          checkUserDepositWallet(wallet)
                        );
                        setShow(!show);
                        copyInfo("Deposit address");
                        setTimeout(function () {
                          setShow(false);
                        }, 1000);
                      }}
                      name="copytoclipboard"
                      color={theme === "light" ? "black" : "white"}
                      size="18"
                    />
                    {wallet.length !== 0 && checkUserDepositWallet(wallet)
                      ? checkUserDepositWallet(wallet)?.substring(0, 5) +
                        "..." +
                        checkUserDepositWallet(wallet)?.substring(
                          checkUserDepositWallet(wallet)?.length - 5
                        )
                      : t("wallet_no_wallet")}
                  </div>
                </div>
              </div>
              <div className="col-sm-4">
                <div className="f_wallet-bg h-100">
                  <h4>{t("wallet_withdraw")}</h4>
                  <h5>{t("wallet_withdraw_wallet")}</h5>
                  <div className="f_wallet-bg-w">
                    <FIcon
                      ref={target}
                      onClick={() => {
                        navigator.clipboard.writeText(
                          checkUserWithdrawWalletAndGetLastWallet(wallet)
                        );
                        copyInfo("Withdraw address");
                        setShow(!show);
                        setTimeout(function () {
                          setShow(false);
                        }, 1000);
                      }}
                      name="copytoclipboard"
                      color={theme === "light" ? "black" : "white"}
                      size="18"
                    />
                    {wallet.length !== 0 && checkUserWithdrawWallet(wallet)
                      ? checkUserWithdrawWalletAndGetLastWallet(
                          wallet
                        )?.substring(0, 5) +
                        "..." +
                        checkUserWithdrawWalletAndGetLastWallet(
                          wallet
                        )?.substring(
                          checkUserWithdrawWalletAndGetLastWallet(wallet)
                            ?.length - 5
                        )
                      : t("wallet_no_wallet")}
                    <img
                      onClick={() => navigate("/settings")}
                      className={
                        theme === "light"
                          ? "edit_image"
                          : "edit_image dark_button"
                      }
                      alt=""
                      width="18"
                      src={editIcon}
                    ></img>
                  </div>
                </div>
              </div>
              <div className="col-sm-4 ">
                <div className="f_wallet-bg h-100">
                  <h4>{t("wallet_cash")}</h4>
                  <div className="d-flex justify-content-between align-items-center">
                    <h5>USDC {t("wallet_balances")}</h5>
                    {loadingBalance ? (
                      <Spinner animation="grow" size="sm" />
                    ) : (
                      <h5 className="fw-bold">
                        $
                        {balances.length !== 0
                          ? parseFloat(balances?.TUSD?.amount_usd)?.toFixed(2)
                          : "0"}
                      </h5>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "15px",
              }}
            >
              <WFunds />
              <WYield />
              <DFundHistory user="true" />
              <WTransaction />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Wallets;
