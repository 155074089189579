import * as services from "../actions";
export const getTransactionsRequest = (reqParams) => ({
  type: services.GET_TRANSACTIONS_REQUEST,
  payload: reqParams,
});

export const getTransactionsSuccess = (reqParams) => ({
  type: services.GET_TRANSACTIONS_SUCCESS,
  payload: reqParams,
});

export const getTransactionsFail = (error) => ({
  type: services.GET_TRANSACTIONS_FAIL,
  payload: error,
});

export const getFeesRequest = (reqParams) => ({
  type: services.GET_FEES_REQUEST,
  payload: reqParams,
});

export const getFeesSuccess = (reqParams) => ({
  type: services.GET_FEES_SUCCESS,
  payload: reqParams,
});

export const getFeesFail = (error) => ({
  type: services.GET_FEES_FAIL,
  payload: error,
});

export const getDepositsRequest = (reqParams) => ({
  type: services.GET_DEPOSITS_REQUEST,
  payload: reqParams,
});

export const getDepositsSuccess = (reqParams) => ({
  type: services.GET_DEPOSITS_SUCCESS,
  payload: reqParams,
});

export const getDepositsFail = (error) => ({
  type: services.GET_DEPOSITS_FAIL,
  payload: error,
});

export const getTransactionByIdService = (reqParams) => ({
  type: services.GET_TRANSACTION_BY_ID_REQUEST,
  payload: reqParams,
});

export const getTransactionByIdServiceSucceed = ({ id, data }) => ({
  type: services.GET_TRANSACTION_BY_ID_SUCCESS,
  payload: {
    id,
    data,
  },
});

export const getTransactionByIdServiceFailed = (error) => ({
  type: services.GET_TRANSACTION_BY_ID_FAIL,
  payload: error,
});

export const getUsersRequest = (reqParams) => ({
  type: services.GET_USERS_REQUEST,
  payload: reqParams,
});

export const getUsersSuccess = (reqParams) => ({
  type: services.GET_USERS_SUCCESS,
  payload: reqParams,
});

export const getUsersFail = (error) => ({
  type: services.GET_USERS_FAIL,
  payload: error,
});

export const getUsersCensoredRequest = (reqParams) => ({
  type: services.GET_USERS_CENSORED_REQUEST,
  payload: reqParams,
});

export const getUsersCensoredSuccess = (reqParams) => ({
  type: services.GET_USERS_CENSORED_SUCCESS,
  payload: reqParams,
});

export const getUsersCensoredFail = (error) => ({
  type: services.GET_USERS_CENSORED_FAIL,
  payload: error,
});

export const getPartnersRequest = (reqParams) => ({
  type: services.GET_PARTNERS_REQUEST,
  payload: reqParams,
});

export const getPartnersSuccess = (reqParams) => ({
  type: services.GET_PARTNERS_SUCCESS,
  payload: reqParams,
});

export const getPartnersFail = (error) => ({
  type: services.GET_PARTNERS_FAIL,
  payload: error,
});

export const getUserByIdService = (reqParams) => ({
  type: services.GET_USER_BY_ID_REQUEST,
  payload: reqParams,
});

export const getUserByIdServiceSucceed = ({ id, data }) => ({
  type: services.GET_USER_BY_ID_SUCCESS,
  payload: {
    id,
    data,
  },
});

export const getUserByIdServiceFailed = (error) => ({
  type: services.GET_USER_BY_ID_FAIL,
  payload: error,
});

export const approveTransactionRequest = (reqParams) => ({
  type: services.APPROVE_TRANSACTION_REQUEST,
  payload: reqParams,
});

export const approveTransactionSuccess = ({ id, data }) => ({
  type: services.APPROVE_TRANSACTION_SUCCESS,
  payload: {
    id,
    data,
  },
});

export const approveTransactionFail = (error) => ({
  type: services.APPROVE_TRANSACTION_FAIL,
  payload: error,
});

export const internalTransferRequest = (reqParams) => ({
  type: services.INTERNAL_TRANSFER_REQUEST,
  payload: reqParams,
});

export const internalTransferSuccess = ({ id, data }) => ({
  type: services.INTERNAL_TRANSFER_SUCCESS,
  payload: {
    id,
    data,
  },
});

export const internalTransferFail = (error) => ({
  type: services.INTERNAL_TRANSFER_FAIL,
  payload: error,
});

export const bnbTraderBuyRequest = (reqParams) => ({
  type: services.BNBTRADER_BUY_REQUEST,
  payload: reqParams,
});

export const bnbTraderBuySuccess = ({ id, data }) => ({
  type: services.BNBTRADER_BUY_SUCCESS,
  payload: {
    id,
    data,
  },
});

export const bnbTraderBuyFail = (error) => ({
  type: services.BNBTRADER_BUY_FAIL,
  payload: error,
});

export const bnbTraderSellRequest = (reqParams) => ({
  type: services.BNBTRADER_SELL_REQUEST,
  payload: reqParams,
});

export const bnbTraderSellSuccess = ({ id, data }) => ({
  type: services.BNBTRADER_SELL_SUCCESS,
  payload: {
    id,
    data,
  },
});

export const bnbTraderSellFail = (error) => ({
  type: services.BNBTRADER_SELL_FAIL,
  payload: error,
});

export const bnbTraderBalanceRequest = (reqParams) => ({
  type: services.BNBTRADER_BALANCE_REQUEST,
  payload: reqParams,
});

export const bnbTraderBalanceSuccess = ({ id, data }) => ({
  type: services.BNBTRADER_BALANCE_SUCCESS,
  payload: {
    id,
    data,
  },
});

export const bnbTraderBalanceFail = (error) => ({
  type: services.BNBTRADER_BALANCE_FAIL,
  payload: error,
});

export const cancelTransactionRequest = (reqParams) => ({
  type: services.CANCEL_TRANSACTION_REQUEST,
  payload: reqParams,
});

export const cancelTransactionSuccess = ({ id, data }) => ({
  type: services.CANCEL_TRANSACTION_SUCCESS,
  payload: {
    id,
    data,
  },
});

export const cancelTransactionFail = (error) => ({
  type: services.CANCEL_TRANSACTION_FAIL,
  payload: error,
});

export const approveFeeRequest = (reqParams) => ({
  type: services.APPROVE_FEE_REQUEST,
  payload: reqParams,
});

export const approveFeeSuccess = ({ id, data }) => ({
  type: services.APPROVE_FEE_SUCCESS,
  payload: {
    id,
    data,
  },
});

export const approveFeeFail = (error) => ({
  type: services.APPROVE_FEE_FAIL,
  payload: error,
});

export const approvePartnerFeeRequest = (reqParams) => ({
  type: services.APPROVE_PARTNER_FEE_REQUEST,
  payload: reqParams,
});

export const approvePartnerFeeSuccess = ({ id, data }) => ({
  type: services.APPROVE_PARTNER_FEE_SUCCESS,
  payload: {
    id,
    data,
  },
});

export const approvePartnerFeeFail = (error) => ({
  type: services.APPROVE_PARTNER_FEE_FAIL,
  payload: error,
});

export const updateUserAdminRequest = (reqParams) => ({
  type: services.UPDATE_USER_BY_ID_ADMIN_REQUEST,
  payload: reqParams,
});

export const updateUserAdminSucceed = ({ id, data }) => ({
  type: services.UPDATE_USER_BY_ID_ADMIN_SUCCESS,
  payload: { id, data },
});

export const updateUserAdminFailed = (error) => ({
  type: services.UPDATE_USER_BY_ID_ADMIN_FAIL,
  payload: error,
});

export const getTotalSupplyChartDataRequest = (reqParams) => ({
  type: services.GET_TOTAL_SUPPLY_CHART_DATA_REQUEST,
  payload: reqParams,
});

export const getTotalSupplyChartDataSuccess = (reqParams) => ({
  type: services.GET_TOTAL_SUPPLY_CHART_DATA_SUCCESS,
  payload: reqParams,
});

export const getTotalSupplyChartDataFail = (error) => ({
  type: services.GET_TOTAL_SUPPLY_CHART_DATA_FAIL,
  payload: error,
});

export const getTotalUserChartDataRequest = (reqParams) => ({
  type: services.GET_TOTAL_USER_CHART_DATA_REQUEST,
  payload: reqParams,
});

export const getTotalUserChartDataSuccess = (reqParams) => ({
  type: services.GET_TOTAL_USER_CHART_DATA_SUCCESS,
  payload: reqParams,
});

export const getTotalUserChartDataFail = (error) => ({
  type: services.GET_TOTAL_USER_CHART_DATA_FAIL,
  payload: error,
});

export const getApproveCountRequest = (reqParams) => ({
  type: services.GET_APPROVE_COUNT_REQUEST,
  payload: reqParams,
});

export const getApproveCountSuccess = (reqParams) => ({
  type: services.GET_APPROVE_COUNT_SUCCESS,
  payload: reqParams,
});

export const getApproveCountFail = (error) => ({
  type: services.GET_APPROVE_COUNT_FAIL,
  payload: error,
});

export const getTodayInsightsRequest = (reqParams) => ({
  type: services.GET_TODAY_INSIGHTS_REQUEST,
  payload: reqParams,
});

export const getTodayInsightsSuccess = (reqParams) => ({
  type: services.GET_TODAY_INSIGHTS_SUCCESS,
  payload: reqParams,
});

export const getTodayInsightsFail = (error) => ({
  type: services.GET_TODAY_INSIGHTS_FAIL,
  payload: error,
});

export const getTotalUsersRequest = (reqParams) => ({
  type: services.GET_TOTAL_USERS_REQUEST,
  payload: reqParams,
});

export const getTotalUsersSuccess = (reqParams) => ({
  type: services.GET_TOTAL_USERS_SUCCESS,
  payload: reqParams,
});

export const getTotalUsersFail = (error) => ({
  type: services.GET_TOTAL_USERS_FAIL,
  payload: error,
});

export const getAumDataRequest = (reqParams) => ({
  type: services.GET_AUM_DATA_REQUEST,
  payload: reqParams,
});

export const getAumDataSuccess = (reqParams) => ({
  type: services.GET_AUM_DATA_SUCCESS,
  payload: reqParams,
});

export const getAumDataFail = (error) => ({
  type: services.GET_AUM_DATA_FAIL,
  payload: error,
});

export const getGeneralChartDataRequest = (reqParams) => ({
  type: services.GET_GENERAL_CHART_DATA_REQUEST,
  payload: reqParams,
});

export const getGeneralChartDataSuccess = (reqParams) => ({
  type: services.GET_GENERAL_CHART_DATA_SUCCESS,
  payload: reqParams,
});

export const getGeneralChartDataFail = (error) => ({
  type: services.GET_GENERAL_CHART_DATA_FAIL,
  payload: error,
});

export const getAllGeneralChartDataRequest = (reqParams) => ({
  type: services.GET_ALL_GENERAL_CHART_DATA_REQUEST,
  payload: reqParams,
});

export const getAllGeneralChartDataSuccess = (reqParams) => ({
  type: services.GET_ALL_GENERAL_CHART_DATA_SUCCESS,
  payload: reqParams,
});

export const getAllGeneralChartDataFail = (error) => ({
  type: services.GET_ALL_GENERAL_CHART_DATA_FAIL,
  payload: error,
});

export const getFundReportsRequest = (reqParams) => ({
  type: services.GET_FUND_REPORTS_REQUEST,
  payload: reqParams,
});

export const getFundReportsSuccess = (reqParams) => ({
  type: services.GET_FUND_REPORTS_SUCCESS,
  payload: reqParams,
});

export const getFundReportsFail = (error) => ({
  type: services.GET_FUND_REPORTS_FAIL,
  payload: error,
});

export const getTotalResultsFeeRequest = (reqParams) => ({
  type: services.GET_TOTAL_RESULTS_FEE_REQUEST,
  payload: reqParams,
});

export const getTotalResultsFeeSuccess = (reqParams) => ({
  type: services.GET_TOTAL_RESULTS_FEE_SUCCESS,
  payload: reqParams,
});

export const getTotalResultsFeeFail = (error) => ({
  type: services.GET_TOTAL_RESULTS_FEE_FAIL,
  payload: error,
});

export const getEmergencyContactsRequest = (reqParams) => ({
  type: services.GET_EMERGENCY_CONTACTS_REQUEST,
  payload: reqParams,
});

export const getEmergencyContactsSuccess = (reqParams) => ({
  type: services.GET_EMERGENCY_CONTACTS_SUCCESS,
  payload: reqParams,
});

export const getEmergencyContactsFail = (error) => ({
  type: services.GET_EMERGENCY_CONTACTS_FAIL,
  payload: error,
});
