import i18next from "i18next";
import { initReactI18next } from "react-i18next";

const resources = {
  tr: {
    translation: {
      newsletter_t: "HAFTALIK BÜLTEN",
      newsletter_button: "Abone Ol",
      newsletter_d_one: "Ürün ve pazar haberlerinden haberdar olun",
      newsletter_d_two:
        "E-posta adresinizi girerek, gizlilik politikamız uyarınca pazar ve ürün haber bültenleri almayı kabul etmiş olursunuz.",
      faq: "SSS",
      faq2: "SSS",
      landing_welcome: "Dow Capital'e hoş geldiniz",
      landing_title_first: "Dijital Finansın",
      landing_title_second: "Geleceği",
      landing_title_third: "MPC cüzdanı ile %100 GÜVENLİ",
      landing_hero_title: "Tokenlaştırılmış Yatırım Fonları:",
      landing_tokenised: "Yönettiğimiz Fonlar:",
      landing_c5: "• C5 Endeks Fonu",
      landing_bitcoin: "• Düşüş Korumalı Bitcoin Fonu",
      landing_hedge: "• Kripto Fırsat Fonu",
      landing_arbitrage: "• Kripto Arbitraj Fonu",
      landing_yield_hub: "Getiri Merkezi Fonları:",
      landing_leveraged: "• Kaldıraçlı Staking Getiri Fonu",
      landing_liquidity: "• Likidite Sağlayıcı Getiri Fonu",
      landing_option: "• Opsiyon Stratejileri Getiri Fonu",
      landing_team_title: "Deneyimli bir ekibe sahibiz",
      landing_team_item1: "• Varlık Yöneticileri",
      landing_team_item2: "• IT Uzmanları",
      landing_team_item3: "• Kripto Uzmanları",
      landing_team_desc:
        "Tecrübeli ekibimiz ve farklı stratejilerdeki fonlarımızla sizlere karlı yatırım fırsatları sunmaktayız.",
      landing_faq: "SSS",
      landing_faq_title1: "MPC nasıl çalışır?",
      landing_faq_item1_1:
        "	1- Para çekme talebi, müşterinin çok onay kurulumuna göre başlatılır ve imzalanır (ör. Ana onay + 2 yani 2/3 onaylayıcı ile)",
      landing_faq_item1_2:
        "2- Saklama, güvenli bir ortamda MPC kullanarak, güvenli TSS (imzalama şeması) işlemlerini tamamlar",
      landing_faq_item1_3: "3- İşlemler blockchain'de yayınlanır.",
      landing_faq_title2: "Fonlarım çalınabilir mi?",
      landing_faq_item2:
        "- HAYIR! Özel anahtarınız (private key) ayrıldığı ve dağıtıldığı için (MPC) kimse fonlarınızı çalamaz. Herhangi biri özel anahtarınızın bir parçasını alsa bile, etkinleştiremezler.",
      landing_faq_title3: "Fiat para kabul ediyor musunuz?",
      landing_faq_item3:
        "- Hayır. Yalnızca USDT ile yatırım yapmaya başlayabilirsiniz.",
      landing_faq_title4: "Fonları istediğim zaman alıp satabilir miyim?",
      landing_faq_item4:
        "- Evet. Fon tarafından belirtilen kurallar altında Fonlarınızı istediğiniz zaman alıp satabilirsiniz.",
      landing_faq_title5: "Fon ücretleri?",
      landing_faq_item5:
        "- Her Fonun kendi yönetim ücreti ve performans ücreti vardır. Her Fon için ücretleri Fonların sayfalarında bulabilirsiniz.",
      landing_faq_title6: "Fon Token’ı nedir?",
      landing_faq_item6:
        "- Fon Token’ları bizim tarafımızdan yaratılır (mint) ve Fonları temsil ederler. Yatırım yaptığınızda, Token’ları cüzdanınızda tutarsınız ve Fonlarınızı fona geri sattığınızda, USDT’nizi geri alır, Fon Token’larını geri verirsiniz ve onları yakarız.",
      flanding_q_one_t: "Emanet ve Sigorta",
      flanding_q_one_d:
        "Ceffu (Binance Custody) çözümü, varlıklarınızı güvende tutmak için oluşturulmuş kurumsal bir ekosistemdir.",
      flanding_q_two_t: "MPC Destekli Soğuk Cüzdan",
      flanding_q_two_d:
        "MPC ile anahtar paylaşımları, gerçek bilgilerin hiçbirini ifşa etmeden güvenli bilgileri iletebilir - tek bir hata noktası yoktur.",
      flanding_q_three_t: "Fon Kanıtı",
      flanding_q_three_d:
        "Her cüzdanın, yalnızca kendi fonlarınızı içeren kendi özel zincir üstü adresi vardır. Bu kanıt doğrudan blok zincirinde doğrulanabilir.",
      flanding_q_button: "Daha fazlası için",
      flanding_how: "NASIL ÇALIŞIR?",
      flanding_who: "BİZ KİMİZ?",
      flanding_start_invest: "Yatırıma Başla",
      flanding_start_now: "Yatırım Yap",
      flanding_bs:
        "Barış Sözen, İktisat Lisans derecesine sahiptir. Sabancı Üniversitesi'nde Executive MBA yapmıştır, derece ile mezun olmuştur. Uzun bir finans kariyerine ve Türkiye'nin en büyük özel bankasının ve saygın yabancı bankaların Hazine bölümünde başarılara sahiptir. Akbank'ta sıfırdan alım satım masası kurmuş, 20 yılı aşkın Tahvil, Döviz, Vadeli İşlem ve Opsiyon alım satım tecrübesine sahip, Sermaye Piyasası Kurulu İleri Düzey ve Türev Düzey Lisansına sahiptir.Çözümlerinden dolayı iki kez ödül almıştır. Tübitak matematik problemlerinin yanı sıra blockchain ile ilgili 2 adet patenti ve Veri Bilimi ile ilgili 2 adet sertifikası bulunmaktadır. Türkçe, İngilizce ve orta düzeyde İspanyolca bilmektedir",
      flanding_oi:
        "Ozan İmamoğlu, İnşaat Mühendisliği lisans üstüne MBA yapmıştır. Tezini iskontolu nakit akışı yöntemine göre hisse senedi değerlenmesi üzerine hazırlamıştır. Uzun bir fon yönetimi, varlık yönetimi ve yatırım tecrübesine sahiptir. Küresel Hisse Senedi Piyasaları, Türev Ürünler, Opsiyonlar ve Kripto Piyasaları dahil olmak üzere 15 yıldan fazla yatırım deneyimi vardır. Özellikle, teknik analiz ve algoritmik al-sat stratejileri ilgi alanları arasındadır. Güçlü iletişim becerilerine sahip, kendini motive eden bir kişidir. Analitik ve problem çözme becerilerinin yanı sıra kararlı bir ekip üyesidir. Sermaye Piyasası Kurulu, ileri düzey türev piyasalar lisansına sahiptir. Türkçe (ana dili), İngilizce ve Hollandaca (A2) bilmektedir.",
      flanding_register: "Kayıt Ol",
      flanding_register_d: "Ücretsiz hesabınızı oluşturmak için kaydolun.",
      flanding_deposit: "Yatır",
      flanding_deposit_d: "USDT'nizi güvenli Dow Cüzdanınıza yatırın.",
      flanding_invest: "Yatırım",
      flanding_invest_d: "Fonlara yatırım yap.",
      flanding_bitcoin_d:
        "BTCp fonu, Bitcoinlerinizi korurken, opsiyon stratejileri ile getirinizi arttırmayı hedefler.",
      flanding_investment: "Yatırım Fonlarımız",
      flanding_funds: "Fonlarımız",
      flanding_c5: "C5 Endeks Fonu",
      flanding_c5_d:
        "C5 , piyasa değeri en yüksek değerli 5 koine yatırım yapan bir endeks fondur.",
      flanding_bitcoin: "BTCp Düşüş Korumalı\nBitcoin Fonu",
      flanding_trading: "TRQ Kripto Fırsat Fonu",
      flanding_trading_d:
        "Kripto piyasası yeni ve büyüyen bir piyasadır. Kripto Fırsat Fonu ile yatırımlarınızı biz yönetelim.",
      flanding_arbitrage_t: "ALGORITMIK",
      flanding_arbitrage: "QNT Algoritmik Al-Sat",
      flanding_arbitrage_d:
        "QNT, Quantitative Algoritmik al-sat yapan bir fondur.",
      flanding_yield: "Getiri Merkezi",
      flanding_yield_funds: "Getiri Merkezi Fonları",
      flanding_leveraged: "Kaldıraçlı Staking",
      flanding_leveraged_d:
        "Birçok blok zinciri, bir PoS mekanizması kullanır. Staking, bir blok zincirinin çalışmasını desteklemeye yardımcı olmak için kripto varlıklarını belirli bir süre boyunca kilitlemenizdir. Kriptonuzu staking karşılığında, daha fazla kripto para kazanırsınız.",

      flanding_liquidity_t: "LİKİDİTE",
      flanding_liquidity: "Likidite Sağlayıcılığı",
      flanding_liquidity_d:
        "Likidite havuzları, mevcut DeFi ekosisteminin arkasındaki temel teknolojilerden biridir. Otomatik piyasa yapıcıların (AMM), ödünç verme protokollerinin, verim çiftçiliğinin, sentetik varlıkların, zincir üstü sigortanın, blok zinciri oyunlarının önemli bir parçasıdır. Kripto para piyasalarındaki likidite, esas olarak, tokenların diğer tokenlarla (veya devlet tarafından verilen fiat para birimleriyle) takas edilebilme kolaylığını ifade eder.",
      flanding_option_t: "OPSİYON",
      flanding_option: "Opsiyon Stratejileri",
      flanding_option_d:
        "Opsiyon piyasasını kullanarak gelir elde edin. Kriptolarınıza uygulayabileceğiniz 3 ana strateji vardır: Örtülü Put Satışı, Nakit Teminatlı Call Satışları, Anapara Korumalı Strateji.",
      flanding_about: "Hakkımızda",
      flanding_team: "Ekibimiz",
      flanding_about_dow: "DOW Tokenları Hakkında",
      flanding_about_dMPC: "dMPC Hakkında",
      flanding_news: "Haberler",
      flanding_launch: "Platformu Başlat",
      footer_investment: "YATIRIM ÜRÜNLERİ",
      footer_c5: "C5 Endeks Fonu",
      footer_bitcoin: "BTCp Düşüş Korumalı Bitcoin Fonu",
      footer_trading: "TRQ Kripto Fırsat Fonu",
      footer_arbitrage: "QNT Algoritmik Al-Sat",
      footer_resource: "KAYNAKLAR",
      footer_investor: "Yatırımcı portalı",
      footer_news: "Haberler",
      footer_contact: "İletişim",
      footer_company: "ŞİRKET",
      footer_feedback: "Geri bildirim",
      footer_legal: "YASAL",
      footer_terms: "Kullanım şartları",
      footer_cookie: "Çerez politikası",
      footer_privacy: "Gizlilik politikası",
      footer_weekly: "HAFTALIK BÜLTEN",
      footer_stay: "Yeni ürünlerimiz ve piyasa hakkında güncel kalmak için",
      footer_email:
        "E-posta adresinizi girerek, gizlilik politikamız uyarınca market ve ürün haber bültenlerini almayı kabul etmiş olursunuz.",
      footer_subs: "Abone ol",
      footer_follow: "TAKİP ET",
      footer_yield: "GETİRİ MERKEZİ",
      footer_staking: "Kaldıraçlı Staking",
      footer_liq: "Likidite Sağlayıcı",
      footer_option: "Opsiyon Stratejileri",
      fund_invest: "Al",
      fund_redeem: "Sat",
      fund_launch: "LANSMAN TARİHİ",
      fund_value: "FON BÜYÜKLÜĞÜ",
      fund_price: "FON FİYATI",
      fund_fact: "Bilgi notu",
      fund_page: "Tek sayfalık genel bakış",
      fund_quarter: "3 aylık raporlar",
      fund_market: "Pazar ve finans bilgileri",
      fund_term: "Şartlar ve koşullar",
      c5_advantage: "Fonun Avantajları",
      c5_divers: "Çeşitlendirme",
      c5_quality: "Kalite",
      c5_by_investing:
        "Bu fona yatırım yaparak, en büyük 5 koine doğrudan yatırım yapmış oluyorsunuz. İstatistiksel olarak incelendiğinde, hiçbir yatırımcının veya fonun endeks fonu perfomansını yakalayamadığı bilinen bir gerçek. Koinlerin dağılımdaki , fiyat artışı kaynaklı, bozulmayı her çeyrek sonunda tekrardan ayarlıyoruz. Paranızı kripto piyasalarda değerlendirmek istiyorsanız bu ürün tam sizin içindir.",
      c5_while:
        "Kripto piyasasına , yüksek kalite koinlerden oluşan bir portföy ile iyi bir dağıtılmış risk ile yatırım yapmış olacaksınız. Koin sayısını 5’te tutarak hem risk dağılımını sağlarken, sadece yüksek kalite koinlere yatırım yapmanızı istedik.",
      c5_current_fund: "Mevcut fon varlıkları",
      c5_who_for: "BU FON KİMLER İÇİN?",
      c5_risk: "Risk: Orta-Yüksek",
      c5_crypto_market:
        "2009'da Bitcoin ile başlayan kripto piyasası kısa bir tarihe sahiptir ve olgunlaşmamıştır. Piyasa ani fiyat dalgalanmaları gösterebilmekte ve peşi sıra keskin düşüşler yaşayabilmektedir. Endeks içindeki koin sayısını 5’te tutarak, vasat koin alma ihtimalimizi azalttık ama bu oranda bile ciddi oynaklık beklenmelidir.",
      c5_timeframe: "Zaman Çerçevesi",
      c5_timeframe_desc:
        "Fon, 4 yıllık bir BTC döngüsünden daha uzun vade tutulduğunda en iyi performansı gösterebilecektir.",
      bitcoin_advantage: "Fonun Avantajları",
      bitcoin_divers: "Bitcoinin Potansiyeli",

      bitcoin_by_investing:
        "Bitcoin 14 yıllık tarihi içinde 1 trilyon piyasa değerine ulaştı. Bu harika yatırım aracına sahip olup, diğer yandan da şiddetli oynaklığından hemen hemen hiç etkilenmemek istemez misiniz?",
      bitcoin_quality: "Düşüşünüzü küçük bir yüzde ile sınırlayın",
      bitcoin_while:
        "Bu eşsiz ürün kazanımlarınızı, türev ürünler kullanmak suretiyle sürekli korumanızı , düşen piyasalarda bile sınırlı düşüşlere maruz kalmanıza yarar.",
      bitcoin_who_for: "BU FON KİMLER İÇİN?",
      bitcoin_risk: "Risk: Orta - Az Yüksek",
      bitcoin_crypto_market:
        "Bu fona yatırım yaparak, BTC pozisyonunuzu profesyonellerin yönettiği türev ürünler ile düşüşlerde büyük kayıplara karşı korumanız mümkündür.",
      bitcoin_timeframe: "Zaman Çerçevesi",
      bitcoin_timeframe_desc:
        "Bu fondan fayda görebilmeniz için minimum 2.5 yıl kadar taşınmanız tavsiye edilmektedir.",

      trading_advantage: "Fonun Avantajları",
      trading_divers: "Zamanlama",
      trading_by_investing:
        "Bu ürüne yatırım yaptığınızda, paranız kripto piyasasında tecrübesi olan bankacılar tarafından yönetilmekte olacaktır. Kripto piyasasında hangi koini alacağınız kadar, ne zaman alacağınızın da kararının doğru yapılması gerekir. Kripto piyasası çok hızlı yükseldiği gibi, çok da hızlı değer kaybedebilir. Ayı piyasalarında koinler değerlerinin %80-90’ını kaybedebilmektedir. Dolayısıyla alım-satım konusunda zamanlama çok önemlidir. Bırakın bu konuyu, işin profesyonelleri halletsin, siz sadece kazançlarınızın artışını seyredin.",
      trading_quality: "Temel Analiz",
      trading_while:
        "Kripto projelere başarılı yatırım yapmanın en önemli ayağı doğru projeleri seçebilmektir. Uzman kadromuz kripto komünitesinin genel beğenisi almış projeleri seçerek yatırımcılarına uzun vadeli getiri fırsatı sunmaktadır.",
      trading_who_for: "BU FON KİMLER İÇİN?",
      trading_risk: "Risk: Orta - Az Yüksek",
      trading_crypto_market:
        "Bu fon, kripto piyasasında oluşabilecek fırsatları değerlendirmek açısından, istikbal vaad eden yeni koinlere de yatırım yapmaktadır. Kripto piyasasında olağan dışı getiriler elde etmek isteniyorsa bu tarz koinlere de yatırım yapmak gerekmektedir. Dolayısıyla çok yerleşmiş koinler olmadıkları için, C5 fonu içindekilere nispeten biraz daha fazla risk taşımaktadır.",
      trading_timeframe: "Zaman Çerçevesi",
      trading_timeframe_desc:
        "Bu fondan fayda görebilmeniz için minimum 2.5 yıl kadar taşımanız tavsiye edilmektedir.",
      algorithmic_divers: "Duyguları Kenara Bırak",
      algorithmic_by_investing:
        "Piyasalarda al-sat yapmak heyecan, açgözlülük, panik, inkar, korku, umut gibi pek çok duyguyu beraberinde getirir. Bu duygular al-sat yaparken bizi etkiler ve çok zaman etkili bir şekilde al-sat yapmamıza engel olur. Bu Fonda sadece verilere dayanan bir yöntemle yonetilir. İnsan müdahalesi yoktur. Kuantatif Algoritmik Fon'a yatırım yapın ve getirilerin tadını çıkarın.",
      algorithmic_quality: "Tanımlanmış Kurallar",
      algorithmic_while:
        "Kuantatif Algoritmik sistemler yaratılırken al-sat kuralları önceden belirlenir ve test edilir. Geleneksel yöntemlerle al-sat yapanlar, duygularının değişimiyle hatalı kararlar verebilirler. Kuantatif Algoritmik Fonumuzda al-sat kurallarımız değişmez. Bu da başarıyı getirir.",
      algorithmic_risk: "Risk: Yüksek",
      algorithmic_crypto_market:
        "Kripto marketler yüksek volatiliteye sahiptir, bu da büyük getiriler yaratır. Bu fon yüksek risk yüksek getiri hedeflemektedir.",
      algorithmic_timeframe_desc:
        "Bu fondan fayda görebilmeniz için minimum 1 yıl kadar taşımanız tavsiye edilmektedir.",
      leveraged_title1: "Kaldıraçlı Staking ne demektir?",
      leveraged_item1:
        "Kaldıraç Staking (veya Margin Staking), staking sürecinde alınan getiriyi artırmak için kripto para ödünç alma ve alınan kriptolarla da staking yapılması işlemidir.",
      leveraged_title2: "Riskler nelerdir?",
      leveraged_item2:
        "Kripto fiyatları çok volatil ve değişken olabilir. Bu nedenle, fiyatları düştüğünde bile tutmaktan mutlu olduğunuz koini stake etmelisiniz. Staking, koinlerinizi minimum bir süre için kilitlemenizi gerektirebilir ve bu nedenle, fiyatı düşmeden önce satıp çıkmak gibi bir niyetiniz varsa bu konu ile ilgili fiyat riski vardır. Bazen, yedi günlük veya daha uzun bir staking süresi olabilir. Ancak getiriler güvenlidir ve fiyat riski dışında neredeyse hiç risk yoktur.",
      liquidity_title1: "Likidite Sağlayıcılığı nedir?",
      liquidity_item1:
        "Merkeziyetsiz platformlarda alım-satımlara yardımcı olmak ve herhangi bir Merkezi Olmayan Platformun likiditesini artırmak için , bir platforma kripto varlıkları sağlamaya “likidite sağlama” denir. Likidite sağlayıcılar, likidite sağladıkları DeFi platformundaki yapılan işlemlerden alınan komisyonlardan pay alırlar. İşlem ücretleri, havuzdaki tüm likidite sağlayıcılarına orantılı olarak dağıtılır, bu nedenle ne kadar çok kripto varlığı yatırırsanız, o kadar çok ücret kazanırsınız.",
      liquidity_title2: "Likidite Sağlayıcılığı nedir?",
      liquidity_item2:
        "Merkeziyetsiz platformlarda alım-satımlara yardımcı olmak ve herhangi bir Merkezi Olmayan Platformun likiditesini artırmak için , bir platforma kripto varlıkları sağlamaya “likidite sağlama” denir. Likidite sağlayıcılar, likidite sağladıkları DeFi platformundaki yapılan işlemlerden alınan komisyonlardan pay alırlar. İşlem ücretleri, havuzdaki tüm likidite sağlayıcılarına orantılı olarak dağıtılır, bu nedenle ne kadar çok kripto varlığı yatırırsanız, o kadar çok ücret kazanırsınız.",
      option_strategy: "Yatırım Stratejileri",
      option_title1: "Covered Call Satış",
      option_content1:
        "Bu havuz otomatize edilmiş covered call satarak, opsiyon primlerinden gelir yaratır. Bu havuza plase edilen paralar haftada bir havuza dahil edilir ve kazançlar her hafta anaparaya eklenerek geri yatırım yapılır.",
      option_desc1:
        "En iyi ayı veya hafif boğa piyasalarında iyi performans gösterir.",
      option_title2: "Nakit Teminatlı Put Satış",
      option_content2:
        "Bu havuza plase edilen stabil koinlerle otomatize edilmiş nakit teminatlı Put satarak gelir yaratır. Bu havuza plase edilen paralar haftada bir havuza dahil edilir ve kazançlar her hafta anaparaya eklenerek geri yatırım yapılır.",
      option_desc2:
        "Bu strateji en iyi boğa veya hafif ayı piyasalarda iyi performans gösterir.",
      option_title3: "Anapara Korumalı Strateji",
      option_content3:
        "Bu havuz, anaparanın bir borç verme platformuna plase edilmesi ve kazanılan faizin bir kısmının bir koinin fiyatının çok hızlı düşmesi veya çıkmasına odaklı opsiyonlar alınması ile yürütülür.",
      option_desc3: "Bu havuz en iyi volatilitenin yüksek olduğu piyasalardır.",

      about_title: "Dow Digital Capital Hakkında",
      about_vision: "Vİzyonumuz",
      about_content1:
        "Dow Capital, finans ve bankacılık alanında ülkenin en önde gelen kurumlarında yetişmiş, en vasıflı profesyonellerin ortak vizyonu ile kurulmuştur. Yatırım fonlarında blockchain teknolojisinin geri dönülemez bir değişim getireceğini görmekteyiz. Bu değişimin içinde, değişime yön vermeyi kendimize vizyon olarak belirledik.",
      about_content2:
        "Yeni nesil yatırım araçları ile yatırım fonlarına yeni bir tanım ve yeni bir anlayış getiriyoruz. Şeffaflık ve hesap verilebilirliği temel aldığımız yatırım ve getiri merkezi fonlarımızla, yüksek getiri sağlarken, fon yatırımcılarımıza aynı zamanda olması gereken iç huzurunu verebilmeyi amaç edindik.",
      about_business: "İşimiz",
      about_funds: "Yatırım Fonları",
      about_funds_content:
        "2009'da Bitcoin ile başlayan kripto piyasası kısa bir tarihe sahiptir ve olgunlaşmamıştır. Piyasa ani fiyat dalgalanmaları gösterebilmekte ve peşi sıra keskin düşüşler yaşayabilmektedir. Endeks içindeki koin sayısını 5’te tutarak, vasat koin alma ihtimalimizi azalttık ama bu oranda bile ciddi oynaklık beklenmelidir.",
      about_invest_title: "Yield Vaults",
      about_invest1:
        "Kripto paralarınıza faiz kazanmak için sizler için hazırladığımız 3 çeşit yöntem bulunmaktadır. Bu 3 yönteme ait olası getiriler ve olası riskleri, her bir yönteme ait sayfada açıkladık: ",
      about_invest2: "1- Kaldıraçlı Staking.",
      about_invest3:
        "2- Defi protokollerindeki işlem çiftlerinin havuzlarına likidite desteği vererek.",
      about_invest4:
        "3- 3- Ana para pozisyonuna göre, türev enstrümanlar olan opsiyonlarla alım-satım pozisyonları açarak.",
      news_back: "Geri dön.",
      login_error: "E-posta veya şifre hatalı.",
      login_success: "Başarıyla giriş yapıldı.",
      forgot_password_success: "E-postanıza mail gönderildi.",
      forgot_password_error: "E-posta bulunamadı.",
      reset_password_success: "Şifreniz başarıyla değiştirildi.",
      reset_password_error: "Bir hata oluştu. Lütfen tekrar deneyin",
      login_button: "Giriş yap",
      login_email: "E-posta",
      login_pass: "Şifre",
      login_forgotpass: "Şifreni mi unuttun?",
      login_forgotpass_button: "Şifremi sıfırla",
      login_resetpass: "Şifreni sıfırla",
      login_have_acc: "Hesabın yok mu?",
      login_create: "Hesap Oluştur",
      signup_title: "Hesap Oluştur",
      signup_name: "İsim",
      signup_surname: "Soyisim",
      signup_email: "E-posta",
      signup_pass: "Şifre",
      signup_confirm: "Şifre tekrar",
      signup_referral: "Referans kodu (opsiyonel)",
      signup_terms1: "Şartlar ve koşulları",
      signup_terms2: " kabul ediyorum.",
      signup_privacy:
        "Gizlilik politikası uyarınca Dow Digital Capital'den e-posta iletişimi almayı kabul ediyorum.",
      signup_create: "Hesap Oluştur",
      signup_already: "Zaten hesabın var mı?",
      signup_button: "Giriş yap",

      dashboard_approve: "Onay işlemi alındı.",
      dashboard_cancel: "Onay işlemi iptal edildi.",
      dashboard_error: "Bir hata oluştu.",
      dashboard_home: "Ana sayfa",
      dashboard_invest: "Yatırım İşlemleri",
      dashboard_redeem: "Talep İşlemleri",
      dashboard_withdraw: "Para Çekme İşlemleri",
      dashboard_users: "Kullanıcılar",
      dashboard_funds: "Fonlar",
      dashboard_news: "Haberler",
      dashboard_add_news: "+ Haber Ekle",
      dashboard_partners: "Partnerler",
      dashboard_deposit: "Para Yatırma İşlemleri",
      dashboard_management: "Management Komisyonları",
      dashboard_performance: "Performance Komisyonları",

      fnavbar_panel: "Panel",
      fnavbar_wallets: "Cüzdanlar",
      fnavbar_invest: "Yatırım",
      fnavbar_hub: "Getiri Merkezi",
      fnavbar_borrow: "Mevduat/Kredi",

      accountstatus_welcome: "HOŞ GELDİNİZ",

      ffundlist_price: "Fon Fiyat Hareketleri",
      ffundlist_noinfo: "Veri yok",

      wallets_wallet: "Cüzdanlar",
      wallets_portfolio: "PORTFOLYO DEĞERİ",
      wallets_go: "Cüzdana git",

      transaction_latest: "Son İşlemler",
      transaction_noinfo: "Veri yok",
      transaction_more: "Daha fazla",

      guide_guide: "Rehber",

      news_news: "Haberler",
      news_more: "Daha fazla",

      sidebar_home: "Ana sayfa",
      sidebar_invest: "Yatırım İşlemleri",
      sidebar_redeem: "Talep İşlemleri",
      sidebar_deposit: "Para Yatırma İşlemleri",
      sidebar_withdraw: "Para Çekme İşlemleri",
      sidebar_users: "Kullanıcılar",
      sidebar_partners: "Partnerler",
      sidebar_fees: "Komisyonlar",
      sidebar_funds: "Fonlar",
      sidebar_news: "Haberler",

      bottombar_approve: "Onay işlemi alındı.",
      bottombar_cancel: "Onay işlemi iptal edildi.",
      bottombar_error: "Bir hata oluştu.",
      bottombar_selected: "Seçilen İşlemler",

      wallet_dow: "DOW Cüzdanı",
      wallet_balance: "CÜZDAN BAKİYESİ",
      wallet_refresh: "Bakiyeyi güncelle",
      wallet_deposit: "Para Yatırma",
      wallet_deposit_wallet: "USDC Yatırma Cüzdanınız",
      wallet_no_wallet: "Kayıtlı cüzdanınız yok.",
      wallet_withdraw: "Para Çekme",
      wallet_withdraw_wallet: "USDC Çekme Cüzdanınız",
      wallet_cash: "Nakit",
      wallet_balances: "Bakiyesi",
      wallet_funds: "Fonlar",
      wallet_funds_info: "Miktar/USD Değeri",
      wallet_latest: "Son İşlemler",
      wallet_noinfo: "Veri yok",
      wallet_more: "Daha fazla",

      invest_funds: "Kripto Fonlarımız",
      invest_detail:
        "Her yatırım tercihine uygun, çeşitli fonlarımızdan size uygun olana yatırım yapabilirsiniz.",

      yield_navbar: "Merkeziyetsiz Bölge",
      yield_hub: "Getiri Merkezi",
      yield_detail:
        "Her yatırım tercihine uygun, çeşitli fonlarımızdan size uygun olana yatırım yapabilirsiniz.",

      about_dow_tokens_main: "Dow Tokenleri Hakkında",
      about_dow_tokens_security: "Menkul Kıymet nedir?",
      about_dow_tokens_body:
        'ABD Finansal Hizmetler düzenleyicisi,”menkul kıymeti” şu şekilde tanımlar; Menkul kıymet" terimi, herhangi bir tahvil, hisse senedi, hazine senedi, menkul kıymet geleceği, menkul kıymet bazlı takas, bono, borç senedi, borçluluk kanıtı, faiz sertifikası veya herhangi bir kar paylaşım anlaşmasına katılım, teminat-tröst sertifikası, kuruluş öncesi sertifika veya abonelik, devredilebilir hisse, yatırım sözleşmesi, oylama-tröst sertifikası, bir menkul kıymet için mevduat sertifikası, petrol, gaz veya diğer maden haklarındaki kısmi bölünmemiş faiz, herhangi bir menkul kıymet üzerinde herhangi bir satın alma, alım, üst üste binme, opsiyon veya imtiyaz, sertifika menkul kıymetler mevduatı veya grubu veya endeksi (bunlardaki herhangi bir faiz dahil veya değerine dayalı olarak) veya yabancı para birimiyle ilgili bir ulusal menkul kıymetler borsasında girilen herhangi bir satım, alım, üst üste binme, opsiyon veya imtiyaz veya genel olarak, yaygın olarak "menkul kıymet" olarak bilinen herhangi bir menfaat veya araç veya herhangi bir menfaat veya katılım sertifikası, geçici veya geçici sertifika, makbuz, garanti veya taahhüt veya abone olma hakkı veya yukarıdakilerden herhangi birisinin satın alınması durumunda oluşur.',
      about_dow_tokens_sectitle: "SEC kimdir?",
      about_dow_tokens_seccontent:
        "ABD Menkul Kıymetler ve Borsa Komisyonu, 1929'daki Wall Street Çöküşü'nün ardından oluşturulan, Birleşik Devletler federal hükümetinin bağımsız bir kurumudur. SEC'in birincil amacı, yasayı piyasa manipülasyonuna karşı uygulamaktır.",
      about_dow_tokens_howeytitle: "Howey Testi Nedir?",
      about_dow_tokens_howeycontent:
        'Howey Testi, bir işlemin "yatırım sözleşmesi(menkul kıymet)" olarak nitelendirilip nitelendirilmediğini ve bu nedenle bir menkul kıymet olarak kabul edilip edilmeyeceğini ve 1933 Menkul Kıymetler Yasası ve 1934 Menkul Kıymetler Borsası Yasası kapsamında ifşa ve kayıt gerekliliklerine tabi olup olmayacağını belirleyen ABD Yüksek Mahkemesi davasına atıfta bulunarak, 4 maddeden oluşan bir test ile karar verilmesine yardımcı olur.',
      about_dow_tokens_ehoweytitle: "Howey Testinin 4 sorusu",
      about_dow_tokens_ehoweycontent:
        "menkul kıymet” var olup olmadığını belirlemede kullanılacak Howey dört sorulu testi: (1) paranın yatırım amacıyla, (2) ortak bir işletmeye, (3) başkalarının çabalarından elde edilmesi şartıyla (4) kar beklentisiyle yatırılmasıyla oluşur.",
      about_dow_tokens_abouttitle: "Dow Tokenleri nedir?",
      about_dow_tokens_aboutcontent:
        "Dow Digital Capital içinde, C5 (Endeks fonu tokeni), BTCp Tokeni (Korumalı BTC Fonu) ve TRQ tokenleri kullanmaktadır. Dow Tokenleri, yatırdığınız fondaki payınızı temsil eden tokenlerdir. Yalnızca dahili bir muhasebe birimi olarak kullanılırlar. Ve yatırım sözleşmeleri değildir. Herhangi bir sermaye veya yatırım getirisi vaat etmezler. Müşterilerimizin muhasebe bakiyelerini kendi IT yapımızdaki 2. katman blockchain üstünde takip etmemize rağmen, bu belirteçler aynı zamanda muhasebemizin bir sağlaması olarak da kullanılmaktadır. Bu tokenler, hesaplarınıza ETH ağı üzerindeki ikinci bir katman ağında verilir. Bunlar menkul kıymet değildir, bu nedenle herhangi bir yatırım veya yatırıma bağlı getiri vaat etmezler.",

      accountsettings_u1: "Güncelleme başarılı",
      accountsettings_u2: "Cüzdanınız güncellendi.",
      accountsettings_logout: "Başarıyla çıkış yapıldı.",
      accountsettings_success1: "Cüzdan eklendi.",
      accountsettings_success2: "Cüzdan başarıyla eklendi.",
      accountsettings_title: "Hesap Ayarları",
      accountsettings_info: "Hesap Bilgileri",
      accountsettings_name: "İsim",
      accountsettings_surname: "Soyisim",
      accountsettings_walletinfo: "Cüzdan Bilgileri",
      accountsettings_withdrawwallet: "Para çekme cüzdanı",
      accountsettings_update: "Güncelle",
      accountsettings_partner: "Partner Bilgileri",
      accountsettings_referral: "Referans kodunuz: ",
      accountsettings_logout_button: "Çıkış yap",
      accountsettings_save: "Değişiklikleri kaydet",
      accountsettings_2fa: "2 Faktörlü Doğrulama",
      accountsettings_2fa_info:
        "İki Faktörlü Kimlik Doğrulama (2FA), oturum açmak için bir kimlik doğrulama kodu ve şifreniz gerektirerek hesabınızın güvenliğini büyük ölçüde artıran bir güvenlik önlemidir.",
      accountsettings_2fa_create: "2FA Oluştur",
      accountsettings_2fa_disable: "2FA Devre Dışı Bırak",

      buy_insuff_err: "Yetersiz bakiye.",
      buy_network_err: "Ağ ücretinden büyük değer girin.",
      buy_invest_succ: "Yatırım işlemi başarıyla oluşturuldu.",
      buy_err: "Bir hata oluştu. Lütfen daha sonra tekrar deneyin.",
      buy_back: "Fonlara dön",
      buy_invest_fund: "Fona yatırım yap",
      buy_invest: "Yatırım",
      buy_usdc: "Kullanılabilir USDC Bakiyeniz",
      buy_fund_price: "Güncel Fon Fiyatı",
      buy_estimated: "Tahmini alabileceğiniz {{money}} sayısı",
      buy_all: "Tüm Bakiye",
      buy_usd_type: "USDC Cinsinden Miktar",
      buy_fee: "Tahmini Ağ Ücreti",
      buy_fee2: "Al",

      redeem_insuff_err: "Yetersiz bakiye.",
      redeem_redeem_succ: "Satış işlemi başarıyla oluşturuldu.",
      redeem_err: "Bir hata oluştu. Lütfen daha sonra tekrar deneyin.",
      redeem_back: "Fonlara dön",
      redeem_redeem_fund: "Fon Sat",
      redeem_redeem: "Satış yap",
      redeem_estimated: "Kullanılabilir {{money}} Bakiyeniz",
      redeem_current: "Güncel Fon Fiyatı",
      redeem_clear: "Tümünü temizle",
      redeem_est: "Tahmini",
      redeem_all: "Tüm Bakiye",
      redeem_amount: "{{textDow}} {{money}} Cinsinden Miktar",
      redeem_usdc: "{{textUsd}} USDC Cinsinden Miktar",
      redeem_fee2: "Sat",

      withdraw_insuff_err: "Yetersiz bakiye.",
      withdraw_network_err: "Ağ ücretinden büyük değer girin.",
      withdraw_invest_succ: "Para çekme işlemi başarıyla oluşturuldu.",
      withdraw_err: "Bir hata oluştu. Lütfen daha sonra tekrar deneyin.",
      withdraw_back: "Cüzdana dön",
      withdraw_invest_fund: "Para çek",
      withdraw_invest: "Para Çekim",
      withdraw_usdc_: "Çekilebilir USDC Bakiyeniz",
      withdraw_fund_price: "Güncel Fon Fiyatı",
      withdraw_estimated: "Tahmini alabileceğiniz {{money}} sayısı",
      withdraw_all: "Tüm Bakiye",
      withdraw_usd_type: "USDC Cinsinden Miktar",
      withdraw_fee: "Tahmini Ağ Ücreti",
      withdraw_fee2: "Para çek",

      transaction_title: "Yatırım İşlemleri",

      daily: "Günlük",
      weekly: "Haftalık",
      monthly: "Aylık",
      today: "Bugün",
      this_week: "Bu Hafta",
      this_month: "Bu Yıl",

      assets: "Varlıklar",

      withdraw_usdc: "USDC Çek",
      deposit_usdc: "USDC Yatır",
      internal_tx: "Para Transferi",

      portfolio: "Portfolyo",

      no_data: "Veri Yok",
      history: "Geçmiş",

      yield_earnings: "Getiri Merkezi Kazançları",
      yield_earnings2: "Yatırım Fonu Kazançları",

      fund: "FON",
      quantity: "MİKTAR",
      buy_price: "ALIŞ FİYATI",
      sell_price: "SATIŞ FİYATI",
      fee: "KOMİSYON",
      total: "TOPLAM",
      date: "TARİH",
      action: "EYLEM",

      funds: "Fonlar",
      invested_usd: "YATIRILAN USD",
      current_price: "ANLIK FİYAT",
      return_dollar: "GETİRİ %",
      mark_to_market: "MARK TO MARKET",
      balance: "Bakiyesi",

      i_today: "Bugünün Yatırımları",
      r_today: "Bugün Çıkan Para",
      d_today: "Bugün Yatan Para",
      total_i: "Toplam Yatırımlar",
      total_r: "Toplam Çekmeler",
      total_d: "Toplam Yatırımlar",
      total_u: "Toplam Kullanıcılar",
      fund_users: "FON KULLANICILARI",
      aum: "Yönetim Altındaki Varlıklar",
      aum_users: "USERS",

      t_tx_id: "TX ID",
      t_user_id: "KULLANICI ID",
      t_name_surname: "AD SOYAD",
      t_fund_token: "FON ADI",
      t_status: "DURUM",
      t_amount_usd: "USD MİKTARI",
      t_amount_token: "TOKEN MİKTARI",
      t_unit_price: "BİRİM FİYATI",
      t_date: "TARİH",
      t_from: "ŞU ADRESTEN",
      t_processing: "İŞLENİYOR",
      t_rejected: "REDDEDİLDİ",
      t_success: "BAŞARILI",
      t_waiting: "ONAY BEKLİYOR",
      t_error: "HATA",

      t_gross_usd: "BRÜT USD",
      t_net_usd: "NET USD",
      t_tx_fee: "TX ÜCRETİ",

      t_total_balance: "TOPLAM BAKİYE",
      t_ceffu_balance: "CEFFU BAKİYESİ",
      t_deposit_address: "YATIRIM ADRESİ",
      t_withdraw_address: "ÇEKİM ADRESİ",
      t_actions: "İŞLEMLER",

      t_name: "AD",
      t_surname: "SOYAD",
      t_partner_count: "PARTNER SAYISI",
      t_partner_commission: "PARTNER KOMİSYONU",
      t_partner_total_commission: "PARTNER TOPLAM KOMİSYONU",
      t_referral_code: "REFERANS KODU",
      t_news_id: "HABER ID",
      t_title: "BAŞLIK",

      t_fund_id: "FON ID",
      t_fund_name: "FON ADI",
      t_performance_fee: "PERFORMANS KOMİSYONU",
      t_management_fee: "YÖNETİM KOMİSYONU",
      t_divider: "BÖLEN",
      t_fund_value: "FON DEĞERİ",

      t_fund: "FON",
      t_commission_usd: "KOMİSYON USD",
      t_approve: "Onayla",

      yield_staking_s: "Yatırım yapın ve sabit gelir elde edin",
      yield_option_s: "Call ve Put opsiyon satımı",
      status: "DURUM",
      rejected: "Reddedildi",
      success: "Başarılı",
      error: "Hata",
      approve: "Onay Bekliyor",

      otp_otp: "Google Authenticator",
      otp_code: "Kod",
      otp_submit: "Tamam",
      accountsettings_ec_title: "Acil Durum Kişisi",
      accountsettings_ec_info:
        "buradan acil durum kişisi oluşturabilir veya güncelleyebilirsiniz.",
      accountsettings_ec_update: "Güncelle",
      accountsettings_ec_create: "Ekle",
    },
  },
  en: {
    translation: {
      newsletter_t: "WEEKLY NEWSLETTER",
      newsletter_button: "Subscribe",
      newsletter_d_one: "Stay up to date on product & market news",
      newsletter_d_two:
        "By entering your e-mail address, you agree to receive market and product newsletters as per our privacy policy.",
      faq: "FAQ's",
      faq2: "FAQ's",
      landing_welcome: "Welcome to Dow Capital",
      landing_title_first: "The Future of",
      landing_title_second: "Digital Investing",
      landing_title_third: "100% SECURE by MPC wallet",
      landing_hero_title: "We manage:",
      landing_tokenised: "Tokenised Investment Funds:",
      landing_c5: "• C5 Index Fund",
      landing_bitcoin: "• Bitcoin Protected Fund",
      landing_hedge: "• Crypto Trading Hedge Fund",
      landing_arbitrage: "• Crypto Arbitrage Fund",
      landing_yield_hub: "Yield Hub Funds:",
      landing_leveraged: "• Leveraged Crypto Staking Yield Fund",
      landing_liquidity: "• Liquidity Providing Yield Fund",
      landing_option: "• Option Strategies Yield Fund",
      landing_team_title: "We are a team of experienced",
      landing_team_item1: "• Asset Managers",
      landing_team_item2: "• IT Specialists",
      landing_team_item3: "• Crypto Experts",
      landing_team_desc:
        "Our team and our funds will help you navigate and increase your wealth through many strategies.",
      landing_faq: "Frequently Asked Questions",
      landing_faq_title1: "How MPC works?",
      landing_faq_item1_1:
        "1- Withdrawal request is initiated and signed off according to client’s multi-approval setup (e.g., Spender + 2 of 3 approvers)",
      landing_faq_item1_2:
        "2- Custodian completes secure TSS (signing scheme) to execute transactions using MPC in a secure environment",
      landing_faq_item1_3: "3- Transactions are broadcast to the blockchain",
      landing_faq_title2: "Can anyone steal my funds?",
      landing_faq_item2:
        "-No! No one can steal your funds because your private key is separated and distributed. Even if any one gets a piece of your private key, they can not activate.",
      landing_faq_title3: "Do you accept fiat money?",
      landing_faq_item3: " No. You can start investing only with USDT.",
      landing_faq_title4: "Can I buy and sell my Funds any time?",
      landing_faq_item4:
        "-Yes. You can buy & sell your Funds anytime under the rules specified by the Fund.",
      landing_faq_title5: "How about fees?",
      landing_faq_item5:
        "-Each fund has its own management fee and performance fee. You can find them in their factsheets.",
      landing_faq_title6: "What is fund token?",
      landing_faq_item6:
        "-Fund tokens are minted tokens by us and they represent the funds. When you invest you keep them in your wallet and when you sell your funds back to the fund, basically you give back funds tokens and we burn them.",
      flanding_q_one_t: "Custody and Insurance",
      flanding_q_one_d:
        "Ceffu (Binance Custody), solution is an institutional ecosystem built to keep your assets safe.",
      flanding_q_two_t: "MPC-Backed Cold Storage",
      flanding_q_two_d:
        "With MPC, key shares can communicate secure information without revealing any of the actual information – there is no single point of failure.",
      flanding_q_three_t: "Proof of Funds",
      flanding_q_three_d:
        "Each wallet has its own dedicated on-chain address, which only contains your own funds. This proof can be verified directly on the blockchain.",
      flanding_q_button: "To Learn More",
      flanding_how: "HOW IT WORKS?",
      flanding_who: "WHO ARE WE?",
      flanding_start_invest: "Start Investing",
      flanding_start_now: "Invest Now",
      flanding_bs:
        "Barış Sözen, has a Bachelor of Science degree in Economics. He has studied Executive MBA at Sabanci University, graduated with honor degree. He has a long finance career and success in Treasury department of the largest private bank of Turkey and reputable foreign banks. He has build up the trading desk in Akbank from scratch and has more than 20 years of trading experience in Bonds, FX , Futures and Options. He holds Capital Markets Board of Turkey Advanced and Derivatives Level License. He has been awarded twice for his solutions of mathematic problems by Tubitak He also holds 2 patents regarding blockchain and also 2 certificates about Data Science He speaks Turkish , English and intermediary level Spanish",
      flanding_oi:
        "Ozan Imamoglu, has a Bachelor of Science degree in Civil Engineering. He has an Master of Business Administration with a theisis of Stock Valuation by DCF.   He has a fund management, asset management experience. He has more than 15 years investment experience including global Stock Markets, Derivatives, Options and Crypto Markets. Technical analysis and Algorithmic Trading strategies are his main core.   He is a self-motivated person with strong communication skills. Along side of his analytic and problem solving skills, he is a determined team member.     He holds Capital Markets Board of Turkey, Advanced and Derivatives Level License.     He speaks Turkish (native), English and Dutch (A2)",
      flanding_register: "Register",
      flanding_register_d: "Sign up to create your free account.",
      flanding_deposit: "Deposit",
      flanding_deposit_d: "Deposit your USDT to your secure Dow Wallet.",
      flanding_invest: "Invest",
      flanding_invest_d: "Invest in managed funds.",
      flanding_investment: "Yatırım Fonlarımız",
      flanding_funds: " Our Funds",
      flanding_c5: "C5 Index Fund",
      flanding_c5_d:
        "C5 is a tokenized index fund that invests in top 5 coin project in market cap.",
      flanding_bitcoin: "BTCp Bitcoin Protected Fund",
      flanding_bitcoin_d:
        "BTCp is a fund that tries to outperform BTC returns by buying BTC put options and financing that option by lending or Call option selling",
      flanding_trading: "TRQ Crypto Trading Fund",
      flanding_trading_d:
        "Crypto market is a new and expanding market. Investing in crypto coins requires experience and network.",
      flanding_arbitrage_t: "ALGORITHMIC",
      flanding_arbitrage: "QNT Algorithmic Trading",
      flanding_arbitrage_d:
        "QNT is a Quantitative based Algorithmic trading fund",
      flanding_yield: "Yield Hub",
      flanding_yield_funds: "Yield Hub Funds",
      flanding_leveraged: "Leveraged Staking",
      flanding_leveraged_d:
        "Many blockchains use a proof of stake consensus mechanism. Staking is when you lock crypto assets for a set period of time to help support the operation of a blockchain. In return for staking your crypto, you earn more cryptocurrency.",
      flanding_liquidity_t: "LIQUIDITY",
      flanding_liquidity: "Liquidity Providing",
      flanding_liquidity_d:
        "Liquidity pools are one of the foundational technologies behind the current DeFi ecosystem. They are an essential part of automated market makers (AMM), borrow-lend protocols, yield farming, synthetic assets, on-chain insurance, blockchain gaming – the list goes on. Liquidity in cryptocurrency markets essentially refers to the ease with which tokens can be swapped to other tokens (or to government issued fiat currencies)",

      flanding_option_t: "OPTION",
      flanding_option: "Option Strategies",
      flanding_option_d:
        "Generate income through the use of Options market. There are 3 main strategies you can apply to your cryptos: Selling Covered Calls, Cash Secured Puts, Principle Protected Strategy.",
      flanding_about: "About Us",
      flanding_team: "Our Team",
      flanding_about_dow: "About DOW Tokens",
      flanding_about_dMPC: "About dMPC",
      flanding_news: "News",
      flanding_launch: "Launch Platform",
      footer_investment: "INVESTMENT PRODUCTS",
      footer_c5: "C5 Index Fund",
      footer_bitcoin: "BTCp Bitcoin Protected Fund",
      footer_trading: "TRQ Crypto Trading Fund",
      footer_arbitrage: "QNT Algorithmic Trading",
      footer_resource: "RESOURCES",
      footer_investor: "Investor portal",
      footer_news: "News",
      footer_contact: "Contact",
      footer_company: "COMPANY",
      footer_feedback: "Feedback",
      footer_legal: "LEGAL",
      footer_terms: "Terms of use",
      footer_cookie: "Cookie policy",
      footer_privacy: "Privacy policy",
      footer_weekly: "WEEKLY NEWSLETTER",
      footer_stay: "Stay up to date on product & market news",
      footer_email:
        "By entering your email address, you agree to receive market and product newsletters as per our privacy policy.",
      footer_subs: "Subscribe",
      footer_follow: "FOLLOW US",
      footer_yield: "YIELD HUB",
      footer_staking: "Staking/Leveraged Staking",
      footer_liq: "Liquidity Providing",
      footer_option: "Option Strategies",
      fund_invest: "Invest now",
      fund_redeem: "Sell now",
      fund_launch: "LAUNCH DATE",
      fund_value: "FUND VALUE",
      fund_price: "FUND PRICE",
      fund_fact: "Factsheet",
      fund_page: "One page overview",
      fund_quarter: "Quarterly reports",
      fund_market: "Market insights and financials",
      fund_term: "Terms and Conditions",

      c5_advantage: "Fund Advantages",
      c5_divers: "Diversification",
      c5_by_investing:
        "By investing into this fund, you will be able to track the performance of top 5 coins. Rather than investing in a single coin, a diversified approach has always outperform. We balance the coin distribution on Quarterly basis. If you want a general broad market exposure to BTC and market leading altcoin markets, this is for you",
      c5_quality: "Quality",
      c5_while:
        "While you keep a diversified exposure to the crypto market, you will have a high quality portfolio. That’s why we kept the numbers of coins at five. It is a nice balance between, quality projects and diversification.",
      c5_current_fund: "Current fund holdings",
      c5_who_for: "Who is this fund for?",
      c5_risk: "Risk: Medium to High",
      c5_crypto_market:
        "Cryto market has a history of 13 years and still has not matured and fully regulated. Historically market experiences sharp declines and higher returns rapidly. By keeping the nuber of coins at 5, we minimize the risk of having a relatively junk coin but even at that rate volatility should be expected.",
      c5_timeframe: "Time frame",
      c5_timeframe_desc:
        "The fund will provide best results when considered no shorter than a BTC cycle, which is approximately 4 year.",

      bitcoin_advantage: "Fund Advantages",
      bitcoin_divers: "Bitcoin, once in a lifetime opportunity",
      bitcoin_by_investing:
        "Bitcoin is offering an extraordinary, once in a lifetime opportunity, but you don’t like prices going up and down in such a volatile market. Let us run your BTC holding with a professional managed derivative products, so that you don’t experience any serious drawdowns, ever.",
      bitcoin_quality: "Limit your drawdown by a small percentage",
      bitcoin_while:
        "This unique product will keep your gains protected by derivative products.",
      bitcoin_who_for: "Who is this fund for?",
      bitcoin_risk: "Risk: Medium to Low High",
      bitcoin_crypto_market:
        "With this fund by hedging your Bitcoin positions by actively managed put options, we protect your Bitcoin against sharp declines.",
      bitcoin_timeframe: "Time frame",
      bitcoin_timeframe_desc:
        "The fund will provide best results when considered to hold at least a BTC cycle , which is roughly 2.5 years. This doesn’t mean , you shouldn’t invest more than 2.5 years but you will see good returns coming out in 2.5 years worst case.",

      trading_advantage: "Fund Advantages",
      trading_divers: "Timing",
      trading_by_investing:
        "Market timing is essential in crypto investing, one should always try to time the bottom and top of the market no matter what because, crypto bear markets are the worst bear markets ever. Coins tend to loose %80-90 of their value. By investing in this fund, you will be taken care of about market timing. Our team is using Quant data to figure out most of the market, so that you can enjoy highest returns.",
      trading_quality: "Fundamentals",
      trading_while:
        "The crucial element of investing in crypto projects is choosing the right projects. Our specialist choose fundamentally solid projects with a strong presence. Our aim is to offer a long term returns for our Fund investors.",
      trading_who_for: "Who is this fund for?",
      trading_risk: "Risk: Medium to Low High",
      trading_crypto_market:
        "This fund will be looking out for opportunities in the whole crypto market. This involves in investing in some emerging coins time to time. Most of the time, emerging coins offer great opportunities.",
      trading_timeframe: "Time frame",
      trading_timeframe_desc:
        "The fund will provide best results when considered to hold at least a BTC cycle , which is roughly 2.5 years.",
      algorithmic_divers: "Without Emotions",
      algorithmic_by_investing:
        "Trading creates many emotions like excitement, greed, panic, denial, fear, hope. These emotions affect traders and prevent them trade effectively. Our fund is based on only data. There is no human intervention. Invest in Quantitative Algorithmic Fund and enjoy emotion free returns.",
      algorithmic_quality: "Defined Rules",
      algorithmic_while:
        "When an algorithmic system is defined, rules are pre-defined and tested. Discretionary traders, because of the emotions, deviate their rules and make mistakes.  In our Quantitative Algorithmic Fund trading rules are define and we don’t deviate. This creates success.",
      algorithmic_risk: "Risk: High",
      algorithmic_crypto_market:
        "Volatile markets like crypto markets can create huge profit opportunities. This fund aims high risk high return.",
      algorithmic_timeframe_desc:
        "The suggest you hold this fund at least 1 year.",
      leveraged_title1: "What is Leveraged Staking?",
      leveraged_item1:
        "Leverage Staking (or Margin Staking) is simply the process of borrowing cryptocurrency to amplify the rewards (yield) that are received through the process of staking.",
      leveraged_title2: "What are the risks?",
      leveraged_item2:
        "Crypto prices are volatile and can drop quickly. So you should stake the coin you are happy to hold even if their prices fall. Staking can require that you lock up your coins for a minimum amount of time and so there is a price risk. If you want to be out of the coin before its price falls. Sometimes, there may be an unstaking period of seven days or longer But the returns are safe and almost no risk other than price risk.",
      liquidity_title1: "What is Liquidity Providing?",
      liquidity_item1:
        "Providing crypto assets to a platform to help with decentralization of trading and increase the liquidity of any Decentralized Platform is called liquidity providing. Liquidity providers earn fees from transactions on the DeFi platform they provide liquidity on. The transaction fees are distributed proportionally to all the liquidity providers in the pool, so the more crypto assets you stake the more fees you'll earn.",
      liquidity_title2: "What is Liquidity Providing?",
      liquidity_item2:
        "Providing crypto assets to a platform to help with decentralization of trading and increase the liquidity of any Decentralized Platform is called liquidity providing. Liquidity providers earn fees from transactions on the DeFi platform they provide liquidity on. The transaction fees are distributed proportionally to all the liquidity providers in the pool, so the more crypto assets you stake the more fees you'll earn.",
      option_strategy: "Investing Strategies",
      option_title1: "Covered Call",
      option_content1:
        "This Volt generates income by running an automated covered call strategy and collecting the option premium. Deposits are deployed in the upcoming Epoch and earnings are auto-compounded weekly.",
      option_desc1: "Best in: Best in bearish to mild bull markets.",
      option_title2: "Cash Secured Put",
      option_content2:
        "This Volt generates returns on stablecoin deposits by running an automated cash secured put strategy. Deposits are deployed into the upcoming Epoch and earnings are auto-compounded weekly.",
      option_desc2: "Best In: Best in bull to moderately bearish markets.",
      option_title3: "Principle Protected",
      option_content3:
        "This Volt uses interest revenue from lending to hedge against some coin’s price falling or raising drastically. As a result, you get 100% principal protection and long volatility in a single powerful product.",
      option_desc3:
        "Best In: Best in volatile markets with rising interest rates.",
      about_title: "About Dow Digital Capital",
      about_vision: "Our vision",
      about_content1:
        "Dow Capital is founded by experienced treasury experts who have more than 15 years of experience in local and global banks who also have a deep understanding of crypto market and driving trends. We expect to see major irreversible change in fund market by the upcoming blockchain revolution and we, as a participant of the crypto market like to be involved in the change and lead the way.",
      about_content2:
        "We are bringing a new understading to investment fund market by our inovative infrastructure and revolutionizing ideas. We have prioritize transparency and accountability while achieving higher returns in a rapidly expanding market. Higher returns on your investments should be achieved by our investment and yield funds",
      about_business: "Our business",
      about_funds: "Investment Funds",
      about_funds_content:
        "Seamlessly invest in and manage holdings of our investment products using our blockchain-powered investor platform.",
      about_invest_title: "Yield Vaults",
      about_invest1:
        "Seamlessly invest in yield generating vaults. Our vaults composed of 3 major yield generating options: ",
      about_invest2: "1- Yield generating through leveraged staking.",
      about_invest3:
        "2- Yield generating through liquidty providing into liquidty pools in respectable defi projects.",
      about_invest4:
        "3- Yield generating through options writing or buying in a professionally managed option book.",
      news_back: "Back to news",
      login_error: "Wrong e-mail or password.",
      login_success: "Signed in successfully.",
      forgot_password_success: "E-mail has been sent.",
      forgot_password_error: "E-mail not found.",
      reset_password_success: "You password has been changed successfully.",
      reset_password_error: "Something happened. please try again.",
      login_button: "Log in",
      login_email: "E-mail",
      login_pass: "Password",
      login_forgotpass: "Forgot password?",
      login_forgotpass_button: "Reset my password",
      login_resetpass: "Reset password",
      login_have_acc: "Don't have an account yet?",
      login_create: "Create Account",
      signup_title: "Create Account",
      signup_name: "Name",
      signup_surname: "Surname",
      signup_email: "E-mail",
      signup_pass: "Password",
      signup_confirm: "Repeat password",
      signup_referral: "Referral code (optional)",
      signup_terms1: "I agree with the",
      signup_terms2: "terms & conditions",
      signup_privacy:
        "I agree to receiving email communication from Dow Digital Capital as per the privacy policy.",
      signup_create: "Create Account",
      signup_already: "Already have an account?",
      signup_button: "Log in",
      dashboard_approve: "Approval has been received.",
      dashboard_cancel: "Approval has been cancelled.",
      dashboard_error: "Something went wrong.",
      dashboard_home: "Home",
      dashboard_invest: "Invest (Buy) Transactions",
      dashboard_redeem: "Redeem (Sell) Transactions",
      dashboard_withdraw: "Withdraw Transactions",
      dashboard_users: "Users",
      dashboard_funds: "Funds",
      dashboard_news: "News",
      dashboard_add_news: "+ Add News",
      dashboard_partners: "Partners",
      dashboard_deposit: "Deposit Transactions",
      dashboard_management: "Management Fees",
      dashboard_performance: "Performance Fees",

      fnavbar_panel: "Dashboard",
      fnavbar_wallets: "Wallets",
      fnavbar_invest: "Invest",
      fnavbar_hub: "Yield Hub",
      fnavbar_borrow: "Borrow/Lend",

      accountstatus_welcome: "WELCOME",

      ffundlist_price: "Fund Price Movements",
      ffundlist_noinfo: "No info",

      wallets_wallet: "Wallets",
      wallets_portfolio: "PORTFOLIO BALANCE",
      wallets_go: "Go to wallets",

      transaction_latest: "Latest Transactions",
      transaction_noinfo: "No info",
      transaction_more: "View more",

      guide_guide: "Guide",

      news_news: "News",
      news_more: "Read more",

      sidebar_home: "Home",
      sidebar_invest: "Invest Transactions",
      sidebar_redeem: "Redeem Transactions",
      sidebar_deposit: "Deposit Transactions",
      sidebar_withdraw: "Withdraw Transactions",
      sidebar_users: "Users",
      sidebar_partners: "Partners",
      sidebar_fees: "Fees",
      sidebar_funds: "Funds",
      sidebar_news: "News",

      bottombar_approve: "Approval has been received.",
      bottombar_cancel: "Approval has been cancellend.",
      bottombar_error: "Something went wrong.",
      bottombar_selected: "Selected Transactions",

      wallet_dow: "DOW Wallet",
      wallet_balance: "WALLET BALANCE",
      wallet_withdraw: "Withdraw USDC",
      wallet_refresh: "Refresh balance",
      wallet_deposit: "Deposit USDC",
      wallet_deposit_wallet: "Your USDC Deposit Wallet",
      wallet_no_wallet: "You have no wallet.",
      wallet_withdraw_wallet: "Your USDC Withdraw Wallet",
      wallet_cash: "Cash",
      wallet_balances: "Balance",
      wallet_funds: "Funds",
      wallet_funds_info: "Amount/USD Value",
      wallet_latest: "Latest Transactions",
      wallet_noinfo: "No info",
      wallet_more: "View more",

      invest_funds: "Investment Funds",
      invest_detail:
        "Invest in a range of tokenized crypto funds deploying capital preservation and diversification strategies.",
      yield_navbar: "Decentralized Enviroment",
      yield_hub: "Yield Hub",
      yield_detail:
        "Invest in a range of tokenized crypto funds deploying capital preservation and diversification strategies.",
      about_dow_tokens_main: "About Dow Tokens",
      about_dow_tokens_security: "What is a security?",
      about_dow_tokens_body:
        "US regulatory of Financial Services defines security as; The term “security” means any note, stock, treasury stock, security future, security-based swap, bond, debenture, evidence of indebtedness, certificate of interest or participation in any profit-sharing agreement, collateral-trust certificate, preorganization certificate or subscription, transferable share, investment contract, voting-trust certificate, certificate of deposit for a security, fractional undivided interest in oil, gas, or other mineral rights, any put, call, straddle, option, or privilege on any security, certificate of deposit, or group or index of securities (including any interest therein or based on the value thereof), or any put, call, straddle, option, or privilege entered into on a national securities exchange relating to foreign currency, or, in general, any interest or instrument commonly known as a “security”, or any certificate of interest or participation in, temporary or interim certificate for, receipt for, guarantee of, or warrant or right to subscribe to or purchase, any of the foregoing.",
      about_dow_tokens_sectitle: "Who is SEC?",
      about_dow_tokens_seccontent:
        "The U.S. Securities and Exchange Commission is an independent agency of the United States federal government, created in the aftermath of the Wall Street Crash of 1929. The primary purpose of the SEC is to enforce the law against market manipulation.",
      about_dow_tokens_howeytitle: "What is Howey Test?",
      about_dow_tokens_howeycontent:
        'The Howey Test refers to the U.S. Supreme Court case for determining whether a transaction  qualifies as an "investment contract," and therefore would be considered a security and subject to disclosure and registration requirements  under the Securities Act of 1933 and the Securities Exchange Act of 1934.',
      about_dow_tokens_ehoweytitle: "4 Elements of Howey Test",
      about_dow_tokens_ehoweycontent:
        "Accordingly, the Howey four-prong test to be used in determining whether an “investment contract” exists is:  (1) an investment of money, (2) in a common enterprise, (3) with the expectation of profit and (4) to be derived from the efforts of others. ",
      about_dow_tokens_abouttitle: "What are Dow Tokens?",
      about_dow_tokens_aboutcontent:
        "Dow Tokens are tokens that represent your share in the fund you have invested.  They are solely used as an internal accounting unit.  They do not offer any return on capital or investment. Although we do track our customers accounting balances, these token are also used as a provision of our accounting. These tokens are issued on a second layer network on ETH network. They are solely not security* so they do not offer any returns.",

      accountsettings_u1: "Update successfull!",
      accountsettings_u2: "User info has been updated.",
      accountsettings_logout: "Logged out successfully.",
      accountsettings_success1: "Wallet added!",
      accountsettings_success2:
        "Withdraw address has been added to your account.",
      accountsettings_title: "Account Settings",
      accountsettings_info: "Account Information",
      accountsettings_name: "Name",
      accountsettings_surname: "Surname",
      accountsettings_walletinfo: "Wallet Information",
      accountsettings_withdrawwallet: "Withdraw wallet",
      accountsettings_update: "Update",
      accountsettings_partner: "Partner Information",
      accountsettings_referral: "Your referral code: ",
      accountsettings_logout_button: "Log out",
      accountsettings_save: "Save changes",
      accountsettings_2fa: "2 Factor Authentication",
      accountsettings_2fa_info:
        "Two-Factor Authentication (2FA) is a security measure that drastically improves the security of your account by requiring an authentication code as well as your password in order to sign in.",
      accountsettings_2fa_create: "Setup 2FA",
      accountsettings_2fa_disable: "2FA Enabled",

      buy_insuff_err: "Insufficient balance.",
      buy_network_err: "Enter a value greater than the network fee.",
      buy_invest_succ: "Investment transaction successfully created.",
      buy_err: "Something went wrong. Please try again later.",
      buy_back: "Back to funds",
      buy_invest_fund: "Invest in Fund",
      buy_invest: "Investing",
      buy_usdc: "Your Available USDC Balance",
      buy_fund_price: "Current Fund Price",
      buy_estimated: "Estimated {{money}} you buy",
      buy_all: "Max Amount",
      buy_usd_type: "Amount in USDC",
      buy_fee: "Ap. Network Fee",
      buy_fee2: "Invest",

      redeem_insuff_err: "Insufficient balance.",
      redeem_redeem_succ: "Redeeming transaction successfully created.",
      redeem_err: "Something went wrong. Please try again later.",
      redeem_back: "Back to funds",
      redeem_redeem_fund: "Sell Fund",
      redeem_redeem: "Redeeming",
      redeem_estimated: "Your {{money}} Balance",
      redeem_current: "Current Fund Price",
      redeem_clear: "Clear all",
      redeem_est: "Estimated",
      redeem_all: "Max Amount",
      redeem_amount: "{{textDow}} Amount in {{money}}",
      redeem_usdc: "{{textUsd}} Amount in USDC",
      redeem_fee2: "Sell",

      withdraw_insuff_err: "Insufficient balance.",
      withdraw_network_err: "Enter a value greater than the network fee.",
      withdraw_invest_succ: "Withdrawal transaction successfully created.",
      withdraw_err: "Something went wrong. Please try again later.",
      withdraw_back: "Back to wallet",
      withdraw_invest_fund: "Withdraw money",
      withdraw_invest: "Withdrawing",
      withdraw_usdc_: "Your Withdrawable USDC Balance",
      withdraw_fund_price: "Current Fund Price",
      withdraw_estimated: "Estimated {{money}} you withdraw",
      withdraw_all: "Max Amount",
      withdraw_usd_type: "Amount in USDC",
      withdraw_fee: "Ap. Network Fee",
      withdraw_fee2: "Withdraw",

      transaction_title: "Transactions",

      daily: "Daily",
      weekly: "Weekly",
      monthly: "Monthly",
      today: "Today",
      this_week: "This Week",
      this_month: "This Month",

      assets: "Assets",

      withdraw_usdc: "Withdraw USDC",
      deposit_usdc: "Deposit USDC",
      internal_tx: "Internal Transfer",

      portfolio: "Portfolio",

      no_data: "No Data",
      history: "History",

      yield_earnings: "Yield Hub Earnings",
      yield_earnings2: "Investment Funds Earnings",
      fund: "FUND",
      quantity: "QUANTITY",
      buy_price: "BUY PRICE",
      sell_price: "SELL PRICE",
      fee: "FEE",
      total: "TOTAL",
      date: "DATE",
      action: "ACTION",
      funds: "Funds",
      invested_usd: "INVESTED USD",
      current_price: "CURRENT PRICE",
      return_dollar: "RETURN %",
      mark_to_market: "MARK TO MARKET",
      balance: "Balance",

      i_today: "Investments Today",
      r_today: "Redeems Today",
      d_today: "Deposits Today",
      total_i: "Total Investments",
      total_r: "Total Redeems",
      total_d: "Total Deposits",
      total_u: "Total Users",
      fund_users: "FUND USERS",
      aum: "Assets Under Management",
      aum_users: "USERS",

      t_tx_id: "TX ID",
      t_user_id: "USER ID",
      t_name_surname: "NAME SURNAME",
      t_fund_token: "FUND TOKEN	",
      t_status: "STATUS",
      t_amount_usd: "AMOUNT USD",
      t_amount_token: "AMOUNT TOKEN",
      t_unit_price: "UNIT PRICE",
      t_date: "DATE",
      t_from: "FROM",

      t_processing: "PROCESSING",
      t_rejected: "REJECTED",
      t_success: "SUCCESS",
      t_waiting: "WAITING FOR APPROVE",
      t_error: "ERROR",

      t_gross_usd: "GROSS USD",
      t_net_usd: "NET USD",
      t_tx_fee: "TX FEE",

      t_total_balance: "TOTAL BALANCE",
      t_ceffu_balance: "CEFFU BALANCE",
      t_deposit_address: "DEPOSIT ADDRESS",
      t_withdraw_address: "WITHDRAW ADDRESS",
      t_actions: "ACTIONS",

      t_name: "NAME",
      t_surname: "SURNAME",
      t_partner_count: "PARTNER COUNT",
      t_partner_commission: "PARTNER COMMISSION",
      t_partner_total_commission: "PARTNER TOTAL COMMISSION",
      t_referral_code: "REFERRAL CODE",

      t_news_id: "NEWS ID",
      t_title: "TITLE",
      t_fund_id: "FUND ID",
      t_fund_name: "FUND NAME",
      t_performance_fee: "PERFORMANCE FEE",
      t_management_fee: "MANAGEMENT FEE",
      t_divider: "DIVIDER",
      t_fund_value: "FUND VALUE",

      t_fund: "FUND",
      t_commission_usd: "COMMISSION USD",
      t_approve: "Approve",

      yield_staking_s: "Invest in and earn fix income",
      yield_option_s: "Sell call and put options",
      status: "STATUS",

      rejected: "Rejected",
      error: "Error",
      success: "Success",
      approve: "In Approval",

      otp_otp: "Google Authenticator",
      otp_code: "Code",
      otp_submit: "Submit",
      accountsettings_ec_title: "Emergency Contact",
      accountsettings_ec_info:
        "You can create or update an emergency contact from here.",
      accountsettings_ec_update: "Update",
      accountsettings_ec_create: "Create",
    },
  },
};

const lng = localStorage.getItem("i18nextLng") || "en";

i18next.use(initReactI18next).init({
  resources,
  lng: lng,
  interpolation: {
    escapeValue: false,
  },
});
export default i18next;
