// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.f_cardContainer {
  background: var(--background-navbar);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  margin-bottom: 16px;
  padding: 24px;
}`, "",{"version":3,"sources":["webpack://./src/components/FCardContainer/FCardContainer.scss"],"names":[],"mappings":"AAAA;EACE,oCAAA;EACA,0CAAA;EACA,kBAAA;EACA,mBAAA;EACA,aAAA;AACF","sourcesContent":[".f_cardContainer {\n  background: var(--background-navbar);\n  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);\n  border-radius: 8px;\n  margin-bottom: 16px;\n  padding: 24px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
