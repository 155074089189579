import React from 'react';
import { Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import './YieldLandingPage.scss';
import landingpage1 from '../../../assets/images/landingpage1.png';

const YieldLandingPage = ({ language }) => {
  const navigate = useNavigate();
  //navigate('/login');
  return (
    <div>
      <div className="landing_wrapper">
        <div className="landing_container">
          <div className="landing_container_text">
            <div className="landing_container_text-headline">
              {language === 'tr' ? (
                <>
                  Dijital Finansın{' '}
                  <span style={{ color: 'var(--text-secondary)' }}>
                    Geleceği
                  </span>
                </>
              ) : (
                <>
                  The Future of{' '}
                  <span style={{ color: 'var(--text-secondary)' }}>
                    Digital Investing
                  </span>
                </>
              )}
            </div>
            <div className="landing_container_text-subline">
              {language === 'tr' ? (
                <>
                  <b>Dijital Yatırımlarınızda ihtiyacınız olan her şey:</b>{' '}
                  <br></br>• Tokenlaştırılmış Fonlar <br></br>• Crypto
                  Paralarınıza Faiz Getiri Platformu<br></br>• Opsiyon
                  Stratejileri ile getiri havuzları
                </>
              ) : (
                <>
                  <b>Everything you need to know about </b>
                  <span>Digital Investing</span>:<br></br>• Tokenized Funds{' '}
                  <br></br>• Yield for Cryptocurrencies <br></br>• Strategy
                  based Yield Generation
                </>
              )}
            </div>
            <Button onClick={() => navigate('yield-list')}>
              {language === 'tr' ? <>Şimdi Başlayın</> : <>Get Started</>}
            </Button>
          </div>
          <img
            className="landing_container_text-headline_image"
            src={landingpage1}
          ></img>
        </div>
      </div>
    </div>
  );
};

export default YieldLandingPage;
