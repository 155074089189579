import { useNavigate } from "react-router-dom";
import "./FLandingNavbar.scss";
import c5icon from "../../assets/images/c5icon.png";
import arbitrageicon from "../../assets/images/arbitrageicon.png";
import btcicon from "../../assets/images/btcicon.png";
import optionicon from "../../assets/images/optionicon.png";
import stakingicon from "../../assets/images/stakingicon.png";
import liqicon from "../../assets/images/liqicon.png";

import tradeicon from "../../assets/images/tradeicon.png";
import themeIcon from "../../assets/icons/theme.svg";
import language from "../../assets/icons/language.svg";

import {
  Button,
  Container,
  Dropdown,
  DropdownButton,
  Nav,
  Navbar,
  NavDropdown,
  Offcanvas,
} from "react-bootstrap";
import { useState } from "react";
import i18n from "../../utils/i18n";
import { useTranslation } from "react-i18next";
import FIcon from "../FIcon/FIcon";
import i18next from "../../utils/i18n";

function FLandingNavbar({ switchTheme, theme }) {
  let navigate = useNavigate();
  const [show, setShow] = useState(false);
  const { t } = useTranslation();

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const changeLanguage = (lng) => {
    localStorage.setItem("i18nextLng", lng);
    i18n.changeLanguage(lng);
  };

  const getLanguage = i18next.language || window.localStorage.i18nextLng;

  return (
    <>
      <Navbar expand={"lg"} sticky="top">
        <Container fluid className="navbar_container">
          <Navbar.Brand
            onClick={() => {
              navigate("/");
              handleClose();
            }}
          >
            <div className="navbar_logo">
              <h1>DOW</h1>
              <h2>Digital Capital</h2>
            </div>
          </Navbar.Brand>
          <div className="navbar-toggler">
            <div
              className="navbar-language"
              onClick={() => changeLanguage(getLanguage === "tr" ? "en" : "tr")}
            >
              {getLanguage === "tr" ? "EN" : "TR"}
            </div>
            <Navbar.Toggle
              onClick={handleShow}
              aria-controls={"offcanvasNavbar-expand-lg"}
            />
          </div>
          <Navbar.Offcanvas
            show={show}
            onHide={handleClose}
            id={"offcanvasNavbar-expand-lg"}
            aria-labelledby={"offcanvasNavbarLabel-expand-lg"}
            placement="end"
          >
            <Offcanvas.Header closeButton>
              <Offcanvas.Title id={"offcanvasNavbarLabel-expand-lg"}>
                <div className="navbar_logo">
                  <h1>DOW</h1> <h2>Digital Capital</h2>
                </div>
              </Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
              <Nav className="left_side">
                <NavDropdown
                  title={t("flanding_funds")}
                  id={"offcanvasNavbarDropdown-expand-lg"}
                >
                  <NavDropdown.Item
                    onClick={() => {
                      navigate("/funds/c5-index?id=1");
                      handleClose();
                    }}
                  >
                    {t("flanding_c5")}
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    onClick={() => {
                      navigate("/funds/crypto-trading?id=3");
                      handleClose();
                    }}
                  >
                    {t("flanding_trading")}
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    onClick={() => {
                      navigate("/funds/bitcoin-protected?id=2");
                      handleClose();
                    }}
                  >
                    {t("flanding_bitcoin")}
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    onClick={() => {
                      navigate("/funds/algorithmic?id=4");
                      handleClose();
                    }}
                  >
                    {t("flanding_arbitrage")}
                  </NavDropdown.Item>
                </NavDropdown>
                <NavDropdown
                  title={t("flanding_yield")}
                  id={"offcanvasNavbarDropdown-expand-lg"}
                >
                  <NavDropdown.Item
                    onClick={() => {
                      navigate("/yield-hub/leveraged-staking?id=5");
                      handleClose();
                    }}
                  >
                    {t("flanding_leveraged")}
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    onClick={() => {
                      navigate("/yield-hub/liquidty-providing?id=6");
                      handleClose();
                    }}
                  >
                    {t("flanding_liquidity")}
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    onClick={() => {
                      navigate("/yield-hub/option-strategies?id=7");
                      handleClose();
                    }}
                  >
                    {t("flanding_option")}
                  </NavDropdown.Item>
                </NavDropdown>
                <Nav.Link
                  id={"offcanvasNavbarDropdown-expand-lg"}
                  onClick={() => {
                    navigate("/about");
                    handleClose();
                  }}
                >
                  {t("flanding_about")}
                </Nav.Link>
                <Nav.Link
                  onClick={() => {
                    navigate("/news");
                    handleClose();
                  }}
                >
                  Blog
                </Nav.Link>
                <Nav.Link
                  onClick={() => {
                    navigate("/faq");
                    handleClose();
                  }}
                >
                  {t("faq2")}
                </Nav.Link>
              </Nav>
              <div className="right_side">
                <div
                  onClick={() =>
                    changeLanguage(getLanguage === "tr" ? "en" : "tr")
                  }
                  style={{
                    fontSize: "16px",
                    fontWeight: "300",
                    lineHeight: "19px",
                    textTransform: "uppercase",
                    cursor: "pointer",
                    borderBottom: "2px solid #4169e1",
                    marginTop: "2px",
                    marginLeft: "25px",
                    marginRight: "25px",
                  }}
                >
                  {getLanguage === "tr" ? "EN" : "TR"}
                </div>
                {/* <Button
                  className={
                    theme === "dark"
                      ? "right_side-icons dark_icon"
                      : "right_side-icons"
                  }
                  onClick={switchTheme}
                >
                  <img alt="" src={themeIcon} className="filter-green"></img>
                </Button>*/}
                <Button
                  onClick={() => {
                    navigate("/login");
                    handleClose();
                  }}
                  className="right_side-launch"
                >
                  {t("flanding_launch")}
                </Button>
              </div>
            </Offcanvas.Body>
          </Navbar.Offcanvas>
        </Container>
      </Navbar>
    </>
  );
}

export default FLandingNavbar;
