// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.f_terms {
  padding-top: 100px;
}
.f_terms h1 {
  font-weight: 700;
  padding-bottom: 50px;
}
.f_terms h6 {
  font-weight: 700;
}`, "",{"version":3,"sources":["webpack://./src/pages/MainLayout/Terms/Terms.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;AACF;AAAE;EACE,gBAAA;EACA,oBAAA;AAEJ;AAAE;EACE,gBAAA;AAEJ","sourcesContent":[".f_terms {\n  padding-top: 100px;\n  h1 {\n    font-weight: 700;\n    padding-bottom: 50px;\n  }\n  h6 {\n    font-weight: 700;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
