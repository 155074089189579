// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.f_navbar {
  background-color: transparent !important;
  box-shadow: none !important;
  height: 100px !important;
}
.f_navbar .navbar-brand {
  padding-bottom: 0px !important;
}
.f_navbar .navbar_logo {
  flex-direction: row;
  align-items: center !important;
  gap: 10px;
}
.f_navbar .nav_icon_buttons h1 {
  font-size: 11px !important;
  font-weight: 400 !important;
}`, "",{"version":3,"sources":["webpack://./src/components/FNavbar/FNavbar.scss"],"names":[],"mappings":"AAAA;EACE,wCAAA;EACA,2BAAA;EACA,wBAAA;AACF;AACE;EACE,8BAAA;AACJ;AAEE;EACE,mBAAA;EACA,8BAAA;EACA,SAAA;AAAJ;AAGE;EACE,0BAAA;EACA,2BAAA;AADJ","sourcesContent":[".f_navbar {\n  background-color: transparent !important;\n  box-shadow: none !important;\n  height: 100px !important;\n\n  .navbar-brand {\n    padding-bottom: 0px !important;\n  }\n\n  .navbar_logo {\n    flex-direction: row;\n    align-items: center !important;\n    gap: 10px;\n  }\n\n  .nav_icon_buttons h1 {\n    font-size: 11px !important;\n    font-weight: 400 !important;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
