import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import './YieldConnectWallet.scss';

import {
  DEFAULT_SUPPORTED_CHAINS,
  useEtherBalance,
  useEthers,
} from '@usedapp/core';
import { formatEther } from '@ethersproject/units';

const YieldConntectWallet = ({ text, color, onClick }) => {
  const { account, activateBrowserWallet, deactivate, chainId } = useEthers();
  const etherBalance = useEtherBalance(account);
  const [clicked, setClicked] = useState(false);

  const networkName = DEFAULT_SUPPORTED_CHAINS.find(
    (network) => network.chainId === chainId
  )?.chainName;

  useEffect(() => {
    if (account && color && clicked) {
      onClick();
    }
  }, [account, clicked]);

  const ConnectButton = () => (
    <Button
      className={color ? 'ycw-button-secondary' : 'ycw-button-primary'}
      onClick={() => {
        setClicked(true);
        activateBrowserWallet();
      }}
    >
      {text}
    </Button>
  );

  return (
    <div className="ycw">
      {account && !color && (
        <div className="ycw-connected" onClick={deactivate}>
          <h2>{networkName}</h2>
          <h2>
            {account?.slice(0, 5)}...{account?.slice(1).slice(-4)}
          </h2>
          <div className="ycw-balance">
            <img
              width="24"
              height="24"
              src="https://icons.iconarchive.com/icons/cjdowner/cryptocurrency-flat/1024/Ethereum-ETH-icon.png"
            />
            {etherBalance &&
              parseFloat(formatEther(etherBalance._hex)).toFixed(4)}
          </div>
        </div>
      )}
      {color ? <ConnectButton /> : !account && <ConnectButton />}
    </div>
  );
};

export default YieldConntectWallet;

YieldConntectWallet.defaultProps = {
  text: 'Connect',
  color: null,
};
