// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@charset "UTF-8";
.d_fundhistory {
  background-color: #d8d8d8;
  border-radius: 10px 10px 0px 0px;
  margin-top: 14px;
}
.d_fundhistory-tab {
  background: white;
  padding: 8px 12px;
}
.d_fundhistory-tab div  {
  align-items: center;
}
.d_fundhistory-tab h4 {
  font-size: 16px;
  font-weight: 700;
}
.d_fundhistory-tab h5 {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
.d_fundhistory .active {
  background-color: transparent !important;
  border: none !important;
}
.d_fundhistory .active img {
  border-top: 3px solid #4169e1;
  padding-top: 5px;
  margin-top: -13px;
}
.d_fundhistory .active img {
  filter: none !important;
}
.d_fundhistory .nav-link:hover {
  color: black !important;
}
.d_fundhistory button,
.d_fundhistory button:hover,
.d_fundhistory button:active,
.d_fundhistory button:focus,
.d_fundhistory li:hover,
.d_fundhistory li:active,
.d_fundhistory li:focus {
  border: none !important;
}
.d_fundhistory button {
  padding-top: 0px !important;
  padding-left: 0px !important;
  padding-right: 0px !important;
}`, "",{"version":3,"sources":["webpack://./src/components/DashboardComponents/DFundHistory/DFundHistory.scss"],"names":[],"mappings":"AAAA,gBAAgB;AAAhB;EACE,yBAAA;EACA,gCAAA;EACA,gBAAA;AAEF;AADE;EACE,iBAAA;EACA,iBAAA;AAGJ;AAFI;EACE,mBAAA;AAIN;AAFI;EACE,eAAA;EACA,gBAAA;AAIN;AAFI;EACE,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,YAAA;AAIN;AADE;EACE,wCAAA;EACA,uBAAA;AAGJ;AAFI;EACE,6BAAA;EACA,gBAAA;EACA,iBAAA;AAIN;AADE;EACE,uBAAA;AAGJ;AADE;EACE,uBAAA;AAGJ;AAAE;;;;;;;EAOE,uBAAA;AAEJ;AAAE;EACE,2BAAA;EACA,4BAAA;EACA,6BAAA;AAEJ","sourcesContent":[".d_fundhistory {\n  background-color: #d8d8d8;\n  border-radius: 10px 10px 0px 0px;\n  margin-top: 14px;\n  &-tab {\n    background: white;\n    padding: 8px 12px;\n    div  {\n      align-items: center;\n    }\n    h4 {\n      font-size: 16px;\n      font-weight: 700;\n    }\n    h5 {\n      display: flex;\n      justify-content: center;\n      align-items: center;\n      height: 100%;\n    }\n  }\n  .active {\n    background-color: transparent !important;\n    border: none !important;\n    img {\n      border-top: 3px solid #4169e1;\n      padding-top: 5px;\n      margin-top: -13px;\n    }\n  }\n  .active img {\n    filter: none !important;\n  }\n  .nav-link:hover {\n    color: black !important;\n  }\n\n  button,\n  button:hover,\n  button:active,\n  button:focus,\n  li:hover,\n  li:active,\n  li:focus {\n    border: none !important;\n  }\n  button {\n    padding-top: 0px !important;\n    padding-left: 0px !important;\n    padding-right: 0px !important;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
