import React, { useEffect } from 'react';
import './YieldLayout.scss';
import { Outlet } from 'react-router-dom';
import YieldNavbar from '../../components/YieldComponents/YieldNavbar/YieldNavbar';

const YieldLayout = ({ switchTheme, theme }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div
      style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}
      className="publicdiv"
    >
      <YieldNavbar switchTheme={switchTheme} theme={theme} />
      <Outlet />
    </div>
  );
};

export default YieldLayout;
