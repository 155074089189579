import React, { useEffect, useState } from "react";
import userimage from "../../assets/images/user.png";
import { useSelector } from "react-redux";
const user = JSON.parse(localStorage.getItem("user"));

const UserLabel = ({ text, isDashboard }) => {
  const userr = JSON.parse(localStorage.getItem("user"));

  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  const { user } = useSelector((state) => state.authentication);
  return (
    <div className="f_wallet mt-3 mb-5">
      {isDashboard ? (
        <>
          {user?.photo_nft ? (
            <img
              src={user?.photo_nft}
              width={66}
              height={66}
              style={{ borderRadius: "100%", marginRight: "12px" }}
            />
          ) : (
            <div
              style={{
                borderRadius: "100%",
                marginRight: "12px",
                width: "66px",
                height: "66px",
                background: "#77838F",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <span style={{ fontSize: "20px", color: "#ffffff" }}>
                {userr?.name?.substring(0, 1) + userr?.surname?.substring(0, 1)}
              </span>
            </div>
          )}
          <div>
            <span style={{ color: "#5E718D" }}>{text}</span>
            <h3>
              {capitalizeFirstLetter(user.name) +
                " " +
                capitalizeFirstLetter(user.surname)}
            </h3>
          </div>
        </>
      ) : (
        <>
          {user?.photo_nft ? (
            <img
              src={user?.photo_nft}
              width={66}
              height={66}
              style={{ borderRadius: "100%", marginRight: "12px" }}
            />
          ) : (
            <div
              style={{
                borderRadius: "100%",
                marginRight: "12px",
                width: "66px",
                height: "66px",
                background: "#77838F",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <span style={{ fontSize: "20px", color: "#ffffff" }}>
                {userr?.name?.substring(0, 1) + userr?.surname?.substring(0, 1)}
              </span>
            </div>
          )}
          <div>
            <h3>
              {userr?.name} {userr?.surname}
            </h3>
            <span style={{ color: "#5E718D" }}>{text}</span>
          </div>
        </>
      )}
    </div>
  );
};

export default UserLabel;
