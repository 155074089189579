import React, { useState } from "react";
import "./BinanceTrader.scss";
import FIcon from "../../FIcon/FIcon";
import { Form } from "react-bootstrap";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import FTextInput from "../../FTextInput/FTextInput";
import { useDispatch, useSelector } from "react-redux";
import {
  bnbTraderBuyRequest,
  bnbTraderSellRequest,
} from "../../../services/admin/adminService";
import {
  Autocomplete,
  Chip,
  FormLabel,
  Grid,
  LinearProgress,
  MenuItem,
  Button,
  TextField,
  Box,
} from "@mui/material";

const BinanceTrader = () => {
  const dispatch = useDispatch();

  const style = { width: 750, margin: 50 };
  const marks = {
    0: 0,
    25: 25,
    50: 50,
    75: 75,
    100: 100,
  };

  function log(value) {
    console.log(value); //eslint-disable-line
  }

  const initialFormData = {
    symbol: "BTC",
    quantity: "",
  };

  const [active, setActive] = useState(0);
  const [formData, setFormData] = useState(initialFormData);

  const handleBuyOnClick = () => {
    console.log("test");
    dispatch(bnbTraderBuyRequest(formData));
  };

  const handleSellOnClick = () => {
    console.log("test");
    dispatch(bnbTraderSellRequest(formData));
  };

  const handleOnChange = (name, value) => {
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  return (
    <div className="f_admin-balances">
      <div className="f_admin-balances-container">
        <div className="f_admin-balances-container-head">
          <h1>Binance Trader</h1>
        </div>

        <Box sx={{ backgroundColor: "white", p: 4, mt: 2 }}>
          <Grid container spacing={3}>
            <Grid item sm={12} xs={12}>
              <TextField
                select
                fullWidth
                color="info"
                size="medium"
                name="symbol"
                placeholder="Select Symbol"
                defaultValue={"BTC"}
                onChange={(_, value) => {
                  handleOnChange("symbol", value?.props?.value);
                }}
                label="Select Symbol"
                SelectProps={{
                  multiple: false,
                }}
              >
                <MenuItem value={"BTC"}>BTC</MenuItem>
                <MenuItem value={"ETH"}>ETH</MenuItem>
                <MenuItem value={"BNB"}>BNB</MenuItem>
                <MenuItem value={"ADA"}>ADA</MenuItem>
                <MenuItem value={"DOT"}>DOT</MenuItem>
              </TextField>
            </Grid>

            <Grid item sm={12} xs={12}>
              <TextField
                fullWidth
                name="quantity"
                label="Quantity"
                color="info"
                size="medium"
                placeholder="quantity"
                type="number"
                onChange={(e) => {
                  handleOnChange(e.target.name, e.target.value);
                }}
              />
            </Grid>
            <Grid item sm={6} xs={12}>
              <Button
                fullWidth
                //disabled={buttonDisabled}
                variant="primary"
                onClick={handleBuyOnClick}
                sx={{ color: "white", backgroundColor: "green" }}
              >
                Buy
              </Button>
            </Grid>
            <Grid item sm={6} xs={12}>
              <Button
                fullWidth
                //disabled={buttonDisabled}
                variant="primary"
                onClick={handleSellOnClick}
                sx={{ color: "white", backgroundColor: "red" }}
              >
                Sell
              </Button>
            </Grid>
          </Grid>
        </Box>
      </div>
    </div>
  );
};

export default BinanceTrader;
