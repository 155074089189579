import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import {
  Accordion,
  Alert,
  Button,
  Container,
  Form,
  Spinner,
  Tab,
  Tabs,
} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
import usdticon from "../../../assets/images/usdticon.png";
import c5icon from "../../../assets/images/c5icon.png";
import btcicon from "../../../assets/images/btcicon.png";
import tradeicon from "../../../assets/images/tradeicon.png";
import arbitrageicon from "../../../assets/images/arbitrageicon.png";
import FAccountStatus from "../../../components/FAccountStatus/FAccountStatus";
import FCardContainer from "../../../components/FCardContainer/FCardContainer";
import FFundList from "../../../components/FFundList/FFundList";
import FGuide from "../../../components/FGuide/FGuide";
import FUserDetailsModal from "../../../components/FUserDetailsModal/FUserDetailsModal";
import FPartnerDetailsModal from "../../../components/FPartnerDetailsModal/FPartnerDetailsModal";
import FNews from "../../../components/FNews/FNews";
import FSidebar from "../../../components/FSidebar/FSidebar";
import FTransactions from "../../../components/FTransactions/FTransactions";
import FWallets from "../../../components/FWallets/FWallets";
import {
  fundsColumns,
  newsColumns,
  sellTransactionColumns,
  transactionColumns,
  userColumns,
  partnersColumns,
  withdrawTransactionColumns,
  depositTransactionColumns,
  feesColumns,
  totalManagementsColumns,
  emergencyColumns,
} from "../../../constants/columnConfigurations";
import {
  approveTransactionRequest,
  getTransactionsRequest,
  getUsersRequest,
  getTotalSupplyChartDataRequest,
  getPartnersRequest,
  getTotalUserChartDataRequest,
  getDepositsRequest,
  getFeesRequest,
  approveFeeRequest,
  cancelTransactionRequest,
  getTotalResultsFeeRequest,
  approvePartnerFeeRequest,
  getEmergencyContactsRequest,
} from "../../../services/admin/adminService";
import "./Dashboard.scss";
import { postDepositWalletRequest } from "../../../services/wallet/walletService";
import refreshIcon from "../../../assets/icons/refresh.svg";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import FBottombar from "../../../components/FBottombar/FBottombar";
import { getNewsRequest } from "../../../services/news/newsService";
import FNewsDetailsModal from "../../../components/FNewsDetailsModal/FNewsDetailsModal";
import { getFundsService } from "../../../services/fund/fundService";
import FFundDetailsModal from "../../../components/FFundDetailsModal/FFundDetailsModal";
import { Pie, Line, Doughnut } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  ArcElement,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { faker } from "@faker-js/faker";
import FIcon from "../../../components/FIcon/FIcon";
import {
  getTotalDepositCountRequest,
  getTotalFundCountRequest,
  getTotalProfitCountRequest,
  getTotalTransactionCountRequest,
  getTotalUserCountRequest,
} from "../../../services/metric/metricService";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import UserLabel from "../../../components/UserLabel/UserLabel";
import DFundPriceMovement from "../../../components/DashboardComponents/DFundPriceMovement/DFundPriceMovement";
import DPortfolio from "../../../components/DashboardComponents/DPortfolio/DPortfolio";
import DFundHistory from "../../../components/DashboardComponents/DFundHistory/DFundHistory";
import DYieldHubEarnings from "../../../components/DashboardComponents/DYieldHubEarnings/DYieldHubEarnings";
import AdminCards from "../../../components/AdminComponents/AdminCards/AdminCards";
import AUM from "../../../components/AdminComponents/AUM/AUM";
import FundAccordion from "../../../components/AdminComponents/FundAccordion/FundAccordion";
import FTable from "../../../components/FTable/FTable";
import FNewsDeleteModal from "../../../components/FNewsDeleteModal/FNewsDeleteModal";
import { timeAgo } from "../../../utils/util";
import { EditorState, convertFromRaw } from "draft-js";
import BinanceTrader from "../../../components/DashboardComponents/BinanceTrader/BinanceTrader";
import DInternalModal from "../../../components/AdminComponents/DInternalModal/DInternalModal";
import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import SearchIcon from "@mui/icons-material/Search";
import DirectionsIcon from "@mui/icons-material/Directions";
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  ArcElement,
  Title,
  Tooltip
);

export const options = {
  responsive: true,
};

export const options2 = {
  responsive: true,
  maintainAspectRatio: false,
};

const labels = ["TUSD", "C5", "BTCp", "TRQ"];
export const data2 = {
  labels,
  datasets: [
    {
      label: "Dataset 1",
      data: labels.map(() => faker.datatype.number({ min: -1000, max: 1000 })),
      borderColor: "rgb(255, 99, 132)",
      backgroundColor: "rgba(255, 99, 132, 0.5)",
    },
  ],
};

const Dashboard = ({ language, theme }) => {
  const initialFormDataNews = {
    title: "",
    content: "",
    image: null,
    type: "news",
  };
  const { t } = useTranslation();

  const getLanguage = i18next.language || window.localStorage.i18nextLng;

  const userRole = localStorage.getItem("userRole");
  let localActiveTab = parseInt(localStorage.getItem("activeTab"));
  const [show, setShow] = useState(false);
  const [selectedCount, setSelectedCount] = useState(0);
  const [selectedRow, setSelectedRow] = useState([]);
  const [bottomShow, setBottomShow] = useState(false);
  const [userModalShow, setUserModalShow] = useState(false);
  const [partnerModalShow, setPartnerModalShow] = useState(false);
  const [partnerAccounts, setPartnerAccounts] = useState();
  const [newsModalShow, setNewsModalShow] = useState(false);
  const [formDataNews, setFormDataNews] = useState({ ...initialFormDataNews });
  const [fundsModalShow, setFundsModalShow] = useState(false);
  const [selectValue, setSelectValue] = useState("ALL");
  const [selectValuePartners, setSelectValuePartners] = useState("MNG");
  const [selectFundValue, setSelectFundValue] = useState("0");
  const [selectTypeValue, setSelectTypeValue] = useState("MNG");
  const [newsDeleteModalShow, setNewsDeleteModalShow] = useState(false);
  const [internalModalShow, setInternalModalShow] = useState(false);
  const [chartData, setChartData] = useState([1, 1]);
  const [chartDataToken, setChartDataToken] = useState("ALL");
  const [userId, setUserId] = useState();
  const [newsId, setNewsId] = useState();
  const [fundId, setFundId] = useState();
  const target = useRef(null);
  const [activeTab, setActiveTab] = useState(localActiveTab);
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(1);
  const [key, setKey] = useState("management");
  const [userSearchValue, setUserSearchValue] = useState("");

  let pageSize = 20;

  const {
    userList,
    partnerList,
    transactionList,
    depositsList,
    count,
    loading,
    loadingAll,
    userCount,
    approveResult,
    cancelResult,
    approveFeeResult,
    totalUserChartData,
    feeList,
    totalResultFee,
    emergencyContactsList,
  } = useSelector((state) => state.admin);
  const { depositWalletResult } = useSelector((state) => state.wallet);
  const { newsList, countNews } = useSelector((state) => state.news);
  const {
    totalUserCount,
    totalTransactionCount,
    totalDepositCount,
    totalFundCount,
    totalProfitCount,
  } = useSelector((state) => state.metric);

  const { fundList, loadingFunds, countFunds } = useSelector(
    (state) => state.fund
  );

  const initialNewsFilters = {
    filter: {},
  };

  const data = React.useMemo(
    () => [
      {
        labels: Object.keys(totalFundCount),
        datasets: [
          {
            data: Object.values(totalFundCount),
            backgroundColor: [
              "rgba(255, 99, 132, 0.2)",
              "rgba(54, 162, 235, 0.2)",
              "rgba(255, 206, 86, 0.2)",
              "rgba(75, 192, 192, 0.2)",
            ],
            borderColor: [
              "rgba(255, 99, 132, 1)",
              "rgba(54, 162, 235, 1)",
              "rgba(255, 206, 86, 1)",
              "rgba(75, 192, 192, 1)",
            ],
            borderWidth: 1,
          },
        ],
      },
    ],
    [totalFundCount]
  );

  const singleSelectRow = {
    mode: "radio",
    clickToSelect: true,
    style: { backgroundColor: theme === "light" ? "#BBD6FB" : "#353535" },
    onSelect: (row, isSelect, rowIndex, e) => {
      if (isSelect) {
        setSelectedCount((prevValue) => prevValue + 1);
        setBottomShow(true);
        setSelectedRow((oldArray) => [
          ...oldArray,
          {
            id: row.id,
            amount: row.amount_usd,
            tx_id: row.transaction_id,
            status: row.status,
            action: row.action,
            token: row.token,
            error: row.error,
          },
        ]);
      } else {
        setSelectedCount((prevValue) => prevValue - 1);
        setSelectedRow(selectedRow.filter((item) => item.id !== row.id));
        if (selectedCount <= 1) {
          setBottomShow(false);
        }
      }
    },
    onSelectAll: (isSelect, rows, e) => {},
  };

  const selectRow = {
    mode: "checkbox",
    clickToSelect: true,
    style: { backgroundColor: theme === "light" ? "#BBD6FB" : "#353535" },
    onSelect: (row, isSelect, rowIndex, e) => {
      if (isSelect) {
        setSelectedCount((prevValue) => prevValue + 1);
        setBottomShow(true);
        setSelectedRow((oldArray) => [
          ...oldArray,
          {
            id: row.id,
            amount: row.amount_usd,
            tx_id: row.transaction_id,
            status: row.status,
            action: row.action,
            token: row.token,
            error: row.error,
          },
        ]);
      } else {
        setSelectedCount((prevValue) => prevValue - 1);
        setSelectedRow(selectedRow.filter((item) => item.id !== row.id));
        if (selectedCount <= 1) {
          setBottomShow(false);
        }
      }
    },
    onSelectAll: (isSelect, rows, e) => {},
  };

  const selectRow2 = {
    mode: "checkbox",
    clickToExpand: true,
  };

  const initialBuyTransactionsFilters = {
    type: "BUY",
    status: "ALL",
    limit: "20",
    pageNumber: "1",
    sort: "DESC",
  };

  const initialSellTransactionsFilters = {
    type: "SELL",
    status: "ALL",
    limit: "20",
    pageNumber: "1",
    sort: "DESC",
  };

  const initialFeesFiltersManagement = {
    limit: "20",
    page: "1",
    sort: "DESC",
    type: "MNG",
    fund_id: selectFundValue,
  };

  const initialFeesFiltersPerformance = {
    limit: "20",
    page: "1",
    sort: "DESC",
    type: "PRF",
    fund_id: selectFundValue,
  };

  const initialWithdrawTransactionsFilters = {
    type: "WITHDRAW",
    status: "ALL",
    limit: "20",
    pageNumber: "1",
    sort: "DESC",
  };

  const initialDepositTransactionsFilters = {
    type: "DEPOSIT",
    status: "A",
    limit: "20",
    page: 1,
    sort: "DESC",
  };

  useEffect(() => {
    setBottomShow(false);
    setSelectedRow([]);
    setSelectedCount(0);
    setSelectValue("ALL");
    window.scrollTo(0, 0);
    setCurrentPage(1);
  }, [activeTab]);

  useEffect(() => {
    if (activeTab === 0 || localActiveTab === 0) {
      dispatch(getTotalUserCountRequest());
      dispatch(getTotalTransactionCountRequest());
      dispatch(getTotalDepositCountRequest());
      dispatch(getTotalFundCountRequest());
    }
  }, [activeTab, depositWalletResult, localActiveTab]);

  useEffect(() => {
    if (activeTab === 1 || localActiveTab === 1) {
      dispatch(getTransactionsRequest(initialBuyTransactionsFilters));
    }
  }, [activeTab, depositWalletResult, localActiveTab]);

  useEffect(() => {
    if (parseInt(activeTab) === 2 || parseInt(localActiveTab) === 2) {
      dispatch(getTransactionsRequest(initialSellTransactionsFilters));
    }
  }, [activeTab, depositWalletResult, localActiveTab]);

  useEffect(() => {
    if (activeTab === 3 || localActiveTab === 3) {
      dispatch(getTransactionsRequest(initialWithdrawTransactionsFilters));
    }
  }, [activeTab, depositWalletResult, localActiveTab]);

  useEffect(() => {
    if (activeTab === 4 || localActiveTab === 4) {
      dispatch(
        getUsersRequest({
          filter: {},
          sort: "DESC",
          limit: 20,
          page: currentPage,
          search: userSearchValue,
        })
      );
    }
  }, [
    activeTab,
    depositWalletResult,
    currentPage,
    localActiveTab,
    userSearchValue,
  ]);

  useEffect(() => {
    if (activeTab === 11 || localActiveTab === 11) {
      dispatch(getEmergencyContactsRequest({}));
    }
    console.log(emergencyContactsList);
  }, [
    activeTab,
    depositWalletResult,
    currentPage,
    localActiveTab,
    userSearchValue,
  ]);

  useEffect(() => {
    if (activeTab === 5 || localActiveTab === 5) {
      dispatch(
        getFundsService({
          filter: {},
          sort: "DESC",
          limit: 20,
          page: currentPage,
        })
      );
    }
  }, [activeTab, depositWalletResult, localActiveTab]);

  useEffect(() => {
    if (activeTab === 6 || localActiveTab === 6) {
      dispatch(getNewsRequest(initialNewsFilters));
    }
  }, [activeTab, depositWalletResult, localActiveTab]);

  useEffect(() => {
    if (activeTab === 7 || localActiveTab === 7) {
      dispatch(
        getPartnersRequest({
          filter: {},
          sort: "ASC",
          limit: 100,
          page: 1,
          type: "MNG",
        })
      );
    }
  }, [activeTab, depositWalletResult, currentPage, localActiveTab]);

  useEffect(() => {
    if (activeTab === 8 || localActiveTab === 8) {
      dispatch(getDepositsRequest(initialDepositTransactionsFilters));
    }
  }, [activeTab, depositWalletResult, localActiveTab]);

  useEffect(() => {
    if (activeTab === 9 || localActiveTab === 9) {
      if (key === "management") {
        dispatch(getFeesRequest(initialFeesFiltersManagement));
      } else {
        dispatch(getFeesRequest(initialFeesFiltersPerformance));
      }
      dispatch(getTotalResultsFeeRequest());
    }
  }, [activeTab, depositWalletResult, localActiveTab, key, selectFundValue]);

  const approve = (id) => {
    dispatch(approveTransactionRequest(id));
    if (approveResult < 400) {
      const Toast = Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 2000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", Swal.stopTimer);
          toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
      });

      Toast.fire({
        icon: "success",
        title: t("dashboard_approve"),
      }).then(() => window.location.reload());
    } else {
      const Toast = Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 2000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", Swal.stopTimer);
          toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
      });

      Toast.fire({
        icon: "error",
        title: t("dashboard_error"),
      });
    }
  };

  const cancel = (id) => {
    dispatch(cancelTransactionRequest(id));
    if (cancelResult < 400) {
      const Toast = Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 2000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", Swal.stopTimer);
          toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
      });

      Toast.fire({
        icon: "info",
        title: t("dashboard_cancel"),
      }).then(() => window.location.reload());
    } else {
      const Toast = Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 2000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", Swal.stopTimer);
          toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
      });

      Toast.fire({
        icon: "error",
        title: t("dashboard_error"),
      });
    }
  };

  const approveFee = (fund_id, account_id, type) => {
    dispatch(approveFeeRequest({ fund_id, account_id, type }));
    if (approveFeeResult < 400) {
      const Toast = Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 2000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", Swal.stopTimer);
          toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
      });

      Toast.fire({
        icon: "success",
        title: t("dashboard_approve"),
      }).then(() => window.location.reload());
    } else {
      const Toast = Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 2000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", Swal.stopTimer);
          toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
      });

      Toast.fire({
        icon: "error",
        title: t("dashboard_error"),
      });
    }
  };

  const refreshTable = () => {
    setBottomShow(false);
    setSelectedRow([]);
    setSelectedCount(0);
    if (activeTab === 1) {
      dispatch(getTransactionsRequest(initialBuyTransactionsFilters));
    } else if (activeTab === 2) {
      dispatch(getTransactionsRequest(initialSellTransactionsFilters));
    } else if (activeTab === 3) {
      dispatch(getTransactionsRequest(initialWithdrawTransactionsFilters));
    } else if (activeTab === 4) {
      dispatch(
        getUsersRequest({
          filter: {},
          sort: "DESC",
          limit: 20,
          page: currentPage,
          search: userSearchValue,
        })
      );
    } else if (activeTab === 5) {
      dispatch(
        getFundsService({
          filter: {},
          sort: "DESC",
          limit: 20,
          page: currentPage,
        })
      );
    } else if (activeTab === 6) {
      dispatch(getNewsRequest(initialNewsFilters));
    } else if (activeTab === 7) {
      dispatch(
        getPartnersRequest({
          filter: {},
          sort: "ASC",
          limit: 100,
          page: 1,
          type: "MNG",
        })
      );
    } else if (activeTab === 8) {
      dispatch(getDepositsRequest(initialDepositTransactionsFilters));
    } else if (activeTab === 9) {
      if (key === "management") {
        dispatch(getFeesRequest(initialFeesFiltersManagement));
      } else {
        dispatch(getFeesRequest(initialFeesFiltersPerformance));
      }
    }
  };

  const copyInfo = (text) => {
    const Toast = Swal.mixin({
      toast: true,
      position: "top-end",
      showConfirmButton: false,
      timer: 2000,
      timerProgressBar: true,
      didOpen: (toast) => {
        toast.addEventListener("mouseenter", Swal.stopTimer);
        toast.addEventListener("mouseleave", Swal.resumeTimer);
      },
    });
    Toast.fire({
      icon: "success",
      title: text + " copied to clipboard.",
    });
  };

  const rowStyleFormat = (row, rowIdx) => {
    return {
      backgroundColor:
        row?.status === "E"
          ? "#F815061A"
          : row?.status === "S"
          ? "#00B42D1C"
          : row?.status === "A"
          ? "#FFFFE0"
          : row?.status === "R"
          ? "#F815061A"
          : "white",
    };
  };

  const rowStyleFormat2 = (row, rowIdx) => {
    return {
      backgroundColor: row?.status === "A" ? "#00B42D1C" : "white",
    };
  };

  const onTableChange = (type, newState) => {
    setCurrentPage(newState.page);

    if (activeTab === 1) {
      dispatch(
        getTransactionsRequest({
          type: "BUY",
          status: selectValue,
          limit: "20",
          pageNumber: newState.page,
          sort: "DESC",
        })
      );
    } else if (activeTab === 2) {
      dispatch(
        getTransactionsRequest({
          type: "SELL",
          status: selectValue,
          limit: "20",
          pageNumber: newState.page,
          sort: "DESC",
        })
      );
    } else if (activeTab === 3) {
      dispatch(
        getTransactionsRequest({
          type: "WITHDRAW",
          status: "ALL",
          limit: "20",
          pageNumber: newState.page,
          sort: "DESC",
        })
      );
    } else if (activeTab === 8) {
      dispatch(
        getDepositsRequest({
          type: "DEPOSIT",
          status: "ALL",
          limit: "20",
          page: newState.page,
          sort: "DESC",
        })
      );
    } else if (activeTab === 4) {
      dispatch(
        getUsersRequest({
          filter: {},
          sort: "DESC",
          limit: 20,
          page: currentPage,
          search: userSearchValue,
        })
      );
    } else if (activeTab === 5) {
      dispatch(
        getFundsService({
          filter: {},
          sort: "DESC",
          limit: 20,
          page: currentPage,
        })
      );
    } else if (activeTab === 6) {
      dispatch(getNewsRequest(initialNewsFilters));
    } else if (activeTab === 7) {
      dispatch(
        getPartnersRequest({
          filter: {},
          sort: "ASC",
          limit: 100,
          page: currentPage,
          type: "MNG",
        })
      );
    } else if (activeTab === 9) {
      dispatch(
        getFeesRequest({
          limit: 20,
          page: newState.page,
          sort: "DESC",
          fund_id: selectFundValue,
          type: selectTypeValue,
        })
      );
      dispatch(getTotalResultsFeeRequest());
    }
  };

  const payFee = (userId) => {
    Swal.fire({
      title: "Are you sure",
      text: "Pay fee",

      showClass: {
        backdrop: "swal2-noanimation", // disable backdrop animation
        popup: "", // disable popup animation
        icon: "", // disable icon animation
      },
      hideClass: {
        popup: "", // disable popup fade-out animation
      },
      showCancelButton: true,
      confirmButtonText: "Submit",
      confirmButtonColor: "royalblue",
      showLoaderOnConfirm: true,
      preConfirm: (login) => {
        dispatch(approvePartnerFeeRequest({ partnerId: userId }));
        if (approveFeeResult < 400) {
          const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 2000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", Swal.stopTimer);
              toast.addEventListener("mouseleave", Swal.resumeTimer);
            },
          });

          Toast.fire({
            icon: "success",
            title: t("dashboard_approve"),
          }).then(() => window.location.reload());
        } else {
          const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 2000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", Swal.stopTimer);
              toast.addEventListener("mouseleave", Swal.resumeTimer);
            },
          });

          Toast.fire({
            icon: "error",
            title: t("dashboard_error"),
          });
        }
      },
      allowOutsideClick: () => !Swal.isLoading(),
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire("Success!", "Success", "success");
      } else {
        Swal.fire("Error", "Something went wrong.", "error");
      }
    });
  };

  const addWallet = (userId) => {
    let formData = { user: userId, address: "" };
    Swal.fire({
      title: "Submit Ceffu Wallet",
      text: "Paste the wallet address you created for this user in Ceffu.",
      input: "text",
      inputPlaceholder: "0x0000000000000000000000000000000000000000",
      inputAttributes: {
        autocapitalize: "off",
      },
      showClass: {
        backdrop: "swal2-noanimation", // disable backdrop animation
        popup: "", // disable popup animation
        icon: "", // disable icon animation
      },
      hideClass: {
        popup: "", // disable popup fade-out animation
      },
      showCancelButton: true,
      confirmButtonText: "Submit",
      confirmButtonColor: "royalblue",
      showLoaderOnConfirm: true,
      preConfirm: (login) => {
        formData.address = login;
        dispatch(postDepositWalletRequest(formData));
      },
      allowOutsideClick: () => !Swal.isLoading(),
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire(
          "Wallet added!",
          "Deposit address has been added to user.",
          "success"
        );
      } else {
        Swal.fire("Error", "Something went wrong.", "error");
      }
    });
  };

  const usersColumnsConfig = userColumns({
    addWallet: addWallet,
    show: show,
    setShow: setShow,
    target: target,
    setUserModalShow: setUserModalShow,
    setUserId: setUserId,
    copyInfo: copyInfo,
    t,
  });

  const partnersColumnsConfig = partnersColumns({
    addWallet: addWallet,
    show: show,
    setShow: setShow,
    target: target,
    setUserModalShow: setUserModalShow,
    setPartnerModalShow: setPartnerModalShow,
    setUserId: setUserId,
    copyInfo: copyInfo,
    setPartnerAccounts: setPartnerAccounts,
    payFee: payFee,
    t,
  });

  const fundsColumnsConfig = fundsColumns({
    addWallet: addWallet,
    show: show,
    setShow: setShow,
    target: target,
    setFundsModalShow: setFundsModalShow,
    setFundId: setFundId,
    copyInfo: copyInfo,
    t,
  });

  const newsColumnsConfig = newsColumns({
    addWallet: addWallet,
    show: show,
    setShow: setShow,
    target: target,
    setNewsModalShow: setNewsModalShow,
    setNewsId: setNewsId,
    copyInfo: copyInfo,
    t,
  });

  const transactionColumnsConfig = transactionColumns({
    addWallet: addWallet,
    show: show,
    setShow: setShow,
    target: target,
    copyInfo: copyInfo,
    language: getLanguage,
    approve: approve,
    cancel: cancel,
    t,
  });

  const mngFeesColumnsConfig = feesColumns({
    addWallet: addWallet,
    show: show,
    setShow: setShow,
    target: target,
    copyInfo: copyInfo,
    language: getLanguage,
    approveFee: approveFee,
    type: "mng",
    t,
  });

  const prfFeesColumnsConfig = feesColumns({
    addWallet: addWallet,
    show: show,
    setShow: setShow,
    target: target,
    copyInfo: copyInfo,
    language: getLanguage,
    approveFee: approveFee,
    type: "prf",
    t,
  });

  const sellTransactionColumnsConfig = sellTransactionColumns({
    addWallet: addWallet,
    show: show,
    setShow: setShow,
    target: target,
    copyInfo: copyInfo,
    language: getLanguage,
    approve: approve,
    cancel: cancel,
    t,
  });

  const withdrawTransactionColumnsConfig = withdrawTransactionColumns({
    addWallet: addWallet,
    show: show,
    setShow: setShow,
    target: target,
    copyInfo: copyInfo,
    language: getLanguage,
    approve: approve,
    t,
  });

  const depositTransactionColumnsConfig = depositTransactionColumns({
    addWallet: addWallet,
    show: show,
    setShow: setShow,
    target: target,
    copyInfo: copyInfo,
    language: getLanguage,
    approve: approve,
    t,
  });

  const handleFilterSellOnChange = (status, value) => {
    setSelectValue(value);
    dispatch(
      getTransactionsRequest({
        ...initialSellTransactionsFilters,
        status: value,
      })
    );
  };

  const handleFilterBuyOnChange = (status, value) => {
    setSelectValue(value);
    dispatch(
      getTransactionsRequest({
        ...initialBuyTransactionsFilters,
        status: value,
      })
    );
  };

  const handlePartnerFeeOnChange = (status, value) => {
    setSelectValuePartners(value);
    dispatch(
      getPartnersRequest({
        filter: {},
        sort: "ASC",
        limit: 1000,
        page: 1,
        type: value,
      })
    );
  };

  const handleFilterManagementFeesOnChange = (status, value) => {
    setSelectFundValue(value);
    setSelectTypeValue("MNG");
    dispatch(
      getFeesRequest({
        ...initialFeesFiltersManagement,
        fund_id: value,
      })
    );
  };

  const handleFilterPerformanceFeesOnChange = (status, value) => {
    setSelectFundValue(value);
    setSelectTypeValue("PRF");
    dispatch(
      getFeesRequest({
        ...initialFeesFiltersPerformance,
        fund: value,
      })
    );
  };

  const expandRow = {
    renderer: (row, rowIndex) => (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <p
          style={{
            display: "flex",
            justifyContent: "center",
            textAlign: "center",
            alignItems: "center",
          }}
        >
          <img
            src={usdticon}
            alt="usdticon"
            style={{ width: "24px", height: "24px", marginRight: 4 }}
          ></img>
          {`USDC Balance: ${row.tusd_balance.toFixed(4)}`}
        </p>
        <p
          style={{
            display: "flex",
            justifyContent: "center",
            textAlign: "center",
            alignItems: "center",
          }}
        >
          <img
            src={c5icon}
            alt="c5icon"
            style={{ width: "32px", marginRight: 4 }}
          ></img>
          {`C5 Balance: ${row.c5_balance.toFixed(4)}`}
        </p>
        <p
          style={{
            display: "flex",
            justifyContent: "center",
            textAlign: "center",
            alignItems: "center",
          }}
        >
          <img
            src={btcicon}
            alt="btcicon"
            style={{ width: "32px", marginRight: 4 }}
          ></img>
          {`BTCp Balance: ${row.btc_balance.toFixed(4)}`}
        </p>
        <p
          style={{
            display: "flex",
            justifyContent: "center",
            textAlign: "center",
            alignItems: "center",
          }}
        >
          <img
            src={tradeicon}
            alt="tradeicon"
            style={{ width: "32px", marginRight: 4 }}
          ></img>
          {`TRQ Balance: ${row.trq_balance.toFixed(4)}`}
        </p>
        <p
          style={{
            display: "flex",
            justifyContent: "center",
            textAlign: "center",
            alignItems: "center",
          }}
        >
          <img
            src={arbitrageicon}
            alt="arbitrageicon"
            style={{ width: "32px", marginRight: 4 }}
          ></img>
          {`QNT Balance: ${row.qnt_balance.toFixed(4)}`}
        </p>
      </div>
    ),
    showExpandColumn: false,

    //expandByColumnOnly: true,
  };

  const expandRow2 = {
    showExpandColumn: false,
  };
  const [width, setWidth] = useState(0);
  const ref = useRef(null);

  useLayoutEffect(() => {
    setWidth(ref.current.offsetWidth);
  }, []);

  const datachart = {
    labels: ["Performance", "Management"],
    datasets: [
      {
        data: chartData,
        backgroundColor: ["#4169E1", "#4169E066"],
        borderWidth: 1,
      },
    ],
  };
  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: true,
        position: "bottom",
      },
    },
    cutout: 90,
  };

  useEffect(() => {
    if (chartDataToken == "ALL") {
      setChartData([
        totalResultFee?.reduce(
          (total, obj) =>
            parseFloat(obj.pfee_dollar != undefined ? obj?.pfee_dollar : 0) +
            total,
          0
        ),
        totalResultFee?.reduce(
          (total, obj) => parseFloat(obj?.mfee_dollar) + total,
          0
        ),
      ]);
    } else if (chartDataToken == "C5") {
      setChartData([
        parseFloat(
          totalResultFee?.filter((item) => item.name == "C5")[0]?.pfee_dollar
        ),
        parseFloat(
          totalResultFee?.filter((item) => item.name == "C5")[0]?.mfee_dollar
        ),
      ]);
    } else if (chartDataToken == "BTCp") {
      setChartData([
        parseFloat(
          totalResultFee?.filter((item) => item.name == "BTCp")[0]?.pfee_dollar
        ),
        parseFloat(
          totalResultFee?.filter((item) => item.name == "BTCp")[0]?.mfee_dollar
        ),
      ]);
    } else if (chartDataToken == "QNT") {
      setChartData([
        parseFloat(
          totalResultFee?.filter((item) => item.name == "QNT")[0]?.pfee_dollar
        ),
        parseFloat(
          totalResultFee?.filter((item) => item.name == "QNT")[0]?.mfee_dollar
        ),
      ]);
    } else if (chartDataToken == "TRQ") {
      setChartData([
        parseFloat(
          totalResultFee?.filter((item) => item.name == "TRQ")[0]?.pfee_dollar
        ),
        parseFloat(
          totalResultFee?.filter((item) => item.name == "TRQ")[0]?.mfee_dollar
        ),
      ]);
    }
  }, [totalResultFee, chartDataToken]);

  function createData(
    name,
    logo,
    mfee_per,
    mfee_dollar,
    pfee_per,
    pfee_dollar
  ) {
    return {
      name,
      logo,
      mfee_per,
      mfee_dollar,
      pfee_per,
      pfee_dollar,
    };
  }

  const logos = [
    "https://dow.capital/static/media/c5icon.a4fc3d13b355bfc93b0f.png",
    "https://dow.capital/static/media/btcicon.f369eb135dd8bed28e0d.png",
    "https://dow.capital/static/media/tradeicon.300070758f7cd253ff5f.png",
    "https://dow.capital/static/media/arbitrageicon.b534faf6f8c6f6c48dd5.png",
    "https://dow.capital/static/media/stakingicon.f82205d14240d22c0019.png",
    "https://dow.capital/static/media/liqicon.4a3d28ab4fbf88d7146f.png",
    "https://dow.capital/static/media/optionicon.5f939fd9e9a63012d64d.png",
  ];

  const rows = [
    createData("C5", logos[0], "%5", "200$", "%5", "200$"),
    createData("BTCp", logos[1], "%5", "200$", "%5", "200$"),
    createData("TRQ", logos[2], "%5", "200$", "%5", "200$"),
    createData("QNT", logos[3], "%5", "200$", "%5", "200$"),
    createData("STAKING", logos[4], "%5", "200$", "%5", "200$"),
    createData("LIQUIDITY", logos[5], "%5", "200$", "%5", "200$"),
    createData("OPTION", logos[6], "%5", "200$", "%5", "200$"),
  ];

  const totalManagementsConfig = totalManagementsColumns({});
  const emergencyConfig = emergencyColumns({});

  return (
    <div className="fener mb-2" ref={ref}>
      {parseInt(userRole) !== 1 ? (
        <>
          <Container>
            <UserLabel text={t("accountstatus_welcome")} isDashboard={true} />
            <div className="row gx-3">
              {width > 767 ? (
                <>
                  <div className="col-lg-4 col-md-12 mb-3">
                    <DFundPriceMovement />
                  </div>
                  <div className="col-lg-4 col-md-12 mb-3">
                    <DPortfolio />
                  </div>
                </>
              ) : (
                <>
                  <div className="col-lg-4 col-md-12 mb-3">
                    <DPortfolio />
                  </div>
                  <div className="col-lg-4 col-md-12 mb-3">
                    <DFundPriceMovement />
                  </div>
                </>
              )}

              <div
                className="col-lg-4 col-md-12 d-flex flex-column mb-3"
                style={{ gap: "15px" }}
              >
                <DFundHistory />
                <DYieldHubEarnings />
                <FNews />
              </div>
            </div>
          </Container>
        </>
      ) : (
        <>
          <FSidebar
            setActiveTab={setActiveTab}
            activeTab={activeTab}
            theme={theme}
          />
          <FBottombar
            setActiveTab={setActiveTab}
            activeTab={activeTab}
            show={bottomShow}
            row={selectedRow}
            setSelectedRow={setSelectedRow}
            setSelectedCount={setSelectedCount}
          />

          <div className="f_admin">
            {
              {
                0: (
                  <div className="f_admin-balances-container">
                    <AdminCards />
                    <div className="row mt-4">
                      <div className="col-md-8">
                        <AUM />
                        <DFundHistory />
                      </div>
                      <div className="col-md-4">
                        <DFundPriceMovement />
                      </div>
                    </div>
                  </div>
                ),
                1: (
                  <div
                    className={
                      bottomShow === true
                        ? "f_admin-balances acik"
                        : "f_admin-balances"
                    }
                  >
                    <div className="f_admin-balances-container">
                      <div className="f_admin-balances-container-head">
                        <h1>{t("dashboard_invest")}</h1>
                      </div>
                      <div className="d-flex f_admin-balances-refresh">
                        <div className="f_admin-balances-refresh-icon">
                          <FIcon
                            name="refresh"
                            color="white"
                            size="22"
                            style={{
                              WebkitTransform: "scaleX(-1)",
                              transform: "scaleX(-1)",
                              marginLeft: "3px",
                              marginTop: "2px",
                            }}
                            onClick={refreshTable}
                          />
                        </div>

                        <div>
                          <Form.Select
                            value={selectValue}
                            onChange={(e) =>
                              handleFilterBuyOnChange("status", e.target.value)
                            }
                          >
                            <option value="ALL">All</option>
                            <option value="A">Approved</option>
                            <option value="Q">Processing</option>
                            <option value="E">Error</option>
                            <option value="R">Rejected</option>
                            <option value="S">Success</option>
                          </Form.Select>
                        </div>
                      </div>
                      <div className="f_admin-balances-container-item">
                        {loading ? (
                          <Spinner animation="grow" />
                        ) : (
                          <BootstrapTable
                            wrapperClasses="table-responsive"
                            remote={{
                              pagination: true,
                              filter: false,
                              sort: false,
                            }}
                            rowStyle={rowStyleFormat}
                            bordered={false}
                            keyField="id"
                            data={transactionList}
                            columns={transactionColumnsConfig}
                            expandRow={expandRow2}
                            selectRow={selectRow}
                            noDataIndication={"Table is Empty"}
                            pagination={paginationFactory({
                              page: currentPage,
                              sizePerPage: pageSize,
                              totalSize: count,
                              showTotal: true, // display pagination information
                              hideSizePerPage: true, // hide the size per page dropdown
                            })}
                            onTableChange={onTableChange}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                ),
                2: (
                  <div
                    className={
                      bottomShow === true
                        ? "f_admin-balances acik"
                        : "f_admin-balances"
                    }
                  >
                    <div className="f_admin-balances-container">
                      <div className="f_admin-balances-container-head">
                        <h1>{t("dashboard_redeem")}</h1>
                      </div>
                      <div className="d-flex f_admin-balances-refresh">
                        <div className="f_admin-balances-refresh-icon">
                          <FIcon
                            name="refresh"
                            color="white"
                            size="22"
                            style={{
                              WebkitTransform: "scaleX(-1)",
                              transform: "scaleX(-1)",
                              marginLeft: "3px",
                              marginTop: "2px",
                            }}
                            onClick={refreshTable}
                          />
                        </div>
                        <div>
                          <Form.Select
                            value={selectValue}
                            onChange={(e) =>
                              handleFilterSellOnChange("status", e.target.value)
                            }
                          >
                            <option value="ALL">All</option>
                            <option value="A">Approved</option>
                            <option value="Q">Processing</option>
                            <option value="E">Error</option>
                            <option value="R">Rejected</option>
                            <option value="S">Success</option>
                          </Form.Select>
                        </div>
                      </div>
                      <div className="f_admin-balances-container-item">
                        {loading ? (
                          <Spinner animation="grow" />
                        ) : (
                          <>
                            <BootstrapTable
                              wrapperClasses="table-responsive"
                              remote={{
                                pagination: true,
                                filter: false,
                                sort: false,
                              }}
                              //rowStyle={rowStyleFormat}
                              bordered={false}
                              keyField="id"
                              rowStyle={rowStyleFormat}
                              data={transactionList}
                              columns={sellTransactionColumnsConfig}
                              selectRow={selectRow}
                              expandRow={expandRow2}
                              noDataIndication={"Table is Empty"}
                              pagination={paginationFactory({
                                page: currentPage,
                                sizePerPage: pageSize,
                                totalSize: count,
                                showTotal: true, // display pagination information
                                hideSizePerPage: true, // hide the size per page dropdown
                              })}
                              onTableChange={onTableChange}
                            />
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                ),
                3: (
                  <div
                    className={
                      bottomShow === true
                        ? "f_admin-balances acik"
                        : "f_admin-balances"
                    }
                  >
                    <div className="f_admin-balances-container">
                      <div className="f_admin-balances-container-head">
                        <h1>{t("dashboard_withdraw")}</h1>
                      </div>

                      <div className="d-flex f_admin-balances-refresh">
                        <div className="f_admin-balances-refresh-icon">
                          <FIcon
                            name="refresh"
                            color="white"
                            size="22"
                            style={{
                              WebkitTransform: "scaleX(-1)",
                              transform: "scaleX(-1)",
                              marginLeft: "3px",
                              marginTop: "2px",
                            }}
                            onClick={refreshTable}
                          />
                        </div>
                      </div>
                      <div className="f_admin-balances-container-item">
                        {loading ? (
                          <Spinner animation="grow" />
                        ) : (
                          <BootstrapTable
                            wrapperClasses="table-responsive"
                            remote={{
                              pagination: true,
                              filter: false,
                              sort: false,
                            }}
                            bordered={false}
                            keyField="id"
                            rowStyle={rowStyleFormat}
                            data={transactionList}
                            columns={withdrawTransactionColumnsConfig}
                            selectRow={selectRow2}
                            expandRow={expandRow2}
                            noDataIndication={"Table is Empty"}
                            pagination={paginationFactory({
                              page: currentPage,
                              sizePerPage: pageSize,
                              totalSize: count,
                              showTotal: true, // display pagination information
                              hideSizePerPage: true, // hide the size per page dropdown
                            })}
                            onTableChange={onTableChange}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                ),
                4: (
                  <div
                    className={
                      bottomShow === true
                        ? "f_admin-balances acik"
                        : "f_admin-balances"
                    }
                  >
                    <div className="f_admin-balances-container">
                      <div className="f_admin-balances-container-head">
                        <h1>{t("dashboard_users")}</h1>
                      </div>

                      <div className="d-flex f_admin-balances-refresh">
                        <div>
                          <Form.Group
                            style={{ display: "flex", position: "relative" }}
                          >
                            <Form.Control
                              name="wallet"
                              type="text"
                              placeholder="Search user"
                              style={{
                                width: "180px",
                                height: "30px",
                                borderRadius: "10px",
                                border: "0",
                                fontSize: "10px",
                              }}
                              value={userSearchValue}
                              onChange={(e) =>
                                setUserSearchValue(e.target.value)
                              }
                            />
                            <div
                              style={{
                                position: "absolute",
                                top: "0",
                                right: "10px",
                              }}
                            >
                              <svg
                                width="8"
                                height="10"
                                viewBox="0 0 8 10"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M6.45277 3.5235C6.45277 5.30539 5.00827 6.74989 3.22638 6.74989C1.4445 6.74989 0 5.30539 0 3.5235C0 1.74162 1.4445 0.297119 3.22638 0.297119C5.00827 0.297119 6.45277 1.74162 6.45277 3.5235ZM0.432875 3.5235C0.432875 5.06632 1.68357 6.31701 3.22638 6.31701C4.7692 6.31701 6.01989 5.06632 6.01989 3.5235C6.01989 1.98069 4.7692 0.729994 3.22638 0.729994C1.68357 0.729994 0.432875 1.98069 0.432875 3.5235Z"
                                  fill="#252525"
                                />
                                <path
                                  d="M4.96387 6.04733L5.3666 5.75718L8.0002 9.41276L7.59746 9.70291L4.96387 6.04733Z"
                                  fill="#252525"
                                />
                              </svg>
                            </div>
                          </Form.Group>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <Button
                            style={{ marginRight: "10px" }}
                            onClick={() => {
                              setInternalModalShow(true);
                            }}
                          >
                            Internal Transaction
                          </Button>
                          <div className="f_admin-balances-refresh-icon">
                            <FIcon
                              name="refresh"
                              color="white"
                              size="22"
                              style={{
                                WebkitTransform: "scaleX(-1)",
                                transform: "scaleX(-1)",
                                marginLeft: "3px",
                                marginTop: "2px",
                              }}
                              onClick={refreshTable}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="f_admin-balances-container-item">
                        {loadingAll ? (
                          <Spinner animation="grow" />
                        ) : (
                          <>
                            <BootstrapTable
                              wrapperClasses="table-responsive"
                              remote={{
                                pagination: true,
                                filter: false,
                                sort: false,
                              }}
                              keyField="account_id"
                              bordered={false}
                              data={userList}
                              columns={usersColumnsConfig}
                              expandRow={expandRow}
                              noDataIndication={"Table is Empty"}
                              pagination={paginationFactory({
                                page: currentPage,
                                sizePerPage: pageSize,
                                totalSize: userCount,
                                showTotal: true, // display pagination information
                                hideSizePerPage: true, // hide the size per page dropdown
                              })}
                              onTableChange={onTableChange}
                            />
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                ),
                5: (
                  <div
                    className={
                      bottomShow === true
                        ? "f_admin-balances acik"
                        : "f_admin-balances"
                    }
                  >
                    <div className="f_admin-balances-container">
                      <div className="f_admin-balances-container-head">
                        <h1>{t("dashboard_funds")}</h1>
                      </div>

                      <div className="d-flex f_admin-balances-refresh">
                        <div className="f_admin-balances-refresh-icon">
                          <FIcon
                            name="refresh"
                            color="white"
                            size="22"
                            style={{
                              WebkitTransform: "scaleX(-1)",
                              transform: "scaleX(-1)",
                              marginLeft: "3px",
                              marginTop: "2px",
                            }}
                            onClick={refreshTable}
                          />
                        </div>
                      </div>

                      <div className="">
                        {loadingFunds ? (
                          <Spinner animation="grow" />
                        ) : (
                          <>
                            <FundAccordion />
                            {/*<BootstrapTable
                              wrapperClasses="table-responsive"
                              remote={{
                                pagination: true,
                                filter: false,
                                sort: false,
                              }}
                              keyField="id"
                              bordered={false}
                              expandRow={expandRow2}
                              data={fundList}
                              selectRow={selectRow2}
                              columns={fundsColumnsConfig}
                              noDataIndication={"Table is Empty"}
                              pagination={paginationFactory({
                                page: currentPage,
                                sizePerPage: pageSize,
                                totalSize: countFunds,
                                showTotal: true, // display pagination information
                                hideSizePerPage: true, // hide the size per page dropdown
                              })}
                              onTableChange={onTableChange}
                            />*/}
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                ),
                6: (
                  <div
                    className={
                      bottomShow === true
                        ? "f_admin-balances acik"
                        : "f_admin-balances"
                    }
                  >
                    <div className="f_admin-balances-container">
                      <div className="f_admin-balances-container-head">
                        <h1>{t("dashboard_news")}</h1>
                        <div>
                          <Button
                            style={{ marginRight: "10px" }}
                            onClick={() => {
                              setFormDataNews(initialFormDataNews);
                              setNewsId(null);
                              setNewsModalShow(true);
                            }}
                          >
                            {t("dashboard_add_news")}
                          </Button>
                          <img
                            onClick={refreshTable}
                            alt=""
                            src={refreshIcon}
                          ></img>
                        </div>
                      </div>
                      <div className="f_admin-news-container">
                        <div className="f_admin-news">
                          <h1>NEWS</h1>
                          <div className="f_admin-news-inner mt-3">
                            {loadingAll ? (
                              <Spinner animation="grow" />
                            ) : (
                              <>
                                {newsList
                                  ?.filter((e) => e.type == "news")
                                  .map((value) => (
                                    <div className="f_admin-news-card">
                                      <div className="d-flex justify-content-between">
                                        <h2>{value?.title}</h2>
                                        <div className="d-flex">
                                          <FIcon
                                            name="edit"
                                            color="#979797"
                                            size="16"
                                            onClick={() => {
                                              setNewsId(value?.id);
                                              setNewsModalShow(true);
                                            }}
                                          />
                                          <FIcon
                                            name="trash"
                                            color="#979797"
                                            size="16"
                                            onClick={() => {
                                              setNewsId(value?.id);
                                              setNewsDeleteModalShow(true);
                                            }}
                                          />
                                        </div>
                                      </div>
                                      <h3>
                                        {timeAgo(value?.c_date, getLanguage)}
                                      </h3>
                                    </div>
                                  ))}
                              </>
                            )}
                          </div>
                        </div>
                        <div className="f_admin-news">
                          <h1>BLOG</h1>
                          <div className="f_admin-news-inner mt-3">
                            {loadingAll ? (
                              <Spinner animation="grow" />
                            ) : (
                              <>
                                {newsList
                                  .filter((e) => e.type == "blog")
                                  ?.map((value) => (
                                    <div className="f_admin-news-card">
                                      <div
                                        className="d-flex"
                                        style={{ gap: "28px" }}
                                      >
                                        <img
                                          src={
                                            process.env
                                              .REACT_APP_DOWNLOAD_IMAGE_BASE_URL +
                                            value?.image
                                          }
                                          height="72"
                                          style={{
                                            aspectRatio: "4/3",
                                            objectFit: "contain",
                                          }}
                                        />

                                        <div className="d-flex justify-content-between  flex-fill">
                                          <div>
                                            <h2 style={{ fontWeight: "700" }}>
                                              {value?.title}
                                            </h2>

                                            <h1
                                              style={{
                                                fontSize: "11px",
                                                fontWeight: "400",
                                                lineHeight: "13px",
                                                color: "#5E718D",
                                                maxWidth: "250px",
                                              }}
                                              className="mt-2"
                                            >
                                              {value?.content !== undefined &&
                                                EditorState.createWithContent(
                                                  convertFromRaw(
                                                    JSON.parse(value?.content)
                                                  )
                                                )
                                                  .getCurrentContent()
                                                  .getPlainText()
                                                  .substring(0, 130) + "..."}
                                            </h1>
                                          </div>
                                          <div className="d-flex">
                                            <FIcon
                                              name="edit"
                                              color="#979797"
                                              size="16"
                                              onClick={() => {
                                                setNewsId(value?.id);
                                                setNewsModalShow(true);
                                              }}
                                            />
                                            <FIcon
                                              name="trash"
                                              color="#979797"
                                              size="16"
                                              onClick={() => {
                                                setNewsId(value?.id);
                                                setNewsDeleteModalShow(true);
                                              }}
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  ))}
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                      {/* <div className="f_admin-balances-container-item">
                        {loadingAll ? (
                          <Spinner animation="grow" />
                        ) : (
                          <>
                            <BootstrapTable
                              wrapperClasses="table-responsive"
                              remote={{
                                pagination: true,
                                filter: false,
                                sort: false,
                              }}
                              keyField="id"
                              data={newsList}
                              selectRow={selectRow2}
                              expandRow={expandRow2}
                              columns={newsColumnsConfig}
                              bordered={false}
                              //selectRow={selectRow}
                              noDataIndication={"Table is Empty"}
                              pagination={paginationFactory({
                                page: currentPage,
                                sizePerPage: pageSize,
                                totalSize: countNews,
                                showTotal: true, // display pagination information
                                hideSizePerPage: true, // hide the size per page dropdown
                              })}
                              onTableChange={onTableChange}
                            />
                          </>
                        )}
                            </div>*/}
                    </div>
                  </div>
                ),
                7: (
                  <div
                    className={
                      bottomShow === true
                        ? "f_admin-balances acik"
                        : "f_admin-balances"
                    }
                  >
                    <div className="  f_admin-balances-container">
                      <div className=" f_admin-balances-container-head">
                        <h1>
                          {selectValuePartners == "MNG"
                            ? t("dashboard_partners") + " " + "(Management)"
                            : t("dashboard_partners") + " " + "(Performance)"}
                        </h1>
                      </div>
                      <div className="d-flex f_admin-balances-refresh">
                        <div>
                          <Form.Select
                            value={selectValuePartners}
                            onChange={(e) =>
                              handlePartnerFeeOnChange("status", e.target.value)
                            }
                          >
                            <option value="MNG">MNG</option>
                            <option value="PRF">PRF</option>
                          </Form.Select>
                        </div>
                        <div className="f_admin-balances-refresh-icon">
                          <FIcon
                            name="refresh"
                            color="white"
                            size="22"
                            style={{
                              WebkitTransform: "scaleX(-1)",
                              transform: "scaleX(-1)",
                              marginLeft: "3px",
                              marginTop: "2px",
                            }}
                            onClick={refreshTable}
                          />
                        </div>
                      </div>

                      <div className="f_admin-balances-container-item">
                        {loadingAll ? (
                          <Spinner animation="grow" />
                        ) : (
                          <>
                            <BootstrapTable
                              wrapperClasses="table-responsive"
                              remote={{
                                pagination: true,
                                filter: false,
                                sort: false,
                              }}
                              keyField="id"
                              bordered={false}
                              data={partnerList}
                              expandRow={expandRow2}
                              columns={partnersColumnsConfig}
                              noDataIndication={"Table is Empty"}
                              onTableChange={onTableChange}
                            />
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                ),
                8: (
                  <div
                    className={
                      bottomShow === true
                        ? "f_admin-balances acik"
                        : "f_admin-balances"
                    }
                  >
                    <div className="f_admin-balances-container">
                      <div className="f_admin-balances-container-head">
                        <h1>{t("dashboard_deposit")}</h1>
                      </div>
                      <div className="d-flex f_admin-balances-refresh">
                        <div className="f_admin-balances-refresh-icon">
                          <FIcon
                            name="refresh"
                            color="white"
                            size="22"
                            style={{
                              WebkitTransform: "scaleX(-1)",
                              transform: "scaleX(-1)",
                              marginLeft: "3px",
                              marginTop: "2px",
                            }}
                            onClick={refreshTable}
                          />
                        </div>
                      </div>
                      <div className="f_admin-balances-container-item">
                        {loading ? (
                          <Spinner animation="grow" />
                        ) : (
                          <BootstrapTable
                            wrapperClasses="table-responsive"
                            remote={{
                              pagination: true,
                              filter: false,
                              sort: false,
                            }}
                            keyField="id"
                            bordered={false}
                            rowStyle={rowStyleFormat2}
                            data={depositsList}
                            columns={depositTransactionColumnsConfig}
                            selectRow={selectRow2}
                            expandRow={expandRow2}
                            noDataIndication={"Table is Empty"}
                            pagination={paginationFactory({
                              page: currentPage,
                              sizePerPage: pageSize,
                              totalSize: count,
                              showTotal: true, // display pagination information
                              hideSizePerPage: true, // hide the size per page dropdown
                            })}
                            onTableChange={onTableChange}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                ),
                9: (
                  <div className="management_tab w-100">
                    <Tabs
                      id="controlled-tab-example"
                      activeKey={key}
                      onSelect={(k) => setKey(k)}
                      className="mb-3"
                    >
                      <Tab eventKey="management" title="Management">
                        {loading ? (
                          <Spinner animation="grow" />
                        ) : (
                          <div
                            className={
                              bottomShow === true
                                ? "f_admin-balances acik"
                                : "f_admin-balances"
                            }
                          >
                            <div className="f_admin-balances-container">
                              <div className="f_admin-balances-container-head">
                                <h1>{t("dashboard_management")}</h1>
                              </div>

                              <div className="d-flex f_admin-balances-refresh">
                                <div className="f_admin-balances-refresh-icon">
                                  <FIcon
                                    name="refresh"
                                    color="white"
                                    size="22"
                                    style={{
                                      WebkitTransform: "scaleX(-1)",
                                      transform: "scaleX(-1)",
                                      marginLeft: "3px",
                                      marginTop: "2px",
                                    }}
                                    onClick={refreshTable}
                                  />
                                </div>
                              </div>
                              <div className="f_admin-balances-container-item">
                                <div className="d-flex gap-2 w-25 py-2 align-items-center">
                                  Fund:
                                  <Form.Select
                                    value={selectFundValue}
                                    onChange={(e) =>
                                      handleFilterManagementFeesOnChange(
                                        "fund_id",
                                        e.target.value
                                      )
                                    }
                                  >
                                    <option value="0">All</option>
                                    <option value="1">C5</option>
                                    <option value="2">BTCp</option>
                                    <option value="3">TRQ</option>
                                    <option value="4">QNT</option>
                                  </Form.Select>
                                </div>
                                <BootstrapTable
                                  wrapperClasses="table-responsive"
                                  remote={{
                                    pagination: true,
                                    filter: false,
                                    sort: false,
                                  }}
                                  //rowStyle={rowStyleFormat}
                                  bordered={false}
                                  keyField="account_id"
                                  selectRow={selectRow2}
                                  data={feeList}
                                  columns={mngFeesColumnsConfig}
                                  expandRow={expandRow2}
                                  //selectRow={selectRow}
                                  noDataIndication={"Table is Empty"}
                                  pagination={paginationFactory({
                                    page: currentPage,
                                    sizePerPage: pageSize,
                                    totalSize: count,
                                    showTotal: true, // display pagination information
                                    hideSizePerPage: true, // hide the size per page dropdown
                                  })}
                                  onTableChange={onTableChange}
                                />
                              </div>
                            </div>
                          </div>
                        )}
                      </Tab>
                      <Tab eventKey="performance" title="Performance">
                        {loading ? (
                          <Spinner animation="grow" />
                        ) : (
                          <div
                            className={
                              bottomShow === true
                                ? "f_admin-balances acik"
                                : "f_admin-balances"
                            }
                          >
                            <div className="f_admin-balances-container">
                              <div className=" f_admin-balances-container-head">
                                <h1>{t("dashboard_performance")}</h1>
                              </div>
                              <div className="d-flex f_admin-balances-refresh">
                                <div className="f_admin-balances-refresh-icon">
                                  <FIcon
                                    name="refresh"
                                    color="white"
                                    size="22"
                                    style={{
                                      WebkitTransform: "scaleX(-1)",
                                      transform: "scaleX(-1)",
                                      marginLeft: "3px",
                                      marginTop: "2px",
                                    }}
                                    onClick={refreshTable}
                                  />
                                </div>
                              </div>
                              <div className="f_admin-balances-container-item">
                                <div className="d-flex gap-2 w-25 py-2 align-items-center">
                                  Fund:
                                  <Form.Select
                                    value={selectFundValue}
                                    onChange={(e) =>
                                      handleFilterPerformanceFeesOnChange(
                                        "fund_id",
                                        e.target.value
                                      )
                                    }
                                  >
                                    <option value="0">All</option>
                                    <option value="2">BTCp</option>
                                    <option value="3">TRQ</option>
                                    <option value="4">QNT</option>
                                  </Form.Select>
                                </div>
                                <BootstrapTable
                                  wrapperClasses="table-responsive"
                                  remote={{
                                    pagination: true,
                                    filter: false,
                                    sort: false,
                                  }}
                                  //rowStyle={rowStyleFormat}
                                  bordered={false}
                                  keyField="account_id"
                                  selectRow={selectRow2}
                                  data={feeList}
                                  columns={prfFeesColumnsConfig}
                                  expandRow={expandRow2}
                                  //selectRow={selectRow}
                                  noDataIndication={"Table is Empty"}
                                  pagination={paginationFactory({
                                    page: currentPage,
                                    sizePerPage: pageSize,
                                    totalSize: count,
                                    showTotal: true, // display pagination information
                                    hideSizePerPage: true, // hide the size per page dropdown
                                  })}
                                  onTableChange={onTableChange}
                                />
                              </div>
                            </div>
                          </div>
                        )}
                      </Tab>
                      <Tab eventKey="total" title="Total Results">
                        {loading ? (
                          <Spinner animation="grow" />
                        ) : (
                          <div
                            className={
                              bottomShow === true
                                ? "f_admin-balances acik"
                                : "f_admin-balances"
                            }
                          >
                            <div className="f_admin-balances-container">
                              <div className=" f_admin-balances-container-head">
                                <h1 style={{ color: "black" }}>
                                  Total Results
                                </h1>
                              </div>
                              <div className="d-flex f_admin-balances-refresh">
                                <div className="f_admin-balances-refresh-icon">
                                  <FIcon
                                    name="refresh"
                                    color="white"
                                    size="22"
                                    style={{
                                      WebkitTransform: "scaleX(-1)",
                                      transform: "scaleX(-1)",
                                      marginLeft: "3px",
                                      marginTop: "2px",
                                    }}
                                    onClick={refreshTable}
                                  />
                                </div>
                              </div>
                              <div className="f_admin-balances-container-item">
                                <div className="w-100 d-flex total-management flex-wrap">
                                  <div>
                                    <div className="d-flex gap-2 w-25 py-2 align-items-center">
                                      <span style={{ fontSize: "14px" }}>
                                        GRAPH:
                                      </span>
                                      <Form.Select
                                        value={chartDataToken}
                                        onChange={(e) =>
                                          setChartDataToken(e.target.value)
                                        }
                                      >
                                        <option value="ALL">All</option>
                                        <option value="C5">C5</option>
                                        <option value="BTCp">BTCp</option>
                                        <option value="TRQ">TRQ</option>
                                        <option value="QNT">QNT</option>
                                      </Form.Select>
                                    </div>
                                    <div
                                      style={{
                                        padding: "24px",
                                        width: "300px",
                                      }}
                                    >
                                      {totalResultFee && (
                                        <Doughnut
                                          data={datachart}
                                          options={options}
                                        />
                                      )}
                                    </div>
                                  </div>
                                  <div style={{ flex: 1 }}>
                                    {totalResultFee && (
                                      <>
                                        <FTable
                                          columns={totalManagementsConfig}
                                          data={totalResultFee}
                                          padding="0"
                                          tmanagement={true}
                                          mfee_per={totalResultFee?.reduce(
                                            (total, obj) =>
                                              parseFloat(obj?.mfee_dollar) +
                                              total,
                                            0
                                          )}
                                          pfee_per={totalResultFee?.reduce(
                                            (total, obj) =>
                                              parseFloat(
                                                obj.pfee_dollar != undefined
                                                  ? obj?.pfee_dollar
                                                  : 0
                                              ) + total,
                                            0
                                          )}
                                        />
                                      </>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      </Tab>
                    </Tabs>
                  </div>
                ),

                10: (
                  <>
                    <BinanceTrader />
                  </>
                ),

                11: (
                  <div
                    className={
                      bottomShow === true
                        ? "f_admin-balances acik"
                        : "f_admin-balances"
                    }
                  >
                    <div className="f_admin-balances-container">
                      <div className="f_admin-balances-container-head">
                        <h1>Emergency Contacts</h1>
                      </div>
                      <hr></hr>
                      <div className="f_admin-balances-container-item">
                        {loading ? (
                          <Spinner animation="grow" />
                        ) : (
                          <div style={{ flex: 1 }}>
                            {emergencyContactsList && (
                              <>
                                <FTable
                                  columns={emergencyConfig}
                                  data={emergencyContactsList}
                                />
                              </>
                            )}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                ),
              }[activeTab]
            }
          </div>
          <FUserDetailsModal
            show={userModalShow}
            onHide={() => setUserModalShow(false)}
            animation={false}
            userId={userId}
          />

          <DInternalModal
            show={internalModalShow}
            onHide={() => setInternalModalShow(false)}
            animation={false}
          />
          <FNewsDetailsModal
            show={newsModalShow}
            onHide={() => setNewsModalShow(false)}
            animation={false}
            newsId={newsId}
            formData={formDataNews}
            setFormData={setFormDataNews}
          />
          <FFundDetailsModal
            show={fundsModalShow}
            onHide={() => setFundsModalShow(false)}
            animation={false}
            fundId={fundId}
          />

          <FPartnerDetailsModal
            show={partnerModalShow}
            onHide={() => setPartnerModalShow(false)}
            animation={false}
            userId={userId}
            partnerAccounts={partnerAccounts}
          />
          <FNewsDeleteModal
            show={newsDeleteModalShow}
            onHide={() => setNewsDeleteModalShow(false)}
            newsId={newsId}
            animation={false}
          />
        </>
      )}
    </div>
  );
};

export default Dashboard;
