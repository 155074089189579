// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.f_transactions {
  width: 100%;
}
.f_transactions-title {
  font-weight: 700;
  font-size: 24px;
  text-align: start;
  padding-bottom: 5px;
  border-bottom: 1px solid #d3d3d3;
}
.f_transactions-noinfo {
  font-weight: 500;
  font-size: 16px;
  text-align: start;
  padding-top: 15px;
  text-align: center;
}
.f_transactions-value {
  text-align: start;
  padding-top: 25px;
  padding-bottom: 25px;
  border-bottom: 1px solid #d3d3d3;
}
.f_transactions-value h1 {
  line-height: 16.31px;
  font-weight: 500;
  font-size: 13.47px;
  color: var(--text-status);
}
.f_transactions-value h2 {
  font-size: 32px;
  font-weight: 700;
  line-height: 38px;
}
.f_transactions-box {
  display: flex;
  justify-content: space-between;
  background: #ffffff;
  box-shadow: 2px 1px 12px -3px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  padding: 10px;
}
.f_transactions-box-title {
  font-weight: 700;
  font-size: 14.7px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}
.f_transactions-box-value {
  font-size: 17.79px;
  font-weight: 700;
}
.f_transactions-footer {
  cursor: pointer;
  font-size: 16px;
  font-weight: 700;
}`, "",{"version":3,"sources":["webpack://./src/components/FTransactions/FTransactions.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;AACF;AAAE;EACE,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,mBAAA;EACA,gCAAA;AAEJ;AAAE;EACE,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,iBAAA;EACA,kBAAA;AAEJ;AAAE;EACE,iBAAA;EACA,iBAAA;EACA,oBAAA;EACA,gCAAA;AAEJ;AADI;EACE,oBAAA;EACA,gBAAA;EACA,kBAAA;EACA,yBAAA;AAGN;AADI;EACE,eAAA;EACA,gBAAA;EACA,iBAAA;AAGN;AAAE;EACE,aAAA;EACA,8BAAA;EACA,mBAAA;EACA,iDAAA;EACA,kBAAA;EACA,aAAA;AAEJ;AADI;EACE,gBAAA;EACA,iBAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,SAAA;AAGN;AADI;EACE,kBAAA;EACA,gBAAA;AAGN;AAAE;EACE,eAAA;EACA,eAAA;EACA,gBAAA;AAEJ","sourcesContent":[".f_transactions {\n  width: 100%;\n  &-title {\n    font-weight: 700;\n    font-size: 24px;\n    text-align: start;\n    padding-bottom: 5px;\n    border-bottom: 1px solid #d3d3d3;\n  }\n  &-noinfo {\n    font-weight: 500;\n    font-size: 16px;\n    text-align: start;\n    padding-top: 15px;\n    text-align: center;\n  }\n  &-value {\n    text-align: start;\n    padding-top: 25px;\n    padding-bottom: 25px;\n    border-bottom: 1px solid #d3d3d3;\n    h1 {\n      line-height: 16.31px;\n      font-weight: 500;\n      font-size: 13.47px;\n      color: var(--text-status);\n    }\n    h2 {\n      font-size: 32px;\n      font-weight: 700;\n      line-height: 38px;\n    }\n  }\n  &-box {\n    display: flex;\n    justify-content: space-between;\n    background: #ffffff;\n    box-shadow: 2px 1px 12px -3px rgba(0, 0, 0, 0.25);\n    border-radius: 8px;\n    padding: 10px;\n    &-title {\n      font-weight: 700;\n      font-size: 14.7px;\n      display: flex;\n      justify-content: center;\n      align-items: center;\n      gap: 10px;\n    }\n    &-value {\n      font-size: 17.79px;\n      font-weight: 700;\n    }\n  }\n  &-footer {\n    cursor: pointer;\n    font-size: 16px;\n    font-weight: 700;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
