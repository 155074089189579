import React, { useEffect } from "react";
import Spinner from "react-bootstrap/Spinner";
import "./FNews.scss";
import { ListGroup } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getNewsRequest } from "../../services/news/newsService";
import { convertFromRaw, EditorState } from "draft-js";
import i18next, { t } from "i18next";
import { useTranslation } from "react-i18next";

const FNews = () => {
  const dispatch = useDispatch();
  let navigate = useNavigate();

  const { newsList, loading } = useSelector((state) => state.news);
  const initialFilters = {
    filter: {},
  };

  useEffect(() => {
    dispatch(getNewsRequest(initialFilters));
  }, []);

  const getLanguage = i18next.language || window.localStorage.i18nextLng;

  const { t } = useTranslation();

  return (
    <div className="f_wallet-bg">
      <div className="f_news-title">{t("news_news")}</div>
      <ListGroup as="ol" variant="flush" className="fnews-flush">
        {loading ? (
          <Spinner animation="grow" />
        ) : (
          newsList?.map(function (item, index) {
            return index > 2 ? (
              ""
            ) : (
              <ListGroup.Item
                key={index}
                as="li"
                className="d-flex justify-content-between align-items-start p-0 fnews-list"
                onClick={() => navigate("/news/newsDetails?id=" + item?.id)}
              >
                <div
                  className="me-auto text-start pt-4 d-flex pb-4"
                  style={{ gap: "10px" }}
                >
                  <img
                    width="150"
                    height="100"
                    src={
                      process.env.REACT_APP_DOWNLOAD_IMAGE_BASE_URL +
                      item?.image
                    }
                    style={{ objectFit: "cover" }}
                  />
                  <div>
                    <div className="fw-bold f_news-listtitle">
                      {item?.title}
                    </div>
                    <div className="fw-bold f_news-listsubtitle">
                      {item?.content !== undefined &&
                        EditorState.createWithContent(
                          convertFromRaw(JSON.parse(item?.content))
                        )
                          .getCurrentContent()
                          .getPlainText()
                          .substring(0, 90) + "..."}
                    </div>
                  </div>
                </div>
              </ListGroup.Item>
            );
          })
        )}
      </ListGroup>
    </div>
  );
};

export default FNews;
