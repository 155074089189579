import React, { useEffect } from "react";
import "./Invest.scss";
import Card from "react-bootstrap/Card";
import { useDispatch, useSelector } from "react-redux";
import { getFundsService } from "../../../services/fund/fundService";
import Spinner from "react-bootstrap/Spinner";
import { useNavigate } from "react-router-dom";
import { Container } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import c5invest from "../../../assets/images/c5invest.png";
import btcinvest from "../../../assets/images/btcinvest.png";
import tradinginvest from "../../../assets/images/tradinginvest.png";
import cryptoinvest from "../../../assets/images/cryptoinvest.png";
import investbg from "../../../assets/images/investbg.png";
import UserLabel from "../../../components/UserLabel/UserLabel";
import WFunds from "../../../components/WalletComponents/WFunds/WFunds";

const Invest = () => {
  const dispatch = useDispatch();
  let navigate = useNavigate();

  const { fundList, loading } = useSelector((state) => state.fund);
  const initialFilters = {
    filter: {},
  };

  useEffect(() => {
    dispatch(getFundsService(initialFilters));
  }, []);

  const { t } = useTranslation();
  const getLanguage = i18next.language || window.localStorage.i18nextLng;

  return (
    <Container>
      <UserLabel text={t("fnavbar_invest")} />
      <div className="f_invest text-center">
        <div style={{ display: "flex", justifyContent: "center" }}>
          <div style={{ maxWidth: "920px", width: "100%" }}>
            <WFunds />
          </div>
        </div>
        <div className="f_invest-funds">
          {loading ? (
            <Spinner animation="grow" />
          ) : (
            fundList?.map(function (item, index) {
              return (
                index < 4 && (
                  <Card
                    key={index}
                    onClick={() =>
                      index == 0
                        ? navigate("/invest/c5-index?id=" + item?.id)
                        : index == 1
                        ? navigate("/invest/bitcoin-protected?id=" + item?.id)
                        : index == 2
                        ? navigate("/invest/crypto-trading?id=" + item?.id)
                        : index == 3
                        ? navigate("/invest/algorithmic?id=" + item?.id)
                        : ""
                    }
                  >
                    <Card.Body>
                      <div className="card-inner">
                        {item?.token == "C5" && (
                          <img src={c5invest} width="120"></img>
                        )}
                        {item?.token == "BTCp" && (
                          <img src={btcinvest} width="120"></img>
                        )}

                        {item?.token == "TRQ" && (
                          <img src={cryptoinvest} width="120"></img>
                        )}

                        {item?.token == "QNT" && (
                          <img src={tradinginvest} width="120"></img>
                        )}

                        <div className="card_subhead">
                          <Card.Title>
                            {item?.id != 4
                              ? getLanguage === "tr"
                                ? item?.token + " " + item?.name
                                : item?.token + " " + item?.name_en
                              : getLanguage === "tr"
                              ? item?.name
                              : item?.name_en}
                          </Card.Title>

                          <Card.Text
                            style={{
                              marginTop:
                                item?.id == 4 || item?.id == 1 ? "25px" : "0px",
                            }}
                          >
                            {getLanguage === "tr"
                              ? item?.slogan
                              : item?.slogan_en}
                          </Card.Text>
                        </div>
                      </div>
                    </Card.Body>
                    <div className="card_arrow">
                      <svg
                        width="13"
                        height="24"
                        viewBox="0 0 13 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M2.65999 23.2603C2.26224 23.7073 1.56354 23.7073 1.16578 23.2603L0.591401 22.6147C0.254205 22.2357 0.254205 21.6643 0.591401 21.2853L8.21678 12.7147C8.55397 12.3357 8.55397 11.7643 8.21678 11.3853L0.591401 2.81471C0.254205 2.43572 0.254205 1.86429 0.591401 1.48529L1.16578 0.83971C1.56354 0.392655 2.26224 0.392655 2.65999 0.83971L12.0426 11.3853C12.3797 11.7643 12.3797 12.3357 12.0426 12.7147L2.65999 23.2603Z"
                          fill="#4169E1"
                        />
                      </svg>
                    </div>
                  </Card>
                )
              );
            })
          )}
        </div>
      </div>
      <div className="f_invest-bg">
        <img src={investbg} width="250" />
      </div>
    </Container>
  );
};

export default Invest;
