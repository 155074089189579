import React, { useState } from "react";
import { Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import "./DYieldHubEarnings.scss";

const DYieldHubEarnings = () => {
  const { t } = useTranslation();

  const [isYield, setIsYield] = useState(true);

  const handleSetSelected = () => {
    if (isYield) {
      setIsYield(false);
    } else {
      setIsYield(true);
    }
  };

  return (
    <div className="f_wallet-bg">
      <div className="d_fundprice-header">
        <div>
          <h3 style={{ fontSize: "16px" }}>
            {isYield ? t("yield_earnings") : t("yield_earnings2")}
          </h3>
          <h4
            style={{
              fontSize: "10px",
              fontWeight: "700",
              color: "#B3B3B3",
              cursor: "pointer",
            }}
            onClick={handleSetSelected}
          >
            {isYield ? t("yield_earnings2") : t("yield_earnings")}
            <svg
              width="12"
              height="16"
              viewBox="0 0 12 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M3 8.75V3.36875L1.06875 5.3L0 4.25L3.75 0.5L7.5 4.25L6.43125 5.3L4.5 3.36875V8.75H3ZM8.25 15.5L4.5 11.75L5.56875 10.7L7.5 12.6313V7.25H9V12.6313L10.9312 10.7L12 11.75L8.25 15.5Z"
                fill="#B3B3B3"
              />
            </svg>
          </h4>
        </div>
        <Form.Select aria-label="Default select example">
          <option>{t("today")}</option>
          <option>{t("this_week")}</option>
          <option>{t("this_month")}</option>
        </Form.Select>
      </div>
      <div className="d_fundprice-date" style={{ marginBottom: "20px" }}>
        <Form.Control name="wallet" type="date" />
        <span> - </span>
        <Form.Control name="wallet" type="date" />
      </div>
      <h1
        style={{
          fontSize: "24px",
          fontWeight: 700,
        }}
      >
        $0
      </h1>
    </div>
  );
};

export default DYieldHubEarnings;
