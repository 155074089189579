import React, { useState } from "react";
import { Container, Modal } from "react-bootstrap";
import "./About.scss";
import about1 from "../../../assets/images/about1.jpeg";
import about2 from "../../../assets/images/about2.jpeg";
import about3 from "../../../assets/images/about3.jpeg";
import { useTranslation } from "react-i18next";
import barissozen from "../../../assets/images/barissozen.png";
import team1 from "../../../assets/images/team1.png";
import i18next from "i18next";

import custody from "../../../assets/images/custody.png";
import mpc from "../../../assets/images/mpc.png";
import proof from "../../../assets/images/proof.png";
import ozan from "../../../assets/images/ozan.jpg";
import utku from "../../../assets/images/utku.jpeg";
import mustafa from "../../../assets/images/mustafa.jpg";
import ercan from "../../../assets/images/ercan.png";

const About = () => {
  const { t } = useTranslation();
  const getLanguage = i18next.language || window.localStorage.i18nextLng;

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [show2, setShow2] = useState(false);
  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);
  const [show3, setShow3] = useState(false);
  const handleClose3 = () => setShow3(false);
  const handleShow3 = () => setShow3(true);
  const [show4, setShow4] = useState(false);
  const handleClose4 = () => setShow4(false);
  const handleShow4 = () => setShow4(true);
  return (
    <>
      <div className="about">
        <div
          className="about-head"
          style={{
            backgroundImage: `url("${about1}")`,
            height: "300px",
            backgroundRepeat: "no-repeat",
            backgroundSize: "100% 150%",
          }}
        >
          <div className="about-head-text container">
            {getLanguage == "tr" ? (
              <div>
                <span style={{ color: "#4169E1" }}>DOW</span> Digital Capital
                Hakkında
              </div>
            ) : (
              <div>
                About <span style={{ color: "#4169E1" }}>DOW</span> Digital
                Capital
              </div>
            )}
          </div>
        </div>
        <div className="about-wrapper">
          <Container>
            <div className="about-body">
              <div>
                <div className="about-body-title">{t("about_vision")} </div>
                <div className="about-body-content">{t("about_content1")}</div>
                <div className="about-body-content">{t("about_content2")}</div>
              </div>
              <svg
                width="246"
                height="246"
                viewBox="0 0 246 246"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clip-path="url(#clip0_3_296)">
                  <path
                    d="M82.5 81.5V62H163.5V81.5H82.5ZM41.5 82.5H81.5V102H41.5V82.5ZM21 103H40.5V122.5H21V103ZM0.5 123.5H20V143H0.5V123.5ZM41.5 184V164.5H81.5V184H41.5ZM82.5 204.5V185H163.5V204.5H82.5ZM204.5 184H164.5V164.5H204.5V184ZM225 163.5H205.5V144H225V163.5ZM245.5 143H226V123.5H245.5V143ZM225 103V122.5H205.5V103H225ZM204.5 82.5V102H164.5V82.5H204.5ZM21 144H40.5V163.5H21V144ZM103 113.25H143V153.25H103V113.25Z"
                    stroke="#4169E1"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_3_296">
                    <rect width="246" height="246" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            </div>
            <div className="about-who">
              <div>
                <div className="about-body-title">{t("flanding_who")}</div>
                <h2>{t("landing_team_title")}</h2>
                <br />
                <div style={{ marginLeft: "20px" }}>
                  <h2>{t("landing_team_item1")}</h2>
                  <h2>{t("landing_team_item2")}</h2>
                  <h2>{t("landing_team_item3")}</h2>
                </div>
                <br />
                <h2>{t("landing_team_desc")}</h2>
              </div>
            </div>
          </Container>
        </div>

        <div className="landing-who container">
          <div className="landing-who-hero ">
            <img src={barissozen} width={310} height={400} />
            <div className="landing-who-hero-desc">
              <h3>"{t("flanding_bs")}"</h3>
              <h4>
                Barış Sözen
                <br />
                CEO
              </h4>
            </div>
          </div>
          <div className="landing-who-team">
            <div
              className="landing-who-team-item"
              onClick={handleShow}
              style={{ cursor: "pointer" }}
            >
              <img
                src={ozan}
                width="100"
                height="100"
                style={{ borderRadius: "100%", filter: "grayscale(100%)" }}
              />
              <h3>Ozan İmamoğlu</h3>
              <h4>Fund Manager</h4>
            </div>

            <div
              className="landing-who-team-item"
              onClick={handleShow2}
              style={{ cursor: "pointer" }}
            >
              <img
                src={ercan}
                width="100"
                height="100"
                style={{ borderRadius: "100%", filter: "grayscale(100%)" }}
              />
              <h3>Ercan Işık</h3>
              <h4>CTO</h4>
            </div>

            <div
              className="landing-who-team-item"
              onClick={handleShow3}
              style={{ cursor: "pointer" }}
            >
              <img
                src={utku}
                width="100"
                height="100"
                style={{ borderRadius: "100%", filter: "grayscale(100%)" }}
              />
              <h3>Utku Fırat Ertürk </h3>
              <h4>Quantitative Researcher</h4>
            </div>

            <div
              className="landing-who-team-item"
              onClick={handleShow4}
              style={{ cursor: "pointer" }}
            >
              <img
                src={mustafa}
                width="100"
                height="100"
                style={{ borderRadius: "100%", filter: "grayscale(100%)" }}
              />
              <h3>Mustafa Ünal</h3>
              <h4>Advisor</h4>
            </div>
          </div>
        </div>
        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <img
              src={ozan}
              width="70"
              height="70"
              style={{
                borderRadius: "100%",
                filter: "grayscale(100%)",
                marginRight: "20px",
              }}
            />
            <Modal.Title>Ozan İmamoğlu</Modal.Title>
          </Modal.Header>
          <Modal.Body style={{ fontStyle: "italic" }}>
            {t("flanding_oi")}
            <a
              href="https://www.linkedin.com/in/ozan-imamoglu-1323255"
              target="_blank"
              style={{ display: "block", marginTop: "20px" }}
            >
              <img
                width={20}
                height={20}
                src="https://cdn-icons-png.flaticon.com/512/174/174857.png"
              ></img>
            </a>
          </Modal.Body>
        </Modal>
        <Modal show={show2} onHide={handleClose2}>
          <Modal.Header closeButton>
            <img
              src={ercan}
              width="70"
              height="70"
              style={{
                borderRadius: "100%",
                filter: "grayscale(100%)",
                marginRight: "20px",
              }}
            />
            <Modal.Title>Ercan Işık</Modal.Title>
          </Modal.Header>
          <Modal.Body style={{ fontStyle: "italic" }}>
            "I am a highly experienced and respected professional with over 15
            years of experience in the field of blockchain, system architecture,
            and start-up leadership. I have a strong technical background and a
            proven track record as a serial entrepreneur, having successfully
            founded and led several start-ups in the past. In my current role as
            CEO of a blockchain-based start-up, I am responsible for overseeing
            all aspects of the business, including product development,
            strategy, and team management. As a team lead with excellent
            communication and leadership skills, I have a talent for building
            and motivating high-performing teams, and have a reputation for
            driving results and delivering on business objectives. Throughout my
            career, I have consistently demonstrated the ability to successfully
            navigate complex technical challenges and deliver innovative
            solutions that drive business growth and success."
            <a
              href="https://www.linkedin.com/in/ercan-isik/"
              target="_blank"
              style={{ display: "block", marginTop: "20px" }}
            >
              <img
                width={20}
                height={20}
                src="https://cdn-icons-png.flaticon.com/512/174/174857.png"
              ></img>
            </a>
          </Modal.Body>
        </Modal>
        <Modal show={show3} onHide={handleClose3}>
          <Modal.Header closeButton>
            <img
              src={utku}
              width="70"
              height="70"
              style={{
                borderRadius: "100%",
                filter: "grayscale(100%)",
                marginRight: "20px",
              }}
            />
            <Modal.Title>Utku Fırat Ertürk</Modal.Title>
          </Modal.Header>
          <Modal.Body style={{ fontStyle: "italic" }}>
            Utku Fırat Ertürk holds a Bachelor of Science degree in Mechanical
            Engineering. Since 2017, he has been researching the cryptocurrency
            ecosystem with a primary focus on liquidity and cost of carry. Since
            2022, he has been actively engaged in research and development
            activities related to perpetual contracts. At Dow Capital, he is
            involved in implementing the cash & carry arbitrage strategy, as
            well as data procurement and organization for Option Vaults and
            Constant Function Market Maker applications. He provides support for
            the development of products in these areas. Utku is fluent in
            Turkish (native), proficient in English, and has a basic
            understanding of German (A2 level).
            <a
              href="https://www.linkedin.com/in/utkufiraterturk/"
              target="_blank"
              style={{ display: "block", marginTop: "20px" }}
            >
              <img
                width={20}
                height={20}
                src="https://cdn-icons-png.flaticon.com/512/174/174857.png"
              ></img>
            </a>
          </Modal.Body>
        </Modal>
        <Modal show={show4} onHide={handleClose4}>
          <Modal.Header closeButton>
            <img
              src={mustafa}
              width="70"
              height="70"
              style={{
                borderRadius: "100%",
                filter: "grayscale(100%)",
                marginRight: "20px",
              }}
            />
            <Modal.Title>Mustafa Ünal</Modal.Title>
          </Modal.Header>
          <Modal.Body style={{ fontStyle: "italic" }}>
            "TBD"
            {/*<a
            href="https://www.linkedin.com/in/sabrina-kihm-43b6533a"
            target="_blank"
            style={{ display: "block", marginTop: "20px" }}
          >
            <img
              width={20}
              height={20}
              src="https://cdn-icons-png.flaticon.com/512/174/174857.png"
            ></img>
          </a>*/}
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
};

export default About;
