import { all, call, fork, put, takeLatest } from "redux-saga/effects";
import { addDays, apiCall, fundApiCall } from "../../../utils/util";
import {
  APPROVE_TRANSACTION_REQUEST,
  GET_PARTNERS_REQUEST,
  GET_TOTAL_SUPPLY_CHART_DATA_REQUEST,
  GET_TOTAL_USER_CHART_DATA_REQUEST,
  GET_TRANSACTIONS_REQUEST,
  GET_TRANSACTION_BY_ID_REQUEST,
  GET_USERS_REQUEST,
  GET_USER_BY_ID_REQUEST,
  UPDATE_USER_BY_ID_ADMIN_REQUEST,
  GET_DEPOSITS_REQUEST,
  GET_FEES_REQUEST,
  APPROVE_FEE_REQUEST,
  CANCEL_TRANSACTION_REQUEST,
  GET_APPROVE_COUNT_REQUEST,
  GET_TODAY_INSIGHTS_REQUEST,
  GET_TOTAL_USERS_REQUEST,
  GET_AUM_DATA_REQUEST,
  GET_GENERAL_CHART_DATA_REQUEST,
  GET_ALL_GENERAL_CHART_DATA_REQUEST,
  GET_FUND_REPORTS_REQUEST,
  GET_TOTAL_RESULTS_FEE_REQUEST,
  APPROVE_PARTNER_FEE_REQUEST,
  INTERNAL_TRANSFER_REQUEST,
  BNBTRADER_BUY_REQUEST,
  BNBTRADER_SELL_REQUEST,
  BNBTRADER_BALANCE_REQUEST,
  GET_USERS_CENSORED_REQUEST,
  GET_EMERGENCY_CONTACTS_REQUEST,
} from "../../actions";
import {
  approveTransactionFail,
  approveTransactionSuccess,
  getPartnersFail,
  getPartnersSuccess,
  getTotalSupplyChartDataFail,
  getTotalSupplyChartDataSuccess,
  getTotalUserChartDataFail,
  getTotalUserChartDataSuccess,
  getTransactionByIdService,
  getTransactionByIdServiceFailed,
  getTransactionsFail,
  getTransactionsSuccess,
  getDepositsSuccess,
  getDepositsFail,
  getUserByIdServiceFailed,
  getUserByIdServiceSucceed,
  getUsersFail,
  getUsersSuccess,
  updateUserAdminFailed,
  updateUserAdminSucceed,
  getFeesSuccess,
  getFeesFail,
  approveFeeSuccess,
  approveFeeFail,
  cancelTransactionSuccess,
  cancelTransactionFail,
  getApproveCountSuccess,
  getApproveCountFail,
  getTodayInsightsSuccess,
  getTodayInsightsFail,
  getTotalUsersSuccess,
  getTotalUsersFail,
  getAumDataSuccess,
  getAumDataFail,
  getGeneralChartDataSuccess,
  getGeneralChartDataFail,
  getAllGeneralChartDataSuccess,
  getAllGeneralChartDataFail,
  getFundReportsSuccess,
  getFundReportsFail,
  getTotalResultsFeeSuccess,
  getTotalResultsFeeFail,
  approvePartnerFeeSuccess,
  approvePartnerFeeFail,
  internalTransferSuccess,
  internalTransferFail,
  bnbTraderBuySuccess,
  bnbTraderBuyFail,
  bnbTraderSellSuccess,
  bnbTraderSellFail,
  bnbTraderBalanceSuccess,
  bnbTraderBalanceFail,
  getUsersCensoredSuccess,
  getUsersCensoredFail,
  getEmergencyContactsSuccess,
  getEmergencyContactsFail,
} from "../adminService";

function* watchGetTransactions() {
  yield takeLatest(GET_TRANSACTIONS_REQUEST, getTransactions);
}

function* getTransactions(action) {
  try {
    let filters = action.payload;
    const res = yield call(
      fundApiCall,
      "get",
      "fundListener/transactions" +
        `?type=${filters.type}&status=${filters.status}&sort=${filters.sort}&limit=${filters.limit}&pageNumber=${filters.pageNumber}`
    );
    let result = res.data.data;
    yield put(
      getTransactionsSuccess({
        data: result,
        count: res.data.count,
      })
    );
  } catch (err) {
    console.log(err);
    yield put(getTransactionsFail(err));
  }
}

function* watchGetFees() {
  yield takeLatest(GET_FEES_REQUEST, getFees);
}

function* getFees(action) {
  try {
    let filters = action.payload;
    const res = yield call(
      apiCall,
      "get",
      "fee/all" +
        `?sort=${filters.sort}&limit=${filters.limit}&page=${filters.page}&type=${filters.type}&fund_id=${filters.fund_id}`
    );
    let result = res.data.data;
    yield put(
      getFeesSuccess({
        data: result,
      })
    );
  } catch (err) {
    console.log(err);
    yield put(getFeesFail(err));
  }
}

function* watchGetDeposits() {
  yield takeLatest(GET_DEPOSITS_REQUEST, getDeposits);
}

function* getDeposits(action) {
  try {
    let filters = action.payload;
    const res = yield call(
      apiCall,
      "get",
      "deposit" +
        `?type=${filters.type}&status=${filters.status}&sort=${filters.sort}&limit=${filters.limit}&page=${filters.page}`
    );
    let result = res.data.data;

    yield put(
      getDepositsSuccess({
        data: result,
      })
    );
  } catch (err) {
    console.log(err);
    yield put(getDepositsFail(err));
  }
}

function* watchGetTransactionById() {
  yield takeLatest(GET_TRANSACTION_BY_ID_REQUEST, getTransactionById);
}

function* getTransactionById(action) {
  try {
    const { id } = action.payload;

    const res = yield call(
      fundApiCall,
      "get",
      "fundListener/transaction/" + id
    );
    let result = res.data.data;
    yield put(
      getTransactionByIdService({
        id,
        data: result,
      })
    );
  } catch (err) {
    console.log(err);
    yield put(getTransactionByIdServiceFailed(err));
  }
}

function* watchGetUsers() {
  yield takeLatest(GET_USERS_REQUEST, getUsers);
}

function* getUsers(action) {
  try {
    let filters = action.payload;

    const res = yield call(
      apiCall,
      "get",
      "account/all/withBalances" +
        `?sort=${filters.sort}&limit=${filters.limit}&page=${filters.page}&search=${filters.search}`
    );
    let result = res.data.data;

    yield put(
      getUsersSuccess({
        data: result,
      })
    );
  } catch (err) {
    console.log(err, "ERER");
    yield put(getUsersFail(err));
  }
}

function* watchGetCensoredUsers() {
  yield takeLatest(GET_USERS_CENSORED_REQUEST, getCensoredUsers);
}

function* getCensoredUsers(action) {
  try {
    //let filters = action.payload;

    const res = yield call(apiCall, "get", "account/censored/list");
    let result = res.data.data;

    yield put(
      getUsersCensoredSuccess({
        data: result,
      })
    );
  } catch (err) {
    console.log(err, "ERER");
    yield put(getUsersCensoredFail(err));
  }
}

function* watchGetPartners() {
  yield takeLatest(GET_PARTNERS_REQUEST, getPartners);
}

function* getPartners(action) {
  try {
    let filters = action.payload;

    const res = yield call(apiCall, "post", "account/partners", filters);
    let result = res.data.data;

    yield put(
      getPartnersSuccess({
        data: result,
      })
    );
  } catch (err) {
    console.log(err);
    yield put(getPartnersFail(err));
  }
}

function* watchGetUserById() {
  yield takeLatest(GET_USER_BY_ID_REQUEST, getUserById);
}

function* getUserById(action) {
  try {
    const { id } = action.payload;

    const res = yield call(apiCall, "get", "account/" + id);
    let result = res.data.data;
    yield put(
      getUserByIdServiceSucceed({
        id,
        data: result,
      })
    );
  } catch (err) {
    console.log(err);
    yield put(getUserByIdServiceFailed(err));
  }
}

function* watchApproveTransaction() {
  yield takeLatest(APPROVE_TRANSACTION_REQUEST, approveTransaction);
}

function* approveTransaction(action) {
  try {
    const { id } = action.payload;

    const res = yield call(
      fundApiCall,
      "patch",
      "fundListener/" + action.payload + "/approve"
    );
    let result = res.data.data;
    yield put(
      approveTransactionSuccess({
        id,
        data: result,
      })
    );
  } catch (err) {
    console.log(err);
    yield put(approveTransactionFail(err));
  }
}

function* watchCancelTransaction() {
  yield takeLatest(CANCEL_TRANSACTION_REQUEST, cancelTransaction);
}

function* cancelTransaction(action) {
  try {
    const { id } = action.payload;

    const res = yield call(
      fundApiCall,
      "patch",
      "fundListener/" + action.payload + "/cancel"
    );
    let result = res.data.data;
    yield put(
      cancelTransactionSuccess({
        id,
        data: result,
      })
    );
  } catch (err) {
    console.log(err);
    yield put(cancelTransactionFail(err));
  }
}

function* watchApproveFee() {
  yield takeLatest(APPROVE_FEE_REQUEST, approveFee);
}

function* approveFee(action) {
  try {
    const { id } = action.payload;

    const res = yield call(
      apiCall,
      "get",
      "fee/approve/" +
        action.payload.fund_id +
        "/" +
        action.payload.account_id +
        "/" +
        action.payload.type
    );
    let result = res.data.data;
    yield put(
      approveFeeSuccess({
        id,
        data: result,
      })
    );
  } catch (err) {
    console.log(err);
    yield put(approveFeeFail(err));
  }
}

function* watchApprovePartnerFee() {
  yield takeLatest(APPROVE_PARTNER_FEE_REQUEST, approvePartnerFee);
}

function* approvePartnerFee(action) {
  try {
    const { id } = action.payload;

    const res = yield call(
      apiCall,
      "get",
      "fee/partner/approve/" + action.payload.partnerId + "/MNG"
    );
    let result = res.data.data;
    yield put(
      approvePartnerFeeSuccess({
        id,
        data: result,
      })
    );
  } catch (err) {
    console.log(err);
    yield put(approvePartnerFeeFail(err));
  }
}

function* watchInternalTransfer() {
  yield takeLatest(INTERNAL_TRANSFER_REQUEST, internalTransfer);
}

function* internalTransfer(action) {
  try {
    const { id } = action.payload;

    const res = yield call(
      apiCall,
      "post",
      "blockchain/transfer/" +
        action.payload.token +
        "/" +
        action.payload.fromAccountId +
        "/" +
        action.payload.toAccountId,
      { amount: action.payload.amount }
    );
    let result = res.data.data;
    yield put(
      internalTransferSuccess({
        id,
        data: result,
      })
    );
  } catch (err) {
    console.log(err);
    yield put(internalTransferFail(err));
  }
}

function* watchBnbTraderBuy() {
  yield takeLatest(BNBTRADER_BUY_REQUEST, bnbTraderBuy);
}

function* bnbTraderBuy(action) {
  try {
    const { id } = action.payload;

    const res = yield call(apiCall, "post", "bnb-trader/buy", action.payload);
    let result = res.data.data;
    yield put(
      bnbTraderBuySuccess({
        id,
        data: result,
      })
    );
  } catch (err) {
    console.log(err);
    yield put(bnbTraderBuyFail(err));
  }
}

function* watchBnbTraderSell() {
  yield takeLatest(BNBTRADER_SELL_REQUEST, bnbTraderSell);
}

function* bnbTraderSell(action) {
  try {
    const { id } = action.payload;

    const res = yield call(apiCall, "post", "bnb-trader/sell", action.payload);

    let result = res.data.data;
    yield put(
      bnbTraderSellSuccess({
        id,
        data: result,
      })
    );
  } catch (err) {
    console.log(err);
    yield put(bnbTraderSellFail(err));
  }
}

function* watchBnbTraderBalance() {
  yield takeLatest(BNBTRADER_BALANCE_REQUEST, bnbTraderBalance);
}

function* bnbTraderBalance(action) {
  try {
    const { id } = action.payload;

    const res = yield call(
      apiCall,
      "get",
      "blockchain/transfer/" +
        action.payload.token +
        "/" +
        action.payload.fromAccountId +
        "/" +
        action.payload.toAccountId +
        "/" +
        action.payload.amount
    );
    let result = res.data.data;
    yield put(
      bnbTraderBalanceSuccess({
        id,
        data: result,
      })
    );
  } catch (err) {
    console.log(err);
    yield put(bnbTraderBalanceFail(err));
  }
}

function* watchUpdateUserAdmin() {
  yield takeLatest(UPDATE_USER_BY_ID_ADMIN_REQUEST, updateUserAdmin);
}

function* updateUserAdmin(action) {
  try {
    const res = yield call(
      apiCall,
      "put",
      "account/" + action.payload.id,
      action.payload.formData
    );
    yield put(updateUserAdminSucceed({ data: res.status }));
  } catch (err) {
    console.log(err);
    yield put(updateUserAdminFailed(err));
  }
}

function* watchTotalSupplyChartData() {
  yield takeLatest(
    GET_TOTAL_SUPPLY_CHART_DATA_REQUEST,
    getTotalSupplyChartData
  );
}

function* getTotalSupplyChartData(action) {
  try {
    const res = yield call(apiCall, "get", "summary/fund");
    let result = res.data.data;

    yield put(
      getTotalSupplyChartDataSuccess({
        data: result,
      })
    );
  } catch (err) {
    console.log(err);
    yield put(getTotalSupplyChartDataFail(err));
  }
}

function* watchTotalUserChartData() {
  yield takeLatest(GET_TOTAL_USER_CHART_DATA_REQUEST, getTotalUserChartData);
}

function* getTotalUserChartData(action) {
  try {
    const res = yield call(apiCall, "get", "summary/user/count");
    let result = res.data.data;

    yield put(
      getTotalUserChartDataSuccess({
        data: result,
      })
    );
  } catch (err) {
    console.log(err);
    yield put(getTotalUserChartDataFail(err));
  }
}

function* watchApproveCount() {
  yield takeLatest(GET_APPROVE_COUNT_REQUEST, getApproveCount);
}

function* getApproveCount(action) {
  try {
    const res = yield call(apiCall, "get", "admin/approve/count");
    let result = res.data.data;

    yield put(
      getApproveCountSuccess({
        data: result,
      })
    );
  } catch (err) {
    console.log(err);
    yield put(getApproveCountFail(err));
  }
}

function* watchTodayInsights() {
  yield takeLatest(GET_TODAY_INSIGHTS_REQUEST, getTodayInsights);
}

function* getTodayInsights(action) {
  try {
    const res = yield call(apiCall, "get", "admin/insight/short");
    let result = res.data.data;

    yield put(
      getTodayInsightsSuccess({
        data: result,
      })
    );
  } catch (err) {
    console.log(err);
    yield put(getTodayInsightsFail(err));
  }
}

function* watchTotalUsers() {
  yield takeLatest(GET_TOTAL_USERS_REQUEST, getTotalUsers);
}

function* getTotalUsers(action) {
  try {
    const res = yield call(apiCall, "get", "admin/fund/distribution");
    let result = res.data.data;

    yield put(
      getTotalUsersSuccess({
        data: result,
      })
    );
  } catch (err) {
    console.log(err);
    yield put(getTotalUsersFail(err));
  }
}

function* watchAumData() {
  yield takeLatest(GET_AUM_DATA_REQUEST, getAumData);
}

function* getAumData(action) {
  try {
    const res = yield call(apiCall, "get", "admin/aum");
    const c5 = yield call(apiCall, "get", "price/fund/" + 1 + "/LAST");
    const btcp = yield call(apiCall, "get", "price/fund/" + 2 + "/LAST");
    const trq = yield call(apiCall, "get", "price/fund/" + 3 + "/LAST");
    const qnt = yield call(apiCall, "get", "price/fund/" + 4 + "/LAST");

    const users = yield call(apiCall, "get", "admin/fund/distribution");

    let result = [
      {
        name: "USDC",
        value: parseFloat(res?.data?.data?.TUSD),
      },
      {
        name: "C5",
        value: parseFloat(res?.data?.data?.C5) * parseFloat(c5.data?.data),
      },
      {
        name: "BTCp",
        value: parseFloat(res?.data?.data?.BTCp) * parseFloat(btcp.data?.data),
      },
      {
        name: "TRQ",
        value: parseFloat(res?.data?.data?.TRQ) * parseFloat(trq.data?.data),
      },
      {
        name: "QNT",
        value: parseFloat(res?.data?.data?.QNT) * parseFloat(qnt.data?.data),
      },
    ];

    yield put(
      getAumDataSuccess({
        data: result,
      })
    );
  } catch (err) {
    console.log(err);
    yield put(getAumDataFail(err));
  }
}
function* watchGeneralChartData() {
  yield takeLatest(GET_GENERAL_CHART_DATA_REQUEST, getGeneralChartData);
}

function* getGeneralChartData(action) {
  const { type, time, start, end } = action.payload;
  try {
    const res = yield call(
      apiCall,
      "post",
      "admin/token/price/" + time + "/" + type
    );
    let result = res.data.data;

    let data = result[type]?.graph[0];
    const newData = data?.map((obj) => {
      return {
        ...obj,
        value: parseFloat(obj.value),
        time: obj?.time?.slice(0, 10),
      };
    });
    yield put(
      getGeneralChartDataSuccess({
        data: newData,
      })
    );
  } catch (err) {
    console.log(err);
    yield put(getGeneralChartDataFail(err));
  }
}

function* watchAllGeneralChartData() {
  yield takeLatest(GET_ALL_GENERAL_CHART_DATA_REQUEST, getAllGeneralChartData);
}

function* getAllGeneralChartData(action) {
  const { time, start, end } = action.payload;

  try {
    const res = yield call(apiCall, "post", "admin/token/price/" + time, {
      startDate: start,
      endDate: end,
    });
    let result = res.data.data;
    yield put(
      getAllGeneralChartDataSuccess({
        data: result,
      })
    );
  } catch (err) {
    console.log(err);
    yield put(getAllGeneralChartDataFail(err));
  }
}

function* watchGetFundReports() {
  yield takeLatest(GET_FUND_REPORTS_REQUEST, getFundReports);
}

function* getFundReports(action) {
  try {
    const res = yield call(apiCall, "get", "admin/fund/report");
    const res2 = yield call(apiCall, "get", "admin/aum");
    const res3 = yield call(apiCall, "get", "admin/fund/distribution");
    const res4 = yield call(apiCall, "post", "admin/token/price/DAY", {
      start: addDays(new Date(), 1),
      end: addDays(new Date(), 0),
    });
    let innerData = {
      C5: {
        data: [
          {
            name: "BTC",
            amount: res.data.data?.C5?.amount?.BTC,
            price: res.data.data?.C5?.price?.BTC,
            ratio: "-",
            total: res.data.data?.C5?.amountUsd?.BTC,
            rebalance: "-",
          },
          {
            name: "ETH",
            amount: res.data.data?.C5?.amount?.ETH,
            price: res.data.data?.C5?.price?.ETH,
            ratio: "-",
            total: res.data.data?.C5?.amountUsd?.ETH,
            rebalance: "-",
          },
          {
            name: "BNB",
            amount: res.data.data?.C5?.amount?.BNB,
            price: res.data.data?.C5?.price?.BNB,
            ratio: "-",
            total: res.data.data?.C5?.amountUsd?.BNB,
            rebalance: "-",
          },
          {
            name: "ADA",
            amount: res.data.data?.C5?.amount?.ADA,
            price: res.data.data?.C5?.price?.ADA,
            ratio: "-",
            total: res.data.data?.C5?.amountUsd?.ADA,
            rebalance: "-",
          },
          {
            name: "DOT",
            amount: res.data.data?.C5?.amount?.DOT,
            price: res.data.data?.C5?.price?.DOT,
            ratio: "-",
            total: res.data.data?.C5?.amountUsd?.DOT,
            rebalance: "-",
          },

          {
            name: "TOTAL",
            amount: "0",
            price: "",
            ratio: "-",
            total: "-",
            rebalance: "",
          },
        ],
        qty: res2?.data?.data?.C5,
        userCount: res3?.data?.data?.filter(
          (element) => element?.token == "C5"
        )[0]?.count,
        price: res4.data.data?.C5?.unitPrice,
      },
      BTC: {
        data: [
          {
            name: "BTC",
            amount: res.data.data?.BTC?.amount,
            price: res.data.data?.BTC?.price,
            ratio: "-",
            total: res.data.data?.BTC?.amountUsd,
            rebalance: "-",
          },
        ],
        qty: res2?.data?.data?.BTCp,
        userCount: res3?.data?.data?.filter(
          (element) => element?.token == "BTCp"
        )[0]?.count,
        price: res4.data.data?.BTCp?.unitPrice,
      },
      TRQ: {
        data: [
          {
            name: "TRQ",
            amount: res.data.data?.TRQ?.amount,
            price: res.data.data?.TRQ?.price,
            ratio: "-",
            total: res.data.data?.TRQ?.amountUsd,
            rebalance: "-",
          },
        ],
        qty: res2?.data?.data?.TRQ,
        userCount: res3?.data?.data?.filter(
          (element) => element?.token == "TRQ"
        )[0]?.count,
        price: res4.data.data?.TRQ?.unitPrice,
      },

      QNT: {
        data: [
          {
            name: "QNT",
            amount: res.data.data?.QNT?.amount,
            price: res.data.data?.QNT?.price,
            ratio: "-",
            total: res.data.data?.QNT?.amountUsd,
            rebalance: "-",
          },
        ],
        qty: res2?.data?.data?.QNT,
        userCount: res3?.data?.data?.filter(
          (element) => element?.token == "QNT"
        )[0]?.count,
        price: res4.data.data?.QNT?.unitPrice,
      },
    };
    yield put(
      getFundReportsSuccess({
        data: innerData,
      })
    );
  } catch (err) {
    yield put(getFundReportsFail(err));
  }
}

function* watchTotalResultsFee() {
  yield takeLatest(GET_TOTAL_RESULTS_FEE_REQUEST, totalResultsFee);
}

function* totalResultsFee(action) {
  try {
    const res = yield call(apiCall, "get", "admin/fee/report");
    let result = res.data.data;
    const logos = [
      "https://dow.capital/static/media/c5icon.a4fc3d13b355bfc93b0f.png",
      "https://dow.capital/static/media/btcicon.f369eb135dd8bed28e0d.png",
      "https://dow.capital/static/media/tradeicon.300070758f7cd253ff5f.png",
      "https://dow.capital/static/media/arbitrageicon.b534faf6f8c6f6c48dd5.png",
      "https://dow.capital/static/media/stakingicon.f82205d14240d22c0019.png",
      "https://dow.capital/static/media/liqicon.4a3d28ab4fbf88d7146f.png",
      "https://dow.capital/static/media/optionicon.5f939fd9e9a63012d64d.png",
    ];

    let innerData = [
      {
        name: "C5",
        logo: logos[0],
        mfee_per: "0",
        mfee_dollar: result.filter(
          (element) => element.fund_id == 1 && element.fee_type == "MNG"
        )[0]?.amount,
        pfee_per: "0",
        pfee_dollar: result.filter(
          (element) => element.fund_id == 1 && element.fee_type == "PRF"
        )[0]?.amount,
      },
      {
        name: "BTCp",
        logo: logos[1],
        mfee_per: "0",
        mfee_dollar: result.filter(
          (element) => element.fund_id == 2 && element.fee_type == "MNG"
        )[0]?.amount,
        pfee_per: "0",
        pfee_dollar: result.filter(
          (element) => element.fund_id == 2 && element.fee_type == "PRF"
        )[0]?.amount,
      },
      {
        name: "TRQ",
        logo: logos[2],
        mfee_per: "0",
        mfee_dollar: result.filter(
          (element) => element.fund_id == 3 && element.fee_type == "MNG"
        )[0]?.amount,
        pfee_per: "0",
        pfee_dollar: result.filter(
          (element) => element.fund_id == 3 && element.fee_type == "PRF"
        )[0]?.amount,
      },
      {
        name: "QNT",
        logo: logos[3],
        mfee_per: "0",
        mfee_dollar: result.filter(
          (element) => element.fund_id == 4 && element.fee_type == "MNG"
        )[0]?.amount,
        pfee_per: "0",
        pfee_dollar: result.filter(
          (element) => element.fund_id == 4 && element.fee_type == "PRF"
        )[0]?.amount,
      },
      {
        name: "STAKING",
        logo: logos[4],
        mfee_per: "0",
        mfee_dollar: "0",
        pfee_per: "0",
        pfee_dollar: "0",
      },
      {
        name: "LIQUIDITY",
        logo: logos[5],
        mfee_per: "0",
        mfee_dollar: "0",
        pfee_per: "0",
        pfee_dollar: "0",
      },

      {
        name: "OPTION",
        logo: logos[6],
        mfee_per: "0",
        mfee_dollar: "0",
        pfee_per: "0",
        pfee_dollar: "0",
      },
    ];
    yield put(
      getTotalResultsFeeSuccess({
        data: innerData,
      })
    );
  } catch (err) {
    yield put(getTotalResultsFeeFail(err));
  }
}

function* watchGetEmergencyContacts() {
  yield takeLatest(GET_EMERGENCY_CONTACTS_REQUEST, getEmergencyContacts);
}

function* getEmergencyContacts(action) {
  try {
    let filters = action.payload;

    const res = yield call(
      apiCall,
      "get",
      "emergency-contact/admin?page=1&limit=100&sort=DESC"
    );
    let result = res.data.data;

    yield put(
      getEmergencyContactsSuccess({
        data: result,
      })
    );
  } catch (err) {
    console.log(err);
    yield put(getEmergencyContactsFail(err));
  }
}

export default function* adminSaga() {
  yield all([
    fork(watchGetTransactions),
    fork(watchGetFees),
    fork(watchGetDeposits),
    fork(watchGetUsers),
    fork(watchGetPartners),
    fork(watchGetUserById),
    fork(watchGetTransactionById),
    fork(watchApproveTransaction),
    fork(watchCancelTransaction),
    fork(watchApproveFee),
    fork(watchUpdateUserAdmin),
    fork(watchTotalSupplyChartData),
    fork(watchTotalUserChartData),
    fork(watchApproveCount),
    fork(watchTodayInsights),
    fork(watchTotalUsers),
    fork(watchAumData),
    fork(watchGeneralChartData),
    fork(watchAllGeneralChartData),
    fork(watchGetFundReports),
    fork(watchTotalResultsFee),
    fork(watchApprovePartnerFee),
    fork(watchInternalTransfer),
    fork(watchBnbTraderBuy),
    fork(watchBnbTraderSell),
    fork(watchBnbTraderBalance),
    fork(watchGetCensoredUsers),
    fork(watchGetEmergencyContacts),
  ]);
}
