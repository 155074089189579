// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.f_modal-userdetails {
  display: flex;
  flex-direction: row;
  gap: 20px;
  justify-content: space-between;
}

.modal-content {
  background-color: var(--background-secondary) !important;
}

.DraftEditor-editorContainer {
  background-color: white !important;
}

.f_modal-userdetails div:first-child {
  height: -moz-fit-content;
  height: fit-content;
}

input[type=file] {
  color: transparent;
}`, "",{"version":3,"sources":["webpack://./src/components/FNewsDetailsModal/FNewsDetailsModal.scss"],"names":[],"mappings":"AACE;EACE,aAAA;EACA,mBAAA;EACA,SAAA;EACA,8BAAA;AAAJ;;AAIA;EACE,wDAAA;AADF;;AAIA;EACE,kCAAA;AADF;;AAIA;EACE,wBAAA;EAAA,mBAAA;AADF;;AAIA;EACE,kBAAA;AADF","sourcesContent":[".f_modal {\n  &-userdetails {\n    display: flex;\n    flex-direction: row;\n    gap: 20px;\n    justify-content: space-between;\n  }\n}\n\n.modal-content {\n  background-color: var(--background-secondary) !important;\n}\n\n.DraftEditor-editorContainer {\n  background-color: white !important;\n}\n\n.f_modal-userdetails div:first-child {\n  height: fit-content;\n}\n\ninput[type='file'] {\n  color: transparent;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
