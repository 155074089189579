import * as actions from "../../actions";

const initialState = {
  wallet: [],
  balances: 0,
  createWalletResult: [],
  depositWalletResult: [],
  withdrawWalletResult: null,
  loading: false,
  loadingBalance: false,
  error: null,
  postSuccess: null,
  fundTableData: null,
};

const wallet = (state = initialState, action) => {
  switch (action.type) {
    case actions.CREATE_NEW_WALLET_REQUEST:
      return {
        ...state,
        wallet: [],
        balances: 0,
        createWalletResult: [],
        loading: true,
        error: null,
        postSuccess: null,
      };
    case actions.CREATE_NEW_WALLET_SUCCESS:
      return {
        ...state,
        wallet: [],
        balances: 0,
        createWalletResult: [action.payload.data],
        loading: false,
        error: null,
        postSuccess: true,
      };
    case actions.CREATE_NEW_WALLET_FAIL:
      return {
        ...state,
        wallet: [],
        balances: 0,
        createWalletResult: [],
        loading: false,
        error: action.payload,
        postSuccess: null,
      };
    case actions.GET_BALANCE_BY_ID_REQUEST:
      return {
        ...state,
        balances: 0,
        loadingBalance: true,
        error: null,
        postSuccess: null,
      };
    case actions.GET_BALANCE_BY_ID_SUCCESS:
      return {
        ...state,
        balances: action.payload.data,
        loadingBalance: false,
        error: null,
        postSuccess: null,
      };
    case actions.GET_BALANCE_BY_ID_FAIL:
      return {
        ...state,
        balances: 0,
        loadingBalance: false,
        error: action.payload,
      };

    case actions.GET_WALLET_BY_ID_REQUEST:
      return {
        ...state,
        wallet: [],
        balances: 0,
        createWalletResult: [],
        loading: true,
        error: null,
        postSuccess: null,
      };
    case actions.GET_WALLET_BY_ID_SUCCESS:
      return {
        ...state,
        wallet: [...action.payload.data],
        createWalletResult: [],
        balances: 0,
        loading: false,
        error: null,
      };
    case actions.GET_WALLET_BY_ID_FAIL:
      return {
        ...state,
        wallet: [],
        createWalletResult: [],
        loading: false,
        error: action.payload,
        postSuccess: null,
        balances: 0,
      };

    case actions.POST_DEPOSIT_WALLET_REQUEST:
      return {
        ...state,
        depositWalletResult: [],
        loading: true,
        error: null,
      };
    case actions.POST_DEPOSIT_WALLET_SUCCESS:
      return {
        ...state,
        depositWalletResult: [action.payload.data],
        loading: false,
        error: null,
      };
    case actions.POST_DEPOSIT_WALLET_FAIL:
      return {
        ...state,
        depositWalletResult: [],
        loading: false,
        error: action.payload,
      };

    case actions.POST_WITHDRAW_WALLET_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
        withdrawWalletResult: null,
      };
    case actions.POST_WITHDRAW_WALLET_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        withdrawWalletResult: action.payload.status,
      };
    case actions.POST_WITHDRAW_WALLET_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
        withdrawWalletResult: action.payload.status,
      };

    case actions.GET_FUND_TABLE_DATA_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
        fundTableData: null,
      };
    case actions.GET_FUND_TABLE_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        fundTableData: action.payload.data,
      };
    case actions.GET_FUND_TABLE_DATA_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
        fundTableData: null,
      };

    default:
      return state;
  }
};

export default wallet;
