// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.f_newsdetails {
  width: 100%;
}
.f_newsdetails-title {
  margin-top: 24px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 10px;
}
.f_newsdetails-title h1 {
  font-size: 24px;
  font-weight: 700;
  color: var(--text-primary);
}
.f_newsdetails-title h2 {
  font-size: 16px;
  font-weight: 400;
  color: #5e718d;
  text-align: end;
}
@media (max-width: 767px) {
  .f_newsdetails-title h2 {
    text-align: start;
  }
}
.f_newsdetails-content img {
  object-fit: cover;
  width: 100%;
  height: 703.13px;
}
@media (max-width: 576px) {
  .f_newsdetails-content img {
    height: 400px !important;
  }
}
.f_newsdetails-content .public-DraftEditor-content {
  background-color: #f5f5f5;
}

.back {
  display: flex;
  min-width: 141px;
  background-color: var(--background-primary);
  border-radius: 16px;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  max-width: 141px;
  cursor: pointer;
  height: 50px;
  transition: all 0.5s;
  color: var(--text-primary);
}
.back:hover {
  background-color: var(--background-primary);
}

.f_newsdetails_container {
  margin-top: 50px;
}`, "",{"version":3,"sources":["webpack://./src/pages/MainLayout/News/NewsDetails/NewsDetails.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;AACF;AAAE;EACE,gBAAA;EACA,aAAA;EACA,8BAAA;EACA,eAAA;EACA,SAAA;AAEJ;AADI;EACE,eAAA;EACA,gBAAA;EACA,0BAAA;AAGN;AADI;EACE,eAAA;EACA,gBAAA;EACA,cAAA;EACA,eAAA;AAGN;AAFM;EALF;IAMI,iBAAA;EAKN;AACF;AAAI;EACE,iBAAA;EACA,WAAA;EACA,gBAAA;AAEN;AADM;EAJF;IAKI,wBAAA;EAIN;AACF;AADI;EACE,yBAAA;AAGN;;AAEA;EACE,aAAA;EACA,gBAAA;EACA,2CAAA;EACA,mBAAA;EACA,uBAAA;EACA,mBAAA;EACA,gBAAA;EACA,gBAAA;EACA,eAAA;EACA,YAAA;EACA,oBAAA;EACA,0BAAA;AACF;AACE;EACE,2CAAA;AACJ;;AAGA;EACE,gBAAA;AAAF","sourcesContent":[".f_newsdetails {\n  width: 100%;\n  &-title {\n    margin-top: 24px;\n    display: flex;\n    justify-content: space-between;\n    flex-wrap: wrap;\n    gap: 10px;\n    h1 {\n      font-size: 24px;\n      font-weight: 700;\n      color: var(--text-primary);\n    }\n    h2 {\n      font-size: 16px;\n      font-weight: 400;\n      color: #5e718d;\n      text-align: end;\n      @media (max-width: 767px) {\n        text-align: start;\n      }\n    }\n  }\n\n  &-content {\n    img {\n      object-fit: cover;\n      width: 100%;\n      height: 703.13px;\n      @media (max-width: 576px) {\n        height: 400px !important;\n      }\n    }\n\n    .public-DraftEditor-content {\n      background-color: #f5f5f5;\n    }\n  }\n}\n\n.back {\n  display: flex;\n  min-width: 141px;\n  background-color: var(--background-primary);\n  border-radius: 16px;\n  justify-content: center;\n  align-items: center;\n  font-weight: 500;\n  max-width: 141px;\n  cursor: pointer;\n  height: 50px;\n  transition: all 0.5s;\n  color: var(--text-primary);\n\n  &:hover {\n    background-color: var(--background-primary);\n  }\n}\n\n.f_newsdetails_container {\n  margin-top: 50px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
