import React, { useEffect, useRef, useState } from "react";
import { Container, Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
import FAccountStatus from "../../../components/FAccountStatus/FAccountStatus";
import FCardContainer from "../../../components/FCardContainer/FCardContainer";
import FFundList from "../../../components/FFundList/FFundList";
import FGuide from "../../../components/FGuide/FGuide";
import FUserDetailsModal from "../../../components/FUserDetailsModal/FUserDetailsModal";
import FNews from "../../../components/FNews/FNews";
import FSidebar from "../../../components/FSidebar/FSidebar";
import FTransactions from "../../../components/FTransactions/FTransactions";
import FWallets from "../../../components/FWallets/FWallets";
import {
  bigUserTransactionColumns,
  fundsColumns,
  newsColumns,
  sellTransactionColumns,
  transactionColumns,
  userColumns,
} from "../../../constants/columnConfigurations";
import {
  approveTransactionRequest,
  getTransactionsRequest,
  getUserByIdService,
  getUsersRequest,
} from "../../../services/admin/adminService";
import "./Transactions.scss";
import { postDepositWalletRequest } from "../../../services/wallet/walletService";
import refreshIcon from "../../../assets/icons/refresh.svg";
import FPagination from "../../../components/FPagination/FPagination";
import BootstrapTable from "react-bootstrap-table-next";
import { checkDepositWallet } from "../../../utils/util";
import paginationFactory from "react-bootstrap-table2-paginator";
import FBottombar from "../../../components/FBottombar/FBottombar";
import { getNewsRequest } from "../../../services/news/newsService";
import FNewsDetailsModal from "../../../components/FNewsDetailsModal/FNewsDetailsModal";
import {
  getFundsService,
  getUserTransactionsByIdService,
} from "../../../services/fund/fundService";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import i18next from "i18next";

const Transactions = ({ theme }) => {
  const { t } = useTranslation();

  const getLanguage = i18next.language || window.localStorage.i18nextLng;

  const userRole = localStorage.getItem("userRole");
  const [show, setShow] = useState(false);
  const [selectedCount, setSelectedCount] = useState(0);
  const [selectedRow, setSelectedRow] = useState([]);
  const [bottomShow, setBottomShow] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [userId, setUserId] = useState();
  const target = useRef(null);
  const [activeTab, setActiveTab] = useState(0);
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(1);
  let totalCount = 62;
  let pageSize = 20;

  const user = JSON.parse(localStorage.getItem("user"));
  let navigate = useNavigate();

  const { txList, loadingTxList, count } = useSelector((state) => state.fund);

  const txListFilters = {
    id: user.id,
  };

  const initialBuyTransactionsFilters = {
    id: user.id,
    limit: "20",
    page: 1,
    sort: "DESC",
  };

  useEffect(() => {
    dispatch(getUserTransactionsByIdService(initialBuyTransactionsFilters));
  }, []);

  useEffect(() => {
    dispatch(
      getUserTransactionsByIdService({
        id: user.id,
        sort: "DESC",
        limit: 20,
        page: currentPage,
      })
    );
  }, [currentPage]);

  const refreshTable = () => {
    dispatch(
      getUserTransactionsByIdService({
        id: user.id,
        sort: "DESC",
        limit: 20,
        page: currentPage,
      })
    );
  };

  const copyInfo = (text) => {
    const Toast = Swal.mixin({
      toast: true,
      position: "top-end",
      showConfirmButton: false,
      timer: 2000,
      timerProgressBar: true,
      didOpen: (toast) => {
        toast.addEventListener("mouseenter", Swal.stopTimer);
        toast.addEventListener("mouseleave", Swal.resumeTimer);
      },
    });

    Toast.fire({
      icon: "success",
      title: text + " copied to clipboard.",
    });
  };

  const rowStyleFormat = (row, rowIdx) => {
    return {
      backgroundColor:
        theme === "light"
          ? row?.status === "E"
            ? "#fee9e9"
            : row?.status === "S"
            ? "#e7f7eb"
            : row?.status === "A"
            ? "#FCF55F"
            : row?.status === "R"
            ? "#fee9e9"
            : "#FDFFDF"
          : row?.status === "E"
          ? "#fee9e9"
          : row?.status === "S"
          ? "#e7f7eb"
          : row?.status === "A"
          ? "#FCF55F"
          : row?.status === "R"
          ? "#fee9e9"
          : "#333600",
    };
  };

  const transactionColumnsConfig = bigUserTransactionColumns({
    show: show,
    setShow: setShow,
    target: target,
    setModalShow: setModalShow,
    setUserId: setUserId,
    copyInfo: copyInfo,
    language: getLanguage,
  });

  const onTableChange = (type, newState) => {
    setCurrentPage(newState.page);
  };

  return (
    <div className="fener">
      <>
        <div className="f_admin">
          <div className="f_admin-balances-container">
            <div className="f_admin-balances-container-head">
              <h1>{t("transaction_title")}</h1>
              <img onClick={refreshTable} alt="" src={refreshIcon}></img>
            </div>
            <div className="f_admin-balances-container-item">
              {loadingTxList ? (
                <Spinner animation="grow" />
              ) : (
                <BootstrapTable
                  remote={{
                    pagination: true,
                    filter: false,
                    sort: false,
                  }}
                  rowStyle={rowStyleFormat}
                  bordered={false}
                  keyField="id"
                  data={txList}
                  columns={transactionColumnsConfig}
                  //selectRow={selectRow}
                  noDataIndication={"Table is Empty"}
                  pagination={paginationFactory({
                    page: currentPage,
                    sizePerPage: pageSize,
                    totalSize: count,
                    showTotal: true, // display pagination information
                    hideSizePerPage: true, // hide the size per page dropdown
                  })}
                  onTableChange={onTableChange}
                />
              )}
            </div>
          </div>
        </div>
      </>
    </div>
  );
};

export default Transactions;
