import React from 'react';
import { Form } from 'react-bootstrap';
import './FCheckbox.scss';

const FCheckbox = ({ children, name, value, handleChange }) => {
  return (
    <Form.Check
      type="checkbox"
      id={`default-checkbox`}
      value={value}
      name={name}
      onChange={(e) => handleChange(e.target.value)}
      label={<div className="f_checkbox">{children}</div>}
    />
  );
};

export default FCheckbox;
