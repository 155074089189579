// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.arbitrage_div {
  background-color: var(--background-navbar);
  padding-bottom: 100px;
}`, "",{"version":3,"sources":["webpack://./src/pages/PublicLayout/Invest/Arbitrage/Arbitrage.scss"],"names":[],"mappings":"AAAA;EACE,0CAAA;EACA,qBAAA;AACF","sourcesContent":[".arbitrage_div {\n  background-color: var(--background-navbar);\n  padding-bottom: 100px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
