// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.f_status-header {
  display: flex;
}
.f_status-title {
  width: "100%";
}
.f_status-title h1 {
  font-weight: 500;
  font-size: 14px;
  color: var(--text-status);
}
.f_status-title h2 {
  font-size: 24px;
  font-weight: 700;
}`, "",{"version":3,"sources":["webpack://./src/components/FAccountStatus/FAccountStatus.scss"],"names":[],"mappings":"AACE;EACE,aAAA;AAAJ;AAEE;EACE,aAAA;AAAJ;AACI;EACE,gBAAA;EACA,eAAA;EACA,yBAAA;AACN;AACI;EACE,eAAA;EACA,gBAAA;AACN","sourcesContent":[".f_status {\n  &-header {\n    display: flex;\n  }\n  &-title {\n    width: '100%';\n    h1 {\n      font-weight: 500;\n      font-size: 14px;\n      color: var(--text-status);\n    }\n    h2 {\n      font-size: 24px;\n      font-weight: 700;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
