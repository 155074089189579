import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import FIcon from "../components/FIcon/FIcon";
import { checkDepositWallet, checkWithdrawWallet } from "../utils/util";
import i18next from "i18next";
export const miniUserTransactionColumns = () => [
  {
    name: "Status",
    selector: "status",
    cell: (row) =>
      row?.status === "E"
        ? "Error"
        : row?.status === "A"
        ? "Waiting for Approve"
        : row?.status === "S"
        ? "Success"
        : row?.status === "R"
        ? "Rejected"
        : "Pending",
  },
  {
    name: "Action",
    selector: "action",
  },
  {
    name: "Token",
    selector: "token",

    cell: (row) => <>{row?.token == "TUSD" ? "USD" : row?.token}</>,
  },
  {
    name: "Amount",
    selector: "amount_usd",
    cell: (row) => (
      <>
        {parseFloat(row.amount).toFixed(4)} ($
        {parseFloat(row.amount_usd).toFixed(4)})
      </>
    ),
  },
];

export const partnerColumns = () => [
  {
    name: "ID",
    selector: "id",
  },
  {
    name: "Name",
    selector: "name",
  },
  {
    name: "Surname",
    selector: "surname",
  },
  {
    name: "Email",
    selector: "email",
  },
];

export const miniFundPriceTransactionColumns = ({ language }) => [
  {
    name: language === "tr" ? "Fon" : "Fund",
    selector: "fund",
    cell: (row) => (
      <div
        style={{
          textAlign: "start",
        }}
      >
        <img
          style={{
            width: "30px",
          }}
          src={
            row.fund === "C5"
              ? "https://dow.capital/static/media/c5icon.a4fc3d13b355bfc93b0f.png"
              : row.fund === "BTCp"
              ? "https://dow.capital/static/media/btcicon.f369eb135dd8bed28e0d.png"
              : row.fund === "TRQ"
              ? "https://dow.capital/static/media/tradeicon.300070758f7cd253ff5f.png"
              : "https://dow.capital/static/media/arbitrageicon.b534faf6f8c6f6c48dd5.png"
          }
        ></img>
        {<b>{row.fund}</b>}
      </div>
    ),
  },
  {
    name: "Current",
    selector: "price",
    cell: (row) => "$" + parseFloat(row.price).toFixed(4),
  },
  {
    name: "Weekly Change",
    selector: "change",
    cell: (row) =>
      row.change >= 0 ? (
        <span
          style={{
            color: "green",
          }}
        >
          {"+" + parseFloat(row.change).toFixed(4)}
        </span>
      ) : (
        <span
          style={{
            color: "red",
          }}
        >
          {parseFloat(row.change).toFixed(4)}
        </span>
      ),
  },
];

export const bigUserTransactionColumns = ({
  show,
  setShow,
  target,
  copyInfo,
  language,
  approve,
}) => [
  {
    dataField: "transaction_id",
    text: "Transaction ID",
    formatter: (cell, row, formatExtraData) => {
      return (
        <>
          <Button
            ref={target}
            onClick={() => {
              navigator.clipboard.writeText(row?.transaction_id);
              copyInfo("TX ID");
              setShow(!show);
              setTimeout(function () {
                setShow(false);
              }, 1000);
            }}
          >
            <FIcon name="copytoclipboard" color="black" size="16" />
            {row?.transaction_id?.substring(0, 5) +
              "..." +
              row?.transaction_id?.substring(row?.transaction_id?.length - 5)}
          </Button>
        </>
      );
    },
  },

  {
    dataField: "c_date",
    text: "Date",
    formatter: (cell, row) => {
      return (
        <>
          {new Date(row?.c_date).toLocaleDateString("tr-TR", {
            year: "numeric",
            month: "long",
            day: "numeric",
            hour: "numeric",
            minute: "numeric",
            timeZone: "UTC",
          })}
        </>
      );
    },
  },
  {
    dataField: "token",
    text: "Token",
    formatter: (cell, row, formatExtraData) => {
      return <>{row?.token == "TUSD" ? "USD" : row?.token}</>;
    },
  },
  {
    dataField: "amount_usd",
    text: "Amount USD",
    headerStyle: (colum, colIndex) => {
      return { width: "130px" };
    },
    formatter: (cell, row, formatExtraData) => {
      return <>{row?.amount_usd ? parseFloat(row?.amount_usd) : "-"}</>;
    },
  },
  {
    dataField: "amount",
    text: "Amount Token",
    headerStyle: (colum, colIndex) => {
      return { width: "130px" };
    },
    formatter: (cell, row, formatExtraData) => {
      return <>{parseFloat(row?.amount)}</>;
    },
  },
  {
    dataField: "status",
    text: "Status",
    formatter: (cell, row, formatExtraData) => {
      return (
        <>
          {row?.status === "Q"
            ? "Processing"
            : row?.status === "R"
            ? "Rejected"
            : row?.status === "S"
            ? "Success"
            : row?.status === "A"
            ? "Waiting for Approve"
            : "Error"}
        </>
      );
    },
  },
  {
    dataField: "action",
    text: "Action",
  },

  {
    dataField: "from",
    text: "From",
    headerAlign: "start",
    align: "center",
    headerStyle: (colum, colIndex) => {
      return { width: "160px" };
    },

    formatExtraData: { target, show, setShow, copyInfo },
    formatter: (cell, row, formatExtraData) => {
      return (
        <>
          <Button
            ref={target}
            onClick={() => {
              navigator.clipboard.writeText(row?.from);
              copyInfo("From address");
              setShow(!show);
              setTimeout(function () {
                setShow(false);
              }, 1000);
            }}
          >
            <FIcon name="copytoclipboard" color="black" size="16" />
            {row?.from?.substring(0, 5) +
              "..." +
              row?.from?.substring(row?.from?.length - 5)}
          </Button>
        </>
      );
    },
  },

  /* 
  {
    dataField: 'transaction_id',
    text: 'Actions',
    headerAlign: 'start',
    align: 'center',
    headerStyle: (colum, colIndex) => {
      return { width: '140px' };
    },
    formatter: (cell, row, formatExtraData) => {
      return (
        <Button onClick={() => approve(row.transaction_id)} className="approve">
          <FIcon name="infoFilled" color="white" size="16" />
          <b className="text-white">Approve</b>
        </Button>
      );
    },
  },
  */
];

export const transactionColumns = ({
  show,
  setShow,
  target,
  copyInfo,
  language,
  approve,
  cancel,
  t,
}) => [
  {
    dataField: "id",
    text: t("t_tx_id"),
  },
  {
    dataField: "account_id",
    text: t("t_user_id"),
  },
  {
    dataField: "user_name",
    text: t("t_name_surname"),
    formatter: (cell, row, formatExtraData) => {
      return <>{row?.user_name + " " + row?.surname}</>;
    },
  },
  {
    dataField: "token",
    text: t("t_fund_token"),
    formatter: (cell, row, formatExtraData) => {
      return <>{row.token}</>;
    },
  },
  {
    dataField: "status",
    text: t("t_status"),
    formatter: (cell, row, formatExtraData) => {
      return (
        <>
          {row?.status === "Q" ? (
            <span
              style={{
                color: "gold",
              }}
            >
              <b>{t("t_processing")}</b>
            </span>
          ) : row?.status === "R" ? (
            <span
              style={{
                color: "red",
              }}
            >
              <b>{t("t_rejected")}</b>
            </span>
          ) : row?.status === "S" ? (
            <span
              style={{
                color: "green",
              }}
            >
              <b>{t("t_success")}</b>
            </span>
          ) : row?.status === "A" ? (
            <span
              style={{
                color: "purple",
              }}
            >
              <b>{t("t_waiting")}</b>
            </span>
          ) : (
            <span
              style={{
                color: "red",
              }}
            >
              <b>{t("t_error")}</b>
            </span>
          )}
        </>
      );
    },
  },
  {
    dataField: "amount_usd",
    text: t("t_amount_usd"),
    formatter: (cell, row, formatExtraData) => {
      return <>{parseFloat(row?.amount_usd)}</>;
    },
  },
  {
    dataField: "amount",
    text: t("t_amount_token"),
    formatter: (cell, row, formatExtraData) => {
      return <>{parseFloat(row?.amount)}</>;
    },
  },
  {
    dataField: "unit_price",
    text: t("t_unit_price"),

    formatter: (cell, row, formatExtraData) => {
      return <>{parseFloat(row?.unit_price)}</>;
    },
  },
  {
    dataField: "c_date",
    text: t("t_date"),
    headerAlign: "center",
    formatter: (cell, row, formatExtraData) => {
      const getLanguage = i18next.language || window.localStorage.i18nextLng;

      return (
        <>
          {new Date(row?.c_date).toLocaleDateString(
            getLanguage == "tr" ? "tr-TR" : undefined,
            {
              year: "numeric",
              month: "long",
              day: "numeric",
              hour: "numeric",
              minute: "numeric",
              timeZone: "UTC",
            }
          )}
        </>
      );
    },
  },
  {
    dataField: "from",
    text: t("t_from"),
    headerAlign: "center",
    align: "center",
    headerStyle: (colum, colIndex) => {
      return { width: "160px" };
    },

    formatExtraData: { target, show, setShow, copyInfo },
    formatter: (cell, row, formatExtraData) => {
      return (
        <>
          <Button
            ref={target}
            onClick={() => {
              navigator.clipboard.writeText(row?.from);
              copyInfo("From address");
              setShow(!show);
              setTimeout(function () {
                setShow(false);
              }, 1000);
            }}
          >
            <FIcon name="copytoclipboard" color="black" size="16" />
            {row?.from?.substring(0, 5) +
              "..." +
              row?.from?.substring(row?.from?.length - 5)}
          </Button>
        </>
      );
    },
  },

  /* 
  {
    dataField: 'transaction_id',
    text: 'Actions',
    headerAlign: 'start',
    align: 'center',
    headerStyle: (colum, colIndex) => {
      return { width: '140px' };
    },
    formatter: (cell, row, formatExtraData) => {
      return (
        <Button onClick={() => approve(row.transaction_id)} className="approve">
          <FIcon name="infoFilled" color="white" size="16" />
          <b className="text-white">Approve</b>
        </Button>
      );
    },
  },
  
  */

  {
    dataField: "transaction_id",
    headerAlign: "center",
    align: "center",
    headerStyle: (colum, colIndex) => {
      return { width: "100px" };
    },
    formatter: (cell, row, formatExtraData) => {
      return (
        <Button
          disabled={row.status != "A" ? true : false}
          onClick={() => approve(row.transaction_id)}
          className="approve"
        >
          <svg
            width="13"
            height="11"
            viewBox="0 0 13 11"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M4.24407 10.3998L-0.358154 5.83979L0.792401 4.69979L4.24407 8.11979L11.652 0.779785L12.8026 1.91979L4.24407 10.3998Z"
              fill="white"
            />
          </svg>
          <b>{t("t_approve")}</b>
        </Button>
      );
    },
  },
  {
    dataField: "transaction_id",
    headerAlign: "center",
    align: "center",
    width: "100px",
    headerStyle: (colum, colIndex) => {
      return { width: "50px" };
    },
    formatter: (cell, row, formatExtraData) => {
      return (
        <Button
          disabled={row.status != "A" ? true : false}
          onClick={() => cancel(row.transaction_id)}
          className="cancel"
        >
          <svg
            width="12"
            height="12"
            viewBox="0 0 12 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0.994708 10.1181L4.70515 6.05818L0.645272 2.34774L1.88209 0.994449L5.94197 4.70489L9.65241 0.645013L11.0057 1.88183L7.29526 5.94171L11.3551 9.65215L10.1183 11.0054L6.05844 7.295L2.348 11.3549L0.994708 10.1181Z"
              fill="white"
            />
          </svg>
        </Button>
      );
    },
  },
];

export const feesColumns = ({ type, approveFee, t }) => [
  {
    dataField: "account_id",
    text: t("t_user_id"),
  },
  {
    dataField: "name",
    text: t("t_name"),
  },
  {
    dataField: "surname",
    text: t("t_surname"),
  },

  {
    dataField: "email",
    text: "Email",
    headerStyle: (colum, colIndex) => {
      return { width: "240px" };
    },
  },
  {
    dataField: "fund_id",
    text: t("t_fund"),
    formatter: (cell, row, formatExtraData) => {
      return (
        <>
          {row?.fund_id === 1
            ? "C5"
            : row?.fund_id === 2
            ? "BTCp"
            : row?.fund_id === 3
            ? "TRQ"
            : row?.fund_id === 4
            ? "QNT"
            : row?.fund_id === 5
            ? "STA"
            : row?.fund_id === 6
            ? "LIQ"
            : row?.fund_id === 7
            ? "OPT"
            : ""}
        </>
      );
    },
  },
  {
    dataField: "amount",
    text: t("t_commission_usd"),
    formatter: (cell, row, formatExtraData) => {
      return <>{parseFloat(row?.amount)}</>;
    },
  },
  {
    dataField: "fee_type",
    text: t("t_actions"),
    headerAlign: "start",
    align: "center",
    headerStyle: (colum, colIndex) => {
      return { width: "140px" };
    },
    formatter: (cell, row, formatExtraData) => {
      return (
        <Button
          //disabled={row.status != "A" ? true : false}
          onClick={() => approveFee(row?.fund_id, row?.account_id, type)}
          className="approve"
        >
          <FIcon name="infoFilled" color="white" size="16" />
          <b>{t("t_approve")}</b>
        </Button>
      );
    },
  },
];

export const sellTransactionColumns = ({
  show,
  setShow,
  target,
  copyInfo,
  language,
  approve,
  cancel,
  t,
}) => [
  {
    dataField: "id",
    text: t("t_tx_id"),
    headerAlign: "center",
  },
  {
    dataField: "account_id",
    text: t("t_user_id"),
    headerAlign: "center",
  },
  {
    dataField: "user_name",
    text: t("t_name_surname"),
    headerAlign: "center",
    formatter: (cell, row, formatExtraData) => {
      return <>{row?.user_name + " " + row?.surname}</>;
    },
  },
  {
    dataField: "status",
    text: t("t_status"),
    headerAlign: "center",
    formatter: (cell, row, formatExtraData) => {
      return (
        <>
          {row?.status === "Q" ? (
            <span
              style={{
                color: "gold",
              }}
            >
              <b>{t("t_processing")}</b>
            </span>
          ) : row?.status === "R" ? (
            <span
              style={{
                color: "red",
              }}
            >
              <b>{t("t_rejected")}</b>
            </span>
          ) : row?.status === "S" ? (
            <span
              style={{
                color: "green",
              }}
            >
              <b>{t("t_success")}</b>
            </span>
          ) : row?.status === "A" ? (
            <span
              style={{
                color: "purple",
              }}
            >
              <b>{t("t_waiting")}</b>
            </span>
          ) : (
            <span
              style={{
                color: "red",
              }}
            >
              <b>{t("t_error")}</b>
            </span>
          )}
        </>
      );
    },
  },
  {
    dataField: "token",
    text: t("t_fund_token"),
    headerAlign: "center",
    formatter: (cell, row, formatExtraData) => {
      return <>{row.token}</>;
    },
  },
  {
    dataField: "amount_usd",
    text: t("t_gross_usd"),
    headerAlign: "center",
    formatter: (cell, row, formatExtraData) => {
      return (
        <>
          {(
            parseFloat(row?.amount_usd) +
            parseFloat(row?.commission_fee) +
            parseFloat(row?.performance_fee)
          ).toFixed(4)}
        </>
      );
    },
  },
  {
    dataField: "name_en",
    text: t("t_net_usd"),
    headerAlign: "center",
    formatter: (cell, row, formatExtraData) => {
      return <>{parseFloat(row?.amount_usd).toFixed(4)}</>;
    },
  },
  {
    dataField: "amount",
    text: t("t_amount_token"),
    headerAlign: "center",
    headerStyle: (colum, colIndex) => {
      return { width: "120px" };
    },
    formatter: (cell, row, formatExtraData) => {
      return <>{parseFloat(row?.amount).toFixed(4)}</>;
    },
  },
  {
    dataField: "c_date",
    text: t("t_date"),
    headerAlign: "center",
    formatter: (cell, row, formatExtraData) => {
      return (
        <>
          {new Date(row?.c_date).toLocaleDateString("tr-TR", {
            year: "numeric",
            month: "long",
            day: "numeric",
            hour: "numeric",
            minute: "numeric",
            timeZone: "UTC",
          })}
        </>
      );
    },
  },
  {
    dataField: "from",
    text: t("t_from"),
    headerStyle: (colum, colIndex) => {
      return { width: "160px" };
    },

    formatExtraData: { target, show, setShow, copyInfo },
    formatter: (cell, row, formatExtraData) => {
      return (
        <>
          <Button
            ref={target}
            onClick={() => {
              navigator.clipboard.writeText(row?.from);
              copyInfo("From address");
              setShow(!show);
              setTimeout(function () {
                setShow(false);
              }, 1000);
            }}
          >
            <FIcon name="copytoclipboard" color="black" size="16" />
            {row?.from?.substring(0, 5) +
              "..." +
              row?.from?.substring(row?.from?.length - 5)}
          </Button>
        </>
      );
    },
  },

  {
    dataField: "transaction_id",
    headerAlign: "center",
    align: "center",
    headerStyle: (colum, colIndex) => {
      return { width: "103px" };
    },
    formatter: (cell, row, formatExtraData) => {
      return (
        <Button
          disabled={row.status != "A" ? true : false}
          onClick={() => approve(row.transaction_id)}
          className="approve"
        >
          <svg
            width="13"
            height="11"
            viewBox="0 0 13 11"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M4.24407 10.3998L-0.358154 5.83979L0.792401 4.69979L4.24407 8.11979L11.652 0.779785L12.8026 1.91979L4.24407 10.3998Z"
              fill="white"
            />
          </svg>{" "}
          <b>{t("t_approve")}</b>
        </Button>
      );
    },
  },
  {
    dataField: "transaction_id",
    headerAlign: "center",
    align: "center",
    width: "100px",
    headerStyle: (colum, colIndex) => {
      return { width: "50px" };
    },
    formatter: (cell, row, formatExtraData) => {
      return (
        <Button
          disabled={row.status != "A" ? true : false}
          onClick={() => cancel(row.transaction_id)}
          className="cancel"
        >
          <svg
            width="12"
            height="12"
            viewBox="0 0 12 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0.994464 10.1181L4.70491 6.05818L0.645028 2.34774L1.88184 0.994449L5.94172 4.70489L9.65216 0.645013L11.0055 1.88183L7.29501 5.94171L11.3549 9.65215L10.1181 11.0054L6.0582 7.295L2.34776 11.3549L0.994464 10.1181Z"
              fill="white"
            />
          </svg>
        </Button>
      );
    },
  },
];

export const withdrawTransactionColumns = ({
  show,
  setShow,
  target,
  copyInfo,
  language,
  approve,
  t,
}) => [
  {
    dataField: "id",
    text: t("t_tx_id"),
  },
  {
    dataField: "account_id",
    text: t("t_user_id"),
  },
  {
    dataField: "user_name",
    text: t("t_name_surname"),
    formatter: (cell, row, formatExtraData) => {
      return <>{row?.user_name + " " + row?.surname}</>;
    },
  },
  {
    dataField: "amount",
    text: t("t_amount_usd"),
    formatter: (cell, row, formatExtraData) => {
      return <>{parseFloat(row?.amount_usd)}</>;
    },
  },
  {
    dataField: "transaction_fee",
    text: t("t_tx_fee"),
    formatter: (cell, row, formatExtraData) => {
      return <>{parseFloat(row?.transaction_fee)}</>;
    },
  },
  {
    dataField: "status",
    text: t("t_status"),
    formatter: (cell, row, formatExtraData) => {
      return (
        <>
          {row?.status === "Q" ? (
            <span
              style={{
                color: "gold",
              }}
            >
              <b>{t("t_processing")}</b>
            </span>
          ) : row?.status === "R" ? (
            <span
              style={{
                color: "red",
              }}
            >
              <b>{t("t_rejected")}</b>
            </span>
          ) : row?.status === "S" ? (
            <span
              style={{
                color: "green",
              }}
            >
              <b>{t("t_success")}</b>
            </span>
          ) : row?.status === "A" ? (
            <span
              style={{
                color: "purple",
              }}
            >
              <b>{t("t_waiting")}</b>
            </span>
          ) : (
            <span
              style={{
                color: "red",
              }}
            >
              <b>{t("t_error")}</b>
            </span>
          )}
        </>
      );
    },
  },
  {
    dataField: "c_date",
    text: t("t_date"),
    headerAlign: "center",
    formatter: (cell, row, formatExtraData) => {
      return (
        <>
          {new Date(row?.c_date).toLocaleDateString("tr-TR", {
            year: "numeric",
            month: "long",
            day: "numeric",
            hour: "numeric",
            minute: "numeric",
            timeZone: "UTC",
          })}
        </>
      );
    },
  },
  {
    dataField: "from",
    text: t("t_from"),
    headerAlign: "center",
    align: "center",
    headerStyle: (colum, colIndex) => {
      return { width: "160px" };
    },

    formatExtraData: { target, show, setShow, copyInfo },
    formatter: (cell, row, formatExtraData) => {
      return (
        <>
          <Button
            ref={target}
            onClick={() => {
              navigator.clipboard.writeText(row?.from);
              copyInfo("From address");
              setShow(!show);
              setTimeout(function () {
                setShow(false);
              }, 1000);
            }}
          >
            <FIcon name="copytoclipboard" color="black" size="16" />
            {row?.from?.substring(0, 5) +
              "..." +
              row?.from?.substring(row?.from?.length - 5)}
          </Button>
        </>
      );
    },
  },
  {
    dataField: "transaction_id",
    text: "Actions",
    headerAlign: "start",
    align: "center",
    headerStyle: (colum, colIndex) => {
      return { width: "140px" };
    },
    formatter: (cell, row, formatExtraData) => {
      return (
        <Button
          onClick={() => approve(row.transaction_id)}
          className="approve"
          disabled={row?.status !== "A"}
        >
          <FIcon name="infoFilled" color="white" size="16" />
          <b>Approve</b>
        </Button>
      );
    },
  },
];

export const depositTransactionColumns = ({
  show,
  setShow,
  target,
  copyInfo,
  language,
  approve,
  t,
}) => [
  {
    dataField: "id",
    text: t("t_tx_id"),
  },
  {
    dataField: "account_id",
    text: t("t_user_id"),
  },
  {
    dataField: "amount",
    text: t("t_amount_usd"),
    formatter: (cell, row, formatExtraData) => {
      return <>{parseFloat(row?.amount_usd)}</>;
    },
  },
  {
    dataField: "status",
    text: t("t_status"),
    formatter: (cell, row, formatExtraData) => {
      return (
        <>
          {row?.status === "R" ? (
            <span
              style={{
                color: "red",
              }}
            >
              <b>{t("t_rejected")}</b>
            </span>
          ) : row?.status === "A" ? (
            <span
              style={{
                color: "green",
              }}
            >
              <b>{t("t_success")}</b>
            </span>
          ) : (
            <span
              style={{
                color: "red",
              }}
            >
              <b>{t("t_error")}</b>
            </span>
          )}
        </>
      );
    },
  },
  {
    dataField: "c_date",
    text: t("date"),
    headerAlign: "center",
    formatter: (cell, row, formatExtraData) => {
      return (
        <>
          {new Date(row?.c_date).toLocaleDateString("tr-TR", {
            year: "numeric",
            month: "long",
            day: "numeric",
            hour: "numeric",
            minute: "numeric",
            timeZone: "UTC",
          })}
        </>
      );
    },
  },
  {
    dataField: "user_address",
    text: t("t_from"),
    headerAlign: "center",
    align: "center",
    headerStyle: (colum, colIndex) => {
      return { width: "160px" };
    },

    formatExtraData: { target, show, setShow, copyInfo },
    formatter: (cell, row, formatExtraData) => {
      return (
        <>
          <Button
            ref={target}
            onClick={() => {
              navigator.clipboard.writeText(row?.user_address);
              copyInfo("From address");
              setShow(!show);
              setTimeout(function () {
                setShow(false);
              }, 1000);
            }}
          >
            <FIcon name="copytoclipboard" color="black" size="16" />
            {row?.user_address?.substring(0, 5) +
              "..." +
              row?.user_address?.substring(row?.user_address?.length - 5)}
          </Button>
        </>
      );
    },
  },
  /*
  {
    dataField: 'transaction_id',
    text: 'Actions',
    headerAlign: 'start',
    align: 'center',
    headerStyle: (colum, colIndex) => {
      return { width: '140px' };
    },
    formatter: (cell, row, formatExtraData) => {
      return (
        <Button onClick={() => approve(row.transaction_id)} className="approve">
          <FIcon name="infoFilled" color="white" size="16" />
          <b>Approve</b>
        </Button>
      );
    },
  },
*/
];

export const userColumns = ({
  addWallet,
  show,
  setShow,
  target,
  setUserModalShow,
  setUserId,
  copyInfo,
  t,
}) => [
  {
    dataField: "name",
    text: t("t_name_surname"),
    formatter: (cell, row, formatExtraData) => {
      return (
        <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
          <div
            style={{
              width: "53px",
              height: "53px",
              background: "#77838F",
              borderRadius: "100%",
              color: "white",
              fontSize: "20px",
              fontWeight: "700",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {row?.name?.charAt(0) + row?.surname?.charAt(0)}
          </div>
          <div style={{ fontWeight: "500" }}>
            {row?.name + " " + row?.surname}
          </div>
        </div>
      );
    },
  },
  {
    dataField: "account_id",
    text: t("t_user_id"),
  },
  {
    dataField: "tusd_balance",
    text: t("t_total_balance"),
    formatter: (cell, row, formatExtraData) => {
      return (
        <>
          {(
            parseFloat(row?.tusd_balance) +
            parseFloat(row?.c5_balance) +
            parseFloat(row?.btc_balance) +
            parseFloat(row?.trq_balance) +
            parseFloat(row?.qnt_balance)
          ).toFixed(4)}
        </>
      );
    },
  },
  {
    dataField: "c5_balance",
    text: t("t_ceffu_balance"),
    formatter: (cell, row, formatExtraData) => {
      return <>{parseFloat(row?.ceffu_balance).toFixed(2)}</>;
    },
  },
  {
    dataField: "is_admin",
    text: t("t_deposit_address"),
    align: "center",
    formatExtraData: { target, show, setShow, copyInfo, addWallet },
    formatter: (cell, row, formatExtraData) => {
      if (checkDepositWallet(row?.user_addresses)) {
        return (
          <div className="d-flex align-items-center justify-content-center">
            <Button
              ref={target}
              onClick={() => {
                navigator.clipboard.writeText(
                  checkDepositWallet(row?.user_addresses)
                );
                copyInfo("Wallet address");
                setShow(!show);
                setTimeout(function () {
                  setShow(false);
                }, 1000);
              }}
            >
              {checkDepositWallet(row?.user_addresses)?.substring(0, 5) +
                "..." +
                checkDepositWallet(row?.user_addresses)?.substring(
                  checkDepositWallet(row?.user_addresses)?.length - 2
                )}

              <FIcon name="copytoclipboard" color="#4169E1" size="16" />
            </Button>
          </div>
        );
      } else {
        return (
          <div className="d-flex align-items-center justify-content-center">
            <Button className="addbutton" onClick={() => addWallet(row.id)}>
              <FIcon name="plusFilled" color="white" size="16" />
              <b>Add Wallet</b>
            </Button>
          </div>
        );
      }
    },
  },
  {
    text: t("t_withdraw_address"),
    dataField: "user_addresses",
    align: "center",

    formatExtraData: { target, show, setShow, copyInfo },
    formatter: (cell, row, formatExtraData) => {
      if (checkWithdrawWallet(row?.user_addresses)) {
        return (
          <div className="d-flex align-items-center justify-content-center">
            <Button
              ref={target}
              onClick={() => {
                navigator.clipboard.writeText(
                  checkWithdrawWallet(row?.user_addresses)
                );
                copyInfo("Wallet address");
                setShow(!show);
                setTimeout(function () {
                  setShow(false);
                }, 1000);
              }}
            >
              {checkWithdrawWallet(row?.user_addresses)?.substring(0, 5) +
                "..." +
                checkWithdrawWallet(row?.user_addresses)?.substring(
                  checkWithdrawWallet(row?.user_addresses)?.length - 2
                )}

              <FIcon name="copytoclipboard" color="#4169E1" size="16" />
            </Button>
          </div>
        );
      } else {
        return <span>No info</span>;
      }
    },
  },
  {
    dataField: "u_user_id",
    text: t("t_actions"),
    align: "center",
    headerStyle: (colum, colIndex) => {
      return { width: "70px" };
    },
    formatExtraData: { setUserModalShow, setUserId },
    formatter: (cell, row, formatExtraData) => {
      return (
        <Button
          onClick={() => {
            setUserId(row?.account_id);
            setUserModalShow(true);
          }}
        >
          <FIcon name="edit" color="black" size="20" />
        </Button>
      );
    },
  },
];

export const partnersColumns = ({
  addWallet,
  show,
  setShow,
  target,
  setPartnerModalShow,
  setUserId,
  copyInfo,
  setPartnerAccounts,
  t,
  payFee,
}) => [
  {
    dataField: "name",
    text: t("t_name_surname"),
    formatter: (cell, row, formatExtraData) => {
      return (
        <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
          <div
            style={{
              width: "53px",
              height: "53px",
              background: "#77838F",
              borderRadius: "100%",
              color: "white",
              fontSize: "20px",
              fontWeight: "700",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {row?.name?.charAt(0) + row?.surname?.charAt(0)}
          </div>
          <div style={{ fontWeight: "500" }}>
            {row?.name + " " + row?.surname}
          </div>
        </div>
      );
    },
  },
  {
    dataField: "id",
    text: t("t_user_id"),
  },
  {
    dataField: "value",
    text: t("t_partner_count"),
    formatter: (cell, row, formatExtraData) => {
      return <span>{row?.accounts?.length}</span>;
    },
  },
  {
    dataField: "partner_commission",
    text: t("t_partner_commission"),
    formatter: (cell, row, formatExtraData) => {
      return <span>{parseFloat(row?.partner_commission)?.toFixed(2)}</span>;
    },
  },
  {
    dataField: "feeTotal",
    text: t("t_partner_total_commission"),
    formatter: (cell, row, formatExtraData) => {
      return (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {row?.feeTotal ? (
            <span>{parseFloat(row?.feeTotal)?.toFixed(2)}</span>
          ) : (
            0
          )}

          {row?.feeTotal && row?.feeTotal >= 1 && (
            <Button
              onClick={() => {
                payFee(row?.id);
              }}
            >
              <FIcon name="wallet" color="green" size="20" />
            </Button>
          )}
        </div>
      );
    },
  },
  // {
  //   dataField: "referral",
  //   text: t("t_referral_code"),
  // },
  {
    dataField: "u_user_id",
    text: t("t_actions"),
    align: "center",
    headerStyle: (colum, colIndex) => {
      return { width: "70px" };
    },
    formatExtraData: { setPartnerModalShow, setUserId },
    formatter: (cell, row, formatExtraData) => {
      return (
        <Button
          onClick={() => {
            setUserId(row?.id);
            setPartnerModalShow(true);
            setPartnerAccounts(row?.accounts);
          }}
        >
          <FIcon name="edit" color="black" size="20" />
        </Button>
      );
    },
  },
];

export const newsColumns = ({
  addWallet,
  show,
  setShow,
  target,
  setNewsModalShow,
  setNewsDeleteModalShow,
  setNewsId,
  copyInfo,
  t,
}) => [
  {
    dataField: "id",
    text: t("t_news_id"),
    headerStyle: (colum, colIndex) => {
      return { width: "70px" };
    },
  },
  {
    dataField: "title",
    text: t("t_title"),
  },
  {
    dataField: "image",
    text: t("t_actions"),
    align: "center",
    headerStyle: (colum, colIndex) => {
      return { width: "70px" };
    },
    formatExtraData: { setNewsModalShow, setNewsId },
    formatter: (cell, row, formatExtraData) => {
      return (
        <div style={{ display: "flex", alignItems: "center" }}>
          <Button
            onClick={() => {
              setNewsId(row?.id);
              setNewsModalShow(true);
            }}
          >
            <FIcon name="edit" color="black" size="20" />
          </Button>
          <Button
            style={{ padding: 0, margin: 0 }}
            onClick={() => {
              setNewsId(row?.id);
              setNewsDeleteModalShow(true);
            }}
          >
            <FIcon name="remove" color="black" size="28" />
          </Button>
        </div>
      );
    },
  },
];

export const fundsColumns = ({
  addWallet,
  show,
  setShow,
  target,
  setFundsModalShow,
  setFundId,
  copyInfo,
  t,
}) => [
  {
    dataField: "id",
    text: t("t_fund_id"),
  },
  {
    dataField: "name",
    text: t("t_fund_name"),
  },
  {
    dataField: "token",
    text: t("t_fund_token"),
  },
  {
    dataField: "performance_fee",
    text: t("t_performance_fee"),
    formatter: (cell, row, formatExtraData) => {
      return <>{row?.performance_fee}%</>;
    },
  },
  {
    dataField: "management_fee",
    text: t("t_management_fee"),
    formatter: (cell, row, formatExtraData) => {
      return <>{row?.management_fee}%</>;
    },
  },
  {
    dataField: "divider",
    text: t("t_divider"),
  },
  {
    dataField: "value",
    text: t("t_fund_value"),
  },
  {
    dataField: "slogan",
    text: t("t_actions"),
    align: "center",
    headerStyle: (colum, colIndex) => {
      return { width: "70px" };
    },
    formatExtraData: { setFundsModalShow, setFundId },
    formatter: (cell, row, formatExtraData) => {
      return (
        <Button
          onClick={() => {
            setFundId(row?.id);
            setFundsModalShow(true);
          }}
        >
          <FIcon name="edit" color="black" size="20" />
        </Button>
      );
    },
  },
];

export const fundsWalletColumns = ({ translateFunction, navigate }) => [
  {
    selector: "id",
    headerAlign: "center",

    cell: (row) => (
      <div
        style={{ cursor: "pointer" }}
        onClick={() => {
          if (row?.assets?.name == "C5") {
            navigate("/invest/c5-index?id=1");
          } else if (row?.assets?.name == "BTCp") {
            navigate("/invest/bitcoin-protected?id=2");
          } else if (row?.assets?.name == "TRQ") {
            navigate("/invest/crypto-trading?id=3");
          } else if (row?.assets?.name == "QNT") {
            navigate("/invest/algorithmic?id=4");
          }
        }}
      >
        <img src={row?.assets?.logo} width="26" alt="" />
        <span style={{ fontWeight: row?.assets?.name == "TOTAL" && "700" }}>
          {row?.assets?.name}
        </span>{" "}
        {row?.assets?.name != "TOTAL" && translateFunction("balance")}
      </div>
    ),
    align: "center",
    name: translateFunction("funds"),
  },
  {
    selector: "qty",
    name: translateFunction("quantity"),
    headerAlign: "center",
    align: "center",

    cell: (row) => (
      <>
        {row?.assets?.name == "TOTAL" ? (
          ""
        ) : (
          <div className="d-flex gap-1 justify-content-center">
            <span>{parseFloat(row?.qty).toFixed(2)}</span>
          </div>
        )}
      </>
    ),
  },
  {
    selector: "c_price",
    name: translateFunction("current_price"),
    headerAlign: "center",
    align: "center",
  },
  {
    selector: "b_price",
    name: translateFunction("buy_price"),
    headerAlign: "center",
    align: "center",
  },
  {
    selector: "return_d",
    name: translateFunction("return_dollar"),
    headerAlign: "center",
    align: "center",
  },
  {
    selector: "pl",
    name: "P/L $",
    headerAlign: "center",
    align: "center",

    cell: (row) => {
      if (row?.assets?.name == "USDC") {
        return "-";
      }
      return (
        <>
          {row?.assets?.name == "TOTAL" ? (
            ""
          ) : (
            <div className="d-flex gap-1 justify-content-center">
              <span>{parseFloat(row?.pl).toFixed(2)}</span>
            </div>
          )}
        </>
      );
    },
  },
  {
    selector: "mark_to_market",
    name: "MARK TO MARKET",
    headerAlign: "center",
    align: "center",
    width: "100px",
    cell: (row) => (
      <>
        <span
          style={{
            fontWeight: row?.assets?.name == "TOTAL" && "700",
            color: row?.assets?.name == "TOTAL" && "#4169E1",
          }}
        >
          ${row?.mark_to_market}
        </span>
      </>
    ),
  },
];

export const yieldWalletColumns = ({ translateFunction }) => [
  {
    selector: "id",
    headerAlign: "center",
    cell: (row) => (
      <>
        <img src={row?.assets?.logo} width="20" />
        <span
          style={{
            fontWeight: row?.assets?.name == "TOTAL" && "700",
            marginLeft: "7px",
          }}
        >
          {row?.assets?.name}
        </span>
      </>
    ),
    align: "center",
    name: translateFunction("yield_hub"),
  },
  {
    selector: "qty",
    name: translateFunction("invested_usd"),
    headerAlign: "center",
    align: "center",
  },
  {
    selector: "return_d",
    name: translateFunction("return_dollar"),
    headerAlign: "center",
    align: "center",
  },
  {
    selector: "pl",
    name: "P/L $",
    headerAlign: "center",
    align: "center",
  },
  {
    name: "MARK TO MARKET",
    headerAlign: "center",
    align: "center",
    cell: (row) => (
      <>
        <span
          style={{
            fontWeight: row?.assets?.name == "TOTAL" && "700",
            color: row?.assets?.name == "TOTAL" && "#4169E1",
          }}
        >
          {row?.mark_to_market}
        </span>
      </>
    ),
  },
];

export const transactionWalletColumns = ({ translateFunction }) => [
  {
    selector: "id",
    headerAlign: "center",
    cell: (row) => (
      <div>
        {row?.action == "Withdraw" || row?.action == "SELL" ? (
          <svg
            width="17"
            height="17"
            viewBox="0 0 17 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M8.50024 15.3C4.75175 15.3 1.70025 12.2485 1.70024 8.5C1.70024 4.7515 4.75174 1.7 8.50024 1.7C12.2487 1.7 15.3002 4.7515 15.3002 8.5C15.3002 12.2485 12.2487 15.3 8.50024 15.3ZM8.50025 17C13.1922 17 17.0002 13.192 17.0002 8.5C17.0002 3.808 13.1922 -1.64629e-06 8.50024 -1.32462e-06C3.80824 -1.00295e-06 0.000244577 3.808 0.0002451 8.5C0.000245623 13.192 3.80825 17 8.50025 17ZM7.65024 8.5L7.65025 11.9L9.35024 11.9L9.35024 8.5L11.9002 8.5L8.50024 5.1L5.10024 8.5L7.65024 8.5Z"
              fill="#252525"
            />
          </svg>
        ) : (
          <svg
            width="17"
            height="17"
            viewBox="0 0 17 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M8.50024 1.7C12.2487 1.7 15.3002 4.7515 15.3002 8.5C15.3002 12.2485 12.2487 15.3 8.50024 15.3C4.75174 15.3 1.70024 12.2485 1.70024 8.5C1.70024 4.7515 4.75174 1.7 8.50024 1.7ZM8.50024 0C3.80824 0 0.000244141 3.808 0.000244141 8.5C0.000244141 13.192 3.80824 17 8.50024 17C13.1922 17 17.0002 13.192 17.0002 8.5C17.0002 3.808 13.1922 0 8.50024 0ZM9.35024 8.5V5.1H7.65024V8.5H5.10024L8.50024 11.9L11.9002 8.5H9.35024Z"
              fill="#4169E1"
            />
          </svg>
        )}
      </div>
    ),
    align: "center",
  },
  {
    selector: "action",
    headerAlign: "center",
    align: "center",
    name: translateFunction("action"),
  },

  {
    selector: "fund",
    headerAlign: "center",
    align: "center",
    name: translateFunction("fund"),
  },
  {
    selector: "qty",
    name: translateFunction("quantity"),
    headerAlign: "center",
    align: "center",
  },
  {
    selector: "b_price",
    name: translateFunction("buy_price"),
    headerAlign: "center",
    align: "center",
  },
  {
    selector: "s_price",
    name: translateFunction("sell_price"),
    headerAlign: "center",
    align: "center",
  },
  {
    selector: "fee",
    name: translateFunction("fee"),
    headerAlign: "center",
    align: "center",
  },

  {
    selector: "total",
    name: translateFunction("total"),
    headerAlign: "center",
    align: "center",
    cell: (row) => <>${row?.total}</>,
  },

  {
    selector: "date",
    name: translateFunction("date"),
    headerAlign: "center",
    align: "center",
  },
  {
    selector: "status",
    name: translateFunction("status"),
    headerAlign: "center",
    align: "center",
    cell: (row) => (
      <>
        {row.status == "R"
          ? translateFunction("rejected")
          : translateFunction("success")}
      </>
    ),
  },
];

export const C5ReportColumns = () => [
  {
    selector: "name",
    name: "",
    headerAlign: "center",
    align: "center",
  },
  {
    selector: "amount",
    name: "amount",
    headerAlign: "center",
    align: "center",
    cell: (row) => (
      <div className="d-flex gap-1 justify-content-center">
        <span>{parseFloat(row?.amount).toFixed(6)}</span>
      </div>
    ),
  },
  {
    selector: "price",
    name: "price",
    headerAlign: "center",
    align: "center",
  },
  {
    selector: "ratio",
    name: "ratio",
    headerAlign: "center",
    align: "center",
  },
  {
    selector: "total",
    name: "total",
    headerAlign: "center",
    align: "center",
  },
  {
    selector: "rebalance",
    name: "rebalance",
    headerAlign: "center",
    align: "center",
  },
];

export const totalManagementsColumns = () => [
  {
    selector: "name",
    name: "fund",
    headerAlign: "start",
    cell: (row) => (
      <div className="d-flex gap-1 justify-content-center">
        <div
          className="d-flex justify-content-start gap-1"
          style={{ width: "50px" }}
        >
          <img src={row?.logo} height={18} style={{ filter: "none" }} />
          <span>{row?.name}</span>
        </div>
      </div>
    ),
  },
  {
    selector: "amount",
    name: "MANAGEMENT FEE",
    headerAlign: "center",
    align: "center",
    cell: (row) => (
      <div className="d-flex justify-content-center align-items-center">
        <div style={{ background: "#4169E129", padding: "14px 8px" }}>
          {"2%"}
        </div>
        <div style={{ padding: "14px 20px", width: "100px" }}>
          {row?.mfee_dollar == null
            ? "0.00$"
            : parseFloat(row?.mfee_dollar).toFixed(2) + "$"}
        </div>
      </div>
    ),
  },
  {
    selector: "price",
    name: "PERFORMANCE FEE",
    headerAlign: "center",
    align: "center",
    cell: (row) => (
      <div className="d-flex justify-content-center align-items-center">
        <div style={{ background: "#4169E129", padding: "14px 8px" }}>
          {row?.pfee_per}
        </div>
        <div style={{ padding: "14px 20px", width: "100px" }}>
          {row?.pfee_dollar == null
            ? "0.00$"
            : parseFloat(row?.pfee_dollar).toFixed(2) + "$"}
        </div>
      </div>
    ),
  },
];

export const emergencyColumns = () => [
  {
    selector: "name",
    name: "User",
    headerAlign: "start",
    cell: (row) => (
      <div>{row?.Account?.name + " " + row?.Account?.surname}</div>
    ),
  },
  {
    selector: "user_id",
    name: "Emergency Contact User",
    headerAlign: "center",
    align: "center",
    cell: (row) => <div>{row?.name + " " + row?.surname}</div>,
  },
  {
    selector: "email",
    name: "Email",
    headerAlign: "center",
    align: "center",
  },
  {
    selector: "phone",
    name: "Phone",
    headerAlign: "center",
    align: "center",
  },
];

export const emergencyAdminColumns = () => [
  {
    selector: "user_id",
    name: "Emergency Contact User",
    headerAlign: "center",
    align: "center",
    cell: (row) => <div>{row?.name + " " + row?.surname}</div>,
  },

  {
    selector: "email",
    name: "Email",
    headerAlign: "center",
    align: "center",
  },
  {
    selector: "phone",
    name: "Phone",
    headerAlign: "center",
    align: "center",
  },
];

export const AssetsToSupplyColumns = ({ handleSupplyOnClick }) => [
  {
    selector: "asset",
    name: "ASSET",
    headerAlign: "left",
    align: "left",

    cell: (row) => (
      <div className="d-flex gap-2 align-items-center">
        {row?.logo && (
          <img
            src={row?.logo}
            height={24}
            width={24}
            style={{ objectFit: "cover" }}
          />
        )}
        {row?.asset}
      </div>
    ),
  },
  {
    selector: "apy",
    name: "APY",
    headerAlign: "left",
    align: "left",

    cell: (row) => row?.apy + "%",
  },
  {
    selector: "wallet_balance",
    name: "WALLET BALANCE",
    width: "100px",
    headerAlign: "left",
    align: "left",
    cell: (row) => (
      <>
        <div>{row?.wallet_balance}</div>
        <div style={{ fontSize: "12px", color: "#616161" }}>
          {"$" + row?.wallet_balance}
        </div>
      </>
    ),
  },
  {
    selector: "can_be_collateral",
    name: "CAN BE COLLATERAL",
    headerAlign: "left",
    align: "left",
    cell: (row) => (
      <div className="d-flex align-items-center gap-5 justify-content-between">
        {row?.can_be_collateral ? (
          <FIcon name="approve" color="#4169e1" size={24} />
        ) : (
          "-"
        )}
        <Button onClick={() => handleSupplyOnClick(row)}>SUPPLY</Button>
      </div>
    ),
  },
];

export const AssetsToBorrowColumns = () => [
  {
    selector: "asset",
    name: "ASSET",
    headerAlign: "left",
    align: "left",

    cell: (row) => (
      <div className="d-flex gap-2 align-items-center">
        {row?.logo && (
          <img
            src={row?.logo}
            height={24}
            width={24}
            style={{ objectFit: "cover" }}
          />
        )}
        {row?.asset}
      </div>
    ),
  },
  {
    selector: "apy",
    name: "APY",
    headerAlign: "left",
    align: "left",

    cell: (row) => (
      <div style={{ whiteSpace: "pre-line" }}>{row?.apy + "%"}</div>
    ),
  },
  {
    selector: "available",
    name: "AVAILABLE",
    headerAlign: "left",
    align: "left",
    cell: (row) => (
      <>
        <div>{row?.wallet_balance}</div>
        <div style={{ fontSize: "12px", color: "#616161" }}>
          {"$" + row?.wallet_balance}
        </div>
      </>
    ),
  },
  {
    selector: "can_be_collateral",
    name: "",
    headerAlign: "left",
    align: "left",
    cell: (row) => <Button>BORROW</Button>,
  },
];
