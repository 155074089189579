import i18next from "i18next";
import React, { useEffect, useState } from "react";
import { Button, Card, Container, Form, Spinner } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import FIcon from "../../../../components/FIcon/FIcon";
import FTextInput from "../../../../components/FTextInput/FTextInput";
import {
  buyFundByIdService,
  getFundByIdService,
  getFundFeeByIdService,
  getFundPriceByIdService,
} from "../../../../services/fund/fundService";
import {
  getBalanceByIdRequest,
  getWalletByIdRequest,
} from "../../../../services/wallet/walletService";
import { checkUserTubuWallet } from "../../../../utils/util";
import "./Buy.scss";

const Buy = ({ theme }) => {
  const fundId = window.location.href.split("buy?id=")[1];
  const user = JSON.parse(localStorage.getItem("user"));
  let navigate = useNavigate();

  const { t } = useTranslation();

  const getLanguage = i18next.language || window.localStorage.i18nextLng;

  const dispatch = useDispatch();
  const { fund, buyFundResult, buyFundStatus, fundPrice, fundFee } =
    useSelector((state) => state.fund);
  const { wallet, balances, loading } = useSelector((state) => state.wallet);
  const [error, setError] = useState("");
  const [amount, setAmount] = useState();
  const [disabled, setDisabled] = useState(true);
  const [submitted, setSubmitted] = useState(false);

  const [estimatedDow, setEstimatedDow] = useState(0.0);

  const initialFilters = {
    id: fundId,
  };

  const initialFeeFilters = {
    id: fundId,
    amount: 0,
  };

  const walletFilters = {
    id: user.id,
  };

  const balanceFilters = {
    wallet: checkUserTubuWallet(wallet),
    id: user.id,
  };

  useEffect(() => {
    setDisabled(false);
    if (
      parseFloat(amount) > parseFloat(balances?.TUSD?.amount_usd).toFixed(6)
    ) {
      setError(t("buy_insuff_err"));
    } else {
      if (parseFloat(amount) <= fundFee) {
        setDisabled(true);
        setError(t("buy_network_err"));
      } else {
        setDisabled(false);
        setError("");
      }
    }

    if (estimatedDow <= 0) {
      setDisabled(true);
      setEstimatedDow(0);
    }
  }, [amount]);

  useEffect(() => {
    dispatch(getFundByIdService(initialFilters));
  }, [fundId]);

  useEffect(() => {
    dispatch(getWalletByIdRequest(walletFilters));
  }, []);

  useEffect(() => {
    dispatch(getFundPriceByIdService(initialFilters));
  }, []);

  useEffect(() => {
    dispatch(getFundFeeByIdService(initialFeeFilters));
  }, []);

  useEffect(() => {
    if (wallet.length !== 0) {
      dispatch(getBalanceByIdRequest(balanceFilters));
    }
  }, [wallet]);

  useEffect(() => {
    if ((buyFundResult != null || buyFundResult != undefined) && submitted) {
      if (buyFundStatus < 400) {
        let timerInterval;
        Swal.fire({
          title: t("buy_invest_succ"),
          icon: "success",
          text: `${buyFundResult}`,
        }).then((result) => {
          navigate("/wallets");
          setSubmitted(false);
        });
      } else {
        let timerInterval;
        Swal.fire({
          title: t("buy_err"),
          icon: "error",
          text: `${buyFundResult}`,
        }).then((result) => {
          navigate("/wallets");
          setSubmitted(false);
        });
      }
    }
  }, [buyFundResult, submitted]);

  function handleSubmit(e) {
    e.preventDefault();
    setSubmitted(true);
    let data = {
      account_id: user?.id,
      fund_id: fundId,
      amount: estimatedDow,
      amount_usd: amount - fundFee,
      from: wallet.filter((w) => w.network == "TUBU")[0]?.user_address,
      unit_price: fundPrice,
      transaction_fee: fundFee,
    };
    dispatch(buyFundByIdService(data));
  }

  return (
    <Container className="buy-container">
      <div className="back" onClick={() => navigate("/invest")}>
        <FIcon
          name="back"
          color={theme === "dark" ? "white" : "black"}
          size="20"
        />
        {t("buy_back")}
      </div>
      <div className="buy">
        <h1>{t("buy_invest_fund")}</h1>
        <Card>
          <Card.Header>Dow Digital Capital</Card.Header>
          <Card.Body>
            <div className="buy-info">
              <div className="buy-info-item">
                <h2>{t("buy_invest")}</h2>
                <h3>{getLanguage === "tr" ? fund?.name : fund?.name_en}</h3>
              </div>
              <div className="buy-info-right">
                <h2>{t("buy_usdc")}</h2>
                <h3>
                  {loading || balances?.TUSD?.amount_usd == undefined ? (
                    <Spinner animation="grow" size="sm" />
                  ) : balances?.length !== 0 ? (
                    parseFloat(balances?.TUSD?.amount_usd)?.toFixed(6)
                  ) : (
                    "0"
                  )}{" "}
                  USDC
                </h3>
              </div>
            </div>
            <div className="buy-info">
              <div className="buy-info-item">
                <h2>{t("buy_fund_price")}</h2>
                <h3>
                  {loading ? (
                    <Spinner animation="grow" size="sm" />
                  ) : fundPrice?.length !== 0 ? (
                    parseFloat(fundPrice)?.toFixed(4)
                  ) : (
                    "0"
                  )}
                </h3>
              </div>
              <div className="buy-info-right">
                <h2>{t("buy_estimated", { money: fund?.token })}</h2>
                <h3>
                  {parseFloat(estimatedDow).toFixed(6)} {fund?.token}
                </h3>
              </div>
            </div>
            <div className="buy-form">
              <h4
                onClick={() => {
                  setAmount(parseFloat(balances?.TUSD?.amount_usd).toFixed(6));
                  setEstimatedDow(
                    parseFloat(
                      (balances?.TUSD?.amount_usd - fundFee) /
                        parseFloat(fundPrice).toFixed(6)
                    ).toFixed(6)
                  );
                }}
              >
                {t("buy_all")}
              </h4>
              <Form>
                <FTextInput label={t("buy_usd_type")} id="amount">
                  <Form.Control
                    onChange={(event) => {
                      setAmount(event.target.value);
                      setEstimatedDow(
                        parseFloat(
                          (event.target.value - fundFee) /
                            parseFloat(fundPrice).toFixed(6)
                        ).toFixed(6)
                      );
                    }}
                    type="number"
                    placeholder="100"
                    step={0.01}
                    value={amount}
                  />
                </FTextInput>
                <div
                  style={{
                    height: "10px",
                  }}
                >
                  <Form.Text className="text-danger">{error}</Form.Text>
                </div>
                <div
                  className="buy-info"
                  style={{ paddingBottom: "10px", paddingTop: "20px" }}
                >
                  <div className="buy-info-item">
                    <h3 style={{ fontSize: "12px", opacity: 0.5 }}>
                      {t("buy_fee")}: {fundFee} USDC
                    </h3>
                  </div>
                </div>
                <div>
                  <Button
                    onClick={handleSubmit}
                    className="buy_button d-flex justify-content-center align-items-center gap-2"
                    type="submit"
                    disabled={error !== "" || disabled || loading}
                  >
                    {t("buy_fee2")}
                  </Button>
                </div>
              </Form>
            </div>
          </Card.Body>
        </Card>
      </div>
    </Container>
  );
};

export default Buy;
