import React from 'react';
import { useTranslation } from 'react-i18next';
import './FAccountStatus.scss';

const FAccountStatus = () => {
  const { t } = useTranslation();

  const user = JSON.parse(localStorage.getItem('user'));

  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  return (
    <div className="f_status">
      <div className="f_status-header">
        <div className="f_status-title">
          <h1>{t('accountstatus_welcome')}</h1>
          <h2>
            {capitalizeFirstLetter(user.name) +
              ' ' +
              capitalizeFirstLetter(user.surname)}
          </h2>
        </div>
        <div className="f_status-progress"></div>
      </div>
    </div>
  );
};

export default FAccountStatus;
