import React, { useEffect, useState } from "react";
import { Button, Form, Spinner } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import FIcon from "../../../components/FIcon/FIcon";
import FTextInput from "../../../components/FTextInput/FTextInput";
import { Tick } from "react-3d-viewer";
import "./ForgotPassword.scss";
import { useDispatch, useSelector } from "react-redux";
import { forgotPasswordRequest } from "../../../services/authentication/authenticationService";
import { validate } from "../../../utils/validate";
import Swal from "sweetalert2";
import { useTranslation } from "react-i18next";
import loginhero from "../../../assets/images/loginhero.png";

const ForgotPassword = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { forgotPasswordInfoResult, loading, error } = useSelector(
    (state) => state.authentication
  );

  const { t } = useTranslation();

  /*useEffect(() => {
    if (auth) {
      navigate('/dashboard');
    }
  }, [auth]);*/

  const initialFormData = {
    email: "",
  };

  /*
  let [x, setX] = useState(-0.8);
  let [y, setY] = useState(0.3);
  let [z, setZ] = useState(0.25);
*/
  const [formData, setFormData] = useState({ ...initialFormData });
  const [formDataErrors, setFormDataErrors] = useState({});

  const formDataValidate = {
    email: {
      isEmail: true,
    },
  };

  const handleOnChange = (name, value) => {
    formDataErrors[name] &&
      setFormDataErrors((prevState) => ({
        ...prevState,
        [name]: "",
      }));
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const checkValidation = () => {
    const errorMsgs = validate(formData, formDataValidate);
    if (Object.keys(errorMsgs).length > 0) {
      setFormDataErrors({ ...errorMsgs });
      return false;
    }
    return true;
  };

  function handleSubmit(e) {
    let check = checkValidation();
    if (check) {
      e.preventDefault();
      dispatch(forgotPasswordRequest(formData));
    } else {
    }
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (error) {
      const Toast = Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 2000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", Swal.stopTimer);
          toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
      });

      Toast.fire({
        icon: "error",
        title: t("forgot_password_error"),
      });
    }
  }, [error]);

  useEffect(() => {
    Tick(() => {
      //setX((prevState) => (prevState += 0.05));
      //setY((prevState) => (prevState += 0.05));
      //setZ((prevState) => (prevState += 0.05));
    });
  }, []);

  useEffect(() => {
    if (forgotPasswordInfoResult) {
      //navigate("/dashboard");
      const Toast = Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 2000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", Swal.stopTimer);
          toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
      });

      Toast.fire({
        icon: "success",
        title: t("forgot_password_success"),
      });
    }
  }, [forgotPasswordInfoResult]);

  return (
    <div className="flogin">
      <div className="flogin-hero">
        <div className="flogin-hero-container">
          <h1>
            THE FUTURE OF <br />
            DIGITAL INVESTING
          </h1>
          <img src={loginhero} />
        </div>
      </div>
      <div className="flogin-login">
        <h1 className=" text-start mb-3">
          <svg
            width="22"
            height="24"
            viewBox="0 0 22 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M21.0607 13.0607C21.6464 12.4749 21.6464 11.5251 21.0607 10.9393L11.5147 1.3934C10.9289 0.807612 9.97918 0.807612 9.3934 1.3934C8.80761 1.97918 8.80761 2.92893 9.3934 3.51472L17.8787 12L9.3934 20.4853C8.80761 21.0711 8.80761 22.0208 9.3934 22.6066C9.97918 23.1924 10.9289 23.1924 11.5147 22.6066L21.0607 13.0607ZM0 13.5H20V10.5H0V13.5Z"
              fill="#4169E1"
            />
          </svg>
          {t("login_forgotpass")}
        </h1>
        <Form>
          <div className="mb-3">
            <FTextInput label={t("login_email")} id="loginemail">
              <Form.Control
                name="email"
                value={formData.email || ""}
                onChange={(e) => handleOnChange(e.target.name, e.target.value)}
                type="email"
                placeholder="name@example.com"
                isInvalid={formDataErrors.email}
              />
            </FTextInput>
            <Form.Text className="text-danger">
              {formDataErrors?.email}
            </Form.Text>
          </div>

          <div className="mb-3">
            <Button
              type="submit"
              disabled={formData.email == "" || loading}
              onClick={handleSubmit}
              className="flogin-button right_side-launch w-100"
            >
              {loading ? (
                <Spinner animation="grow" />
              ) : (
                <>{t("login_forgotpass_button")}</>
              )}
            </Button>
          </div>
        </Form>
        <div className="mb-3 flogin-dha cursor-pointer">
          {t("login_have_acc")}
          <br />
          <span
            style={{ cursor: "pointer" }}
            onClick={() => navigate("/signup")}
          >
            {t("login_create")}
          </span>
        </div>
        {/* <div className="mb-3 text-center f_login-loginText">
            Are you a US citizen? <FIcon name="out" color="red" size="16" />
          </div> */}
      </div>
    </div>
  );
};
export default ForgotPassword;

/*

*/
