import React, { useEffect, useState } from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import { Button, Form, Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { checkDepositWallet, checkWithdrawWallet } from "../../utils/util";
import FTextInput from "../FTextInput/FTextInput";
import "./FNewsDetailsModal.scss";
import {
  getNewsByIdRequest,
  postNewsRequest,
  postNewsSuccess,
  updateNewsByIdRequest,
  uploadFileRequest,
} from "../../services/news/newsService";
import ClassicEditor from "@ckeditor/ckeditor5-editor-classic/src/classiceditor";
import DOWEditor from "../DOWEditor/DOWEditor";
import { convertFromRaw, EditorState } from "draft-js";
import Swal from "sweetalert2";
const FNewsDetailsModal = (props) => {
  const dispatch = useDispatch();
  const { news, loading, postNewsResult, updateSuccess } = useSelector(
    (state) => state.news
  );

  const handleOnChange = (name, value) => {
    props.setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const initialFilters = {
    id: props.newsId,
  };

  useEffect(() => {
    if (props.newsId != undefined && !loading) {
      props?.setFormData({
        ...news[0],
        content: EditorState.createWithContent(
          convertFromRaw(JSON.parse(news[0]?.content))
        ),
      });
    }
  }, [loading]);

  useEffect(() => {
    if (props.newsId != undefined) {
      dispatch(getNewsByIdRequest(initialFilters));
    }
  }, [props.newsId]);

  useEffect(() => {
    if (postNewsResult?.id) {
      dispatch(
        uploadFileRequest({
          id: postNewsResult?.id,
          image: props.formData.image,
        })
      );
    }
  }, [postNewsResult]);

  const handleSaveOnClick = () => {
    dispatch(postNewsRequest(props.formData));
  };

  const handleEditOnClick = () => {
    if (props.formData.image instanceof File) {
      dispatch(
        uploadFileRequest({ id: props.newsId, image: props.formData.image })
      );
    }
    dispatch(updateNewsByIdRequest({ id: props.newsId, data: props.formData }));
  };

  useEffect(() => {
    if (updateSuccess) {
      Swal.fire("News updated!", "success").then(() =>
        window.location.reload()
      );
    }
  }, [updateSuccess]);

  useEffect(() => {
    if (postNewsResult) {
      Swal.fire("News added!", "success").then(() => window.location.reload());
    }
  }, [postNewsResult]);

  return (
    <Modal
      {...props}
      size="xl"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {props.newsId ? "News details of " + news[0]?.id : "Add New News"}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form enctype="multipart/form-data">
          <div className="f_modal-userdetails">
            <FTextInput
              style={{ height: "fit-content" }}
              label={"Başlık"}
              id="name"
            >
              <Form.Control
                onChange={(event) =>
                  handleOnChange("title", event.target.value)
                }
                type="text"
                value={props.formData?.title}
                style={{ height: "fit-content" }}
              />
            </FTextInput>
            <div style={{ width: "min-content" }}>
              <div>Yazı Üst Resmi</div>
              {props.formData.image ? (
                props.formData.image instanceof File ? (
                  <img
                    style={{ width: "355px", height: "200px" }}
                    src={URL.createObjectURL(props.formData.image)}
                  />
                ) : (
                  <img
                    style={{ width: "355px", height: "200px" }}
                    src={
                      process.env.REACT_APP_DOWNLOAD_IMAGE_BASE_URL +
                      props.formData.image
                    }
                  />
                )
              ) : (
                ""
              )}
              <input
                style={{ marginTop: "20px" }}
                type="file"
                name="image"
                onChange={(e) =>
                  handleOnChange(e.target.name, e.target.files[0])
                }
              />
            </div>
          </div>
        </Form>

        <Form.Select
          onChange={(e) => handleOnChange("type", e.target.value)}
          value={props.formData.type}
        >
          <option value="news">News</option>
          <option value="blog">Blog</option>
        </Form.Select>
        <h6 className="mt-4 mb-1">Content</h6>

        <div>
          <DOWEditor
            editorState={props.formData?.content}
            setEditorState={handleOnChange}
          />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.newsId ? handleEditOnClick : handleSaveOnClick}>
          {props.newsId ? "Save Changes" : "Create News"}
        </Button>
        <Button onClick={props.onHide}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
};

export default FNewsDetailsModal;
