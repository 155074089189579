import React, { useEffect } from 'react';
import { Container } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { getLastPricesService } from '../../../../services/fund/fundService';
import './OptionStrategies.scss';
import FundDetails from '../../../PublicLayout/Invest/FundDetails/FundDetails';
import option1 from '../../../../assets/images/option1.png';
import option2 from '../../../../assets/images/option2.png';
import option3 from '../../../../assets/images/option3.png';
import { useTranslation } from 'react-i18next';

const OptionStrategies = ({ from, theme }) => {
  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state.fund);

  const { t } = useTranslation();

  const initialFilters = {
    filter: {},
  };

  useEffect(() => {
    dispatch(getLastPricesService(initialFilters));
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <FundDetails location={'option-strategies'} from={from} theme={theme} />

      {loading ? (
        ''
      ) : (
        <div className="f_funddetailswrapperr">
          <Container className="option_container">
            <div className="f_funddetails-advantagess">
              <div className="f_funddetails-advantagess-title">
                {t('option_strategy')}
              </div>
              <div className="f_funddetails-advantagess-content">
                <div className="f_funddetails-advantagess-content-section">
                  <img alt="for" src={option1}></img>
                  <h1>{t('option_title1')}</h1>
                  <h2>{t('option_content1')}</h2>
                  <h3>{t('option_desc1')}</h3>
                </div>
                <div className="f_funddetails-advantagess-content-section">
                  <img alt="for" src={option2}></img>
                  <h1>{t('option_title2')}</h1>
                  <h2>{t('option_content2')}</h2>
                  <h3>{t('option_desc2')}</h3>
                </div>
                <div className="f_funddetails-advantagess-content-section">
                  <img alt="for" src={option3}></img>
                  <h1>{t('option_title3')}</h1>
                  <h2>{t('option_content3')}</h2>
                  <h3>{t('option_desc3')}</h3>
                </div>
              </div>
            </div>
          </Container>
        </div>
      )}
    </>
  );
};

export default OptionStrategies;
