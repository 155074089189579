import React from 'react';
import { FloatingLabel } from 'react-bootstrap';
import './FTextInput.scss';

const FTextInput = ({ children, label, id }) => {
  return (
    <FloatingLabel controlId={id} label={label} className="f_textInput">
      {children}
    </FloatingLabel>
  );
};

export default FTextInput;
