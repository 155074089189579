// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.App {
  color: var(--text-primary);
  background-color: var(--background-primary);
  font-size: large;
  font-weight: bold;
  transition: all 0.5s;
}
`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE,0BAA0B;EAC1B,2CAA2C;EAC3C,gBAAgB;EAChB,iBAAiB;EACjB,oBAAoB;AACtB","sourcesContent":[".App {\n  color: var(--text-primary);\n  background-color: var(--background-primary);\n  font-size: large;\n  font-weight: bold;\n  transition: all 0.5s;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
