import React, { useEffect, useState } from "react";
import { Button, Modal, Spinner } from "react-bootstrap";
import { Doughnut } from "react-chartjs-2";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import {
  getFundFeeByIdService,
  getFundPriceMovementsService,
  getUserTransactionsByIdService,
} from "../../../services/fund/fundService";
import {
  getBalanceByIdRequest,
  getWalletByIdRequest,
} from "../../../services/wallet/walletService";
import {
  checkUserDepositWallet,
  checkUserTubuWallet,
  numberWithCommas,
} from "../../../utils/util";
import FIcon from "../../FIcon/FIcon";
import "./WAssets.scss";
import QRCode from "react-qr-code";
import Swal from "sweetalert2";
import { getAllGeneralChartDataRequest } from "../../../services/admin/adminService";
import DInternalModal from "../../DashboardComponents/DInternalModal/DInternalModal";
const WAssets = ({ isDashboard }) => {
  const user = JSON.parse(localStorage.getItem("user"));

  console.log(user);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { wallet, loadingBalance, balances } = useSelector(
    (state) => state.wallet
  );

  const { generalChartDataAll } = useSelector((state) => state.admin);
  const walletFilters = {
    id: user.id,
  };
  const balanceFilters = {
    wallet: checkUserTubuWallet(wallet),
    id: user.id,
  };

  console.log(balances);
  useEffect(() => {
    dispatch(getWalletByIdRequest(walletFilters));
  }, []);

  useEffect(() => {
    if (wallet.length !== 0) {
      dispatch(getBalanceByIdRequest(balanceFilters));
    }
    if (generalChartDataAll !== null) {
    }
  }, [wallet]);

  const refreshBalance = () => {
    dispatch(getBalanceByIdRequest(balanceFilters));
  };

  const data = {
    labels: Object.keys(balances),
    datasets: [
      {
        data: Object.values(balances).map((value) => value.amount_usd),

        backgroundColor: [
          "#2672C4",
          "rgb(116,125,128)",
          "rgb(242,169,0)",
          "rgb(105,194,231)",
          "rgb(228,55,150)",
        ],
        borderWidth: 1,
      },
    ],
  };
  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: true,
        position: "bottom",
      },

      tooltip: {
        callbacks: {
          label: (context) => {
            return "$" + context.formattedValue;
          },
        },
      },
    },
  };

  const logos = [
    "https://logowik.com/content/uploads/images/usd-coin-usdc4695.jpg",
    "https://dow.capital/static/media/c5icon.a4fc3d13b355bfc93b0f.png",
    "https://dow.capital/static/media/btcicon.f369eb135dd8bed28e0d.png",
    "https://dow.capital/static/media/tradeicon.300070758f7cd253ff5f.png",
    "https://dow.capital/static/media/arbitrageicon.b534faf6f8c6f6c48dd5.png",
  ];

  const { t } = useTranslation();

  const [show, setShow] = useState(false);
  const [showInternal, setShowInternal] = useState(false);

  const copyInfo = (text) => {
    const Toast = Swal.mixin({
      toast: true,
      position: "top-end",
      showConfirmButton: false,
      timer: 2000,
      timerProgressBar: true,
      didOpen: (toast) => {
        toast.addEventListener("mouseenter", Swal.stopTimer);
        toast.addEventListener("mouseleave", Swal.resumeTimer);
      },
    });

    Toast.fire({
      icon: "success",
      title: text + " copied to clipboard.",
    });
  };

  return (
    <div className="f_wallet-bg">
      <div className="f_wallet-assets-head">
        <h6 style={{ fontWeight: "700" }}>{t("assets")}</h6>
        <div className="f_wallet-assets-head-r">
          <div className="f_wallet-assets-head-r-i">
            <FIcon
              name="refresh"
              color="gray"
              size="18"
              style={{ WebkitTransform: "scaleX(-1)", transform: "scaleX(-1)" }}
              onClick={refreshBalance}
            />
          </div>
          <span>
            {loadingBalance ? (
              <Spinner animation="grow" size="sm" />
            ) : (
              <span>
                $
                {balances?.length !== 0
                  ? numberWithCommas(
                      (
                        parseFloat(balances?.TUSD?.amount_usd) +
                        parseFloat(balances?.C5?.amount_usd) +
                        parseFloat(balances?.BTCp?.amount_usd) +
                        parseFloat(balances?.TRQ?.amount_usd) +
                        parseFloat(balances?.QNT?.amount_usd)
                      )?.toFixed(2)
                    )
                  : "0"}
              </span>
            )}
          </span>
        </div>
      </div>
      <div
        style={{
          marginTop: "40px",
          marginBottom: "40px",
          marginLeft: "40px",
          marginRight: "40px",
        }}
      >
        {loadingBalance ? (
          <Spinner animation="grow" size="sm" />
        ) : balances?.length !== 0 &&
          numberWithCommas(
            (
              parseFloat(balances?.TUSD?.amount_usd) +
              parseFloat(balances?.C5?.amount_usd) +
              parseFloat(balances?.BTCp?.amount_usd) +
              parseFloat(balances?.TRQ?.amount_usd) +
              parseFloat(balances?.QNT?.amount_usd)
            )?.toFixed(2)
          ) !== "0.00" ? (
          <Doughnut data={data} options={options} />
        ) : (
          ""
        )}
      </div>
      <div style={{ position: "relative" }}>
        {Object.keys(balances)?.map((value, index) => {
          if (index == 0) {
            return (
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginTop: "10px",
                  marginBottom: "10px",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "6px",
                    color: value,
                    fontWeight: "400",
                    fontSize: "15px",
                    width: "80px",
                  }}
                >
                  <img height="18" src={logos[index]} />
                  USDC
                </div>
                <span style={{ fontWeight: "400", fontSize: "15px" }}>
                  $
                  {numberWithCommas(
                    parseFloat(balances[value].amount_usd)?.toFixed(2)
                  )}
                </span>
              </div>
            );
          }
          return (
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: "10px",
                marginBottom: "10px",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "6px",
                  color: value,
                  fontWeight: "400",
                  fontSize: "15px",
                }}
              >
                <img height="20" src={logos[index]} />
                {value}
              </div>
              <div
                style={{
                  fontSize: "11px",
                  fontWeight: "400",
                  width: "170px",
                  position: "absolute",
                  //left: "50%",
                  right: "0%",

                  color:
                    generalChartDataAll && generalChartDataAll[value]?.ratio < 0
                      ? "red"
                      : "green",
                }}
              >
                {generalChartDataAll &&
                generalChartDataAll[value]?.ratio < 0 ? (
                  <svg
                    width="13"
                    height="7"
                    viewBox="0 0 13 7"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M6.5 7L0.870834 0.25L12.1292 0.25L6.5 7Z"
                      fill="#F81506"
                    />
                  </svg>
                ) : (
                  <svg
                    width="13"
                    height="7"
                    viewBox="0 0 13 7"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M6.5 0L12.1292 6.75H0.870835L6.5 0Z"
                      fill="#00B42D"
                    />
                  </svg>
                )}
                {generalChartDataAll &&
                parseFloat(generalChartDataAll[value]?.ratio).toFixed(2) !=
                  "NaN"
                  ? parseFloat(generalChartDataAll[value]?.ratio).toFixed(2)
                  : 0}
              </div>
              <span style={{ fontWeight: "400", fontSize: "15px" }}>
                $
                {numberWithCommas(
                  parseFloat(balances[value].amount_usd)?.toFixed(2)
                )}
              </span>
            </div>
          );
        })}
      </div>

      <Button
        style={{
          width: "100%",
          background: "#4169E1",
          fontSize: "12px",
          fontWeight: "500",
          border: "none",
          marginTop: "32px",
          marginBottom: "11px",
        }}
        onClick={() => setShow(true)}
      >
        {t("deposit_usdc")}
      </Button>
      <Button
        style={{
          width: "100%",
          background: "#5E718D",
          fontSize: "12px",
          fontWeight: "500",
          border: "none",
        }}
        onClick={() => navigate("/wallets/withdraw")}
      >
        {t("withdraw_usdc")}
      </Button>
      <Button
        style={{
          width: "100%",
          background: "#4169E1",
          fontSize: "12px",
          fontWeight: "500",
          border: "none",
          marginTop: "11px",
          marginBottom: "11px",
        }}
        onClick={() => setShowInternal(true)}
      >
        {t("internal_tx")}
      </Button>
      <div className="">
        <Modal
          show={show}
          onHide={() => setShow(false)}
          animation={false}
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          className="wassets_modal"
        >
          <Modal.Header
            closeButton
            style={{
              border: 0,
            }}
          >
            <Modal.Title
              id="contained-modal-title-vcenter"
              style={{ width: "32px" }}
            ></Modal.Title>
            <Modal.Title
              id="contained-modal-title-vcenter"
              style={{
                fontSize: "18px",
                fontWeight: 700,
                color: "#333333",
              }}
            >
              Deposit USDC
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "20px",
                alignItems: "center",
              }}
            >
              <h4
                style={{ fontSize: "16px", color: "#333333", fontWeight: 400 }}
              >
                Scan the QR code to deposit USDC!
              </h4>
              <QRCode
                size={256}
                style={{ height: "auto", width: "155px" }}
                value={checkUserDepositWallet(wallet)}
                viewBox={`0 0 256 256`}
              />
              <div
                style={{ display: "flex", alignItems: "center", gap: "2px" }}
              >
                <FIcon
                  name="copytoclipboard"
                  color="#4169E1"
                  size="20"
                  onClick={() => {
                    navigator.clipboard.writeText(
                      checkUserDepositWallet(wallet)
                    );
                    copyInfo("Deposit address");
                  }}
                />
                <span style={{ fontWeight: 700, color: "#252525" }}>
                  {wallet.length !== 0 && checkUserDepositWallet(wallet)
                    ? checkUserDepositWallet(wallet)?.substring(0, 5) +
                      "..." +
                      checkUserDepositWallet(wallet)?.substring(
                        checkUserDepositWallet(wallet)?.length - 5
                      )
                    : t("wallet_no_wallet")}
                </span>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </div>

      <DInternalModal
        show={showInternal}
        onHide={() => setShowInternal(false)}
        animation={false}
        userId={user.id}
        isAdmin={false}
      />
    </div>
  );
};

export default WAssets;

{
  /*
  const tableData = [
    {
      logo: "https://media.discordapp.net/attachments/784152625662132235/1039092709190799370/btcicon.png",
      color: "#CFA654",
      name: "BTCp",
      ratio_one: "32",
      ratio_two: "-2",
      amount: "550,25",
    },
    {
      logo: "https://media.discordapp.net/attachments/784152625662132235/1039092709190799370/btcicon.png",
      color: "#4169E1",
      name: "Staking",
      ratio_one: "32",
      ratio_two: "2",
      amount: "550,25",
    },
    {
      logo: "https://media.discordapp.net/attachments/784152625662132235/1039092709190799370/btcicon.png",
      color: "#5E718D",
      name: "C5",
      ratio_one: "32",
      ratio_two: "-2",
      amount: "550,25",
    },
    {
      logo: "https://media.discordapp.net/attachments/784152625662132235/1039092709190799370/btcicon.png",
      color: "#C0254A",
      name: "TRQ",
      ratio_one: "32",
      ratio_two: "2",
      amount: "550,25",
    },
  ]*/
}
