import React, { useEffect, useRef, useState } from "react";
import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
import {
  approveTransactionRequest,
  cancelTransactionRequest,
} from "../../services/admin/adminService";
import { getLastPricesService } from "../../services/fund/fundService";
import FIcon from "../FIcon/FIcon";
import "./FBottombar.scss";

function FBottombar({ show, row }) {
  const dispatch = useDispatch();
  const [showCopy, setShowCopy] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [showButton, setShowButton] = useState(true);
  const [showTokens, setShowTokens] = useState(true);

  const { t } = useTranslation();

  const target = useRef(null);
  const [totalAmount, setTotalAmount] = useState(0);

  const { lastPrices } = useSelector((state) => state.fund);
  const { approveResult, cancelResult } = useSelector((state) => state.admin);

  const initialFilters = {
    filter: {},
  };

  useEffect(() => {
    dispatch(getLastPricesService(initialFilters));
  }, []);

  useEffect(() => {
    setButtonDisabled(false);
    setShowButton(true);
    setShowTokens(true);

    row.forEach((element) => {
      if (element.status !== "A") {
        setButtonDisabled(true);
      }
    });
    row.forEach((element) => {
      if (element.action === "WITHDRAW" || element.action === "SELL") {
        setShowButton(false);
      }
    });
    row.forEach((element) => {
      if (element.action === "WITHDRAW" || element.action === "DEPOSIT") {
        setShowTokens(false);
      }
    });
  }, [row]);

  const copyInfo = (text) => {
    const Toast = Swal.mixin({
      toast: true,
      position: "top-end",
      showConfirmButton: false,
      timer: 2000,
      timerProgressBar: true,
      didOpen: (toast) => {
        toast.addEventListener("mouseenter", Swal.stopTimer);
        toast.addEventListener("mouseleave", Swal.resumeTimer);
      },
    });

    Toast.fire({
      icon: "success",
      title: text + " copied to clipboard.",
    });
  };

  const calcTotalAmount = (object) => {
    for (let i = 0; i < object.length; i++) {
      setTotalAmount((prevValue) => prevValue + parseFloat(object[i].amount));
    }
  };

  useEffect(() => {
    setTotalAmount(0);
    calcTotalAmount(row);
  }, [row]);

  const approve = (array) => {
    array.forEach((element) => {
      let tx = element?.tx_id;
      dispatch(approveTransactionRequest(tx));
    });
    if (approveResult < 400) {
      const Toast = Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 4000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", Swal.stopTimer);
          toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
      });

      Toast.fire({
        icon: "success",
        title: t("bottombar_approve"),
      }).then(() => window.location.reload());
    } else {
      const Toast = Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 2000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", Swal.stopTimer);
          toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
      });

      Toast.fire({
        icon: "error",
        title: t("bottombar_error"),
      });
    }
  };

  const cancel = (array) => {
    array.forEach((element) => {
      let tx = element?.tx_id;
      dispatch(cancelTransactionRequest(tx));
    });
    if (cancelResult < 400) {
      const Toast = Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 4000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", Swal.stopTimer);
          toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
      });

      Toast.fire({
        icon: "info",
        title: t("bottombar_cancel"),
      }).then(() => window.location.reload());
    } else {
      const Toast = Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 2000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", Swal.stopTimer);
          toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
      });

      Toast.fire({
        icon: "error",
        title: t("bottombar_error"),
      });
    }
  };

  return (
    <>
      {show ? (
        <div className="f_bottombar anime">
          <div className="f_bottombar">
            <div className="f_bottombar-item">
              <div className="f_bottombar-item-element">
                {t("bottombar_selected")}
              </div>
              <div className="f_bottombar-item-element">
                {row?.map((item, index) => {
                  return <h6 key={index}>{item.id + ","}&nbsp;</h6>;
                })}
              </div>
            </div>
            <div className="f_bottombar-item">
              <div className="f_bottombar-item-element">Total USDC Amount</div>
              <div className="f_bottombar-item-element-normal">
                <h6>{parseFloat(totalAmount)?.toFixed(4)}</h6>
              </div>
            </div>
            {showTokens &&
            row.filter((e) => e.token === "C5").length == row.length ? (
              /* vendors contains the element we're looking for */
              <div className="f_bottombar-item">
                <div className="f_bottombar-item-element">Amount of Tokens</div>
                <div className="f_bottombar-item-element-normal">
                  <Button
                    ref={target}
                    onClick={() => {
                      navigator.clipboard.writeText(
                        (totalAmount / 5 / parseFloat(lastPrices?.BTC)).toFixed(
                          10
                        )
                      );
                      copyInfo("BTC value");
                      setShowCopy(!showCopy);
                      setTimeout(function () {
                        setShowCopy(false);
                      }, 1000);
                    }}
                  >
                    <FIcon name="copytoclipboard" color="white" size="18" />
                    <img
                      alt="btc"
                      src="https://s2.coinmarketcap.com/static/img/coins/64x64/1.png"
                    ></img>
                    BTC:{" "}
                    {(totalAmount / 5 / parseFloat(lastPrices?.BTC)).toFixed(
                      10
                    )}
                  </Button>
                </div>
                <div className="f_bottombar-item-element-normal">
                  <Button
                    ref={target}
                    onClick={() => {
                      navigator.clipboard.writeText(
                        (totalAmount / 5 / parseFloat(lastPrices?.ETH)).toFixed(
                          10
                        )
                      );
                      copyInfo("ETH value");
                      setShowCopy(!showCopy);
                      setTimeout(function () {
                        setShowCopy(false);
                      }, 1000);
                    }}
                  >
                    <FIcon name="copytoclipboard" color="white" size="18" />
                    <img
                      alt="eth"
                      src="https://s2.coinmarketcap.com/static/img/coins/64x64/1027.png"
                    ></img>
                    ETH:{" "}
                    {(totalAmount / 5 / parseFloat(lastPrices?.ETH)).toFixed(
                      10
                    )}
                  </Button>
                </div>
                <div className="f_bottombar-item-element-normal">
                  <Button
                    ref={target}
                    onClick={() => {
                      navigator.clipboard.writeText(
                        (totalAmount / 5 / parseFloat(lastPrices?.BNB)).toFixed(
                          10
                        )
                      );
                      copyInfo("BNB value");
                      setShowCopy(!showCopy);
                      setTimeout(function () {
                        setShowCopy(false);
                      }, 1000);
                    }}
                  >
                    <FIcon name="copytoclipboard" color="white" size="18" />
                    <img
                      alt="bnb"
                      src="https://s2.coinmarketcap.com/static/img/coins/64x64/1839.png"
                    ></img>
                    BNB:{" "}
                    {(totalAmount / 5 / parseFloat(lastPrices?.BNB)).toFixed(
                      10
                    )}
                  </Button>
                </div>
                <div className="f_bottombar-item-element-normal">
                  <Button
                    ref={target}
                    onClick={() => {
                      navigator.clipboard.writeText(
                        (totalAmount / 5 / parseFloat(lastPrices?.ADA)).toFixed(
                          10
                        )
                      );
                      copyInfo("ADA value");
                      setShowCopy(!showCopy);
                      setTimeout(function () {
                        setShowCopy(false);
                      }, 1000);
                    }}
                  >
                    <FIcon name="copytoclipboard" color="white" size="18" />
                    <img
                      alt="ada"
                      src="https://s2.coinmarketcap.com/static/img/coins/64x64/2010.png"
                    ></img>{" "}
                    ADA:{" "}
                    {(totalAmount / 5 / parseFloat(lastPrices?.ADA)).toFixed(
                      10
                    )}
                  </Button>
                </div>
                <div className="f_bottombar-item-element-normal">
                  <Button
                    ref={target}
                    onClick={() => {
                      navigator.clipboard.writeText(
                        (totalAmount / 5 / parseFloat(lastPrices?.DOT)).toFixed(
                          10
                        )
                      );
                      copyInfo("DOT value");
                      setShowCopy(!showCopy);
                      setTimeout(function () {
                        setShowCopy(false);
                      }, 1000);
                    }}
                  >
                    <FIcon name="copytoclipboard" color="white" size="18" />
                    <img
                      alt="dot"
                      src="https://s2.coinmarketcap.com/static/img/coins/64x64/6636.png"
                    ></img>
                    DOT:{" "}
                    {(totalAmount / 5 / parseFloat(lastPrices?.DOT)).toFixed(
                      10
                    )}
                  </Button>
                </div>
              </div>
            ) : (
              <div className="f_bottombar-item"></div>
            )}
            {showButton ? (
              <div className="f_bottombar-item">
                <div className="f_bottombar-item-element">Actions</div>
                <div className="f_bottombar-item-element">
                  <Button
                    disabled={buttonDisabled}
                    className="approve"
                    onClick={() => approve(row)}
                  >
                    <FIcon name="infoFilled" color="white" size="16" />
                    <b>Approve All</b>
                  </Button>
                  <Button
                    disabled={buttonDisabled}
                    className="cancel"
                    onClick={() => cancel(row)}
                  >
                    <FIcon name="infoFilled" color="white" size="16" />
                    <b>Cancel all</b>
                  </Button>
                </div>
              </div>
            ) : (
              <div className="f_bottombar-item"></div>
            )}
          </div>
          <div
            style={{ color: "white", zIndex: 1000000000, marginTop: "100px" }}
          >
            {row.length == 1 && row[0]?.error}
          </div>
        </div>
      ) : (
        <></>
      )}
    </>
  );
}

export default FBottombar;
