// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.fund_accordion-top {
  padding-left: 24px;
  padding-right: 24px;
}
.fund_accordion-bottom {
  padding: 32px 50px;
  background: rgba(65, 105, 225, 0.3098039216);
  display: flex;
  flex-wrap: wrap;
  row-gap: 5px;
  justify-content: space-between;
}
.fund_accordion-bottom h3 {
  font-size: 14px;
}
.fund_accordion .accordion {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.fund_accordion .accordion-item {
  border: none;
}
.fund_accordion .accordion-item:first-of-type .accordion-button {
  border-top-left-radius: 100px;
  border-top-right-radius: 100px;
}
.fund_accordion .accordion-button {
  color: #252525;
  font-size: 16px;
  font-weight: 700;
  background-color: white;
  border: none;
}
.fund_accordion .accordion-button:not(.collapsed) {
  color: #252525;
  font-size: 16px;
  font-weight: 700;
  background-color: white;
  border: none;
}
.fund_accordion .accordion-body {
  padding: 0;
}`, "",{"version":3,"sources":["webpack://./src/components/AdminComponents/FundAccordion/FundAccordion.scss"],"names":[],"mappings":"AACE;EACE,kBAAA;EACA,mBAAA;AAAJ;AAEE;EACE,kBAAA;EACA,4CAAA;EACA,aAAA;EACA,eAAA;EACA,YAAA;EACA,8BAAA;AAAJ;AACI;EACE,eAAA;AACN;AAEE;EACE,aAAA;EACA,sBAAA;EACA,SAAA;AAAJ;AAEE;EACE,YAAA;AAAJ;AAEE;EACE,6BAAA;EACA,8BAAA;AAAJ;AAEE;EACE,cAAA;EACA,eAAA;EACA,gBAAA;EACA,uBAAA;EACA,YAAA;AAAJ;AAEE;EACE,cAAA;EACA,eAAA;EACA,gBAAA;EACA,uBAAA;EACA,YAAA;AAAJ;AAEE;EACE,UAAA;AAAJ","sourcesContent":[".fund_accordion {\n  &-top {\n    padding-left: 24px;\n    padding-right: 24px;\n  }\n  &-bottom {\n    padding: 32px 50px;\n    background: #4169e14f;\n    display: flex;\n    flex-wrap: wrap;\n    row-gap: 5px;\n    justify-content: space-between;\n    h3 {\n      font-size: 14px;\n    }\n  }\n  .accordion {\n    display: flex;\n    flex-direction: column;\n    gap: 20px;\n  }\n  .accordion-item {\n    border: none;\n  }\n  .accordion-item:first-of-type .accordion-button {\n    border-top-left-radius: 100px;\n    border-top-right-radius: 100px;\n  }\n  .accordion-button {\n    color: #252525;\n    font-size: 16px;\n    font-weight: 700;\n    background-color: white;\n    border: none;\n  }\n  .accordion-button:not(.collapsed) {\n    color: #252525;\n    font-size: 16px;\n    font-weight: 700;\n    background-color: white;\n    border: none;\n  }\n  .accordion-body {\n    padding: 0;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
