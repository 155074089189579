// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.f_confirmation {
  row-gap: 5px;
  height: 100vh;
}
.f_confirmation .launch {
  margin-top: 100px;
}
.f_confirmation .launch:hover {
  background-color: #6182e5 !important;
}
.f_confirmation h1 {
  font-weight: 700 !important;
  font-size: 40px !important;
  padding-top: 100px;
}
.f_confirmation h3 {
  padding-top: 50px;
}
.f_confirmation .logo {
  font-style: normal;
  font-weight: 900;
  font-size: 24px !important;
  line-height: 39px;
  color: var(--text-secondary) !important;
  display: inline-block;
}
.f_confirmation .by {
  font-size: 24px !important;
  font-weight: 700;
  display: inline-block;
}
.f_confirmation-funds {
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  align-items: flex-start;
}
.f_confirmation-fund {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
  align-items: flex-start;
}`, "",{"version":3,"sources":["webpack://./src/pages/MainLayout/Confirmation/Confirmation.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,aAAA;AACF;AACE;EACE,iBAAA;AACJ;AAEE;EACE,oCAAA;AAAJ;AAGE;EACE,2BAAA;EACA,0BAAA;EACA,kBAAA;AADJ;AAIE;EACE,iBAAA;AAFJ;AAKE;EACE,kBAAA;EACA,gBAAA;EACA,0BAAA;EACA,iBAAA;EACA,uCAAA;EACA,qBAAA;AAHJ;AAME;EACE,0BAAA;EACA,gBAAA;EACA,qBAAA;AAJJ;AAME;EACE,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,eAAA;EACA,uBAAA;AAJJ;AAME;EACE,aAAA;EACA,mBAAA;EACA,2BAAA;EACA,eAAA;EACA,uBAAA;AAJJ","sourcesContent":[".f_confirmation {\n  row-gap: 5px;\n  height: 100vh;\n\n  .launch {\n    margin-top: 100px;\n  }\n\n  .launch:hover {\n    background-color: #6182e5 !important;\n  }\n\n  h1 {\n    font-weight: 700 !important;\n    font-size: 40px !important;\n    padding-top: 100px;\n  }\n\n  h3 {\n    padding-top: 50px;\n  }\n\n  .logo {\n    font-style: normal;\n    font-weight: 900;\n    font-size: 24px !important;\n    line-height: 39px;\n    color: var(--text-secondary) !important;\n    display: inline-block;\n  }\n\n  .by {\n    font-size: 24px !important;\n    font-weight: 700;\n    display: inline-block;\n  }\n  &-funds {\n    display: flex;\n    flex-direction: row;\n    justify-content: center;\n    flex-wrap: wrap;\n    align-items: flex-start;\n  }\n  &-fund {\n    display: flex;\n    flex-direction: row;\n    justify-content: flex-start;\n    flex-wrap: wrap;\n    align-items: flex-start;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
