import * as actions from "../../actions";

const initialState = {
  newsList: [],
  news: [],
  postNewsResult: null,
  loading: false,
  error: null,
  countNews: 0,
  updateSuccess: null,
  uploadResult: [],
  deleteSuccess: null,
};

const news = (state = initialState, action) => {
  switch (action.type) {
    case actions.GET_NEWS_REQUEST:
      return {
        ...state,
        newsList: [],
        news: [],
        postNewsResult: null,
        loading: true,
        error: null,
        countNews: 0,
        deleteSuccess: null,
      };
    case actions.GET_NEWS_SUCCESS:
      return {
        ...state,
        newsList: [...action.payload.data.data],
        news: [],
        postNewsResult: null,
        loading: false,
        error: null,
        countNews: action.payload.data._meta.countTotal,
      };
    case actions.GET_NEWS_FAIL:
      return {
        ...state,
        newsList: [],
        news: [],
        postNewsResult: null,
        loading: false,
        error: action.payload,
        countNews: 0,
      };
    case actions.GET_NEWS_BY_ID_REQUEST:
      return {
        ...state,
        news: [],
        postNewsResult: null,
        loading: true,
        error: false,
      };
    case actions.GET_NEWS_BY_ID_SUCCESS:
      return {
        ...state,
        news: [...action.payload.data],
        postNewsResult: null,
        loading: false,
        error: false,
      };
    case actions.GET_NEWS_BY_ID_FAIL:
      return {
        ...state,
        news: [],
        postNewsResult: null,
        loading: false,
        error: action.payload,
      };

    case actions.POST_NEWS_REQUEST:
      return {
        ...state,
        postNewsResult: null,
        loading: true,
        error: false,
      };
    case actions.POST_NEWS_SUCCESS:
      return {
        ...state,
        postNewsResult: action.payload.data,
        loading: false,
        error: false,
      };
    case actions.POST_NEWS_FAIL:
      return {
        ...state,
        postNewsResult: null,
        loading: false,
        error: action.payload.err,
      };
    case actions.UPDATE_NEWS_BY_ID_REQUEST:
      return {
        ...state,
        updateSuccess: null,
        error: false,
      };
    case actions.UPDATE_NEWS_BY_ID_SUCCESS:
      return {
        ...state,
        updateSuccess: true,
        error: false,
      };
    case actions.UPDATE_NEWS_BY_ID_FAIL:
      return {
        ...state,
        updateSuccess: false,
        error: action.payload,
      };
    case actions.UPLOAD_FILE_REQUEST:
      return {
        ...state,
        error: false,
      };
    case actions.UPLOAD_FILE_SUCCESS:
      return {
        ...state,
        uploadResult: [...action.payload.data],
        error: false,
      };
    case actions.UPLOAD_FILE_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case actions.DELETE_NEWS_REQUEST:
      return {
        ...state,
        error: false,
      };
    case actions.DELETE_NEWS_SUCCESS:
      return {
        ...state,
        deleteSuccess: action.payload.data,
        error: false,
      };
    case actions.DELETE_NEWS_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    default:
      return state;
  }
};

export default news;
