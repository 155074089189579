// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.about-head img {
  width: 100%;
  height: 500px;
  opacity: 0.7;
}
.about-body-title {
  color: var(--text-primary);
  font-size: 40px;
  font-weight: 700;
  margin-top: 50px;
  margin-bottom: 10px;
}
.about-body-content {
  color: var(--text-navitem);
  font-weight: 400;
  font-size: 20px;
  margin-bottom: 30px;
}`, "",{"version":3,"sources":["webpack://./src/pages/MainLayout/AboutDMPC/AboutDMPC.scss"],"names":[],"mappings":"AAEI;EACE,WAAA;EACA,aAAA;EACA,YAAA;AADN;AAMI;EACE,0BAAA;EACA,eAAA;EACA,gBAAA;EACA,gBAAA;EACA,mBAAA;AAJN;AAOI;EACE,0BAAA;EACA,gBAAA;EACA,eAAA;EACA,mBAAA;AALN","sourcesContent":[".about {\n  &-head {\n    img {\n      width: 100%;\n      height: 500px;\n      opacity: 0.7;\n    }\n  }\n\n  &-body {\n    &-title {\n      color: var(--text-primary);\n      font-size: 40px;\n      font-weight: 700;\n      margin-top: 50px;\n      margin-bottom: 10px;\n    }\n\n    &-content {\n      color: var(--text-navitem);\n      font-weight: 400;\n      font-size: 20px;\n      margin-bottom: 30px;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
