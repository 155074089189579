import { DEFAULT_SUPPORTED_CHAINS, useEthers } from "@usedapp/core";
import React from "react";
import { Button } from "react-bootstrap";
import "./YieldCoinCard.scss";

const YieldCoinCard = ({ data, showModal, onClick }) => {
  const { account, chainId } = useEthers();

  const cssSelector = (type) => {
    if (type === "Covered Call") {
      return "blue";
    } else if (type === "Cash Secured Put") {
      return "yellow";
    } else {
      return "black";
    }
  };

  return (
    <div>
      <div className={"ysp-title-" + cssSelector(data?.type)}>{data?.type}</div>
      <div className="ysp-assets-item">
        <div className="ysp-assets-item-name">
          <img width="62" height="62" src={data?.logo} />
          <div className="ysp-assets-item-income">
            <h5>{data?.name}</h5>
            <div className="ysp-assets-item-income-apy">
              <h4>{data?.apy}</h4>
              <h5>APY</h5>
            </div>
          </div>
        </div>
        {/*<div className="ysp-assets-item-balance">
        {account && "You: 0 " + data?.name}
  </div>*/}
        <Button
          onClick={() => {
            showModal(true);
            onClick();
          }}
        >
          Stake
        </Button>
      </div>
    </div>
  );
};

export default YieldCoinCard;
