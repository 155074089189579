import { all, call, fork, put, takeLatest } from "redux-saga/effects";
import { apiCall } from "../../../utils/util";
import {
  CREATE_NEW_WALLET_REQUEST,
  GET_BALANCE_BY_ID_REQUEST,
  GET_FUND_TABLE_DATA_REQUEST,
  GET_WALLET_BY_ID_REQUEST,
  POST_DEPOSIT_WALLET_REQUEST,
  POST_WITHDRAW_WALLET_REQUEST,
} from "../../actions";
import {
  createNewWalletFail,
  createNewWalletSuccess,
  getBalanceByIdFail,
  getBalanceByIdSuccess,
  getFundTableDataFail,
  getFundTableDataSuccess,
  getWalletByIdFail,
  getWalletByIdSuccess,
  postDepositWalletFail,
  postDepositWalletSuccess,
  postWithdrawWalletFail,
  postWithdrawWalletSuccess,
} from "../walletService";

function* watchCreateNewWallet() {
  yield takeLatest(CREATE_NEW_WALLET_REQUEST, createNewWallet);
}

function* createNewWallet(action) {
  try {
    let filters = action.payload;

    const res = yield call(apiCall, "get", "create_wallet", filters);
    let result = res.data.data;

    yield put(
      createNewWalletSuccess({
        data: result,
      })
    );
  } catch (err) {
    console.log(err);
    yield put(createNewWalletFail(err));
  }
}

function* watchGetBalanceById() {
  yield takeLatest(GET_BALANCE_BY_ID_REQUEST, getBalanceById);
}

function* getBalanceById(action) {
  try {
    let filters = action.payload;
    const res1 = yield call(
      apiCall,
      "get",
      "balance/1/TUSD/" + action.payload.wallet + "/" + action.payload.id
    );
    const res2 = yield call(
      apiCall,
      "get",
      "balance/1/C5/" + action.payload.wallet + "/" + action.payload.id
    );

    const res3 = yield call(
      apiCall,
      "get",
      "balance/2/BTCp/" + action.payload.wallet + "/" + action.payload.id
    );

    const res4 = yield call(
      apiCall,
      "get",
      "balance/3/TRQ/" + action.payload.wallet + "/" + action.payload.id
    );

    const res5 = yield call(
      apiCall,
      "get",
      "balance/4/QNT/" + action.payload.wallet + "/" + action.payload.id
    );

    let result = {
      TUSD: res1.data.data,
      C5: res2.data.data,
      BTCp: res3.data.data,
      TRQ: res4.data.data,
      QNT: res5.data.data,
    };

    yield put(
      getBalanceByIdSuccess({
        filters,
        data: result,
      })
    );
  } catch (err) {
    console.log(err);
    yield put(getBalanceByIdFail(err));
  }
}

function* watchGetWalletById() {
  yield takeLatest(GET_WALLET_BY_ID_REQUEST, getWalletById);
}

function* getWalletById(action) {
  try {
    const { id } = action.payload;

    const res = yield call(apiCall, "get", "address/" + id);
    let result = res.data.data;
    yield put(
      getWalletByIdSuccess({
        id,
        data: result,
      })
    );
  } catch (err) {
    console.log(err);
    yield put(getWalletByIdFail(err));
  }
}

function* watchPostDepositWallet() {
  yield takeLatest(POST_DEPOSIT_WALLET_REQUEST, postDepositWallet);
}

function* postDepositWallet(action) {
  try {
    let filters = action.payload;

    const res = yield call(apiCall, "post", "wallet/deposit/", filters);
    let result = res.data.data;
    yield put(
      postDepositWalletSuccess({
        data: result,
      })
    );
  } catch (err) {
    console.log(err);
    yield put(postDepositWalletFail(err));
  }
}

function* watchPostWithdrawWallet() {
  yield takeLatest(POST_WITHDRAW_WALLET_REQUEST, postWithdrawWallet);
}

function* postWithdrawWallet(action) {
  try {
    let filters = action.payload;

    const res = yield call(apiCall, "post", "wallet/withdraw/", filters);
    let result = res.data.data;
    yield put(
      postWithdrawWalletSuccess({
        data: result,
        status: res.status,
      })
    );
  } catch (err) {
    console.log(err);
    yield put(postWithdrawWalletFail(err));
  }
}

function* watchGetFundTableData() {
  yield takeLatest(GET_FUND_TABLE_DATA_REQUEST, getFundTableData);
}
function* getFundTableData(action) {
  try {
    const res = yield call(
      apiCall,
      "get",
      "fund/table/" + action.payload.wallet + "/" + action.payload.id
    );
    let result = res.data.data;

    yield put(
      getFundTableDataSuccess({
        data: result,
      })
    );
  } catch (err) {
    console.log(err);
    yield put(getFundTableDataFail(err));
  }
}

export default function* walletSaga() {
  yield all([
    fork(watchCreateNewWallet),
    fork(watchGetBalanceById),
    fork(watchGetWalletById),
    fork(watchPostDepositWallet),
    fork(watchPostWithdrawWallet),
    fork(watchGetFundTableData),
  ]);
}
