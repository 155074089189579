import React, { useEffect, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
import {
  getUserByIdService,
  updateUserAdminRequest,
} from "../../services/admin/adminService";
import FTextInput from "../FTextInput/FTextInput";
import "./FUserDetailsModal.scss";
import FTable from "../FTable/FTable";
import {
  emergencyAdminColumns,
  emergencyColumns,
} from "../../constants/columnConfigurations";

const FUserDetailsModal = (props) => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.admin);
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const initialFilters = {
    id: props.userId,
  };

  const initialFormData = {
    name: user?.name,
    surname: user?.surname,
    email: user?.email,
    is_partner: user?.is_partner,
  };

  const [formData, setFormData] = useState({ ...initialFormData });

  useEffect(() => {
    if (props.userId != undefined) {
      dispatch(getUserByIdService(initialFilters));
    }
  }, [props.userId]);

  useEffect(() => {
    if (user) {
      setFormData({
        name: user?.name,
        surname: user?.surname,
        email: user?.email,
        is_partner: user?.is_partner,
      });
    }
  }, [user]);

  const handleOnChange = (name, value) => {
    setButtonDisabled(false);
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  function handleSubmit(e) {
    e.preventDefault();
    dispatch(updateUserAdminRequest({ id: user.id, formData }));
    Swal.fire("User updated!", "User updated successfully.", "success").then(
      () => window.location.reload()
    );
  }

  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  const emergencyAdminConfig = emergencyAdminColumns({});

  return (
    <Modal
      {...props}
      size="xl"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          User details of {capitalizeFirstLetter(String(user?.name))}{" "}
          {capitalizeFirstLetter(String(user?.surname))}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <div className="f_modal-userdetails">
            <FTextInput label={"İsim"} id="name">
              <Form.Control
                value={formData.name || ""}
                name="name"
                onChange={(e) => handleOnChange(e.target.name, e.target.value)}
                type="text"
                placeholder="name@example.com"
              />
            </FTextInput>
            <FTextInput label={"Soyisim"} id="surname">
              <Form.Control
                value={formData.surname || ""}
                name="surname"
                onChange={(e) => handleOnChange(e.target.name, e.target.value)}
                type="text"
                placeholder="name@example.com"
              />
            </FTextInput>
            <FTextInput label={"Email"} id="email">
              <Form.Control
                value={formData.email || ""}
                name="email"
                onChange={(e) => handleOnChange(e.target.name, e.target.value)}
                type="text"
                placeholder="name@example.com"
              />
            </FTextInput>
          </div>
          <Form.Group className="mt-3" controlId="formBasicCheckbox">
            <Form.Check
              name="is_partner"
              type="checkbox"
              label="Is partner?"
              checked={formData.is_partner}
              onChange={(e) => handleOnChange(e.target.name, e.target.checked)}
            />
          </Form.Group>

          {/* 
          <div className="f_modal-userdetails mt-4">
            <FTextInput label={'Deposit Wallet'} id="deposit">
              <Form.Control
                //onChange={(event) => setPassword(event.target.value)}
                type="text"
                placeholder="name@example.com"
                value={userDeposit}
              />
            </FTextInput>
            <FTextInput label={'Withdraw Wallet'} id="withdraw">
              <Form.Control
                //onChange={(event) => setPassword(event.target.value)}
                type="text"
                placeholder="name@example.com"
                value={userWithdraw}
              />
            </FTextInput>
          </div>
          */}
        </Form>

        {user && (
          <div
            style={{
              marginTop: "16px",
              display: "flex",
              gap: "8px",
              flexDirection: "column",
            }}
          >
            <h5>Emergency Contacts</h5>
            <FTable
              columns={emergencyAdminConfig}
              data={[
                {
                  email: user?.EmergencyContact?.email,
                  phone: user?.EmergencyContact?.phone,
                  name: user?.EmergencyContact?.name,
                  surname: user?.EmergencyContact?.surname,
                },
              ]}
            />
          </div>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button
          disabled={buttonDisabled}
          variant="primary"
          onClick={handleSubmit}
        >
          Save Changes
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default FUserDetailsModal;
