import React from 'react';
import { ListGroup } from 'react-bootstrap';
import './FGuide.scss';
import Image from 'react-bootstrap/Image';
import image from '../../assets/images/about4.jpeg';
import { useTranslation } from 'react-i18next';

const FGuide = () => {
  const { t } = useTranslation();
  return (
    <div className="f_guide">
      <div className="f_guide-title">{t('guide_guide')}</div>
      <ListGroup as="ol" variant="flush">
        <ListGroup.Item
          as="li"
          className="d-flex justify-content-between align-items-start p-0"
        >
          <Image
            style={{
              width: '100px',
              height: '100px',
            }}
            className="pt-2 pb-2"
            fluid="true"
            src={image}
          ></Image>
          <div className="ms-2 me-auto text-start pt-2">
            <div className="fw-bold f_guide-listtitle">Token Claim</div>
            <div className="fw-bold f_guide-listsubtitle">
              We run through the Token Claim process in a step-by-step tutorial
              to help you with this simple process.
            </div>
          </div>
        </ListGroup.Item>
      </ListGroup>
    </div>
  );
};

export default FGuide;
