// authentication
export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const LOGIN_SUCCEEDED = "LOGIN_SUCCEEDED";
export const LOGIN_FAILED = "LOGIN_FAILED";
export const FORGOT_PASSWORD_REQUEST = "FORGOT_PASSWORD_REQUEST";
export const FORGOT_PASSWORD_SUCCEEDED = "FORGOT_PASSWORD_SUCCEEDED";
export const FORGOT_PASSWORD_FAILED = "FORGOT_PASSWORD_FAILED";
export const RESET_PASSWORD_REQUEST = "RESET_PASSWORD_REQUEST";
export const RESET_PASSWORD_SUCCEEDED = "RESET_PASSWORD_SUCCEEDED";
export const RESET_PASSWORD_FAILED = "RESET_PASSWORD_FAILED";
export const TWOFA_ACTIVATE_REQUEST = "TWOFA_ACTIVATE_REQUEST";
export const TWOFA_ACTIVATE_SUCCEEDED = "TWOFA_ACTIVATE_SUCCEEDED";
export const TWOFA_ACTIVATE_FAILED = "TWOFA_ACTIVATE_FAILED";
export const TWOFA_LOGIN_REQUEST = "TWOFA_LOGIN_REQUEST";
export const TWOFA_LOGIN_SUCCEEDED = "TWOFA_LOGIN_SUCCEEDED";
export const TWOFA_LOGIN_FAILED = "TWOFA_LOGIN_FAILED";
export const TWOFA_GENERATE_REQUEST = "TWOFA_GENERATE_REQUEST";
export const TWOFA_GENERATE_SUCCEEDED = "TWOFA_GENERATE_SUCCEEDED";
export const TWOFA_GENERATE_FAILED = "TWOFA_GENERATE_FAILED";
export const USER_INFO_REQUEST = "USER_INFO_REQUEST";
export const USER_INFO_SUCCEEDED = "USER_INFO_SUCCEEDED";
export const USER_INFO_FAILED = "USER_INFO_FAILED";
export const SIGNUP_REQUEST = "SIGNUP_REQUEST";
export const SIGNUP_SUCCEEDED = "SIGNUP_SUCCEEDED";
export const SIGNUP_FAILED = "SIGNUP_FAILED";
export const LOGOUT = "LOGOUT";
export const NAVIGATE_OTP = "NAVIGATE_OTP";
export const UPDATE_USER_BY_ID_REQUEST = "UPDATE_USER_BY_ID_REQUEST";
export const UPDATE_USER_BY_ID_SUCCESS = "UPDATE_USER_BY_ID_SUCCESS";
export const UPDATE_USER_BY_ID_FAIL = "UPDATE_USER_BY_ID_FAIL";

export const EMERGENCY_CONTACT_CREATE_BY_ID_REQUEST =
  "EMERGENCY_CONTACT_CREATE_BY_ID_REQUEST";
export const EMERGENCY_CONTACT_CREATE_BY_ID_SUCCESS =
  "EMERGENCY_CONTACT_CREATE_BY_ID_SUCCESS";
export const EMERGENCY_CONTACT_CREATE_BY_ID_FAIL =
  "EMERGENCY_CONTACT_CREATE_BY_ID_FAIL";
export const EMERGENCY_CONTACT_UPDATE_BY_ID_REQUEST =
  "EMERGENCY_CONTACT_UPDATE_BY_ID_REQUEST";
export const EMERGENCY_CONTACT_UPDATE_BY_ID_SUCCESS =
  "EMERGENCY_CONTACT_UPDATE_BY_ID_SUCCESS";
export const EMERGENCY_CONTACT_UPDATE_BY_ID_FAIL =
  "EMERGENCY_CONTACT_UPDATE_BY_ID_FAIL";
export const EMERGENCY_CONTACT_DELETE_BY_ID_REQUEST =
  "EMERGENCY_CONTACT_DELETE_BY_ID_REQUEST";
export const EMERGENCY_CONTACT_DELETE_BY_ID_SUCCESS =
  "EMERGENCY_CONTACT_DELETE_BY_ID_SUCCESS";
export const EMERGENCY_CONTACT_DELETE_BY_ID_FAIL =
  "EMERGENCY_CONTACT_DELETE_BY_ID_FAIL";
export const EMERGENCY_CONTACT_READ_BY_ID_REQUEST =
  "EMERGENCY_CONTACT_READ_BY_ID_REQUEST";
export const EMERGENCY_CONTACT_READ_BY_ID_SUCCESS =
  "EMERGENCY_CONTACT_READ_BY_ID_SUCCESS";
export const EMERGENCY_CONTACT_READ_BY_ID_FAIL =
  "EMERGENCY_CONTACT_READ_BY_ID_FAIL";

// fund
export const GET_FUNDS_SERVICE = "GET_FUNDS_SERVICE";
export const GET_FUNDS_SERVICE_SUCCEED = "GET_FUNDS_SERVICE_SUCCEED";
export const GET_FUNDS_SERVICE_FAILED = "GET_FUNDS_SERVICE_FAILED";
export const GET_FUND_BY_ID_SERVICE = "GET_FUND_BY_ID_SERVICE";
export const GET_FUND_BY_ID_SERVICE_SUCCEED = "GET_FUND_BY_ID_SERVICE_SUCCEED";
export const GET_FUND_BY_ID_SERVICE_FAILED = "GET_FUND_BY_ID_SERVICE_FAILED";
export const GET_FUND_FEE_BY_ID_SERVICE = "GET_FUND_FEE_BY_ID_SERVICE";
export const GET_FUND_FEE_BY_ID_SERVICE_SUCCEED =
  "GET_FUND_FEE_BY_ID_SERVICE_SUCCEED";
export const GET_FUND_FEE_BY_ID_SERVICE_FAILED =
  "GET_FUND_FEE_BY_ID_SERVICE_FAILED";
export const GET_LAST_PRICES_SERVICE = "GET_LAST_PRICES_SERVICE";
export const GET_LAST_PRICES_SERVICE_SUCCEED =
  "GET_LAST_PRICES_SERVICE_SUCCEED";
export const GET_LAST_PRICES_SERVICE_FAILED = "GET_LAST_PRICES_SERVICE_FAILED";
export const GET_FUND_PRICE_BY_ID_SERVICE = "GET_FUND_PRICE_BY_ID_SERVICE";
export const GET_FUND_PRICE_BY_ID_SERVICE_SUCCEED =
  "GET_FUND_PRICE_BY_ID_SERVICE_SUCCEED";
export const GET_FUND_PRICE_BY_ID_SERVICE_FAILED =
  "GET_FUND_PRICE_BY_ID_SERVICE_FAILED";
export const POST_FUND_SERVICE = "POST_FUND_SERVICE";
export const POST_FUND_SERVICE_SUCCEED = "POST_FUND_SERVICE_SUCCEED";
export const POST_FUND_SERVICE_FAILED = "POST_FUND_SERVICE_FAILED";
export const UPDATE_FUND_SERVICE = "UPDATE_FUND_SERVICE";
export const UPDATE_FUND_SERVICE_SUCCEED = "UPDATE_FUND_SERVICE_SUCCEED";
export const UPDATE_FUND_SERVICE_FAILED = "UPDATE_FUND_SERVICE_FAILED";
export const BUY_FUND_BY_ID_SERVICE = "BUY_FUND_BY_ID_SERVICE";
export const BUY_FUND_BY_ID_SERVICE_SUCCEED = "BUY_FUND_BY_ID_SERVICE_SUCCEED";
export const BUY_FUND_BY_ID_SERVICE_FAILED = "BUY_FUND_BY_ID_SERVICE_FAILED";
export const WITHDRAW_FUND_BY_ID_SERVICE = "WITHDRAW_FUND_BY_ID_SERVICE";
export const WITHDRAW_FUND_BY_ID_SERVICE_SUCCEED =
  "WITHDRAW_FUND_BY_ID_SERVICE_SUCCEED";
export const WITHDRAW_FUND_BY_ID_SERVICE_FAILED =
  "WITHDRAW_FUND_BY_ID_SERVICE_FAILED";
export const REDEEM_FUND_BY_ID_SERVICE = "REDEEM_FUND_BY_ID_SERVICE";
export const REDEEM_FUND_BY_ID_SERVICE_SUCCEED =
  "REDEEM_FUND_BY_ID_SERVICE_SUCCEED";
export const REDEEM_FUND_BY_ID_SERVICE_FAILED =
  "REDEEM_FUND_BY_ID_SERVICE_FAILED";

export const GET_USER_TRANSACTIONS_BY_ID_REQUEST =
  "GET_USER_TRANSACTIONS_BY_ID_REQUEST";
export const GET_USER_TRANSACTIONS_BY_ID_SUCCESS =
  "GET_USER_TRANSACTIONS_BY_ID_SUCCESS";
export const GET_USER_TRANSACTIONS_BY_ID_FAIL =
  "GET_USER_TRANSACTIONS_BY_ID_FAIL";

export const GET_FUND_PRICE_MOVEMENTS_REQUEST =
  "GET_FUND_PRICE_MOVEMENTS_REQUEST";
export const GET_FUND_PRICE_MOVEMENTS_SUCCESS =
  "GET_FUND_PRICE_MOVEMENTS_SUCCESS";
export const GET_FUND_PRICE_MOVEMENTS_FAIL = "GET_FUND_PRICE_MOVEMENTS_FAIL";

// wallet
export const CREATE_NEW_WALLET_REQUEST = "CREATE_NEW_WALLET_REQUEST";
export const CREATE_NEW_WALLET_SUCCESS = "CREATE_NEW_WALLET_SUCCESS";
export const CREATE_NEW_WALLET_FAIL = "CREATE_NEW_WALLET_FAIL";
export const GET_BALANCE_BY_ID_REQUEST = "GET_BALANCE_BY_ID_REQUEST";
export const GET_BALANCE_BY_ID_SUCCESS = "GET_BALANCE_BY_ID_SUCCESS";
export const GET_BALANCE_BY_ID_FAIL = "GET_BALANCE_BY_ID_FAIL";
export const GET_WALLET_BY_ID_REQUEST = "GET_WALLET_BY_ID_REQUEST";
export const GET_WALLET_BY_ID_SUCCESS = "GET_WALLET_BY_ID_SUCCESS";
export const GET_WALLET_BY_ID_FAIL = "GET_WALLET_BY_ID_FAIL";
export const POST_DEPOSIT_WALLET_REQUEST = "POST_DEPOSIT_WALLET_REQUEST";
export const POST_DEPOSIT_WALLET_SUCCESS = "POST_DEPOSIT_WALLET_SUCCESS";
export const POST_DEPOSIT_WALLET_FAIL = "POST_DEPOSIT_WALLET_FAIL";
export const POST_WITHDRAW_WALLET_REQUEST = "POST_WITHDRAW_WALLET_REQUEST";
export const POST_WITHDRAW_WALLET_SUCCESS = "POST_WITHDRAW_WALLET_SUCCESS";
export const POST_WITHDRAW_WALLET_FAIL = "POST_WITHDRAW_WALLET_FAIL";

// news
export const GET_NEWS_REQUEST = "GET_NEWS_REQUEST";
export const GET_NEWS_SUCCESS = "GET_NEWS_SUCCESS";
export const GET_NEWS_FAIL = "GET_NEWS_FAIL";
export const GET_NEWS_BY_ID_REQUEST = "GET_NEWS_BY_ID_REQUEST";
export const GET_NEWS_BY_ID_SUCCESS = "GET_NEWS_BY_ID_SUCCESS";
export const GET_NEWS_BY_ID_FAIL = "GET_NEWS_BY_ID_FAIL";
export const POST_NEWS_REQUEST = "POST_NEWS_REQUEST";
export const POST_NEWS_SUCCESS = "POST_NEWS_SUCCESS";
export const POST_NEWS_FAIL = "POST_NEWS_FAIL";
export const UPDATE_NEWS_BY_ID_REQUEST = "UPDATE_NEWS_BY_ID_REQUEST";
export const UPDATE_NEWS_BY_ID_SUCCESS = "UPDATE_NEWS_BY_ID_SUCCESS";
export const UPDATE_NEWS_BY_ID_FAIL = "UPDATE_NEWS_BY_ID_FAIL";
export const UPLOAD_FILE_REQUEST = "UPLOAD_FILE_REQUEST";
export const UPLOAD_FILE_SUCCESS = "UPLOAD_FILE_SUCCESS";
export const UPLOAD_FILE_FAIL = "UPLOAD_FILE_FAIL";
export const DELETE_NEWS_REQUEST = "DELETE_NEWS_REQUEST";
export const DELETE_NEWS_SUCCESS = "DELETE_NEWS_SUCCESS";
export const DELETE_NEWS_FAIL = "DELETE_NEWS_FAIL";

// admin
export const GET_TRANSACTIONS_REQUEST = "GET_TRANSACTIONS_REQUEST";
export const GET_TRANSACTIONS_SUCCESS = "GET_TRANSACTIONS_SUCCESS";
export const GET_TRANSACTIONS_FAIL = "GET_TRANSACTIONS_FAIL";
export const GET_FEES_REQUEST = "GET_FEES_REQUEST";
export const GET_FEES_SUCCESS = "GET_FEES_SUCCESS";
export const GET_FEES_FAIL = "GET_FEES_FAIL";
export const GET_DEPOSITS_REQUEST = "GET_DEPOSITS_REQUEST";
export const GET_DEPOSITS_SUCCESS = "GET_DEPOSITS_SUCCESS";
export const GET_DEPOSITS_FAIL = "GET_DEPOSITS_FAIL";
export const GET_USERS_REQUEST = "GET_USERS_REQUEST";
export const GET_USERS_SUCCESS = "GET_USERS_SUCCESS";
export const GET_USERS_FAIL = "GET_USERS_FAIL";
export const GET_USERS_CENSORED_REQUEST = "GET_USERS_CENSORED_REQUEST";
export const GET_USERS_CENSORED_SUCCESS = "GET_USERS_CENSORED_SUCCESS";
export const GET_USERS_CENSORED_FAIL = "GET_USERS_CENSORED_FAIL";
export const GET_PARTNERS_REQUEST = "GET_PARTNERS_REQUEST";
export const GET_PARTNERS_SUCCESS = "GET_PARTNERS_SUCCESS";
export const GET_PARTNERS_FAIL = "GET_PARTNERS_FAIL";
export const GET_USER_BY_ID_REQUEST = "GET_USER_BY_ID_REQUEST";
export const GET_USER_BY_ID_SUCCESS = "GET_USER_BY_ID_SUCCESS";
export const GET_USER_BY_ID_FAIL = "GET_USER_BY_ID_FAIL";
export const GET_PARTNER_BY_ID_REQUEST = "GET_PARTNER_BY_ID_REQUEST";
export const GET_PARTNER_BY_ID_SUCCESS = "GET_PARTNER_BY_ID_SUCCESS";
export const GET_PARTNER_BY_ID_FAIL = "GET_PARTNER_BY_ID_FAIL";
export const GET_TRANSACTION_BY_ID_REQUEST = "GET_TRANSACTION_BY_ID_REQUEST";
export const GET_TRANSACTION_BY_ID_SUCCESS = "GET_TRANSACTION_BY_ID_SUCCESS";
export const GET_TRANSACTION_BY_ID_FAIL = "GET_TRANSACTION_BY_ID_FAIL";
export const APPROVE_TRANSACTION_REQUEST = "APPROVE_TRANSACTION_REQUEST";
export const APPROVE_TRANSACTION_SUCCESS = "APPROVE_TRANSACTION_SUCCESS";
export const APPROVE_TRANSACTION_FAIL = "APPROVE_TRANSACTION_FAIL";
export const CANCEL_TRANSACTION_REQUEST = "CANCEL_TRANSACTION_REQUEST";
export const CANCEL_TRANSACTION_SUCCESS = "CANCEL_TRANSACTION_SUCCESS";
export const CANCEL_TRANSACTION_FAIL = "CANCEL_TRANSACTION_FAIL";
export const APPROVE_FEE_REQUEST = "APPROVE_FEE_REQUEST";
export const APPROVE_FEE_SUCCESS = "APPROVE_FEE_SUCCESS";
export const APPROVE_FEE_FAIL = "APPROVE_FEE_FAIL";
export const APPROVE_PARTNER_FEE_REQUEST = "APPROVE_PARTNER_FEE_REQUEST";
export const APPROVE_PARTNER_FEE_SUCCESS = "APPROVE_PARTNER_FEE_SUCCESS";
export const APPROVE_PARTNER_FEE_FAIL = "APPROVE_PARTNER_FEE_FAIL";
export const INTERNAL_TRANSFER_REQUEST = "INTERNAL_TRANSFER_REQUEST";
export const INTERNAL_TRANSFER_SUCCESS = "INTERNAL_TRANSFER_SUCCESS";
export const INTERNAL_TRANSFER_FAIL = "INTERNAL_TRANSFER_FAIL";
export const BNBTRADER_BUY_REQUEST = "BNBTRADER_BUY_REQUEST";
export const BNBTRADER_BUY_SUCCESS = "BNBTRADER_BUY_SUCCESS";
export const BNBTRADER_BUY_FAIL = "BNBTRADER_BUY_FAIL";
export const BNBTRADER_SELL_REQUEST = "BNBTRADER_SELL_REQUEST";
export const BNBTRADER_SELL_SUCCESS = "BNBTRADER_SELL_SUCCESS";
export const BNBTRADER_SELL_FAIL = "BNBTRADER_SELL_FAIL";
export const BNBTRADER_BALANCE_REQUEST = "BNBTRADER_BALANCE_REQUEST";
export const BNBTRADER_BALANCE_SUCCESS = "BNBTRADER_BALANCE_SUCCESS";
export const BNBTRADER_BALANCE_FAIL = "BNBTRADER_BALANCE_FAIL";
export const UPDATE_USER_BY_ID_ADMIN_REQUEST =
  "UPDATE_USER_BY_ID_ADMIN_REQUEST";
export const UPDATE_USER_BY_ID_ADMIN_SUCCESS =
  "UPDATE_USER_BY_ID_ADMIN_SUCCESS";
export const UPDATE_USER_BY_ID_ADMIN_FAIL = "UPDATE_USER_BY_ID_ADMIN_FAIL";

export const GET_TOTAL_SUPPLY_CHART_DATA_REQUEST =
  "GET_TOTAL_SUPPLY_CHART_DATA_REQUEST";
export const GET_TOTAL_SUPPLY_CHART_DATA_SUCCESS =
  "GET_TOTAL_SUPPLY_CHART_DATA_SUCCESS";
export const GET_TOTAL_SUPPLY_CHART_DATA_FAIL =
  "GET_TOTAL_SUPPLY_CHART_DATA_FAIL";

export const GET_TOTAL_USER_CHART_DATA_REQUEST =
  "GET_TOTAL_USER_CHART_DATA_REQUEST";
export const GET_TOTAL_USER_CHART_DATA_SUCCESS =
  "GET_TOTAL_USER_CHART_DATA_SUCCESS";
export const GET_TOTAL_USER_CHART_DATA_FAIL = "GET_TOTAL_USER_CHART_DATA_FAIL";
export const GET_EMERGENCY_CONTACTS_REQUEST = "GET_EMERGENCY_CONTACTS_REQUEST";
export const GET_EMERGENCY_CONTACTS_SUCCESS = "GET_EMERGENCY_CONTACTS_SUCCESS";
export const GET_EMERGENCY_CONTACTS_FAIL = "GET_EMERGENCY_CONTACTS_FAIL";

//METRICS

export const GET_TOTAL_USER_COUNT_REQUEST = "GET_TOTAL_USER_COUNT_REQUEST";
export const GET_TOTAL_USER_COUNT_SUCCESS = "GET_TOTAL_USER_COUNT_SUCCESS";
export const GET_TOTAL_USER_COUNT_FAIL = "GET_TOTAL_USER_COUNT_FAIL";

export const GET_TOTAL_TRANSACTION_COUNT_REQUEST =
  "GET_TOTAL_TRANSACTION_COUNT_REQUEST";
export const GET_TOTAL_TRANSACTION_COUNT_SUCCESS =
  "GET_TOTAL_TRANSACTION_COUNT_SUCCESS";
export const GET_TOTAL_TRANSACTION_COUNT_FAIL =
  "GET_TOTAL_TRANSACTION_COUNT_FAIL";

export const GET_TOTAL_DEPOSIT_COUNT_REQUEST =
  "GET_TOTAL_DEPOSIT_COUNT_REQUEST";
export const GET_TOTAL_DEPOSIT_COUNT_SUCCESS =
  "GET_TOTAL_DEPOSIT_COUNT_SUCCESS";
export const GET_TOTAL_DEPOSIT_COUNT_FAIL = "GET_TOTAL_DEPOSIT_COUNT_FAIL";

export const GET_TOTAL_PROFIT_COUNT_REQUEST = "GET_TOTAL_PROFIT_COUNT_REQUEST";
export const GET_TOTAL_PROFIT_COUNT_SUCCESS = "GET_TOTAL_PROFIT_COUNT_SUCCESS";
export const GET_TOTAL_PROFIT_COUNT_FAIL = "GET_TOTAL_PROFIT_COUNT_FAIL";

export const GET_TOTAL_FUND_COUNT_REQUEST = "GET_TOTAL_FUND_COUNT_REQUEST";
export const GET_TOTAL_FUND_COUNT_SUCCESS = "GET_TOTAL_FUND_COUNT_SUCCESS";
export const GET_TOTAL_FUND_COUNT_FAIL = "GET_TOTAL_FUND_COUNT_FAIL";

//APPROVE COUNT

export const GET_APPROVE_COUNT_REQUEST = "GET_APPROVE_COUNT_REQUEST";
export const GET_APPROVE_COUNT_SUCCESS = "GET_APPROVE_COUNT_SUCCESS";
export const GET_APPROVE_COUNT_FAIL = "GET_APPROVE_COUNT_FAIL";

//TODAY INSIGHTS

export const GET_TODAY_INSIGHTS_REQUEST = "GET_TODAY_INSIGHTS_REQUEST";
export const GET_TODAY_INSIGHTS_SUCCESS = "GET_TODAY_INSIGHTS_SUCCESS";
export const GET_TODAY_INSIGHTS_FAIL = "GET_TODAY_INSIGHTS_FAIL";

//TODAY INSIGHTS

export const GET_TOTAL_USERS_REQUEST = "GET_TOTAL_USERS_REQUEST";
export const GET_TOTAL_USERS_SUCCESS = "GET_TOTAL_USERS_SUCCESS";
export const GET_TOTAL_USERS_FAIL = "GET_TOTAL_USERS_FAIL";

//AUM DATA

export const GET_AUM_DATA_REQUEST = "GET_AUM_DATA_REQUEST";
export const GET_AUM_DATA_SUCCESS = "GET_AUM_DATA_SUCCESS";
export const GET_AUM_DATA_FAIL = "GET_AUM_DATA_FAIL";

//GENERAL CHART DATA
export const GET_GENERAL_CHART_DATA_REQUEST = "GET_GENERAL_CHART_DATA_REQUEST";
export const GET_GENERAL_CHART_DATA_SUCCESS = "GET_GENERAL_CHART_DATA_SUCCESS";
export const GET_GENERAL_CHART_DATA_FAIL = "GET_GENERAL_CHART_DATA_FAIL";

//GENERAL CHART DATA
export const GET_ALL_GENERAL_CHART_DATA_REQUEST =
  "GET_ALL_GENERAL_CHART_DATA_REQUEST";
export const GET_ALL_GENERAL_CHART_DATA_SUCCESS =
  "GET_ALL_GENERAL_CHART_DATA_SUCCESS";
export const GET_ALL_GENERAL_CHART_DATA_FAIL =
  "GET_ALL_GENERAL_CHART_DATA_FAIL";
//GENERAL CHART DATA
export const GET_FUND_REPORTS_REQUEST = "GET_FUND_REPORTS_REQUEST";
export const GET_FUND_REPORTS_SUCCESS = "GET_FUND_REPORTS_SUCCESS";
export const GET_FUND_REPORTS_FAIL = "GET_FUND_REPORTS_FAIL";

export const GET_TOTAL_RESULTS_FEE_REQUEST = "GET_TOTAL_RESULTS_FEE_REQUEST";
export const GET_TOTAL_RESULTS_FEE_SUCCESS = "GET_TOTAL_RESULTS_FEE_SUCCESS";
export const GET_TOTAL_RESULTS_FEE_FAIL = "GET_TOTAL_RESULTS_FEE_FAIL";

//GET FUND TABLE DATA
export const GET_FUND_TABLE_DATA_REQUEST = "GET_FUND_TABLE_DATA_REQUEST";
export const GET_FUND_TABLE_DATA_SUCCESS = "GET_FUND_TABLE_DATA_SUCCESS";
export const GET_FUND_TABLE_DATA_FAIL = "GET_FUND_TABLE_DATA_FAIL";
