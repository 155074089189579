import React, { useEffect, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
import "./DEmergencyModal.scss";
import {
  Autocomplete,
  Box,
  Chip,
  FormLabel,
  Grid,
  LinearProgress,
  MenuItem,
  TextField,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { AccountCircle, Mail, Phone } from "@mui/icons-material";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { getEmergencyContactsRequest } from "../../services/admin/adminService";
import {
  createEmergencyRequest,
  deleteEmergencyRequest,
  readEmergencyRequest,
  updateEmergencyRequest,
} from "../../services/authentication/authenticationService";

const DEmergencyModal = (props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { userList, emergencyContactsList } = useSelector(
    (state) => state.admin
  );
  const {
    updateUserResult,
    emergencyContactResult,
    emergencyContactDeleteResult,
    emergencyContactUpdateResult,
    emergencyContactCreateResult,
    loading,
  } = useSelector((state) => state.authentication);
  const [buttonDisabled, setButtonDisabled] = useState(true);

  console.log(props);
  const initialFormData = {
    user_id: props.userId,
    email: "",
    phone: "",
    name: "",
    surname: "",
  };

  const [formData, setFormData] = useState({ ...initialFormData });
  console.log(props);
  console.log(formData);

  useEffect(() => {
    dispatch(readEmergencyRequest({ id: parseInt(props.userId) }));
    if (emergencyContactResult?.length > 0) {
      setFormData({
        user_id: props.userId,
        email: emergencyContactResult[0]?.email,
        phone: emergencyContactResult[0]?.phone,
        name: emergencyContactResult[0]?.name,
        surname: emergencyContactResult[0]?.surname,
      });
      setButtonDisabled(false);
    }
  }, [props.show]);

  const handleOnChange = (name, value) => {
    setButtonDisabled(false);
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  function handleSubmit(e) {
    e.preventDefault();
    console.log(props.update);

    Swal.fire({
      title: "Are you sure?",
      showCancelButton: true,
      confirmButtonText: "Yes",
      denyButtonText: `Don't save`,
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        if (props.update == true) {
          dispatch(updateEmergencyRequest(formData));
          Swal.fire("Success!", "", "success").then(() =>
            window.location.reload()
          );
        } else {
          dispatch(createEmergencyRequest(formData));
          Swal.fire("Success!", "", "success").then(() =>
            window.location.reload()
          );
        }
      } else if (result.isDenied) {
        Swal.fire("Canceled", "", "info");
      }
    });
  }

  function handleDelete(e) {
    e.preventDefault();

    Swal.fire({
      title: "Are you sure you want to delete?",
      showCancelButton: true,
      confirmButtonText: "Yes",
      denyButtonText: `Don't save`,
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        dispatch(deleteEmergencyRequest({ id: parseInt(props.userId) }));
        Swal.fire("Success!", "Deleted successfully!", "success").then(() =>
          window.location.reload()
        );
      } else if (result.isDenied) {
        Swal.fire("Canceled", "", "info");
      }
    });
  }

  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {t("accountsettings_ec_title")}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Grid container spacing={3}>
          {loading == false ? (
            <Grid item sm={6} xs={6}>
              <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                <AccountCircleIcon
                  sx={{ color: "action.active", mr: 1, my: 0.5 }}
                />
                <TextField
                  fullWidth
                  id="input-with-sx"
                  label="Name"
                  variant="standard"
                  defaultValue={formData.name}
                  name="name"
                  onChange={(e) => {
                    handleOnChange(e.target.name, e.target.value);
                  }}
                />
              </Box>
            </Grid>
          ) : (
            <Grid item sm={6} xs={6}>
              <LinearProgress />
            </Grid>
          )}

          {loading == false ? (
            <Grid item sm={6} xs={6}>
              <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                <AccountCircleIcon
                  sx={{ color: "action.active", mr: 1, my: 0.5 }}
                />
                <TextField
                  fullWidth
                  id="input-with-sx"
                  label="Surname"
                  variant="standard"
                  defaultValue={formData.surname}
                  name="surname"
                  onChange={(e) => {
                    handleOnChange(e.target.name, e.target.value);
                  }}
                />
              </Box>
            </Grid>
          ) : (
            <Grid item sm={6} xs={6}>
              <LinearProgress />
            </Grid>
          )}

          {loading == false ? (
            <Grid item sm={12} xs={12}>
              <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                <Mail sx={{ color: "action.active", mr: 1, my: 0.5 }} />
                <TextField
                  fullWidth
                  id="input-with-sx"
                  label="Email"
                  variant="standard"
                  defaultValue={formData.email}
                  name="email"
                  onChange={(e) => {
                    handleOnChange(e.target.name, e.target.value);
                  }}
                />
              </Box>
            </Grid>
          ) : (
            <Grid item sm={12} xs={12}>
              <LinearProgress />
            </Grid>
          )}

          {loading == false ? (
            <Grid item sm={12} xs={12}>
              <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                <Phone sx={{ color: "action.active", mr: 1, my: 0.5 }} />
                <TextField
                  fullWidth
                  id="input-with-sx"
                  label="Phone"
                  variant="standard"
                  name="phone"
                  defaultValue={formData.phone}
                  onChange={(e) => {
                    handleOnChange(e.target.name, e.target.value);
                  }}
                />
              </Box>
            </Grid>
          ) : (
            <Grid item sm={12} xs={12}>
              <LinearProgress />
            </Grid>
          )}
        </Grid>
      </Modal.Body>
      <Modal.Footer>
        {emergencyContactResult?.length != 0 && (
          <Button variant="danger" onClick={handleDelete}>
            Delete
          </Button>
        )}
        <Button
          disabled={buttonDisabled}
          variant="primary"
          onClick={handleSubmit}
        >
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default DEmergencyModal;
