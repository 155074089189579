import React from 'react';
import { Container } from 'react-bootstrap';
import './AboutDMPC.scss';
import about4 from '../../../assets/images/about4.jpeg';

const AboutDMPC = ({ language }) => {
  return (
    <>
      <div className="about">
        <div className="about-head">
          <img src={about4}></img>
          <div className="about-head-text">
            {language === 'tr' ? 'dMPC Hakkında' : 'About dMPC'}
          </div>
        </div>
        <Container>
          <div className="about-body">
            <div className="about-body-title">
              {language === 'tr' ? 'What is MPC?' : 'What is MPC?'}
            </div>
            <div className="about-body-content">
              <p>
                Multi-party computation (MPC) is a cryptographic technique
                invented by Chinese computer scientist Andrew Yao in the 1980s.
              </p>
              <p>
                The innovation is reflected in Yao’s{' '}
                <a
                  href="https://en.wikipedia.org/wiki/Yao%27s_Millionaires%27_problem"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  “Millionaires' Problem”
                </a>
                , which aims to solve the issue of two secretive wealthy
                individuals seeking to find out which is richer without
                divulging the exact amount of money that they have.
              </p>
              <p>
                MPC works by using complex encryption to distribute computation
                efforts between multiple parties.
              </p>
              <p>
                From anonymising buyers’ offers in Danish beet auctions to
                protecting sensitive medical information in large scale trials,
                MPC has evolved into a favored encryption technique of crypto
                asset users seeking to protect their private keys.
              </p>
              <p>
                By splitting the data across multiple nodes, the data is held in
                a more secure environment where no single stakeholder holds the
                keys to the system.
              </p>
              <p>
                One of the major issues with cryptoasset security remains that
                of private keys, which if lost or compromised can open up assets
                to unknown third parties.
              </p>
              <p>
                <a
                  href="https://www.qredo.com/blog/what-is-multi-party-computation-mpc"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Multi-party computation (MPC)
                </a>{' '}
                is increasingly being used for digital asset custody. This is a
                revolutionary cryptographic technique that allows multiple
                parties to jointly perform mathematical computations without any
                party revealing its secret to the others.
              </p>
              <p>
                What this means is that multiple computers holding private key
                data can work together to solve signature equations without ever
                creating a private key — or exposing any critical information to
                one another. Thus potential hackers cannot obtain the private
                key by compromising a single device, and the single point of
                failure of centralized custody can be eliminated.
              </p>
              <p>
                MPC also offers important advantages over multisig in terms of{' '}
                <a
                  href="https://www.qredo.com/blog/digital-asset-governance-for-investment-institutions"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  governance
                </a>
                 and operational efficiency. For example, the signing threshold
                can be easily changed if all signers agree, rather than having
                to create a completely new wallet as with multisig — an
                operational burden that increases the likelihood of funds being
                sent to the wrong address.
              </p>
              <p>
                However, despite these big potential benefits, most
                implementations of MPC still either hold sensitive private key
                data, making them de facto centralized, or give sensitive
                private key data to the customer — raising the possibility of
                permanent loss through theft or error.
              </p>
              <p>
                MPC instead uses Threshold Signature Schemes (TSS) to create and
                distribute independently held parts of a private key where no
                one single person controls the private key.
              </p>
              <p>
                We are using Qredo dMPC infrastructure to resolve these issues
                by decentralizing private keys completely with a novel
                implementation of MPC we call decentralized multi-party
                computation (dMPC).
              </p>
            </div>
            <div className="about-body-title">
              {language === 'tr' ? 'Decentralized MPC' : 'Decentralized MPC'}
            </div>
            <div className="about-body-content">
              <p>
                Qredo's unique innovation is multi-party computation driven by
                blockchain consensus.
              </p>
              <p>
                From the private key controlling the digital assets, Qredo's MPC
                protocol generates independent secrets that are distributed
                between MPC nodes on a fast-finality blockchain. In most MPC
                implementations, these nodes are typically controlled by the
                same organization, leaving assets exposed to rogue employees,
                conspiring cloud providers, or other colluding partners that
                could potentially abscond with the funds.
              </p>
              <p>
                In Qredo's 
                <a
                  href="https://www.qredo.com/blog/qredos-consensus-driven-mpc"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  decentralized MPC
                </a>
                , each MPC node is housed in a security-hardened tier 4 data
                center, and these data centers are distributed across financial
                hubs around the world, from London to Chicago and Hong Kong.
                When the pathway to decentralization is complete, each MPC node
                will then be controlled by an independent validator.
              </p>
              <p>
                When an asset owner wants to send funds, they coordinate with
                their appointed approvers via the Qredo network to confirm asset
                ownership on the blockchain, creating a consensus that enables
                the asset owner to invoke the MPC nodes to run the MPC protocol
                from their Qredo wallet. The MPC protocol then generates a
                digital signature for the underlying blockchain to send digital
                assets from that address.
              </p>
            </div>
            <div className="about-body-title">
              {language === 'tr' ? 'MPC vs Multisig' : 'MPC vs Multisig'}
            </div>
            <div className="about-body-content">
              <p>
                Multisignature crypto wallets share a similar goal with MPC
                implemented using a Threshold Signature Scheme (MPC TSS): They
                both distribute signing power among multiple parties.
              </p>
              <p>
                The difference is that multisig crypto wallets are secured by
                several distinct on-chain signatures generated by different
                private keys, while MPC relies on a single signature created
                off-chain.
              </p>
              <p>Signing transactions off-chain with MPC has big benefits:</p>
              <p>
                <b>Speed</b>:  Transactions can be signed faster off-chain,
                because they don't rely on transacting with slow underlying
                blockchains.
              </p>
              <p>
                <b>Cost</b>:  Signatures computed off-chain do not incur network
                fees.
              </p>
              <p>
                <b>Privacy</b>: Off-chain signing cannot be viewed on the public
                ledger. This prevents the chain of transactions being
                exposed—something which could reveal sensitive signing schemes
                and workflows to potential attackers.
              </p>
              <p>
                <b>Compatibility</b>: While multisig crypto wallets are tied to
                a specific blockchain, MPC works on the standardized
                cryptographic signature algorithm (ECDSA) that can be
                implemented across 95% of blockchains.
              </p>
              <p>
                <b>Flexibility</b>: Offchain distributed signing allows for
                complex governance schemes that can be more easily configured to
                fit organizational requirements and comply with regulatory
                needs.
              </p>
              <p>
                All considered, MPC offers a number of distinct advantages
                over other forms of crypto custody layered with multisig
                technology. Read more about MPC vs Multisig.
              </p>
              <p>More about MPC and dMPC below articles:</p>
              <p>
                <b>
                  <a
                    href="https://cdn.discordapp.com/attachments/784152625662132235/1054529564623257640/sensors-21-01540-v2.pdf"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Article 1
                  </a>{' '}
                  Using Secure Multi-Party Computation to Protect Privacy on a
                  Permissioned Blockchain
                </b>
              </p>
              <p>
                <b>
                  <a
                    href="https://cdn.discordapp.com/attachments/784152625662132235/1054529564199616612/1408-Multi-layer-decentralized-model-predictive-control-of-large-scale-networked-systems.pdf"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Article 2
                  </a>{' '}
                  Multi-layer Decentralized MPC of Large-scale Networked Systems
                </b>
              </p>
              <p>
                We are expecting to move into Fluid dMPC later on, which you can
                read about it here:
              </p>
              <p>
                <b>
                  <a
                    href="https://cdn.discordapp.com/attachments/784152625662132235/1054528979287154728/2020-754.pdf"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Article 3
                  </a>{' '}
                  Fluid MPC: Secure Multiparty Computation with Dynamic
                  Participants
                </b>
              </p>
              <p>
                <b>
                  <a
                    href="https://cdn.discordapp.com/attachments/784152625662132235/1054530811963129918/RH-2022-02-multiparty-computation-Matt-OGrady-pp8.pdf"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Article 4
                  </a>{' '}
                  An overview of secure multiparty computation and its
                  application to digital asset custody
                </b>
              </p>
            </div>
          </div>
        </Container>
      </div>
    </>
  );
};

export default AboutDMPC;
