import * as services from "../actions";
export const getNewsRequest = (reqParams) => ({
  type: services.GET_NEWS_REQUEST,
  payload: reqParams,
});

export const getNewsSuccess = (reqParams) => ({
  type: services.GET_NEWS_SUCCESS,
  payload: reqParams,
});

export const getNewsFail = (error) => ({
  type: services.GET_NEWS_FAIL,
  payload: error,
});

export const getNewsByIdRequest = (reqParams) => ({
  type: services.GET_NEWS_BY_ID_REQUEST,
  payload: reqParams,
});

export const getNewsByIdSuccess = ({ id, data }) => ({
  type: services.GET_NEWS_BY_ID_SUCCESS,
  payload: {
    id,
    data,
  },
});

export const getNewsByIdFail = (error) => ({
  type: services.GET_NEWS_BY_ID_FAIL,
  payload: error,
});

export const postNewsRequest = (reqParams) => ({
  type: services.POST_NEWS_REQUEST,
  payload: reqParams,
});

export const postNewsSuccess = (reqParams) => ({
  type: services.POST_NEWS_SUCCESS,
  payload: reqParams,
});

export const postNewsFail = (error) => ({
  type: services.POST_NEWS_FAIL,
  payload: error,
});

export const updateNewsByIdRequest = (reqParams) => ({
  type: services.UPDATE_NEWS_BY_ID_REQUEST,
  payload: reqParams,
});

export const updateNewsByIdSuccess = ({ id, data }) => ({
  type: services.UPDATE_NEWS_BY_ID_SUCCESS,
  payload: {
    id,
    data,
  },
});

export const updateNewsByIdFail = (error) => ({
  type: services.UPDATE_NEWS_BY_ID_FAIL,
  payload: error,
});

export const uploadFileRequest = (reqParams) => ({
  type: services.UPLOAD_FILE_REQUEST,
  payload: reqParams,
});

export const uploadFileSuccess = (reqParams) => ({
  type: services.UPLOAD_FILE_SUCCESS,
  payload: reqParams,
});

export const uploadFileFail = (error) => ({
  type: services.UPLOAD_FILE_FAIL,
  payload: error,
});

export const deleteNewsRequest = (reqParams) => ({
  type: services.DELETE_NEWS_REQUEST,
  payload: reqParams,
});

export const deleteNewsSuccess = (reqParams) => ({
  type: services.DELETE_NEWS_SUCCESS,
  payload: reqParams,
});

export const deleteNewsFail = (error) => ({
  type: services.DELETE_NEWS_FAIL,
  payload: error,
});
