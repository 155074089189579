import React, { useEffect } from "react";
import "./PublicLayout.scss";
import { Outlet } from "react-router-dom";
import FNavbar from "../../components/FNavbar/FNavbar";

const PublicLayout = ({ switchTheme, theme }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const userRole = localStorage.getItem("userRole");
  return (
    <div
      style={{ display: "flex", flexDirection: "column", minHeight: "100vh" }}
      className="publicdiv"
    >
      <FNavbar switchTheme={switchTheme} theme={theme} isAdmin={userRole} />
      <Outlet />
    </div>
  );
};

export default PublicLayout;
