import React, { useEffect, useState } from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import { Button, Form, Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { checkDepositWallet, checkWithdrawWallet } from "../../utils/util";
import FTextInput from "../FTextInput/FTextInput";
import {
  deleteNewsRequest,
  getNewsByIdRequest,
  getNewsRequest,
  postNewsRequest,
  postNewsSuccess,
  updateNewsByIdRequest,
  uploadFileRequest,
} from "../../services/news/newsService";
import ClassicEditor from "@ckeditor/ckeditor5-editor-classic/src/classiceditor";
import DOWEditor from "../DOWEditor/DOWEditor";
import { convertFromRaw, EditorState } from "draft-js";
import Swal from "sweetalert2";
const FNewsDeleteModal = (props) => {
  const dispatch = useDispatch();
  const { deleteSuccess } = useSelector((state) => state.news);

  const handleDeleteOnClick = () => {
    dispatch(deleteNewsRequest({ id: props?.newsId }));
  };
  const initialFilters = {
    filter: {},
  };
  useEffect(() => {
    if (deleteSuccess) {
      props.onHide();
      window.location.reload();
    }
  }, [deleteSuccess]);

  return (
    <Modal
      {...props}
      size="sm"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>Haberi silmek istiyor musunuz</Modal.Body>
      <Modal.Footer>
        <Button onClick={handleDeleteOnClick} style={{ background: "red" }}>
          Sil
        </Button>
        <Button onClick={props.onHide}>Kapat</Button>
      </Modal.Footer>
    </Modal>
  );
};

export default FNewsDeleteModal;
