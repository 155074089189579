import React, { useEffect, useState } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import Swal from 'sweetalert2';

import {
  getFundByIdService,
  updateFundService,
} from '../../services/fund/fundService';
import FTextInput from '../FTextInput/FTextInput';
import './FFundDetailsModal.scss';

const FFundDetailsModal = (props) => {
  const dispatch = useDispatch();
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const { fund, loading } = useSelector((state) => state.fund);

  const initialFilters = {
    id: props.fundId,
  };

  const initialFormData = {
    name: fund?.name,
    token: fund?.token,
    performance_fee: fund?.performance_fee,
    management_fee: fund?.management_fee,
    divider: fund?.divider,
    value: fund?.value,
  };

  const [formData, setFormData] = useState({ ...initialFormData });
  useEffect(() => {
    if (props.fundId != undefined) {
      dispatch(getFundByIdService(initialFilters));
    }
  }, [props.fundId]);

  useEffect(() => {
    if (fund) {
      setFormData({
        name: fund?.name,
        token: fund?.token,
        performance_fee: fund?.performance_fee,
        management_fee: fund?.management_fee,
        divider: fund?.divider,
        value: fund?.value,
      });
    }
  }, [fund]);

  const handleOnChange = (name, value) => {
    setButtonDisabled(false);
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  function handleSubmit(e) {
    e.preventDefault();
    dispatch(updateFundService({ id: fund.id, formData }));
    Swal.fire('Fund updated!', 'Fund updated successfully.', 'success').then(
      () => window.location.reload()
    );
  }

  return (
    <Modal
      {...props}
      size="xl"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Fund details of {fund?.name}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <div className="f_modal-userdetails">
            <FTextInput label={'İsim'} id="name">
              <Form.Control
                value={formData.name || ''}
                name="name"
                onChange={(e) => handleOnChange(e.target.name, e.target.value)}
                type="text"
                placeholder="name@example.com"
              />
            </FTextInput>
            <FTextInput label={'Token'} id="token">
              <Form.Control
                value={formData.token || ''}
                name="token"
                onChange={(e) => handleOnChange(e.target.name, e.target.value)}
                type="text"
                placeholder="name@example.com"
              />
            </FTextInput>
          </div>
          <div className="f_modal-userdetails mt-4">
            <FTextInput label={'Performance Fee %'} id="performance_fee">
              <Form.Control
                value={formData.performance_fee || ''}
                name="performance_fee"
                onChange={(e) => handleOnChange(e.target.name, e.target.value)}
                type="text"
                placeholder="name@example.com"
              />
            </FTextInput>
            <FTextInput label={'Management Fee %'} id="management_fee">
              <Form.Control
                value={formData.management_fee || ''}
                name="management_fee"
                onChange={(e) => handleOnChange(e.target.name, e.target.value)}
                type="text"
                placeholder="name@example.com"
              />
            </FTextInput>
          </div>
          <div className="f_modal-userdetails mt-4">
            <FTextInput label={'Divider'} id="divider">
              <Form.Control
                value={formData.divider || ''}
                name="divider"
                onChange={(e) => handleOnChange(e.target.name, e.target.value)}
                type="text"
                placeholder="name@example.com"
              />
            </FTextInput>
            {props.fundId != '1' ? (
              <FTextInput label={'Value'} id="value">
                <Form.Control
                  value={formData.value || ''}
                  name="value"
                  onChange={(e) =>
                    handleOnChange(e.target.name, e.target.value)
                  }
                  type="text"
                  placeholder="name@example.com"
                />
              </FTextInput>
            ) : (
              <></>
            )}
          </div>

          {/* 
          <div className="f_modal-userdetails mt-4">
            <FTextInput label={'Deposit Wallet'} id="deposit">
              <Form.Control
                //onChange={(event) => setPassword(event.target.value)}
                type="text"
                placeholder="name@example.com"
                value={userDeposit}
              />
            </FTextInput>
            <FTextInput label={'Withdraw Wallet'} id="withdraw">
              <Form.Control
                //onChange={(event) => setPassword(event.target.value)}
                type="text"
                placeholder="name@example.com"
                value={userWithdraw}
              />
            </FTextInput>
          </div>
          */}
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button
          disabled={buttonDisabled}
          variant="primary"
          onClick={handleSubmit}
        >
          Save Changes
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default FFundDetailsModal;
