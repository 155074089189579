import i18next from "i18next";
import React, { useEffect } from "react";
import { Spinner } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { miniFundPriceTransactionColumns } from "../../constants/columnConfigurations";
import {
  getFundPriceMovementsService,
  getUserTransactionsByIdService,
} from "../../services/fund/fundService";
import FTable from "../FTable/FTable";
import "./FFundList.scss";

const FFundList = () => {
  const { t } = useTranslation();

  const user = JSON.parse(localStorage.getItem("user"));
  const dispatch = useDispatch();

  const getLanguage = i18next.language || window.localStorage.i18nextLng;

  const { loadingMovements, fundPriceMovements } = useSelector(
    (state) => state.fund
  );

  useEffect(() => {
    dispatch(getFundPriceMovementsService());
  }, []);

  const fundPriceColumnsConfig = miniFundPriceTransactionColumns({
    language: getLanguage,
  });

  return (
    <div className="f_fundlist">
      <div className="f_fundlist-title">{t("ffundlist_price")}</div>
      {loadingMovements ? (
        <Spinner animation="grow" size="sm" />
      ) : fundPriceMovements?.length !== 0 ? (
        <FTable data={fundPriceMovements} columns={fundPriceColumnsConfig} />
      ) : (
        <div className="f_fundlist-noinfo">{t("ffundlist_noinfo")}</div>
      )}
    </div>
  );
};

export default FFundList;
