// add validation messages
/**
 * @param {string} validateType type of validation.
 * @param {*} validateValue value of validation.
 */
const getMessage = (validateType, validateValue) => {
  const msgs = {
    required: 'This field is required',
    minLength: `Minimum ${validateValue} characters`,
    length: `Please enter less than ${validateValue} characters`,
    maxLength: `Please enter less than ${validateValue} characters`,
    isAllowChars: 'Only allowed characters can be used.',
    phoneLength: '10 rakamdan oluşmalıdır',
    isEmail: 'Please enter a valid email address.',
    isNumeric: "0'dan büyük olmalı",
    isPercent: "100'den büyük olmamalı",
    isPosition: 'Geçersiz konum',
    isValidCampaignCodeGenerator: 'Gerekli alanları doldurunuz.',
    isWebSite: 'Geçersiz web sitesi.',
    isCapital: 'At least one capital letter',
    isPassword: 'Passwords do not match.',
  };
  return msgs[validateType];
};

// add validations
/**
 * @param {string} dataValue any value.
 * @param {string} validateKey type of validation.
 * @param {string} validateValue value of validation.
 */
const checkValidate = (dataValue, validateKey, validateValue) => {
  if (
    (validateKey === 'required' &&
      (!dataValue ||
        (typeof dataValue === 'string' && dataValue.trim() === '') ||
        (Array.isArray(dataValue) && dataValue.length === 0))) ||
    dataValue === false
  ) {
    return getMessage(validateKey);
  }

  if (validateKey === 'isAllowChars') {
    const allowCharsRegexp = new RegExp(/[a-zA-Z '-]+$/);

    let test = allowCharsRegexp.test(dataValue);
    if (dataValue === '' || !dataValue) {
      test = true;
    }

    if (!test) {
      return getMessage(validateKey, validateValue);
    }
  }

  if (validateKey === 'minLength' && dataValue.trim().length < validateValue) {
    return getMessage(validateKey, validateValue);
  }
  if (validateKey === 'isCapital') {
    // match all capital letters and store in array x
    var capitalLetters = dataValue.match(/[A-Z]/g);
    // if x is null, or has length less than 2 then string is valid
    if (capitalLetters) {
    } else {
      return getMessage(validateKey, validateValue);
    }
  }
  if (validateKey === 'isPassword') {
    // match all capital letters and store in array x
    if (validateValue == dataValue) {
    } else {
      return getMessage(validateKey, validateValue);
    }
  }

  if (validateKey === 'maxLength' && dataValue.trim().length > validateValue) {
    return getMessage(validateKey, validateValue);
  }

  if (validateKey === 'length' && dataValue.trim().length !== validateValue) {
    return getMessage(validateKey, validateValue);
  }
  if (
    validateKey === 'phoneLength' &&
    dataValue.trim().length < validateValue
  ) {
    return getMessage(validateKey, validateValue);
  }
  if (validateKey === 'isEmail') {
    const emailRegexp = new RegExp(
      /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/
    );
    let test = emailRegexp.test(dataValue);

    if (dataValue === '' || !dataValue) {
      test = true;
    }

    if (!test) {
      return getMessage(validateKey, validateValue);
    }
  }
  if (validateKey === 'isNumeric' && +dataValue === 0) {
    return getMessage(validateKey);
  }
  if (validateKey === 'isPercent' && dataValue > 100) {
    return getMessage(validateKey);
  }
  if (
    validateKey === 'isPosition' &&
    (dataValue?.lat || dataValue?.lng) &&
    !checkIsValidCoordinates(dataValue?.lat, dataValue?.lng)
  ) {
    return getMessage(validateKey);
  }
  if (validateKey === 'isWebSite') {
    var test = true;
    if (dataValue) {
      test = isValidUrl(dataValue);
    }
    if (!test) {
      return getMessage(validateKey);
    }
  }
  return '';
};

export const validate = (dataObj, validateObj) => {
  let errorsObj = {};
  for (let key in validateObj) {
    for (let type in validateObj[key]) {
      let msg;
      if (type === 'isPercent') {
        if (dataObj[validateObj[key][type]] === 'PERCENT') {
          msg = checkValidate(dataObj[key], type, validateObj[key][type]);
        }
      } else {
        msg = checkValidate(dataObj[key], type, validateObj[key][type]);
      }
      if (msg) {
        errorsObj = {
          ...errorsObj,
          [key]: msg,
        };
        break;
      }
    }
  }
  return errorsObj;
};

export const checkOnChangeNumeric = (dataValue) => {
  const pattern = new RegExp(/^\d+$/);
  // const pattern = new RegExp(/^(?=.)(?:\d+,)*\d*(?:\.\d+)?$/);
  const isValid = pattern.test(dataValue);
  return isValid;
};

export const checkOnChangeDecimal = (dataValue) => {
  const pattern = new RegExp(/^\d/);
  // const pattern = new RegExp(/^(?=.)(?:\d+,)*\d*(?:\.\d+)?$/);
  const isValid = pattern.test(dataValue);
  return isValid;
};

export const checkIsValidCoordinates = (lat, lng) => {
  if (isNaN(lat) || isNaN(lng) || (lat === '') | (lng === '')) {
    return false;
  }
  if (!isFinite(lat) || -90 > Number(lat) || Number(lat) > 90) {
    return false;
  }
  if (!isFinite(lng) || -180 > Number(lng) || Number(lng) > 180) {
    return false;
  }
  return true;
};

export const isValidUrl = (str) => {
  var pattern = new RegExp(
    '^(https?:\\/\\/)?' + // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
      '(\\#[-a-z\\d_]*)?$',
    'i'
  ); // fragment locator
  return !!pattern.test(str);
};
