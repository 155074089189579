import React, { useEffect } from "react";
import { Spinner } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  getBalanceByIdRequest,
  getWalletByIdRequest,
} from "../../services/wallet/walletService";
import { checkUserTubuWallet } from "../../utils/util";
import FIcon from "../FIcon/FIcon";
import "./FWallets.scss";

const FWallets = () => {
  const user = JSON.parse(localStorage.getItem("user"));
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const {
    wallet,
    createWalletResult,
    loading,
    loadingBalance,
    postSuccess,
    balances,
  } = useSelector((state) => state.wallet);

  const initialFilters = {
    filter: {},
  };

  const walletFilters = {
    id: user.id,
  };

  const balanceFilters = {
    wallet: checkUserTubuWallet(wallet),
    id: user.id,
  };

  useEffect(() => {
    dispatch(getWalletByIdRequest(walletFilters));
  }, []);

  useEffect(() => {
    if (wallet.length !== 0) {
      dispatch(getBalanceByIdRequest(balanceFilters));
    }
  }, [wallet]);

  const { t } = useTranslation();

  return (
    <div className="f_wallets">
      <div className="f_wallets-title">{t("wallets_wallet")}</div>
      <div className="f_wallets-value">
        <h1> {t("wallets_portfolio")}</h1>
        {loadingBalance || balances?.TUSD?.amount_usd == undefined ? (
          <Spinner animation="grow" size="sm" />
        ) : (
          <h2>
            $
            {balances?.length !== 0
              ? (
                  parseFloat(balances?.TUSD?.amount_usd) +
                  parseFloat(balances?.C5?.amount_usd) +
                  parseFloat(balances?.BTCp?.amount_usd) +
                  parseFloat(balances?.TRQ?.amount_usd) +
                  parseFloat(balances?.QNT?.amount_usd)
                )?.toFixed(4)
              : "0"}
          </h2>
        )}
      </div>
      {/* 
      <div className="f_wallets-box mb-2 mt-3">
        <div className="f_wallets-box-title">
          <div
            style={{
              background: '#d9d9d9',
              padding: '5px',
              borderRadius: '100%',
              width: '17px',
              height: '17px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <FIcon name="profile" color="black" size="10" />
          </div>
          Linked Address
        </div>
        <div className="f_wallets-box-value">$534.45</div>
      </div>
      <div className="f_wallets-box mb-2">
        <div className="f_wallets-box-title">
          <div
            style={{
              background: '#d9d9d9',
              padding: '5px',
              borderRadius: '100%',
              width: '17px',
              height: '17px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              marginLeft: '20px',
            }}
          >
            <FIcon name="profile" color="black" size="10" />
          </div>
          Staking vault
        </div>
        <div className="f_wallets-box-value">$0.00</div>
      </div>
      <div className="f_wallets-box mb-2">
        <div className="f_wallets-box-title">
          <div
            style={{
              background: '#d9d9d9',
              padding: '5px',
              borderRadius: '100%',
              width: '17px',
              height: '17px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <FIcon name="profile" color="black" size="10" />
          </div>
          Linked Address
        </div>
        <div className="f_wallets-box-value">$534.45</div>
      </div>
      <div className="f_wallets-box mb-2">
        <div className="f_wallets-box-title">
          <div
            style={{
              background: '#d9d9d9',
              padding: '5px',
              borderRadius: '100%',
              width: '17px',
              height: '17px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              marginLeft: '20px',
            }}
          >
            <FIcon name="profile" color="black" size="10" />
          </div>
          Staking vault
        </div>
        <div className="f_wallets-box-value">$0.00</div>
      </div>
      */}
      <div
        className="f_wallets-footer mt-3 text-center"
        onClick={() => navigate("/wallets")}
      >
        {t("wallets_go")}
      </div>
    </div>
  );
};

export default FWallets;
