import i18next from "i18next";
import React, { useEffect, useState } from "react";
import { Button, Card, Container, Form, Spinner } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import FIcon from "../../../../components/FIcon/FIcon";
import FTextInput from "../../../../components/FTextInput/FTextInput";
import {
  getFundByIdService,
  getFundPriceByIdService,
  redeemFundByIdService,
} from "../../../../services/fund/fundService";
import {
  getBalanceByIdRequest,
  getWalletByIdRequest,
} from "../../../../services/wallet/walletService";
import { checkUserTubuWallet } from "../../../../utils/util";
import "./Redeem.scss";

const Redeem = ({ theme }) => {
  const { t } = useTranslation();

  const getLanguage = i18next.language || window.localStorage.i18nextLng;

  const fundId = window.location.href.split("redeem?id=")[1];
  const user = JSON.parse(localStorage.getItem("user"));
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const { fund, fundPrice, redeemFundResult, redeemFundStatus } = useSelector(
    (state) => state.fund
  );
  const { wallet, balances, loading } = useSelector((state) => state.wallet);
  const [amountUsd, setAmountUsd] = useState();
  //const [amountPerformance, setAmountPerformance] = useState(0.0);
  //const [amountManagement, setAmountManagement] = useState(0.0);
  const [balance, setBalance] = useState(0.0);
  const [amountDow, setAmountDow] = useState();
  const [textUsd, setTextUsd] = useState("");
  const [textDow, setTextDow] = useState("");
  const [error, setError] = useState("");
  const [disabled, setDisabled] = useState(true);
  const [submitted, setSubmitted] = useState(false);

  const initialFilters = {
    id: fundId,
  };

  const walletFilters = {
    id: user.id,
  };

  const balanceFilters = {
    wallet: checkUserTubuWallet(wallet),
    id: user.id,
  };

  useEffect(() => {
    dispatch(getFundByIdService(initialFilters));
  }, [fundId]);

  useEffect(() => {
    dispatch(getWalletByIdRequest(walletFilters));
  }, []);

  useEffect(() => {
    dispatch(getFundPriceByIdService(initialFilters));
  }, []);

  useEffect(() => {
    if (wallet.length !== 0) {
      dispatch(getBalanceByIdRequest(balanceFilters));
    }

    console.log("ümit", wallet);
  }, [wallet]);

  useEffect(() => {
    if (balances?.length !== 0) {
      if (fundId == 1) {
        setBalance(parseFloat(balances?.C5?.amount)?.toFixed(6));
      } else if (fundId == 2) {
        setBalance(parseFloat(balances?.BTCp?.amount)?.toFixed(6));
      } else if (fundId == 3) {
        setBalance(parseFloat(balances?.TRQ?.amount)?.toFixed(6));
      } else {
        setBalance(parseFloat(balances?.QNT?.amount)?.toFixed(6));
      }
    }
  }, [balances]);

  useEffect(() => {
    setDisabled(false);

    if (parseFloat(amountDow) > balance) {
      setError(t("redeem_insuff_err"));
    } else if (parseFloat(amountDow) <= 0 || !amountDow) {
      setDisabled(true);
      setError("");
    } else {
      setError("");
    }
  }, [amountDow]);

  /*useEffect(() => {
    if (fundPrice) {
      setAmountManagement(
        amountDow *
          parseFloat(fundPrice)?.toFixed(4) *
          (fund?.management_fee / 100)
      );
      setAmountPerformance(
        amountDow *
          parseFloat(fundPrice)?.toFixed(4) *
          (fund?.performance_fee / 100)
      );
      if (amountDow === undefined) {
        setAmountManagement(0.0);
        setAmountPerformance(0.0);
      }
    }
  }, [amountDow]);*/

  useEffect(() => {
    if (
      (redeemFundResult != null || redeemFundResult != undefined) &&
      submitted
    ) {
      if (redeemFundStatus < 400) {
        Swal.fire({
          title: t("redeem_redeem_succ"),
          icon: "success",
          text: `${redeemFundResult}`,
        }).then((result) => {
          setSubmitted(false);
          navigate("/wallets");
        });
      } else {
        Swal.fire({
          title: t("redeem_err"),
          icon: "error",
          text: `${redeemFundResult}`,
        }).then((result) => {
          setSubmitted(false);
          navigate("/wallets");
        });
      }
    }
  }, [redeemFundResult, submitted]);

  function handleSubmit(e) {
    e.preventDefault();

    let data = {
      account_id: user?.id,
      fund_id: fundId,
      amount: amountDow,
      from: wallet.filter((w) => w.network == "TUBU")[0]?.user_address,
      amount_usd: amountUsd,
      commission_fee: 0,
      performance_fee: 0,
      unit_price: fundPrice,
    };
    setSubmitted(true);
    dispatch(redeemFundByIdService(data));
  }

  return (
    <Container className="redeem-container">
      <div className="back" onClick={() => navigate("/invest")}>
        <FIcon
          name="back"
          color={theme == "dark" ? "white" : "black"}
          size="20"
        />
        {t("redeem_back")}
      </div>
      <div className="redeem">
        <h1>{t("redeem_redeem_fund")}</h1>
        <Card className="mb-4">
          <Card.Header>Dow Digital Capital</Card.Header>
          <Card.Body>
            <div className="redeem-info">
              <div className="redeem-info-item">
                <h2>{t("redeem_redeem")}</h2>
                <h3>{getLanguage === "tr" ? fund?.name : fund?.name_en}</h3>
              </div>
              <div className="redeem-info-right">
                {t("redeem_estimated", { money: fund?.token })}
                <h3>
                  {loading || balance == "NaN" ? (
                    <Spinner animation="grow" size="sm" />
                  ) : balances?.length !== 0 ? (
                    balance
                  ) : (
                    "0"
                  )}{" "}
                  {fund?.token}
                </h3>
              </div>
            </div>
            <div className="redeem-info">
              <div className="redeem-info-item">
                <h2>{t("redeem_current")}</h2>
                <h3>
                  {loading ? (
                    <Spinner animation="grow" size="sm" />
                  ) : fundPrice?.length !== 0 ? (
                    parseFloat(fundPrice)?.toFixed(4)
                  ) : (
                    "0"
                  )}
                </h3>
              </div>
            </div>
            <div className="redeem-form">
              <div className="redeem-form-div">
                <h4
                  onClick={() => {
                    setAmountUsd();
                    setAmountDow();
                    //setAmountManagement(0);
                    //setAmountPerformance(0);
                    setTextDow("");
                    setTextUsd("");
                  }}
                >
                  {t("redeem_clear")}
                </h4>
                <h4
                  onClick={() => {
                    setAmountDow(balance);
                    setAmountUsd(balance * parseFloat(fundPrice)?.toFixed(4));
                    /*setAmountManagement(
                      balance *
                        parseFloat(fundPrice)?.toFixed(4) *
                        (fund?.management_fee / 100)
                    );
                    setAmountPerformance(
                      balance *
                        parseFloat(fundPrice)?.toFixed(4) *
                        (fund?.performance_fee / 100)
                    );*/
                    setTextDow("");
                    setTextUsd(t("redeem_est"));
                  }}
                >
                  {t("redeem_all")}
                </h4>
              </div>

              <Form>
                <FTextInput
                  label={t("redeem_amount", {
                    textDow: textDow,
                    money: fund?.token,
                  })}
                  id="amount"
                >
                  <Form.Control
                    className="mb-3"
                    onChange={(event) => {
                      setAmountDow(parseFloat(event.target.value));
                      setAmountUsd(
                        event.target.value * parseFloat(fundPrice)?.toFixed(4)
                      );
                      /*setAmountManagement(
                        event.target.value *
                          parseFloat(fundPrice)?.toFixed(4) *
                          (fund?.management_fee / 100)
                      );
                      setAmountPerformance(
                        event.target.value *
                          parseFloat(fundPrice)?.toFixed(4) *
                          (fund?.performance_fee / 100)
                      );
                      */
                      setTextUsd(t("redeem_est"));
                      setTextDow("");
                    }}
                    type="number"
                    placeholder="100"
                    //defaultValue={maxAmount || amountDow}
                    value={parseFloat(amountDow)}
                  />
                </FTextInput>
                <FTextInput
                  label={t("redeem_usdc", { textUsd: textUsd })}
                  id="amount"
                >
                  <Form.Control
                    onChange={(event) => {
                      setAmountUsd(parseFloat(event.target.value));
                      setAmountDow(
                        event.target.value / parseFloat(fundPrice)?.toFixed(4)
                      );
                      setTextDow(t("redeem_est"));
                      setTextUsd("");
                    }}
                    type="number"
                    placeholder="100"
                    //defaultValue={maxAmount || amountUsd}
                    value={parseFloat(amountUsd)}
                  />
                </FTextInput>
                <div
                  style={{
                    height: "10px",
                  }}
                >
                  <Form.Text className="text-danger">{error}</Form.Text>
                </div>
                {/* 
                <div
                  className="redeem-info"
                  style={{ paddingBottom: '10px', paddingTop: '20px' }}
                >
                  <div className="redeem-info-item">
                    <h3 style={{ fontSize: '12px', opacity: 0.5 }}>
                      {language === 'tr'
                        ? 'Performans ücreti'
                        : 'Performance Fee'}{' '}
                      ({fund?.performance_fee}%): {amountPerformance} USD
                    </h3>
                    <h3 style={{ fontSize: '12px', opacity: 0.5 }}>
                      {language === 'tr' ? 'Yönetim ücreti' : 'Management Fee'}{' '}
                      ({fund?.management_fee}%): {amountManagement} USD
                    </h3>
                    <h3 style={{ fontSize: '16px' }}></h3>
                  </div>
                </div>
                */}
                <Button
                  onClick={handleSubmit}
                  className="redeem-button d-flex justify-content-center align-items-center gap-2"
                  type="submit"
                  disabled={error !== "" || disabled || loading}
                >
                  {t("redeem_fee2")}
                </Button>
              </Form>
            </div>
          </Card.Body>
        </Card>
      </div>
    </Container>
  );
};

export default Redeem;
