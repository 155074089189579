import { t } from "i18next";
import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { Accordion, Spinner } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { transactionWalletColumns } from "../../../constants/columnConfigurations";
import { getUserTransactionsByIdService } from "../../../services/fund/fundService";
import FTable from "../../FTable/FTable";
import "./WTransaction.scss";
const WTransaction = ({ homepage }) => {
  const user = JSON.parse(localStorage.getItem("user"));
  const navigate = useNavigate();

  const [fakeData, setFakeData] = useState([]);
  const ref = useRef(null);
  const [width, setWidth] = useState(0);
  useLayoutEffect(() => {
    setWidth(ref.current.offsetWidth);
  }, []);

  const { t } = useTranslation();

  const transactionWalletConfigs = transactionWalletColumns({
    translateFunction: t,
  });
  function createData(
    action,
    fund,
    qty,
    b_price,
    s_price,
    fee,
    total_amount,
    total,
    date,
    status
  ) {
    return {
      action,
      fund,
      qty,
      b_price,
      s_price,
      fee,
      total_amount,
      total,
      date,
      status,
    };
  }

  const { txList, loadingTxList } = useSelector((state) => state.fund);

  const txListFilters = {
    id: user.id,
    limit: "20",
    page: 1,
    sort: "DESC",
  };
  const dispatch = useDispatch();

  useEffect(() => {
    setFakeData([]);
    dispatch(getUserTransactionsByIdService(txListFilters));
  }, []);

  useEffect(() => {
    setFakeData([]);
    if (txList) {
      if (txList?.length == 1) {
        let total = txList?.amount_usd;
        let action = txList?.action;
        setFakeData([
          createData(
            txList[0]?.action,
            txList[0]?.token,
            parseFloat(txList[0]?.amount).toFixed(4),
            action == "BUY" ? txList[0]?.unit_price : "-",
            action == "SELL" ? txList[0]?.unit_price : "-",
            "-",
            parseFloat(txList[0]?.amount).toFixed(4),
            parseFloat(txList[0]?.amount_usd).toFixed(4),
            new Date(txList[0]?.c_date).toLocaleDateString("tr-TR", {
              year: "numeric",
              month: "numeric",
              day: "numeric",
              hour: "numeric",
              minute: "numeric",
              timeZone: "UTC",
            }),
            txList[0]?.status
          ),
        ]);
      } else {
        let total = 0;
        for (let i = 0; i < txList?.length; i++) {
          let action = txList[i]?.action;
          total = total + txList[i]?.amount_usd;
          setFakeData((oldArray) => [
            ...oldArray,
            createData(
              txList[i]?.action,
              txList[i]?.token,
              parseFloat(txList[i]?.amount).toFixed(4),
              action == "BUY" ? txList[0]?.unit_price : "-",
              action == "SELL" ? txList[0]?.unit_price : "-",
              "-",
              parseFloat(txList[i]?.amount).toFixed(4),
              parseFloat(txList[i]?.amount_usd).toFixed(4),
              new Date(txList[i]?.c_date).toLocaleDateString("tr-TR", {
                year: "numeric",
                month: "numeric",
                day: "numeric",
                hour: "numeric",
                minute: "numeric",
                timeZone: "UTC",
              }),
              txList[i]?.status
            ),
          ]);
        }
      }
    }
  }, [txList]);

  return (
    <div className="f_wallet-bg w_transaction" ref={ref}>
      {width > 550 ? (
        <div
          style={{
            overflow: "-moz-scrollbars-vertical",
            overflowY: "scroll",
            maxHeight: "390px",
          }}
        >
          <div>
            <h4>{t("transaction_latest")}</h4>
            <div></div>
          </div>
          {fakeData && loadingTxList ? (
            <Spinner animation="grow" size="sm" />
          ) : (
            <FTable
              columns={transactionWalletConfigs}
              data={fakeData}
              type="fund"
              colored={true}
            />
          )}
        </div>
      ) : (
        <>
          <h4>{t("transaction_latest")}</h4>
          <Accordion style={{ marginTop: "15px" }}>
            {homepage &&
              fakeData?.slice(0, 5)?.map((value, index) => (
                <>
                  <Accordion.Item
                    className={
                      value?.status == "R"
                        ? "bg-red-acc"
                        : value?.status == "E"
                        ? "bg-red-acc"
                        : value?.status == "S"
                        ? "bg-green-acc"
                        : "bg-yellow-acc"
                    }
                    eventKey={index}
                  >
                    <Accordion.Header>
                      <>
                        <div
                          style={{
                            marginRight: "10px",
                            display: "flex",
                            justifyContent: "space-between",
                            width: "100%",
                          }}
                        >
                          <div className="d-flex">
                            {value?.action == "BUY" ||
                              ("WITHDRAW" && (
                                <svg
                                  width="17"
                                  height="17"
                                  viewBox="0 0 17 17"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M8.50024 15.3C4.75175 15.3 1.70025 12.2485 1.70024 8.5C1.70024 4.7515 4.75174 1.7 8.50024 1.7C12.2487 1.7 15.3002 4.7515 15.3002 8.5C15.3002 12.2485 12.2487 15.3 8.50024 15.3ZM8.50025 17C13.1922 17 17.0002 13.192 17.0002 8.5C17.0002 3.808 13.1922 -1.64629e-06 8.50024 -1.32462e-06C3.80824 -1.00295e-06 0.000244577 3.808 0.0002451 8.5C0.000245623 13.192 3.80825 17 8.50025 17ZM7.65024 8.5L7.65025 11.9L9.35024 11.9L9.35024 8.5L11.9002 8.5L8.50024 5.1L5.10024 8.5L7.65024 8.5Z"
                                    fill="#252525"
                                  />
                                </svg>
                              ))}
                            {value?.action == "SELL" ||
                              ("DEPOSIT" && (
                                <svg
                                  width="17"
                                  height="17"
                                  viewBox="0 0 17 17"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M8.5 1.7C12.2485 1.7 15.3 4.7515 15.3 8.5C15.3 12.2485 12.2485 15.3 8.5 15.3C4.7515 15.3 1.7 12.2485 1.7 8.5C1.7 4.7515 4.7515 1.7 8.5 1.7ZM8.5 0C3.808 0 0 3.808 0 8.5C0 13.192 3.808 17 8.5 17C13.192 17 17 13.192 17 8.5C17 3.808 13.192 0 8.5 0ZM9.35 8.5V5.1H7.65V8.5H5.1L8.5 11.9L11.9 8.5H9.35Z"
                                    fill="#4169E1"
                                  />
                                </svg>
                              ))}
                            <div className="d-flex flex-column">
                              <span style={{ marginLeft: "10px" }}>
                                {value?.action} {value?.fund}
                              </span>
                              <div
                                style={{
                                  fontSize: "12px",
                                  fontWeight: "400",
                                  color: "#979797",
                                  marginLeft: "10px",
                                  marginTop: "3px",
                                }}
                              >
                                {value?.date}
                              </div>
                            </div>
                          </div>

                          <h2>
                            {value?.action == "TRANSFER"
                              ? value?.fund + " "
                              : "$"}
                            {value?.action == "TRANSFER"
                              ? parseFloat(value?.total_amount).toFixed(2)
                              : parseFloat(value?.total).toFixed(4)}
                          </h2>
                        </div>
                      </>
                    </Accordion.Header>
                    <Accordion.Body>
                      <div className="w_transaction-acc-desc">
                        <div>
                          <h4>{t("fund")}</h4>
                          <span>{value?.fund}</span>
                        </div>
                        <div>
                          <h4>{t("quantity")}</h4>
                          <span>{value?.qty}</span>
                        </div>
                        {value?.action == "BUY" ? (
                          <>
                            <div>
                              <h4>{t("buy_price")}</h4>
                              <span>
                                ${parseFloat(value?.b_price).toFixed(4)}
                              </span>
                            </div>
                          </>
                        ) : value?.action == "SELL" ? (
                          <>
                            <div>
                              <h4>{t("sell_price")}</h4>
                              <span>
                                ${parseFloat(value?.s_price).toFixed(4)}
                              </span>
                            </div>
                          </>
                        ) : (
                          <></>
                        )}
                        <div>
                          <h4>{t("fee")}</h4>
                          <span>-</span>
                        </div>
                        <div>
                          <h4>{t("total")}</h4>
                          <span>
                            {value?.action == "TRANSFER"
                              ? value?.fund + " "
                              : "$"}
                            {value?.action == "TRANSFER"
                              ? parseFloat(value?.total_amount).toFixed(2)
                              : parseFloat(value?.total).toFixed(4)}
                          </span>
                        </div>
                        <div>
                          <h4>{t("date")}</h4>
                          <span>{value?.date}</span>
                        </div>

                        <div>
                          <h4>{t("status")}</h4>
                          <span>
                            {value?.status == "R"
                              ? t("rejected")
                              : value?.status == "E"
                              ? t("error")
                              : value?.status == "S"
                              ? t("success")
                              : t("approve")}
                          </span>
                        </div>
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                </>
              ))}
            {!homepage &&
              fakeData?.map((value, index) => (
                <>
                  <Accordion.Item
                    className={
                      value?.status == "R"
                        ? "bg-red-acc"
                        : value?.status == "E"
                        ? "bg-red-acc"
                        : value?.status == "S"
                        ? "bg-green-acc"
                        : "bg-yellow-acc"
                    }
                    eventKey={index}
                  >
                    <Accordion.Header>
                      <>
                        <div
                          style={{
                            marginRight: "10px",
                            display: "flex",
                            justifyContent: "space-between",
                            width: "100%",
                          }}
                        >
                          <div className="d-flex">
                            {value?.action == "BUY" ||
                              ("WITHDRAW" && (
                                <svg
                                  width="17"
                                  height="17"
                                  viewBox="0 0 17 17"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M8.50024 15.3C4.75175 15.3 1.70025 12.2485 1.70024 8.5C1.70024 4.7515 4.75174 1.7 8.50024 1.7C12.2487 1.7 15.3002 4.7515 15.3002 8.5C15.3002 12.2485 12.2487 15.3 8.50024 15.3ZM8.50025 17C13.1922 17 17.0002 13.192 17.0002 8.5C17.0002 3.808 13.1922 -1.64629e-06 8.50024 -1.32462e-06C3.80824 -1.00295e-06 0.000244577 3.808 0.0002451 8.5C0.000245623 13.192 3.80825 17 8.50025 17ZM7.65024 8.5L7.65025 11.9L9.35024 11.9L9.35024 8.5L11.9002 8.5L8.50024 5.1L5.10024 8.5L7.65024 8.5Z"
                                    fill="#252525"
                                  />
                                </svg>
                              ))}
                            {value?.action == "SELL" ||
                              ("DEPOSIT" && (
                                <svg
                                  width="17"
                                  height="17"
                                  viewBox="0 0 17 17"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M8.5 1.7C12.2485 1.7 15.3 4.7515 15.3 8.5C15.3 12.2485 12.2485 15.3 8.5 15.3C4.7515 15.3 1.7 12.2485 1.7 8.5C1.7 4.7515 4.7515 1.7 8.5 1.7ZM8.5 0C3.808 0 0 3.808 0 8.5C0 13.192 3.808 17 8.5 17C13.192 17 17 13.192 17 8.5C17 3.808 13.192 0 8.5 0ZM9.35 8.5V5.1H7.65V8.5H5.1L8.5 11.9L11.9 8.5H9.35Z"
                                    fill="#4169E1"
                                  />
                                </svg>
                              ))}
                            <div className="d-flex flex-column">
                              <span style={{ marginLeft: "10px" }}>
                                {value?.action} {value?.fund}
                              </span>
                              <div
                                style={{
                                  fontSize: "12px",
                                  fontWeight: "400",
                                  color: "#979797",
                                  marginLeft: "10px",
                                  marginTop: "3px",
                                }}
                              >
                                {value?.date}
                              </div>
                            </div>
                          </div>

                          <h2>${parseFloat(value?.total).toFixed(4)}</h2>
                        </div>
                      </>
                    </Accordion.Header>
                    <Accordion.Body>
                      <div className="w_transaction-acc-desc">
                        <div>
                          <h4>{t("fund")}</h4>
                          <span>{value?.fund}</span>
                        </div>
                        <div>
                          <h4>{t("quantity")}</h4>
                          <span>{value?.qty}</span>
                        </div>
                        <div>
                          <h4>{t("buy_price")}</h4>
                          <span>-</span>
                        </div>
                        <div>
                          <h4>{t("sell_price")}</h4>
                          <span>-</span>
                        </div>
                        <div>
                          <h4>{t("fee")}</h4>
                          <span>-</span>
                        </div>
                        <div>
                          <h4>{t("total")}</h4>
                          <span>${parseFloat(value?.total).toFixed(4)}</span>
                        </div>
                        <div>
                          <h4>{t("date")}</h4>
                          <span>{value?.date}</span>
                        </div>

                        <div>
                          <h4>{t("status")}</h4>
                          <span>
                            {value?.status == "R"
                              ? t("rejected")
                              : value?.status == "E"
                              ? t("error")
                              : value?.status == "S"
                              ? t("success")
                              : t("approve")}
                          </span>
                        </div>
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                </>
              ))}

            {homepage && (
              <h3
                style={{
                  textAlign: "center",
                  fontSize: "14px",
                  fontWeight: "700",
                  color: "#4169E0",
                  cursor: "pointer",
                  paddingTop: "50px !important",
                  height: "35px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
                onClick={() => navigate("/transactions")}
              >
                {t("transaction_more")}
              </h3>
            )}
          </Accordion>
        </>
      )}
    </div>
  );
};

export default WTransaction;
