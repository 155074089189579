import React, { useEffect, useState } from "react";
import { Button, Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import FCheckbox from "../../../components/FCheckbox/FCheckbox";
import FTextInput from "../../../components/FTextInput/FTextInput";
import { twoFaLoginRequest } from "../../../services/authentication/authenticationService";
import "./OTP.scss";
import { validate } from "../../../utils/validate";
import wolf from "../../../assets/images/model.png";
import { useTranslation } from "react-i18next";
import loginhero from "../../../assets/images/loginhero.png";
import Swal from "sweetalert2";

const OTP = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { error, isTwoFaLogin } = useSelector((state) => state.authentication);

  const initialFormData = {
    user_id: JSON.parse(localStorage.getItem("user"))?.id,
    token: "",
  };

  const [formData, setFormData] = useState({ ...initialFormData });
  const [formDataErrors, setFormDataErrors] = useState({});

  const formDataValidate = {
    token: {
      minLength: 6,
    },
  };

  const handleOnChange = (name, value) => {
    console.log(formData);
    formDataErrors[name] &&
      setFormDataErrors((prevState) => ({
        ...prevState,
        [name]: "",
      }));
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const checkValidation = () => {
    const errorMsgs = validate(formData, formDataValidate);
    if (Object.keys(errorMsgs).length > 0) {
      setFormDataErrors({ ...errorMsgs });
      return false;
    }
    return true;
  };

  function handleSubmit(e) {
    e.preventDefault();
    dispatch(twoFaLoginRequest(formData));
  }
  const { t } = useTranslation();

  useEffect(() => {
    if (error) {
      const Toast = Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 2000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", Swal.stopTimer);
          toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
      });

      Toast.fire({
        icon: "error",
        title: t("reset_password_error"),
      });
    }
  }, [error]);

  useEffect(() => {
    if (isTwoFaLogin) {
      navigate("/dashboard");
      const Toast = Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 2000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", Swal.stopTimer);
          toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
      });

      Toast.fire({
        icon: "success",
        title: t("login_success"),
      });
    }
  }, [isTwoFaLogin]);

  return (
    <div className="flogin">
      <div className="flogin-hero">
        <div className="flogin-hero-container">
          <h1>
            THE FUTURE OF <br />
            DIGITAL INVESTING
          </h1>
          <img src={loginhero} />
        </div>
      </div>
      <div className="flogin-login">
        <h1 className=" text-start mb-3">
          <svg
            width="22"
            height="24"
            viewBox="0 0 22 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M21.0607 13.0607C21.6464 12.4749 21.6464 11.5251 21.0607 10.9393L11.5147 1.3934C10.9289 0.807612 9.97918 0.807612 9.3934 1.3934C8.80761 1.97918 8.80761 2.92893 9.3934 3.51472L17.8787 12L9.3934 20.4853C8.80761 21.0711 8.80761 22.0208 9.3934 22.6066C9.97918 23.1924 10.9289 23.1924 11.5147 22.6066L21.0607 13.0607ZM0 13.5H20V10.5H0V13.5Z"
              fill="#4169E1"
            />
          </svg>
          {t("otp_otp")}
        </h1>
        <Form>
          <div className="mb-3" id="signuppassword" n>
            <FTextInput label={t("otp_code")}>
              <Form.Control
                name="token"
                value={formData.token || ""}
                onChange={(e) => handleOnChange(e.target.name, e.target.value)}
                type="number"
                placeholder=""
                isInvalid={formDataErrors.token}
              />{" "}
            </FTextInput>
            <Form.Text className="text-danger">
              {formDataErrors?.token}
            </Form.Text>
          </div>

          <div className="mb-3 text-center f_login-loginText">
            <Button
              type="submit"
              disabled={formData.token == ""}
              onClick={handleSubmit}
              className="login-button"
            >
              {t("otp_submit")}
            </Button>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default OTP;
