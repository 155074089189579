import React from 'react';
import { Container } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import './Terms.scss';

const Terms = () => {
  const navigate = useNavigate();
  //navigate('/login');
  return (
    <>
      <Container>
        <div className="f_terms">
          <h1>General terms of service, legal and privacy policy</h1>
          <section>
            <h6>GENERAL TERMS OF SERVICE</h6>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut
              malesuada rhoncus tellus, id mollis lacus scelerisque ut. Nullam
              consectetur ipsum enim, et venenatis lacus fermentum eu. Phasellus
              volutpat risus ut feugiat commodo. Suspendisse ac leo sit amet
              justo dignissim ultricies in vel dui. Curabitur ut placerat nulla.
              Vestibulum in est et metus porta placerat. Proin vel lacus eget
              eros dapibus facilisis ac at lectus. Sed eu venenatis ante.
              Quisque condimentum vehicula ultrices. Proin sollicitudin turpis
              non enim dapibus faucibus. Aliquam lobortis ante id ex blandit,
              vel pretium diam molestie. Quisque hendrerit id massa nec
              vestibulum. Donec nec purus velit. Maecenas luctus sem hendrerit
              facilisis posuere. Pellentesque sed nisl nisi.
            </p>
          </section>
        </div>
      </Container>
    </>
  );
};

export default Terms;
