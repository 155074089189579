import React, { useEffect, useState } from "react";
import { Button, Form, Modal, Tab, Tabs } from "react-bootstrap";
import FTextInput from "../../FTextInput/FTextInput";
import "./YieldModal.scss";

const YieldModal = (props) => {
  const [key, setKey] = useState("deposit");

  //when modal closed set tab first tab
  useEffect(() => {
    if (props.show === false) {
      setKey("deposit");
    }
  }, [props.show]);

  return (
    <Modal
      {...props}
      size="lg"
      className="ym"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Tabs
        id="controlled-tab-example"
        activeKey={key}
        onSelect={(k) => setKey(k)}
        className="mb-3"
      >
        <Tab eventKey="deposit" title={<span>Deposit</span>}>
          <div className="ym-content">
            <FTextInput>
              <div>
                <img width="41" height="41" src={props?.data?.logo} />
                <span>{props?.data?.name}</span>
              </div>
              <Form.Control
                name="surname"
                type="number"
                placeholder="0"
                style={{ marginRight: "10px" }}
              />

              <Button>MAX</Button>
            </FTextInput>
            <div className="ym-balances">
              <div>
                <h4>Wallet balance</h4>
                <h5>0 {props?.data?.name}</h5>
              </div>
              <div>
                <h5>Your deposits</h5>
                <h5>0 {props?.data?.name}</h5>
              </div>
              <div>
                <h5>Projected APY</h5>
                <h5>27.6%</h5>
              </div>
            </div>
            <Button>Deposit {props?.data?.name}</Button>
            <div className="ym-stats">
              <div>
                <h4>Underlying asset</h4>
                <h5>Native {props?.data?.name}</h5>
              </div>
              <div>
                <h5>Capacity</h5>
                <h5>65.000/100.000</h5>
              </div>
              <div>
                <h5>{props?.data?.name} price</h5>
                <h5>$9.99</h5>
              </div>
              <div>
                <h5>Fees</h5>
                <h5>%10</h5>
              </div>
            </div>

            <h6>
              Go to Analytics/Details
              <svg
                width="20"
                height="16"
                viewBox="0 0 20 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M19.3836 8.61873C19.7742 8.2282 19.7742 7.59504 19.3836 7.20451L13.0197 0.840553C12.6292 0.450029 11.996 0.450029 11.6055 0.840553C11.2149 1.23108 11.2149 1.86424 11.6055 2.25477L17.2623 7.91162L11.6055 13.5685C11.2149 13.959 11.2149 14.5922 11.6055 14.9827C11.996 15.3732 12.6292 15.3732 13.0197 14.9827L19.3836 8.61873ZM0.735352 8.91162H18.6765V6.91162H0.735352V8.91162Z"
                  fill="#4169E1"
                />
              </svg>
            </h6>
          </div>
        </Tab>
        <Tab eventKey="withdraw" title={<span>Withdraw</span>}>
          <div className="ym-content">
            <FTextInput>
              <div>
                <img width="41" height="41" src={props?.data?.logo} />
                <span>{props?.data?.name}</span>
              </div>
              <Form.Control name="surname" type="number" placeholder="0" />
              <Button>MAX</Button>
            </FTextInput>
            <div className="ym-balances">
              <div>
                <h4>Wallet balance</h4>
                <h5>0 {props?.data?.name}</h5>
              </div>
              <div>
                <h5>Your deposits</h5>
                <h5>0 {props?.data?.name}</h5>
              </div>
              <div>
                <h5>Projected APY</h5>
                <h5>27.6%</h5>
              </div>
            </div>
            <Button>Withdraw {props?.data?.name}</Button>
            <div className="ym-stats">
              <div>
                <h4>Underlying asset</h4>
                <h5>Native {props?.data?.name}</h5>
              </div>
              <div>
                <h5>Capacity</h5>
                <h5>65.000/100.000</h5>
              </div>
              <div>
                <h5>{props?.data?.name} price</h5>
                <h5>$9.99</h5>
              </div>
              <div>
                <h5>Fees</h5>
                <h5>%10</h5>
              </div>
            </div>
            <h6>Go to Analytics/Details</h6>
          </div>
        </Tab>
      </Tabs>
    </Modal>
  );
};

export default YieldModal;
