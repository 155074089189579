// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ycw {
  font-weight: 700 !important;
  display: flex;
  align-items: center;
  width: 100%;
}
.ycw-balance {
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 16px;
  padding: 6px 8px;
  color: white;
  background-color: var(--background-royalblue) !important;
}
.ycw-connected {
  cursor: pointer;
  display: flex;
  align-items: center;
  flex-direction: row;
  border-radius: 10px;
  border: 3px solid var(--background-royalblue);
}
.ycw-connected h2:first-child {
  border-right: 1px solid var(--background-royalblue);
  height: 13px;
  font-size: 14px;
  line-height: 1px;
}
.ycw-connected h2 {
  padding: 6px 8px;
  font-weight: 300;
  font-size: 16px;
}

.ycw-button-secondary {
  width: 100%;
  font-size: 20px !important;
  font-weight: 700 !important;
  background-color: #2c6dcb !important;
  border: none !important;
  height: 40px !important;
}
.ycw-button-secondary:hover {
  background-color: #2c6dcb !important;
}`, "",{"version":3,"sources":["webpack://./src/components/YieldComponents/YieldNavbar/YieldConnectWallet/YieldConnectWallet.scss"],"names":[],"mappings":"AAAA;EACE,2BAAA;EACA,aAAA;EACA,mBAAA;EACA,WAAA;AACF;AACE;EACE,aAAA;EACA,mBAAA;EACA,QAAA;EACA,eAAA;EACA,gBAAA;EACA,YAAA;EACA,wDAAA;AACJ;AACE;EACE,eAAA;EACA,aAAA;EACA,mBAAA;EACA,mBAAA;EACA,mBAAA;EACA,6CAAA;AACJ;AAAI;EACE,mDAAA;EACA,YAAA;EACA,eAAA;EACA,gBAAA;AAEN;AAAI;EACE,gBAAA;EACA,gBAAA;EACA,eAAA;AAEN;;AAMA;EACE,WAAA;EACA,0BAAA;EACA,2BAAA;EACA,oCAAA;EACA,uBAAA;EACA,uBAAA;AAHF;AAKE;EACE,oCAAA;AAHJ","sourcesContent":[".ycw {\n  font-weight: 700 !important;\n  display: flex;\n  align-items: center;\n  width: 100%;\n\n  &-balance {\n    display: flex;\n    align-items: center;\n    gap: 5px;\n    font-size: 16px;\n    padding: 6px 8px;\n    color: white;\n    background-color: var(--background-royalblue) !important;\n  }\n  &-connected {\n    cursor: pointer;\n    display: flex;\n    align-items: center;\n    flex-direction: row;\n    border-radius: 10px;\n    border: 3px solid var(--background-royalblue);\n    h2:first-child {\n      border-right: 1px solid var(--background-royalblue);\n      height: 13px;\n      font-size: 14px;\n      line-height: 1px;\n    }\n    h2 {\n      padding: 6px 8px;\n      font-weight: 300;\n      font-size: 16px;\n    }\n  }\n}\n\n.ycw-button-primary {\n}\n\n.ycw-button-secondary {\n  width: 100%;\n  font-size: 20px !important;\n  font-weight: 700 !important;\n  background-color: #2c6dcb !important;\n  border: none !important;\n  height: 40px !important;\n\n  &:hover {\n    background-color: #2c6dcb !important;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
