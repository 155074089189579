import React, { useEffect, useRef, useState } from "react";
import { Button, Form, Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import FTextInput from "../../../components/FTextInput/FTextInput";
import "./AccountSettings.scss";
import {
  logout,
  readEmergencyRequest,
  updateUserRequest,
  userInfo,
} from "../../../services/authentication/authenticationService";
import {
  getWalletByIdRequest,
  postWithdrawWalletRequest,
} from "../../../services/wallet/walletService";
import Swal from "sweetalert2";
import {
  checkUserWithdrawWallet,
  checkUserWithdrawWalletAndGetLastWallet,
} from "../../../utils/util";
import FIcon from "../../../components/FIcon/FIcon";
import { useTranslation } from "react-i18next";
import F2FAModal from "../../../components/F2FAModal/F2FAModal";
import UserLabel from "../../../components/UserLabel/UserLabel";
import DEmergencyModal from "../../../components/DEmergencyModal/DEmergencyModal";

const AccountSettings = ({ theme }) => {
  const { t } = useTranslation();
  const [twofaModalShow, setTwofaModalShow] = useState(false);
  const [emergencyModalShow, setEmergencyModalShow] = useState(false);

  let loading = false;
  const target = useRef(null);
  const [show, setShow] = useState(false);

  let navigate = useNavigate();
  const dispatch = useDispatch();

  const initialFormData = {
    name: "",
    surname: "",
    email: "",
    password: "",
  };

  const initialWithdrawFormData = {
    address: "",
  };

  const [formData, setFormData] = useState({ ...initialFormData });
  const [withdrawFormData, setWithdrawFormData] = useState({
    ...initialWithdrawFormData,
  });
  const [passwordFormData, setPasswordFormData] = useState({
    ...initialFormData,
  });

  const { wallet, withdrawWalletResult } = useSelector((state) => state.wallet);
  const {
    updateUserResult,
    emergencyContactResult,
    emergencyContactDeleteResult,
    emergencyContactUpdateResult,
    emergencyContactCreateResult,
  } = useSelector((state) => state.authentication);

  const [formDataErrors, setFormDataErrors] = useState({});

  const copyInfo = (text) => {
    const Toast = Swal.mixin({
      toast: true,
      position: "top-end",
      showConfirmButton: false,
      timer: 2000,
      timerProgressBar: true,
      didOpen: (toast) => {
        toast.addEventListener("mouseenter", Swal.stopTimer);
        toast.addEventListener("mouseleave", Swal.resumeTimer);
      },
    });

    Toast.fire({
      icon: "success",
      title: text + " copied to clipboard.",
    });
  };

  const handleOnChange = (name, value) => {
    if (name === "wallet") {
      setWithdrawFormData((prevState) => ({
        ...prevState,
        address: value,
      }));
    } else if (name === "password") {
      setPasswordFormData((prevState) => ({
        ...prevState,
        password: value,
      }));
    } else {
      formDataErrors[name] &&
        setFormDataErrors((prevState) => ({
          ...prevState,
          [name]: "",
        }));
      setFormData((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };

  const user = JSON.parse(localStorage.getItem("user"));

  useEffect(() => {
    setFormData({
      name: user?.name,
      surname: user?.surname,
      email: user?.email,
    });
    setPasswordFormData({
      name: user?.name,
      surname: user?.surname,
      email: user?.email,
    });
  }, []);

  function handleSubmit(e) {
    dispatch(
      updateUserRequest({
        id: user.id,
        formData,
      })
    );
  }

  useEffect(() => {
    if (updateUserResult.length !== 0) {
      Swal.fire(
        t("accountsettings_u1"),
        t("accountsettings_u2"),
        "success"
      ).then(() => window.location.reload());
    }
  }, [updateUserResult]);

  const logOut = () => {
    dispatch(logout());
    navigate("/login");
    const Toast = Swal.mixin({
      toast: true,
      position: "top-end",
      showConfirmButton: false,
      timer: 2000,
      timerProgressBar: true,
      didOpen: (toast) => {
        toast.addEventListener("mouseenter", Swal.stopTimer);
        toast.addEventListener("mouseleave", Swal.resumeTimer);
      },
    });

    Toast.fire({
      icon: "success",
      title: t("accountsettings_logout"),
    });
  };

  const submitWitdrawWallet = () => {
    dispatch(postWithdrawWalletRequest(withdrawFormData));
    if (withdrawWalletResult < 400) {
      Swal.fire(
        t("accountsettings_success1"),
        t("accountsettings_success2"),
        "success"
      ).then(() => window.location.reload());
    } else {
      Swal.fire("Error", "Something went wrong", "error").then(() =>
        window.location.reload()
      );
    }
  };

  const submitPassword = () => {
    dispatch(
      updateUserRequest({
        id: user.id,
        formData: passwordFormData,
      })
    );
    if (updateUserResult < 400) {
      Swal.fire(
        "Password updated!",
        "Password updated successfully!",
        "success"
      ).then(() => window.location.reload());
    } else {
      Swal.fire("Error", "Something went wrong", "error").then(() =>
        window.location.reload()
      );
    }
  };

  const walletFilters = {
    id: user?.id,
  };

  useEffect(() => {
    dispatch(getWalletByIdRequest(walletFilters));
  }, []);

  useEffect(() => {
    dispatch(readEmergencyRequest({ id: parseInt(user?.id) }));
  }, []);

  useEffect(() => {
    dispatch(userInfo({ id: parseInt(user?.id) }));
  }, []);

  useEffect(() => {
    setWithdrawFormData({
      address: checkUserWithdrawWalletAndGetLastWallet(wallet),
    });
  }, [wallet]);

  function handleDisable() {
    // Swal.fire({
    //   title: "Do you really want to disable 2FA?",
    //   showCancelButton: true,
    //   confirmButtonText: "Yes",
    //   denyButtonText: `Don't save`,
    // }).then((result) => {
    //   /* Read more about isConfirmed, isDenied below */
    //   if (result.isConfirmed) {
    //     Swal.fire("Success!", "", "success");
    //     //dispatch(internalTransferRequest(formData));
    //   } else if (result.isDenied) {
    //     Swal.fire("Canceled", "", "info");
    //   }
    // });
  }

  return (
    <>
      <div className="container">
        <UserLabel text={t("accountsettings_title")} />
        <div className="row g-2  f_account">
          <div className="col-sm-8">
            <div className="f_wallet-bg">
              <h4>{t("accountsettings_title")}</h4>
              <h5>User ID: {user?.id}</h5>
              <div className="f_account-l row g-4 mt-4 mb-5">
                <div className="col-lg-6">
                  <FTextInput label={t("accountsettings_name")} id="editName">
                    <Form.Control
                      name="name"
                      value={formData.name || ""}
                      onChange={(e) =>
                        handleOnChange(e.target.name, e.target.value)
                      }
                      type="text"
                      placeholder="Name"
                      isInvalid={formDataErrors.email}
                    />
                  </FTextInput>
                </div>
                <div className="col-lg-6">
                  <FTextInput
                    label={t("accountsettings_surname")}
                    id="editSurname"
                  >
                    <Form.Control
                      name="surname"
                      value={formData.surname || ""}
                      onChange={(e) =>
                        handleOnChange(e.target.name, e.target.value)
                      }
                      type="text"
                      placeholder="Surname"
                      isInvalid={formDataErrors.email}
                    />
                  </FTextInput>
                </div>
                <div className="col-lg-6">
                  <FTextInput label={"Email"} id="editEmail">
                    <Form.Control
                      name="email"
                      value={formData.email || ""}
                      onChange={(e) =>
                        handleOnChange(e.target.name, e.target.value)
                      }
                      type="email"
                      placeholder="name@example.com"
                      isInvalid={formDataErrors.email}
                    />
                  </FTextInput>
                </div>
                <div className="col-lg-6"></div>
                <div className="col-lg-6"></div>
              </div>
              <div className="d-flex justify-content-between">
                <Button
                  onClick={logOut}
                  className="logout_button bg-danger border-0"
                >
                  {t("accountsettings_logout_button")}
                </Button>
                <Button onClick={handleSubmit} style={{ width: "288px" }}>
                  {t("accountsettings_save")}
                </Button>
              </div>
            </div>
            <div
              className="f_account_settings-user"
              style={{ marginTop: "8px", marginBottom: "8px" }}
            >
              <div className="f_account_settings-user-head">
                <h1>{t("accountsettings_2fa")}</h1>
              </div>
              <div className="f_account_settings-user-item">
                <h6>{t("accountsettings_2fa_info")}</h6>
                <Button
                  //onClick={submitWitdrawWallet}
                  onClick={() => {
                    JSON.parse(localStorage.getItem("otp_enabled")) == true
                      ? handleDisable()
                      : setTwofaModalShow(true);
                  }}
                  style={{ minWidth: "113px" }}
                >
                  {JSON.parse(localStorage.getItem("otp_enabled")) == true
                    ? t("accountsettings_2fa_disable")
                    : t("accountsettings_2fa_create")}
                </Button>
              </div>
            </div>
            <div
              className="f_account_settings-user"
              style={{ marginTop: "8px", marginBottom: "8px" }}
            >
              <div className="f_account_settings-user-head">
                <h1>{t("accountsettings_ec_title")}</h1>
              </div>
              <div className="f_account_settings-user-item">
                <h6>{t("accountsettings_ec_info")}</h6>
                {emergencyContactResult ? (
                  <Button
                    onClick={() => {
                      setEmergencyModalShow(true);
                    }}
                    style={{ minWidth: "113px" }}
                  >
                    {emergencyContactResult?.length > 0 == true
                      ? t("accountsettings_ec_update")
                      : t("accountsettings_ec_create")}
                  </Button>
                ) : (
                  <></>
                )}
              </div>
            </div>
            <F2FAModal
              show={twofaModalShow}
              onHide={() => setTwofaModalShow(false)}
              userId={user?.id}
              otp_auth_url={user?.otp_auth_url}
            ></F2FAModal>
            <DEmergencyModal
              show={emergencyModalShow}
              onHide={() => setEmergencyModalShow(false)}
              userId={parseInt(user?.id)}
              update={emergencyContactResult?.length > 0 == true ? true : false}
            ></DEmergencyModal>
          </div>
          <div className="col-sm-4">
            <div className="f_wallet-bg f_account-r">
              <h4>{t("accountsettings_walletinfo")}</h4>
              <div className="mt-5">
                <FTextInput
                  label={t("accountsettings_withdrawwallet")}
                  id="editWithdraw"
                >
                  <Form.Control
                    name="wallet"
                    value={withdrawFormData.address || ""}
                    onChange={(e) =>
                      handleOnChange(e.target.name, e.target.value)
                    }
                    type="text"
                    // isInvalid={formDataErrors.wallet}
                  />
                </FTextInput>
                <Button onClick={submitWitdrawWallet} className="mt-5 w-100">
                  {t("accountsettings_update")}
                </Button>
              </div>

              <h4 className="mt-5">Update Password</h4>
              <div className="mt-5">
                <FTextInput label={"Password"} id="editWithdraw">
                  <Form.Control
                    name="password"
                    onChange={(e) =>
                      handleOnChange(e.target.name, e.target.value)
                    }
                    type="text"
                    // isInvalid={formDataErrors.wallet}
                  />
                </FTextInput>
                <Button onClick={submitPassword} className="mt-5 w-100">
                  {t("accountsettings_update")}
                </Button>
              </div>

              {user?.is_partner == true ? (
                <div
                  className="f_account_settings-user-referral"
                  style={{
                    alignItems: "start !important",
                  }}
                >
                  <div className="f_account_settings-user-head">
                    <h1>{t("accountsettings_partner")}</h1>
                  </div>
                  <div className="f_account_settings-user-item-referral">
                    <span>{t("accountsettings_referral")}</span>{" "}
                    {" " + user?.referral}
                    <Button
                      className="copy_image"
                      ref={target}
                      onClick={() => {
                        navigator.clipboard.writeText(user?.referral);
                        setShow(!show);
                        copyInfo("Referral code");
                        setTimeout(function () {
                          setShow(false);
                        }, 1000);
                      }}
                    >
                      <FIcon
                        name="copytoclipboard"
                        color={theme === "light" ? "black" : "white"}
                        size="18"
                      />
                    </Button>
                  </div>
                  {/* 
          <div className="f_account_settings-user-item">
            <FTextInput
              label={language === 'tr' ? 'E-posta' : 'E-mail'}
              id="editEmail"
            >
              <Form.Control
                name="email"
                value={formData.email || ''}
                onChange={(e) =>
                  handleOnChange(e.target.name, e.target.value)
                }
                type="email"
                placeholder="name@example.com"
                isInvalid={formDataErrors.email}
              />
            </FTextInput>
          </div>*/}
                </div>
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AccountSettings;
