import * as actions from '../../actions';

const initialState = {
  totalUserCount: 'N/A',
  totalTransactionCount: 'N/A',
  totalDepositCount: 'N/A',
  totalFundCount: 'N/A',
  totalProfitCount: 'N/A',
};

const metric = (state = initialState, action) => {
  switch (action.type) {
    case actions.GET_TOTAL_USER_COUNT_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case actions.GET_TOTAL_USER_COUNT_SUCCESS:
      return {
        ...state,
        totalUserCount: action.payload.data,
        loading: false,
        error: null,
      };
    case actions.GET_TOTAL_USER_COUNT_FAIL:
      return {
        ...state,
        totalUserCount: 'N/A',
        loading: false,
        error: action.payload,
      };
    case actions.GET_TOTAL_TRANSACTION_COUNT_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case actions.GET_TOTAL_TRANSACTION_COUNT_SUCCESS:
      return {
        ...state,
        totalTransactionCount: action.payload.data,
        loading: false,
        error: null,
      };
    case actions.GET_TOTAL_TRANSACTION_COUNT_FAIL:
      return {
        ...state,
        totalTransactionCount: 'N/A',
        loading: false,
        error: action.payload,
      };
    case actions.GET_TOTAL_DEPOSIT_COUNT_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case actions.GET_TOTAL_DEPOSIT_COUNT_SUCCESS:
      return {
        ...state,
        totalDepositCount: action.payload.data,
        loading: false,
        error: null,
      };
    case actions.GET_TOTAL_DEPOSIT_COUNT_FAIL:
      return {
        ...state,
        totalDepositCount: 'N/A',
        loading: false,
        error: action.payload,
      };
    case actions.GET_TOTAL_FUND_COUNT_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case actions.GET_TOTAL_FUND_COUNT_SUCCESS:
      return {
        ...state,
        totalFundCount: action.payload.data,
        loading: false,
        error: null,
      };
    case actions.GET_TOTAL_FUND_COUNT_FAIL:
      return {
        ...state,
        totalFundCount: 'N/A',
        loading: false,
        error: action.payload,
      };

    case actions.GET_TOTAL_PROFIT_COUNT_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case actions.GET_TOTAL_PROFIT_COUNT_SUCCESS:
      return {
        ...state,
        totalProfitCount: action.payload.data,
        loading: false,
        error: null,
      };
    case actions.GET_TOTAL_PROFIT_COUNT_FAIL:
      return {
        ...state,
        totalProfitCount: 'N/A',
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default metric;
