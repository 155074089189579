import React, { useEffect, useState } from "react";
import { ProgressBar, Spinner } from "react-bootstrap";
import "./AdminCards.scss";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  getTodayInsightsRequest,
  getTotalUsersRequest,
} from "../../../services/admin/adminService";
import { checkNull, numberWithCommas } from "../../../utils/util";

const AdminCards = () => {
  const { t } = useTranslation();

  const { todayInsights, totalUsers, loadingToday, totalUserCount } =
    useSelector((state) => state.admin);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getTodayInsightsRequest());
    dispatch(getTotalUsersRequest());
  }, [dispatch]);

  return (
    <div className="admin_cards">
      <div className="admin_card variant-one">
        <h1>{t("i_today")}</h1>
        <h2>
          {loadingToday ? (
            <Spinner animation="grow" size="sm" />
          ) : (
            "$" +
            numberWithCommas(
              parseFloat(todayInsights?.invest?.amount)?.toFixed(2)
            )
          )}
        </h2>
        <div className="admin_card-num">
          <svg
            width="26"
            height="17"
            viewBox="0 0 26 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M25.7485 2.04995C25.774 1.52157 25.3293 1.07083 24.7553 1.04319L15.4019 0.592761C14.8279 0.56512 14.342 0.971044 14.3165 1.49942C14.2911 2.02779 14.7358 2.47853 15.3097 2.50617L23.6239 2.90655L23.2553 10.5602C23.2299 11.0886 23.6746 11.5393 24.2485 11.567C24.8225 11.5946 25.3084 11.1887 25.3339 10.6603L25.7485 2.04995ZM13.8842 12.3413L25.4116 2.71178L24.007 1.28802L12.4796 10.9175L13.8842 12.3413Z"
              fill="#00B42D"
            />
            <path
              d="M14.749 11.405L9.53112 7.13302L0.648424 13.4293"
              stroke="#00B42D"
              stroke-width="2"
            />
          </svg>
          <h3>
            {loadingToday ? (
              <Spinner animation="grow" size="sm" />
            ) : (
              "%" +
              parseFloat(checkNull(todayInsights?.invest?.percentage))?.toFixed(
                2
              )
            )}
          </h3>
        </div>
        <h4>
          {t("total_i")}:{" "}
          {loadingToday ? (
            <Spinner animation="grow" size="sm" />
          ) : (
            "$" +
            numberWithCommas(
              parseFloat(todayInsights?.invest?.total)?.toFixed(2)
            )
          )}
        </h4>
      </div>
      <div className="admin_card variant-one">
        <h1>{t("r_today")}</h1>
        <h2>
          {loadingToday ? (
            <Spinner animation="grow" size="sm" />
          ) : (
            "$" +
            numberWithCommas(
              parseFloat(todayInsights?.redeem?.amount)?.toFixed(2)
            )
          )}
        </h2>
        <div className="admin_card-num">
          <svg
            width="26"
            height="17"
            viewBox="0 0 26 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M25.7485 2.04995C25.774 1.52157 25.3293 1.07083 24.7553 1.04319L15.4019 0.592761C14.8279 0.56512 14.342 0.971044 14.3165 1.49942C14.2911 2.02779 14.7358 2.47853 15.3097 2.50617L23.6239 2.90655L23.2553 10.5602C23.2299 11.0886 23.6746 11.5393 24.2485 11.567C24.8225 11.5946 25.3084 11.1887 25.3339 10.6603L25.7485 2.04995ZM13.8842 12.3413L25.4116 2.71178L24.007 1.28802L12.4796 10.9175L13.8842 12.3413Z"
              fill="#00B42D"
            />
            <path
              d="M14.749 11.405L9.53112 7.13302L0.648424 13.4293"
              stroke="#00B42D"
              stroke-width="2"
            />
          </svg>
          <h3>
            {loadingToday ? (
              <Spinner animation="grow" size="sm" />
            ) : (
              "%" + parseFloat(todayInsights?.redeem?.percentage)?.toFixed(2)
            )}
          </h3>
        </div>
        <h4>
          {t("total_r")}:{" "}
          {loadingToday ? (
            <Spinner animation="grow" size="sm" />
          ) : (
            "$" +
            numberWithCommas(
              parseFloat(todayInsights?.redeem?.total)?.toFixed(2)
            )
          )}
        </h4>
      </div>
      <div className="admin_card variant-one">
        <h1>{t("d_today")}</h1>
        <h2>
          {loadingToday ? (
            <Spinner animation="grow" size="sm" />
          ) : (
            "$" +
            numberWithCommas(
              parseFloat(todayInsights?.deposit?.amount)?.toFixed(2)
            )
          )}
        </h2>
        <div className="admin_card-num">
          <svg
            width="26"
            height="17"
            viewBox="0 0 26 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M25.7485 2.04995C25.774 1.52157 25.3293 1.07083 24.7553 1.04319L15.4019 0.592761C14.8279 0.56512 14.342 0.971044 14.3165 1.49942C14.2911 2.02779 14.7358 2.47853 15.3097 2.50617L23.6239 2.90655L23.2553 10.5602C23.2299 11.0886 23.6746 11.5393 24.2485 11.567C24.8225 11.5946 25.3084 11.1887 25.3339 10.6603L25.7485 2.04995ZM13.8842 12.3413L25.4116 2.71178L24.007 1.28802L12.4796 10.9175L13.8842 12.3413Z"
              fill="#00B42D"
            />
            <path
              d="M14.749 11.405L9.53112 7.13302L0.648424 13.4293"
              stroke="#00B42D"
              stroke-width="2"
            />
          </svg>
          <h3>
            {loadingToday ? (
              <Spinner animation="grow" size="sm" />
            ) : todayInsights?.deposit?.percentage == null ? (
              "%" + 0
            ) : (
              "%" + parseFloat(todayInsights?.deposit?.percentage)?.toFixed(2)
            )}
          </h3>
        </div>
        <h4>
          {t("total_d")}:{" "}
          {loadingToday ? (
            <Spinner animation="grow" size="sm" />
          ) : (
            "$" +
            numberWithCommas(
              parseFloat(todayInsights?.deposit?.total)?.toFixed(2)
            )
          )}
        </h4>
      </div>
      <div className="admin_card">
        <div className="admin_card-user">
          <h1>{t("total_u")}</h1>
          <h2>{totalUserCount}</h2>
        </div>
        <div className="admin_card-p" style={{ marginBottom: "23px" }}>
          <h5>{t("fund_users")}</h5>
          {totalUserCount === 0 ? (
            <Spinner animation="grow" size="sm" />
          ) : (
            <ProgressBar max={totalUserCount}>
              {totalUsers?.map((value, index) => {
                if (index == 1) {
                  return (
                    <ProgressBar
                      variant="colorone"
                      now={value?.count}
                      max={totalUserCount}
                      key={index}
                    />
                  );
                } else if (index == 2) {
                  return (
                    <ProgressBar
                      variant="colortwo"
                      now={value?.count}
                      max={totalUserCount}
                      key={index}
                    />
                  );
                } else if (index == 3) {
                  return (
                    <ProgressBar
                      variant="colorthree"
                      now={value?.count}
                      max={totalUserCount}
                      key={index}
                    />
                  );
                }
              })}
            </ProgressBar>
          )}
          <div className="admin_card-p-labels">
            {totalUsers?.map((value, index) => {
              if (index == 0) {
                return (
                  <div className="admin_card-p-label">
                    <div className="admin_card-p-label-color bg-colorone"></div>
                    <span>{value?.token}</span>
                  </div>
                );
              } else if (index == 1) {
                return (
                  <div className="admin_card-p-label">
                    <div className="admin_card-p-label-color bg-colortwo"></div>
                    <span>{value?.token}</span>
                  </div>
                );
              } else if (index == 2) {
                return (
                  <div className="admin_card-p-label">
                    <div className="admin_card-p-label-color bg-colorthree"></div>
                    <span>{value?.token}</span>
                  </div>
                );
              } else {
                return (
                  <div className="admin_card-p-label">
                    <div className="admin_card-p-label-color progress"></div>
                    <span>{value?.token}</span>
                  </div>
                );
              }
            })}
          </div>
        </div>
        <div className="admin_card-p">
          <h5>YIELD</h5>
          <ProgressBar>
            <ProgressBar variant="colorone" now={100} key={1} />
          </ProgressBar>

          <div className="admin_card-p-labels">
            <div className="admin_card-p-label">
              <div className="admin_card-p-label-color bg-colorone"></div>
              <span>Staking</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminCards;
