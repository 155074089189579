import * as actions from "../../actions";

const initialState = {
  transactionList: [],
  feeList: [],
  depositsList: [],
  userList: [],
  partnerList: [],
  user: {},
  loading: false,
  loadingAll: false,
  error: null,
  count: 0,
  userCount: 0,
  updateUserAdminResult: [],
  totalSupplyChartData: [],
  totalUserChartData: [],
  approveResult: null,
  cancelResult: null,
  approveFeeResult: null,
  approvePartnerFeeResult: null,
  internalTransferResult: null,
  approveCount: null,
  todayInsights: null,
  totalUsers: null,
  aumData: null,
  loadingToday: false,
  totalUserCount: 0,
  generalChartData: null,
  generalChartDataAll: null,
  loadingGeneral: false,
  loadingGeneralAll: false,
  fundReports: null,
  totalResultFee: null,
};

const admin = (state = initialState, action) => {
  switch (action.type) {
    case actions.APPROVE_TRANSACTION_REQUEST:
      return {
        ...state,
        approveResult: null,
        loading: true,
        error: null,
        count: 0,
      };
    case actions.APPROVE_TRANSACTION_SUCCESS:
      return {
        ...state,
        approveResult: action.payload.status,
        loading: false,
        error: null,
      };
    case actions.APPROVE_TRANSACTION_FAIL:
      return {
        ...state,
        approveResult: null,
        loading: false,
        error: action.payload,
        count: 0,
      };
    case actions.CANCEL_TRANSACTION_REQUEST:
      return {
        ...state,
        cancelResult: null,
        loading: true,
        error: null,
        count: 0,
      };
    case actions.CANCEL_TRANSACTION_SUCCESS:
      return {
        ...state,
        cancelResult: action.payload.status,
        loading: false,
        error: null,
      };
    case actions.CANCEL_TRANSACTION_FAIL:
      return {
        ...state,
        cancelResult: null,
        loading: false,
        error: action.payload,
        count: 0,
      };
    case actions.APPROVE_FEE_REQUEST:
      return {
        ...state,
        approveFeeResult: null,
        loading: true,
        error: null,
        count: 0,
      };
    case actions.APPROVE_FEE_SUCCESS:
      return {
        ...state,
        approveFeeResult: action.payload.status,
        loading: false,
        error: null,
      };
    case actions.APPROVE_FEE_FAIL:
      return {
        ...state,
        approveFeeResult: null,
        loading: false,
        error: action.payload,
        count: 0,
      };
    case actions.APPROVE_PARTNER_FEE_REQUEST:
      return {
        ...state,
        approvePartnerFeeResult: null,
        loading: true,
        error: null,
        count: 0,
      };
    case actions.APPROVE_PARTNER_FEE_SUCCESS:
      return {
        ...state,
        approvePartnerFeeResult: action.payload.status,
        loading: false,
        error: null,
      };
    case actions.APPROVE_PARTNER_FEE_FAIL:
      return {
        ...state,
        approvePartnerFeeResult: null,
        loading: false,
        error: action.payload,
        count: 0,
      };
    case actions.INTERNAL_TRANSFER_REQUEST:
      return {
        ...state,
        internalTransferResult: null,
        loading: true,
        error: null,
        count: 0,
      };
    case actions.INTERNAL_TRANSFER_SUCCESS:
      return {
        ...state,
        internalTransferResult: action.payload.status,
        loading: false,
        error: null,
      };
    case actions.INTERNAL_TRANSFER_FAIL:
      return {
        ...state,
        internalTransferResult: null,
        loading: false,
        error: action.payload,
        count: 0,
      };
    case actions.GET_TRANSACTIONS_REQUEST:
      return {
        ...state,
        transactionList: [],
        loading: true,
        error: null,
        count: 0,
      };
    case actions.GET_TRANSACTIONS_SUCCESS:
      return {
        ...state,
        transactionList: [...action.payload.data],
        loading: false,
        error: null,
        count: action.payload.count,
      };
    case actions.GET_TRANSACTIONS_FAIL:
      return {
        ...state,
        transactionList: [],
        loading: false,
        error: action.payload,
        count: 0,
      };

    case actions.GET_FEES_REQUEST:
      return {
        ...state,
        feeList: [],
        loading: true,
        error: null,
        count: 0,
      };
    case actions.GET_FEES_SUCCESS:
      return {
        ...state,
        feeList: [...action.payload.data.fees],
        loading: false,
        error: null,
        count: action.payload.data.count,
      };
    case actions.GET_FEES_FAIL:
      return {
        ...state,
        feeList: [],
        loading: false,
        error: action.payload,
        count: 0,
      };
    case actions.GET_DEPOSITS_REQUEST:
      return {
        ...state,
        depositsList: [],
        loading: true,
        error: null,
        count: 0,
      };
    case actions.GET_DEPOSITS_SUCCESS:
      return {
        ...state,
        depositsList: [...action.payload.data.data_deposit],
        loading: false,
        error: null,
        count: action.payload.data.count,
      };
    case actions.GET_DEPOSITS_FAIL:
      return {
        ...state,
        depositsList: [],
        loading: false,
        error: action.payload,
        count: 0,
      };
    case actions.GET_USERS_REQUEST:
      return {
        ...state,
        loadingAll: true,
        error: false,
        userCount: 0,
        userList: [],
      };
    case actions.GET_USERS_SUCCESS:
      return {
        ...state,
        transactionList: [],
        userList: action.payload.data.accounts,
        userCount: action.payload.data.count,
        loadingAll: false,
        error: false,
      };
    case actions.GET_USERS_FAIL:
      return {
        ...state,
        transactionList: [],
        userList: [],
        userCount: 0,
        loadingAll: false,
        error: action.payload,
      };
    case actions.GET_USERS_CENSORED_REQUEST:
      return {
        ...state,
        loadingAll: true,
        error: false,
        userCount: 0,
        userList: [],
      };
    case actions.GET_USERS_CENSORED_SUCCESS:
      return {
        ...state,
        transactionList: [],
        userList: action.payload.data,
        loadingAll: false,
        error: false,
      };
    case actions.GET_USERS_CENSORED_FAIL:
      return {
        ...state,
        transactionList: [],
        userList: [],
        userCount: 0,
        loadingAll: false,
        error: action.payload,
      };
    case actions.GET_PARTNERS_REQUEST:
      return {
        ...state,
        loadingAll: true,
        error: false,
        partnerList: [],
        count: 0,
      };
    case actions.GET_PARTNERS_SUCCESS:
      return {
        ...state,
        partnerList: [...action.payload.data],
        loadingAll: false,
        error: false,
        count: action.payload.data.count,
      };
    case actions.GET_PARTNERS_FAIL:
      return {
        ...state,
        partnerList: [],
        loadingAll: false,
        error: action.payload,
        count: 0,
      };
    case actions.GET_USER_BY_ID_REQUEST:
      return {
        ...state,
        transactionList: [],
        user: {},
        loading: true,
        error: false,
      };
    case actions.GET_USER_BY_ID_SUCCESS:
      return {
        ...state,
        transactionList: [],
        user: action.payload.data,
        loading: false,
        error: false,
      };
    case actions.GET_USER_BY_ID_FAIL:
      return {
        ...state,
        transactionList: [],
        user: {},
        loading: false,
        error: action.payload,
      };
    case actions.UPDATE_USER_BY_ID_ADMIN_REQUEST:
      return {
        ...state,
        updateUserAdminResult: [],
        loading: true,
        error: false,
      };
    case actions.UPDATE_USER_BY_ID_ADMIN_SUCCESS:
      return {
        ...state,
        updateUserAdminResult: [action.payload],
        loading: false,
        error: false,
      };
    case actions.UPDATE_USER_BY_ID_ADMIN_FAIL:
      return {
        ...state,
        updateUserAdminResult: [],
        loading: false,
        error: action.payload,
      };
    case actions.GET_TOTAL_SUPPLY_CHART_DATA_REQUEST:
      return {
        ...state,
        totalSupplyChartData: [],
        loading: true,
        error: false,
      };
    case actions.GET_TOTAL_SUPPLY_CHART_DATA_SUCCESS:
      return {
        ...state,
        totalSupplyChartData: [action.payload],
        loading: false,
        error: false,
      };
    case actions.GET_TOTAL_SUPPLY_CHART_DATA_FAIL:
      return {
        ...state,
        totalSupplyChartData: [],
        loading: false,
        error: action.payload,
      };
    case actions.GET_TOTAL_USER_CHART_DATA_REQUEST:
      return {
        ...state,
        totalUserChartData: [],
        loading: true,
        error: false,
      };
    case actions.GET_TOTAL_USER_CHART_DATA_SUCCESS:
      return {
        ...state,
        totalUserChartData: [action.payload],
        loading: false,
        error: false,
      };
    case actions.GET_TOTAL_USER_CHART_DATA_FAIL:
      return {
        ...state,
        totalUserChartData: [],
        loading: false,
        error: action.payload,
      };
    case actions.GET_APPROVE_COUNT_REQUEST:
      return {
        ...state,
        approveCount: null,
        loading: true,
        error: false,
      };
    case actions.GET_APPROVE_COUNT_SUCCESS:
      return {
        ...state,
        loading: false,
        approveCount: action.payload.data,
        error: false,
      };
    case actions.GET_APPROVE_COUNT_FAIL:
      return {
        ...state,
        loading: false,
        approveCount: null,
        error: action.payload,
      };
    case actions.GET_TODAY_INSIGHTS_REQUEST:
      return {
        ...state,
        todayInsights: null,
        loadingToday: true,
        todayInsightsTotal: 0,
        error: false,
      };
    case actions.GET_TODAY_INSIGHTS_SUCCESS:
      return {
        ...state,
        loadingToday: false,
        todayInsights: action.payload.data,
        error: false,
      };
    case actions.GET_TODAY_INSIGHTS_FAIL:
      return {
        ...state,
        loadingToday: false,
        todayInsights: null,
        error: action.payload,
      };
    case actions.GET_TOTAL_USERS_REQUEST:
      return {
        ...state,
        totalUsers: null,
        totalUserCount: 0,
        loading: true,
        error: false,
      };
    case actions.GET_TOTAL_USERS_SUCCESS:
      let sum = 0;
      for (let i = 0; i < action.payload.data.length; i++) {
        sum += action.payload.data[i].count;
      }
      return {
        ...state,
        loading: false,
        totalUsers: action.payload.data,
        totalUserCount: sum,
        error: false,
      };
    case actions.GET_TOTAL_USERS_FAIL:
      return {
        ...state,
        loading: false,
        totalUsers: null,
        totalUserCount: 0,
        error: action.payload,
      };
    case actions.GET_AUM_DATA_REQUEST:
      return {
        ...state,
        aumData: null,
        loading: true,
        error: false,
      };
    case actions.GET_AUM_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        aumData: action.payload.data,
        error: false,
      };
    case actions.GET_AUM_DATA_FAIL:
      return {
        ...state,
        loading: false,
        aumData: null,
        error: action.payload,
      };
    case actions.GET_GENERAL_CHART_DATA_REQUEST:
      return {
        ...state,
        loadingGeneral: true,
        generalChartData: null,
        error: false,
      };
    case actions.GET_GENERAL_CHART_DATA_SUCCESS:
      return {
        ...state,
        loadingGeneral: false,
        generalChartData: action.payload.data,
        error: false,
      };
    case actions.GET_GENERAL_CHART_DATA_FAIL:
      return {
        ...state,
        loadingGeneral: false,
        generalChartData: null,
        error: action.payload,
      };
    case actions.GET_ALL_GENERAL_CHART_DATA_REQUEST:
      return {
        ...state,
        loadingGeneralAll: true,
        generalChartDataAll: null,
        error: false,
      };
    case actions.GET_ALL_GENERAL_CHART_DATA_SUCCESS:
      return {
        ...state,
        loadingGeneralAll: false,
        generalChartDataAll: action.payload.data,
        error: false,
      };
    case actions.GET_ALL_GENERAL_CHART_DATA_FAIL:
      return {
        ...state,
        loadingGeneralAll: false,
        generalChartDataAll: null,
        error: action.payload,
      };

    case actions.GET_FUND_REPORTS_REQUEST:
      return {
        ...state,
        error: false,
        fundReports: null,
        loading: true,
      };
    case actions.GET_FUND_REPORTS_SUCCESS:
      return {
        ...state,
        error: false,
        loading: false,

        fundReports: action.payload.data,
      };
    case actions.GET_FUND_REPORTS_FAIL:
      return {
        ...state,
        fundReports: null,
        error: action.payload,
        loading: false,
      };
    case actions.GET_TOTAL_RESULTS_FEE_REQUEST:
      return {
        ...state,
        error: false,
        totalResultFee: null,
      };
    case actions.GET_TOTAL_RESULTS_FEE_SUCCESS:
      return {
        ...state,
        error: false,
        totalResultFee: action.payload.data,
      };
    case actions.GET_TOTAL_RESULTS_FEE_FAIL:
      return {
        ...state,
        totalResultFee: null,
        error: action.payload,
      };
    case actions.GET_EMERGENCY_CONTACTS_REQUEST:
      return {
        ...state,
        loadingAll: true,
        error: false,
        emergencyContactsList: [],
        count: 0,
      };
    case actions.GET_EMERGENCY_CONTACTS_SUCCESS:
      return {
        ...state,
        emergencyContactsList: [...action.payload.data.result],
        loadingAll: false,
        error: false,
        count: action.payload.data.count,
      };
    case actions.GET_EMERGENCY_CONTACTS_FAIL:
      return {
        ...state,
        emergencyContactsList: [],
        loadingAll: false,
        error: action.payload,
        count: 0,
      };
    default:
      return state;
  }
};

export default admin;
