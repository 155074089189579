import React from 'react';
import { Container } from 'react-bootstrap';
import './Funds.scss';

const Funds = () => {
  return (
    <>
      <Container>Coming soon</Container>
    </>
  );
};

export default Funds;
