import { all, call, fork, put, takeLatest } from 'redux-saga/effects';
import { apiCall } from '../../../utils/util';
import {
  GET_TOTAL_DEPOSIT_COUNT_REQUEST,
  GET_TOTAL_FUND_COUNT_REQUEST,
  GET_TOTAL_PROFIT_COUNT_REQUEST,
  GET_TOTAL_TRANSACTION_COUNT_REQUEST,
  GET_TOTAL_USER_COUNT_REQUEST,
} from '../../actions';
import {
  getTotalDepositCountFail,
  getTotalDepositCountSuccess,
  getTotalFundCountFail,
  getTotalFundCountSuccess,
  getTotalProfitCountFail,
  getTotalProfitCountSuccess,
  getTotalTransactionCountFail,
  getTotalTransactionCountSuccess,
  getTotalUserCountFail,
  getTotalUserCountSuccess,
} from '../metricService';

function* watchGetTotalUserCount() {
  yield takeLatest(GET_TOTAL_USER_COUNT_REQUEST, getTotalUserCount);
}

function* getTotalUserCount(action) {
  try {
    const res = yield call(apiCall, 'get', 'summary/user/count');
    let result = res.data.data;

    yield put(
      getTotalUserCountSuccess({
        data: result,
      })
    );
  } catch (err) {
    console.log(err);
    yield put(getTotalUserCountFail(err));
  }
}

function* watchGetTotalTransactionCount() {
  yield takeLatest(
    GET_TOTAL_TRANSACTION_COUNT_REQUEST,
    getTotalTransactionCount
  );
}

function* getTotalTransactionCount(action) {
  try {
    const res = yield call(apiCall, 'get', 'summary/fund/count');
    let result = res.data.data;

    yield put(
      getTotalTransactionCountSuccess({
        data: result,
      })
    );
  } catch (err) {
    console.log(err);
    yield put(getTotalTransactionCountFail(err));
  }
}

function* watchGetTotalDepositCount() {
  yield takeLatest(GET_TOTAL_DEPOSIT_COUNT_REQUEST, getTotalDepositCount);
}

function* getTotalDepositCount(action) {
  try {
    const res = yield call(apiCall, 'get', 'summary/mainnet/count');
    let result = res.data.data;

    yield put(
      getTotalDepositCountSuccess({
        data: result,
      })
    );
  } catch (err) {
    console.log(err);
    yield put(getTotalDepositCountFail(err));
  }
}

function* watchGetTotalProfitCount() {
  yield takeLatest(GET_TOTAL_PROFIT_COUNT_REQUEST, getTotalProfitCount);
}

function* getTotalProfitCount(action) {
  try {
    const res = yield call(apiCall, 'get', 'summary/profit');
    let result = res.data.data;

    yield put(
      getTotalProfitCountSuccess({
        data: result,
      })
    );
  } catch (err) {
    console.log(err);
    yield put(getTotalProfitCountFail(err));
  }
}

function* watchGetTotalFundCount() {
  yield takeLatest(GET_TOTAL_FUND_COUNT_REQUEST, getTotalFundCount);
}

function* getTotalFundCount(action) {
  try {
    const res = yield call(apiCall, 'get', 'summary/totalSupply');
    let result = res.data.data;

    yield put(
      getTotalFundCountSuccess({
        data: result,
      })
    );
  } catch (err) {
    console.log(err);
    yield put(getTotalFundCountFail(err));
  }
}

export default function* metricSaga() {
  yield all([
    fork(watchGetTotalUserCount),
    fork(watchGetTotalTransactionCount),
    fork(watchGetTotalDepositCount),
    fork(watchGetTotalProfitCount),
    fork(watchGetTotalFundCount),
  ]);
}
