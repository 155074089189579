import { all, call, fork, put, takeLatest } from "redux-saga/effects";
import { apiCall, fundApiCall } from "../../../utils/util";
import {
  BUY_FUND_BY_ID_SERVICE,
  GET_FUNDS_SERVICE,
  GET_FUND_BY_ID_SERVICE,
  GET_FUND_FEE_BY_ID_SERVICE,
  GET_FUND_PRICE_BY_ID_SERVICE,
  GET_FUND_PRICE_MOVEMENTS_REQUEST,
  GET_LAST_PRICES_SERVICE,
  GET_USER_TRANSACTIONS_BY_ID_REQUEST,
  POST_FUND_SERVICE,
  REDEEM_FUND_BY_ID_SERVICE,
  UPDATE_FUND_SERVICE,
  WITHDRAW_FUND_BY_ID_SERVICE,
} from "../../actions";
import {
  buyFundByIdServiceFailed,
  buyFundByIdServiceSucceed,
  getFundByIdServiceFailed,
  getFundByIdServiceSucceed,
  getFundFeeByIdServiceFailed,
  getFundFeeByIdServiceSucceed,
  getFundPriceByIdServiceFailed,
  getFundPriceByIdServiceSucceed,
  getFundPriceMovementsServiceFailed,
  getFundPriceMovementsServiceSucceed,
  getFundsServiceFailed,
  getFundsServiceSucceed,
  getLastPricesServiceFailed,
  getLastPricesServiceSucceed,
  getUserTransactionsByIdService,
  getUserTransactionsByIdServiceFailed,
  getUserTransactionsByIdServiceSucceed,
  postFundServiceFailed,
  postFundServiceSucceed,
  redeemFundByIdServiceFailed,
  redeemFundByIdServiceSucceed,
  updateFundFailed,
  updateFundSucceed,
  withdrawFundByIdService,
  withdrawFundByIdServiceFailed,
  withdrawFundByIdServiceSucceed,
} from "../fundService";

function* watchGetFunds() {
  yield takeLatest(GET_FUNDS_SERVICE, getFunds);
}

function* getFunds(action) {
  try {
    let filters = action.payload;

    const res = yield call(apiCall, "post", "fund/all", filters);
    let result = res.data;

    yield put(
      getFundsServiceSucceed({
        data: result,
      })
    );
  } catch (err) {
    console.log(err);
    yield put(getFundsServiceFailed(err));
  }
}

function* watchFundById() {
  yield takeLatest(GET_FUND_BY_ID_SERVICE, fundById);
}

function* fundById(action) {
  try {
    const { id } = action.payload;

    const res = yield call(apiCall, "get", "fund/" + id);
    let result = res.data.data;
    yield put(
      getFundByIdServiceSucceed({
        id,
        data: result,
      })
    );
  } catch (err) {
    console.log(err);
    yield put(getFundByIdServiceFailed(err));
  }
}

function* watchFundFeeById() {
  yield takeLatest(GET_FUND_FEE_BY_ID_SERVICE, fundFeeById);
}

function* fundFeeById(action) {
  try {
    const { id } = action.payload;

    const res = yield call(apiCall, "post", "fund/fee", {
      amount: action.payload.amount,
      walletId: action.payload.walletId,
    });
    let result = res.data.data;
    yield put(
      getFundFeeByIdServiceSucceed({
        id,
        data: result,
      })
    );
  } catch (err) {
    console.log(err);
    yield put(getFundFeeByIdServiceFailed(err));
  }
}

function* watchFundPriceById() {
  yield takeLatest(GET_FUND_PRICE_BY_ID_SERVICE, fundPriceById);
}

function* fundPriceById(action) {
  try {
    const { id } = action.payload;

    const res = yield call(apiCall, "get", "price/fund/" + id + "/LAST");
    let result = res.data.data;
    yield put(
      getFundPriceByIdServiceSucceed({
        id,
        data: result,
      })
    );
  } catch (err) {
    console.log(err);
    yield put(getFundPriceByIdServiceFailed(err));
  }
}

function* watchFundPriceMovements() {
  yield takeLatest(GET_FUND_PRICE_MOVEMENTS_REQUEST, fundPriceMovements);
}

function* fundPriceMovements(action) {
  try {
    const res1 = yield call(apiCall, "get", "price/fund/" + 1 + "/WEEKLY");
    const res2 = yield call(apiCall, "get", "price/fund/" + 2 + "/WEEKLY");
    const res3 = yield call(apiCall, "get", "price/fund/" + 3 + "/WEEKLY");
    const res4 = yield call(apiCall, "get", "price/fund/" + 1 + "/LAST");
    const res5 = yield call(apiCall, "get", "price/fund/" + 2 + "/LAST");
    const res6 = yield call(apiCall, "get", "price/fund/" + 3 + "/LAST");
    const res8 = yield call(apiCall, "get", "price/fund/" + 4 + "/LAST");
    const res7 = yield call(apiCall, "get", "price/fund/" + 4 + "/WEEKLY");

    let result = [
      {
        fund: "C5",
        price: res4.data.data,
        change: (res4.data.data - res1.data.data) / res1.data.data,
      },
      {
        fund: "BTCp",
        price: res5.data.data,
        change: (res5.data.data - res2.data.data) / res2.data.data,
      },
      {
        fund: "TRQ",
        price: res6.data.data,
        change: (res6.data.data - res3.data.data) / res3.data.data,
      },
      {
        fund: "QNT",
        price: res8.data.data,
        change: (res8.data.data - res7.data.data) / res7.data.data,
      },
    ];
    yield put(
      getFundPriceMovementsServiceSucceed({
        data: result,
      })
    );
  } catch (err) {
    console.log(err);
    yield put(getFundPriceMovementsServiceFailed(err));
  }
}

function* watchLastPrices() {
  yield takeLatest(GET_LAST_PRICES_SERVICE, lastPrices);
}

function* lastPrices(action) {
  try {
    let filters = action.payload;

    const res = yield call(apiCall, "get", "price", filters);
    let result = res.data.data;

    yield put(
      getLastPricesServiceSucceed({
        data: result,
      })
    );
  } catch (err) {
    console.log(err);
    yield put(getLastPricesServiceFailed(err));
  }
}

function* watchPostFund() {
  yield takeLatest(POST_FUND_SERVICE, postFund);
}

function* postFund(action) {
  const data = action.payload;
  const { icerik, gorsel, duyuru_durumu_id, duyuru_tipi, baslik } = data;
  let formData = new FormData();
  formData.append("icerik", icerik);
  formData.append("duyuru_durumu_id", duyuru_durumu_id);
  formData.append("duyuru_tipi", duyuru_tipi);
  formData.append("baslik", baslik);
  gorsel && formData.append("gorsel", gorsel);
  try {
    const res = yield call(apiCall, "post", "fund", formData);
    console.log(res);
    yield put(postFundServiceSucceed({ data: res.status }));
  } catch (err) {
    console.log(err);
    yield put(postFundServiceFailed(err));
  }
}

function* watchUpdateFund() {
  yield takeLatest(UPDATE_FUND_SERVICE, updateFund);
}

function* updateFund(action) {
  const { id, formData } = action.payload;
  try {
    const res = yield call(apiCall, "put", "fund/" + id, formData);
    console.log(res);
    yield put(updateFundSucceed({ data: res.status }));
  } catch (err) {
    console.log(err);
    yield put(updateFundFailed(err));
  }
}

function* watchBuyFundById() {
  yield takeLatest(BUY_FUND_BY_ID_SERVICE, buyFundById);
}

function* buyFundById(action) {
  try {
    const { id } = action.payload;

    const res = yield call(
      fundApiCall,
      "post",
      "fundListener/buy",
      action.payload
    );
    let result = res.data.message;
    yield put(
      buyFundByIdServiceSucceed({
        id,
        data: result,
        status: res.status,
      })
    );
  } catch (err) {
    console.log(err);
    yield put(buyFundByIdServiceFailed(err));
  }
}

function* watchWithdrawFundById() {
  yield takeLatest(WITHDRAW_FUND_BY_ID_SERVICE, withdrawFundById);
}

function* withdrawFundById(action) {
  try {
    const { id } = action.payload;

    const res = yield call(
      fundApiCall,
      "post",
      "fundListener/withdraw",
      action.payload
    );
    let result = res.data.message;
    yield put(
      withdrawFundByIdServiceSucceed({
        id,
        data: result,
        status: res.data.status,
      })
    );
  } catch (err) {
    console.log(err);
    yield put(withdrawFundByIdServiceFailed(err));
  }
}

function* watchRedeemFundById() {
  yield takeLatest(REDEEM_FUND_BY_ID_SERVICE, redeemFundById);
}

function* redeemFundById(action) {
  try {
    const { id } = action.payload;

    const res = yield call(
      fundApiCall,
      "post",
      "fundListener/sell",
      action.payload
    );
    let result = res.data.message;
    yield put(
      redeemFundByIdServiceSucceed({
        id,
        data: result,
        status: res.status,
      })
    );
  } catch (err) {
    console.log(err);
    yield put(redeemFundByIdServiceFailed(err));
  }
}

function* watchGetUserTransactionsById() {
  yield takeLatest(
    GET_USER_TRANSACTIONS_BY_ID_REQUEST,
    getUserTransactionsById
  );
}

function* getUserTransactionsById(action) {
  try {
    let filters = action.payload;
    const res = yield call(
      fundApiCall,
      "get",
      "fundListener/txList/" +
        filters.id +
        `?sort=${filters.sort}&limit=${filters.limit}&page=${filters.page}`
    );
    let result = res.data;
    yield put(
      getUserTransactionsByIdServiceSucceed({
        data: result,
      })
    );
  } catch (err) {
    console.log(err);
    yield put(getUserTransactionsByIdServiceFailed(err));
  }
}

export default function* fundSaga() {
  yield all([
    fork(watchFundById),
    fork(watchFundFeeById),
    fork(watchFundPriceById),
    fork(watchLastPrices),
    fork(watchPostFund),
    fork(watchUpdateFund),
    fork(watchGetFunds),
    fork(watchBuyFundById),
    fork(watchRedeemFundById),
    fork(watchGetUserTransactionsById),
    fork(watchWithdrawFundById),
    fork(watchFundPriceMovements),
  ]);
}
