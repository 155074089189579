import React, { useEffect, useState } from 'react';
import { Button, Form, Modal, Spinner } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import { partnerColumns } from '../../constants/columnConfigurations';
import {
  getUserByIdService,
  updateUserAdminRequest,
} from '../../services/admin/adminService';
import FTable from '../FTable/FTable';
import FTextInput from '../FTextInput/FTextInput';
import './FPartnerDetailsModal.scss';

const FPartnerDetailsModal = (props) => {
  const dispatch = useDispatch();
  const { user, loading } = useSelector((state) => state.admin);
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const initialFilters = {
    id: props.userId,
  };

  const initialFormData = {
    name: user?.name,
    surname: user?.surname,
    partner_commission: user?.partner_commission,
  };

  const [formData, setFormData] = useState({ ...initialFormData });

  useEffect(() => {
    if (props.userId != undefined) {
      dispatch(getUserByIdService(initialFilters));
    }
  }, [props.userId]);

  useEffect(() => {
    if (user) {
      setFormData({
        name: user?.name,
        surname: user?.surname,
        partner_commission: user?.partner_commission,
      });
    }
  }, [user]);

  const handleOnChange = (name, value) => {
    setButtonDisabled(false);
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  function handleSubmit(e) {
    e.preventDefault();
    dispatch(updateUserAdminRequest({ id: user.id, formData }));
    Swal.fire(
      'Partner updated!',
      'Partner updated successfully.',
      'success'
    ).then(() => window.location.reload());
  }

  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  const partnerColumnsConfig = partnerColumns();

  return (
    <Modal
      {...props}
      size="xl"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Partner details of {capitalizeFirstLetter(String(user?.name))}{' '}
          {capitalizeFirstLetter(String(user?.surname))}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <div className="f_modal-userdetails">
            <FTextInput label={'İsim'} id="name">
              <Form.Control
                value={formData.name || ''}
                name="name"
                onChange={(e) => handleOnChange(e.target.name, e.target.value)}
                type="text"
                placeholder="name@example.com"
              />
            </FTextInput>
            <FTextInput label={'Soyisim'} id="surname">
              <Form.Control
                value={formData.surname || ''}
                name="surname"
                onChange={(e) => handleOnChange(e.target.name, e.target.value)}
                type="text"
                placeholder="name@example.com"
              />
            </FTextInput>
            <FTextInput label={'Partner Commission'} id="partner_commission">
              <Form.Control
                value={formData.partner_commission || ''}
                name="partner_commission"
                onChange={(e) => handleOnChange(e.target.name, e.target.value)}
                type="text"
                placeholder="name@example.com"
              />
            </FTextInput>
          </div>
          <h5 style={{ paddingTop: '40px' }}>Referred to</h5>
          <div className="f_modal-userdetails">
            <div className="f_transactions">
              {props?.partnerAccounts?.length !== 0 ? (
                <FTable
                  data={props?.partnerAccounts}
                  columns={partnerColumnsConfig}
                />
              ) : (
                <div className="f_transactions-noinfo">No info</div>
              )}
            </div>
          </div>
          {/* 
          <div className="f_modal-userdetails mt-4">
            <FTextInput label={'Deposit Wallet'} id="deposit">
              <Form.Control
                //onChange={(event) => setPassword(event.target.value)}
                type="text"
                placeholder="name@example.com"
                value={userDeposit}
              />
            </FTextInput>
            <FTextInput label={'Withdraw Wallet'} id="withdraw">
              <Form.Control
                //onChange={(event) => setPassword(event.target.value)}
                type="text"
                placeholder="name@example.com"
                value={userWithdraw}
              />
            </FTextInput>
          </div>
          */}
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button
          disabled={buttonDisabled}
          variant="primary"
          onClick={handleSubmit}
        >
          Save Changes
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default FPartnerDetailsModal;
