import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { Button, Card } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  getFundByIdService,
  getFundPriceByIdService,
} from "../../../../services/fund/fundService";
import Spinner from "react-bootstrap/Spinner";
import "./FundDetails.scss";
import { useNavigate } from "react-router-dom";
import FIcon from "../../../../components/FIcon/FIcon";
import YieldConntectWallet from "../../../../components/YieldComponents/YieldNavbar/YieldConnectWallet/YieldConnectWallet";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import BTCp_en from "../../../../docs/BTCp_en.pdf";
import BTCp_tr from "../../../../docs/BTCp_tr.pdf";
import TRQ_en from "../../../../docs/TRQ_en.pdf";
import TRQ_tr from "../../../../docs/TRQ_tr.pdf";
import QNT_en from "../../../../docs/QNT_en.pdf";
import QNT_tr from "../../../../docs/QNT_tr.pdf";
import C5_en from "../../../../docs/C5_en.pdf";
import C5_tr from "../../../../docs/C5_tr.pdf";
import stakingicon from "../../../../assets/images/stakingicon.png";
const FundDetails = ({ location, from, theme }) => {
  let navigate = useNavigate();
  const { t } = useTranslation();

  const fundId = window.location.href.split(location + "?id=")[1];
  const dispatch = useDispatch();
  const { fund, loading, fundPrice } = useSelector((state) => state.fund);

  const initialFilters = {
    id: fundId,
  };

  const getLanguage = i18next.language || window.localStorage.i18nextLng;

  useEffect(() => {
    dispatch(getFundByIdService(initialFilters));
    dispatch(getFundPriceByIdService(initialFilters));
  }, [fundId]);

  const navigations = (data) => {
    if (data == "Staking/Kaldıraçlı Staking") {
      navigate("/yields/staking");
    } else if (data == "Likidite Sağlayıcılığı") {
      navigate("/yields/liquidity");
    } else if (data == "Opsiyon Stratejileri") {
      navigate("/yields/option");
    }
  };

  const ref = useRef(null);
  const [width, setWidth] = useState(0);
  useLayoutEffect(() => {
    setWidth(ref.current.offsetWidth);
  }, []);

  const getFile = (name) => {
    if (name == "Kripto Endeks Fonu" && getLanguage == "tr") {
      return C5_tr;
    } else if (name == "Kripto Endeks Fonu") {
      return C5_en;
    } else if (name == "Crypto Trading Fund" && getLanguage == "en") {
      return TRQ_en;
    } else if (name == "Kripto Fırsat Fonu") {
      return TRQ_tr;
    } else if (name == "Düşüş Korumalı BTC Fonu" && getLanguage == "tr") {
      return BTCp_tr;
    } else if (name == "Bitcoin Protected Fund") {
      return BTCp_en;
    } else if (name == "Algorithmic Trading" && getLanguage == "en") {
      return QNT_en;
    } else if (name == "Algoritmik Al-Sat") {
      return QNT_tr;
    }
  };
  return (
    <div className="text-center details_wrapper" ref={ref}>
      {loading ? (
        <Spinner animation="grow" />
      ) : (
        <>
          <div className="f_funddetails text-center">
            <div className="f_funddetails-head">
              {console.log(fund?.token_symbol)}
              <img src={fund?.token_symbol}></img>
              <div className="f_funddetails-head-text">
                <h1>{fund?.type?.length !== 0 ? fund?.type : "."}</h1>
                <h2>
                  {getLanguage === "tr"
                    ? fund?.token + " " + fund?.name
                    : fund?.token + " " + fund?.name_en}
                </h2>
                <h3>By Dow Digital Capital</h3>
              </div>
            </div>
            <div className="f_funddetails-body">
              <div className="f_funddetails-body-text">
                <div className="f_funddetails-body-text-content">
                  <h1>
                    {getLanguage === "tr" ? fund?.slogan : fund?.slogan_en}
                  </h1>
                  <h2>
                    {getLanguage === "tr" ? fund?.content : fund?.content_en}
                  </h2>
                  <div className="f_funddetails-invest">
                    {fund?.name !== "Kripto Arbitraj Fonu" && from ? (
                      <Button
                        className="f_funddetails-invest-buy"
                        onClick={() => navigate("/invest/buy?id=" + fundId)}
                      >
                        {t("fund_invest")}
                      </Button>
                    ) : (
                      <Button
                        className="f_funddetails-invest-buy"
                        onClick={() => navigations(fund?.name)}
                      >
                        {t("fund_invest")}
                      </Button>
                    )}

                    {from === "invest" &&
                      fund?.name !== "Kripto Arbitraj Fonu" && (
                        <Button
                          className="f_funddetails-invest-redeem"
                          onClick={() =>
                            navigate("/invest/redeem?id=" + fundId)
                          }
                        >
                          {t("fund_redeem")}
                        </Button>
                      )}
                  </div>
                </div>
                <div
                  style={{
                    backgroundColor: "var(--background-darkroyalblue)",
                    borderRadius: "24px",
                    width: "474px",
                    height: "270px",
                  }}
                >
                  <iframe
                    style={{ borderRadius: "24px" }}
                    width={"100%"}
                    height={"100%"}
                    src={fund?.video}
                    title="YouTube video player"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                  ></iframe>
                </div>
              </div>
            </div>
          </div>
          <div
            style={{
              background: "rgba(65, 105, 225, 0.15)",
              marginTop: "64px",
            }}
          >
            {fund?.name !== "Kripto Arbitraj Fonu" &&
              (width > 576 ? (
                <div className="f_funddetails-body-details">
                  <div className="stat">
                    <h1>{t("fund_launch")}</h1>
                    <h2>
                      {getLanguage === "tr"
                        ? new Date(fund?.launch_date).toLocaleDateString(
                            "tr-TR",
                            {
                              year: "numeric",
                              month: "long",
                              day: "numeric",
                            }
                          )
                        : new Date(fund?.launch_date).toLocaleDateString(
                            "en-EN",
                            {
                              year: "numeric",
                              month: "long",
                              day: "numeric",
                            }
                          )}
                    </h2>
                  </div>
                  <div className="stat">
                    <h1>{t("fund_value")}</h1>
                    <h2>{fund?.value}</h2>
                  </div>
                  <div className="stat">
                    <h1>{t("fund_price")}</h1>
                    <h2>
                      {fundId === "1" && fundPrice.length !== 0
                        ? parseFloat(fundPrice)?.toFixed(4)
                        : "1"}
                    </h2>
                  </div>
                  <div className="stat">
                    <h1>TOKEN</h1>
                    <h2>{fund?.token}</h2>
                  </div>
                </div>
              ) : (
                <div className="f_funddetails-body-details">
                  <div className="stat">
                    <h1>{t("fund_launch")}</h1>
                    <h2>
                      {getLanguage === "tr"
                        ? new Date(fund?.launch_date).toLocaleDateString(
                            "tr-TR",
                            {
                              year: "numeric",
                              month: "long",
                              day: "numeric",
                            }
                          )
                        : new Date(fund?.launch_date).toLocaleDateString(
                            "en-EN",
                            {
                              year: "numeric",
                              month: "long",
                              day: "numeric",
                            }
                          )}
                    </h2>
                  </div>

                  <div className="stat">
                    <h1>TOKEN</h1>
                    <h2>{fund?.token}</h2>
                  </div>
                  <div className="stat">
                    <h1>{t("fund_value")}</h1>
                    <h2>{fund?.value}</h2>
                  </div>
                  <div className="stat">
                    <h1>{t("fund_price")}</h1>
                    <h2>
                      {fundId === "1" && fundPrice.length !== 0
                        ? parseFloat(fundPrice)?.toFixed(4)
                        : "1"}
                    </h2>
                  </div>
                </div>
              ))}
          </div>
          <div className="f_funddetails text-center">
            {fund?.name !== "Kripto Arbitraj Fonu" &&
              fund?.name !== "Staking/Kaldıraçlı Staking" &&
              fund?.name !== "Likidite Sağlayıcılığı" &&
              fund?.name !== "Opsiyon Stratejileri" && (
                <div className="f_funddetails-body-pdf">
                  <div className="f_funddetails-body-pdf-item">
                    <Card>
                      <Card.Body>
                        <div className="card_head">
                          <FIcon
                            name="bar2"
                            color={theme === "dark" ? "white" : "black"}
                            size="42"
                          ></FIcon>
                          <div className="card_subhead">
                            {fund.name && (
                              <a
                                href={getFile(fund?.name)}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <Card.Subtitle>{t("fund_fact")}</Card.Subtitle>
                                <Card.Title>{t("fund_page")}</Card.Title>
                              </a>
                            )}
                          </div>
                        </div>
                      </Card.Body>
                    </Card>
                  </div>
                  <div className="f_funddetails-body-pdf-item">
                    <Card>
                      <Card.Body>
                        <div className="card_head">
                          <FIcon
                            name="paper2"
                            color={theme === "dark" ? "white" : "black"}
                            size="42"
                          ></FIcon>
                          <div className="card_subhead">
                            <Card.Subtitle>{t("fund_quarter")}</Card.Subtitle>
                            <Card.Title>{t("fund_market")}</Card.Title>
                          </div>
                        </div>
                      </Card.Body>
                    </Card>
                  </div>
                  <div className="f_funddetails-body-pdf-item">
                    <Card>
                      <Card.Body>
                        <div className="card_head">
                          <FIcon
                            name="shield2"
                            color={theme === "dark" ? "white" : "black"}
                            size="42"
                          ></FIcon>
                          <div className="card_subhead">
                            <Card.Subtitle>
                              {"Offering Memorandum"}
                            </Card.Subtitle>
                            <Card.Title>{t("fund_term")}</Card.Title>
                          </div>
                        </div>
                      </Card.Body>
                    </Card>
                  </div>
                </div>
              )}
          </div>
        </>
      )}
    </div>
  );
};

export default FundDetails;
