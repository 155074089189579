import { combineReducers } from 'redux';
import authentication from './authentication/reducers/authentication';
import fund from './fund/reducers/fund';
import news from './news/reducers/news';
import wallet from './wallet/reducers/wallet';
import admin from './admin/reducers/admin';
import metric from './metric/reducers/metric';

const rootReducer = combineReducers({
  authentication,
  fund,
  wallet,
  news,
  admin,
  metric,
});

export default rootReducer;
