import './App.css';
import { BrowserRouter } from 'react-router-dom';
import Router from './routes/routes';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import useLocalStorage from 'use-local-storage';
import { loginSucceeded } from './services/authentication/authenticationService';

function App() {
  const dispatch = useDispatch();
  const { token } = useSelector((state) => state.authentication.user);
  const localToken = localStorage.getItem('token');
  const user = JSON.parse(localStorage.getItem('user'));

  if (!token && localToken) {
    dispatch(loginSucceeded({ ...user, token: localToken }));
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [theme, setTheme] = useLocalStorage('theme', 'light');

  const [language, setLanguage] = useLocalStorage('language', 'tr');

  const switchTheme = () => {
    const newTheme = theme === 'light' ? 'dark' : 'light';
    setTheme(newTheme);
  };
  const switchLanguage = () => {
    const newLanguage = language === 'tr' ? 'en' : 'tr';
    setLanguage(newLanguage);
  };

  return (
    <BrowserRouter>
      <div className="App" data-theme={theme}>
        <Router
          auth={localToken}
          switchTheme={switchTheme}
          theme={theme}
          switchLanguage={switchLanguage}
          language={language}
        />
      </div>
    </BrowserRouter>
  );
}

export default App;
