import React, { useEffect, useState } from "react";
import { Button, Form, Modal, Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
import { partnerColumns } from "../../constants/columnConfigurations";
import {
  getUserByIdService,
  updateUserAdminRequest,
} from "../../services/admin/adminService";
import FTable from "../FTable/FTable";
import FTextInput from "../FTextInput/FTextInput";
import "./F2FAModal.scss";
import QRCode from "qrcode";
import {
  twoFaActivateRequest,
  twoFaGenerateRequest,
  twoFaLoginRequest,
} from "../../services/authentication/authenticationService";

const F2FAModal = (props) => {
  const [disabled, setDisabled] = useState(true);
  const [QRCodeString, setQRCodeString] = useState(null);
  const dispatch = useDispatch();
  const { twoFaActivateInfoResult, otpUrl } = useSelector(
    (state) => state.authentication
  );

  const initialFormdata = {
    user_id: props.userId,
    token: "",
  };

  const [formData, setFormdata] = useState({
    ...initialFormdata,
  });

  const submitActivate = () => {
    dispatch(twoFaActivateRequest(formData));
    if (twoFaActivateInfoResult < 400) {
      Swal.fire(
        "Configured!",
        "Two-Factor Authentication (2FA) configured successfully!",
        "success"
      ).then(() => window.location.reload());
    } else {
      Swal.fire("Error", "Something went wrong", "error").then(() =>
        window.location.reload()
      );
    }
  };

  useEffect(() => {
    if (props?.otp_auth_url != null && props?.otp_auth_url != "") {
      QRCode.toDataURL(props?.otp_auth_url).then((data) => {
        setQRCodeString(data);
      });
    } else {
      console.log("test");
      dispatch(twoFaGenerateRequest({ id: props?.userId }));
    }
  }, [props.otp_auth_url]);

  useEffect(() => {
    if (otpUrl != null) {
      QRCode.toDataURL(otpUrl).then((data) => {
        setQRCodeString(data);
      });
    }
  }, [otpUrl]);

  const handleOnChange = (name, value) => {
    setFormdata((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    console.log(formData);
  };

  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="twoFa_modal"
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Two-Factor Authentication (2FA)
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h2>1. Install Google Authenticator (IOS - Android).</h2>
        <h2>2. In the authenticator app, select "+" icon.</h2>
        <h2>
          3. Select "Scan a barcode (or QR code)" and use the phone's camera to
          scan this barcode.
        </h2>
        <br></br>
        <h1>Scan QR Code</h1>
        <hr></hr>
        {QRCodeString && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <img
              style={{ width: "16rem" }}
              src={QRCodeString}
              alt="qrcode url"
            />
          </div>
        )}

        <h1>Verify Code</h1>
        <hr></hr>
        <h2>Please verify the authentication code.</h2>
        <br></br>
        <FTextInput label={"Authentication code"} id="editWithdraw">
          <Form.Control
            name="token"
            type="text"
            onChange={(e) => handleOnChange(e.target.name, e.target.value)}
          />
        </FTextInput>
      </Modal.Body>
      <Modal.Footer>
        <Button className="verify_button" onClick={() => submitActivate()}>
          Verify & Activate
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default F2FAModal;
