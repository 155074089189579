import * as services from "../actions";

export const loginRequest = (reqParams) => ({
  type: services.LOGIN_REQUEST,
  payload: reqParams,
});

export const loginSucceeded = (user) => ({
  type: services.LOGIN_SUCCEEDED,
  payload: user,
});

export const loginFailed = (error) => ({
  type: services.LOGIN_FAILED,
  payload: error,
});

export const navigateToOtp = (user) => ({
  type: services.NAVIGATE_OTP,
  payload: user,
});

export const forgotPasswordRequest = (reqParams) => ({
  type: services.FORGOT_PASSWORD_REQUEST,
  payload: reqParams,
});

export const forgotPasswordSucceeded = (user) => ({
  type: services.FORGOT_PASSWORD_SUCCEEDED,
  payload: user,
});

export const forgotPasswordFailed = (error) => ({
  type: services.FORGOT_PASSWORD_FAILED,
  payload: error,
});

export const resetPasswordRequest = (reqParams) => ({
  type: services.RESET_PASSWORD_REQUEST,
  payload: reqParams,
});

export const resetPasswordSucceeded = (user) => ({
  type: services.RESET_PASSWORD_SUCCEEDED,
  payload: user,
});

export const resetPasswordFailed = (error) => ({
  type: services.RESET_PASSWORD_FAILED,
  payload: error,
});

export const twoFaActivateRequest = (reqParams) => ({
  type: services.TWOFA_ACTIVATE_REQUEST,
  payload: reqParams,
});

export const twoFaActivateSucceeded = (user) => ({
  type: services.TWOFA_ACTIVATE_SUCCEEDED,
  payload: user,
});

export const twoFaActivateFailed = (error) => ({
  type: services.TWOFA_ACTIVATE_FAILED,
  payload: error,
});

export const twoFaLoginRequest = (reqParams) => ({
  type: services.TWOFA_LOGIN_REQUEST,
  payload: reqParams,
});

export const twoFaLoginSucceeded = (user) => ({
  type: services.TWOFA_LOGIN_SUCCEEDED,
  payload: user,
});

export const twoFaLoginFailed = (error) => ({
  type: services.TWOFA_LOGIN_FAILED,
  payload: error,
});

export const twoFaGenerateRequest = (reqParams) => ({
  type: services.TWOFA_GENERATE_REQUEST,
  payload: reqParams,
});

export const twoFaGenerateSucceeded = (user) => ({
  type: services.TWOFA_GENERATE_SUCCEEDED,
  payload: user,
});

export const twoFaGenerateFailed = (error) => ({
  type: services.TWOFA_GENERATE_FAILED,
  payload: error,
});

export const logout = () => ({
  type: services.LOGOUT,
});

export const userInfo = (reqParams) => ({
  type: services.USER_INFO_REQUEST,
  payload: reqParams,
});

export const userInfoSucceeded = (userInfo) => ({
  type: services.USER_INFO_SUCCEEDED,
  payload: userInfo,
});

export const userInfoFailed = (error) => ({
  type: services.USER_INFO_FAILED,
  payload: error,
});

export const signupRequest = (data) => ({
  type: services.SIGNUP_REQUEST,
  payload: data,
});

export const signupSucceeded = (data) => ({
  type: services.SIGNUP_SUCCEEDED,
  payload: data,
});

export const signupFailed = (error) => ({
  type: services.SIGNUP_FAILED,
  payload: error,
});

export const updateUserRequest = (reqParams) => ({
  type: services.UPDATE_USER_BY_ID_REQUEST,
  payload: reqParams,
});

export const updateUserSucceed = ({ id, data }) => ({
  type: services.UPDATE_USER_BY_ID_SUCCESS,
  payload: { id, data },
});

export const updateUserFailed = (error) => ({
  type: services.UPDATE_USER_BY_ID_FAIL,
  payload: error,
});

export const createEmergencyRequest = (reqParams) => ({
  type: services.EMERGENCY_CONTACT_CREATE_BY_ID_REQUEST,
  payload: reqParams,
});

export const createEmergencySuccess = (reqParams) => ({
  type: services.EMERGENCY_CONTACT_CREATE_BY_ID_SUCCESS,
  payload: reqParams,
});

export const createEmergencyFail = (error) => ({
  type: services.EMERGENCY_CONTACT_CREATE_BY_ID_FAIL,
  payload: error,
});

export const updateEmergencyRequest = (reqParams) => ({
  type: services.EMERGENCY_CONTACT_UPDATE_BY_ID_REQUEST,
  payload: reqParams,
});

export const updateEmergencySuccess = (reqParams) => ({
  type: services.EMERGENCY_CONTACT_UPDATE_BY_ID_SUCCESS,
  payload: reqParams,
});

export const updateEmergencyFail = (error) => ({
  type: services.EMERGENCY_CONTACT_UPDATE_BY_ID_FAIL,
  payload: error,
});

export const deleteEmergencyRequest = (reqParams) => ({
  type: services.EMERGENCY_CONTACT_DELETE_BY_ID_REQUEST,
  payload: reqParams,
});

export const deleteEmergencySuccess = (reqParams) => ({
  type: services.EMERGENCY_CONTACT_DELETE_BY_ID_SUCCESS,
  payload: reqParams,
});

export const deleteEmergencyFail = (error) => ({
  type: services.EMERGENCY_CONTACT_DELETE_BY_ID_FAIL,
  payload: error,
});

export const readEmergencyRequest = (reqParams) => ({
  type: services.EMERGENCY_CONTACT_READ_BY_ID_REQUEST,
  payload: reqParams,
});

export const readEmergencySuccess = (reqParams) => ({
  type: services.EMERGENCY_CONTACT_READ_BY_ID_SUCCESS,
  payload: reqParams,
});

export const readEmergencyFail = (error) => ({
  type: services.EMERGENCY_CONTACT_READ_BY_ID_FAIL,
  payload: error,
});
