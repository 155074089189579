import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getLastPricesService } from "../../../../services/fund/fundService";
import FundDetails from "../FundDetails/FundDetails";
import "../FundDetails/FundDetails.scss";
import c5riskeng from "../../../../assets/images/c5riskeng.png";
import c5risktr from "../../../../assets/images/c5risktr.png";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import graphc5 from "../../../../assets/images/graphc5.png";
import "./C5Index.scss";
const C5Index = ({ from, theme }) => {
  const dispatch = useDispatch();
  const { lastPrices, loading } = useSelector((state) => state.fund);
  const { t } = useTranslation();
  const initialFilters = {
    filter: {},
  };

  const getLanguage = i18next.language || window.localStorage.i18nextLng;

  useEffect(() => {
    dispatch(getLastPricesService(initialFilters));
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <FundDetails location={"c5-index"} from={from} theme={theme} />

      {loading ? (
        ""
      ) : (
        <>
          <div className="f_funddetailswrapper">
            <div className="f_funddetails-advantages">
              <div className="f_funddetails-advantages-title">
                {t("c5_advantage")}
              </div>
              <div className="f_funddetails-advantages-content">
                <div className="f_funddetails-advantages-content-section">
                  <span className="icon-wrapper--qLc_c icon-wrapper--lg--3Dvqr">
                    <svg
                      width="95"
                      height="95"
                      viewBox="0 0 95 95"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M11.875 83.125V59.375"
                        stroke="#4169E1"
                        stroke-width="3"
                      />
                      <path
                        d="M29.6875 83.125V47.5"
                        stroke="#4169E1"
                        stroke-width="3"
                      />
                      <path
                        d="M47.5 83.125V35.625"
                        stroke="#4169E1"
                        stroke-width="3"
                      />
                      <path
                        d="M65.3125 83.125V23.75"
                        stroke="#4169E1"
                        stroke-width="3"
                      />
                      <path
                        d="M83.125 83.125V11.875"
                        stroke="#4169E1"
                        stroke-width="3"
                      />
                    </svg>
                  </span>
                  <h1>{t("c5_divers")} </h1>
                  <h2>{t("c5_by_investing")}</h2>
                </div>
                <div className="f_funddetails-advantages-content-section">
                  <span className="icon-wrapper--qLc_c icon-wrapper--lg--3Dvqr">
                    <svg
                      width="83"
                      height="86"
                      viewBox="0 0 83 86"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M77.4538 8.43799C27.8394 66.0381 36.8942 79.5258 23.3743 43.9819"
                        stroke="#4169E1"
                        stroke-width="3"
                        stroke-linecap="round"
                      />
                      <path
                        d="M22.2613 10.4182C34.9015 1.57911 47.2393 -0.993296 60.6392 6.0586C96.6814 25.0248 80.9618 89.6562 36.0776 83.1149C11.6385 79.5524 -12.8108 48.7713 13.0516 21.3229"
                        stroke="#4169E1"
                        stroke-width="3"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </span>
                  <h1> {t("c5_quality")}</h1>
                  <h2>{t("c5_while")}</h2>
                </div>
              </div>
            </div>
            <div className="f_funddetails-holdings">
              <div className="f_funddetails-holdings-title">
                {t("c5_current_fund")}
              </div>
              <div className="f_funddetails-holdings-content">
                <div className="f_funddetails-holdings-content-section">
                  <h1>${lastPrices?.ETH}</h1>
                  <img
                    alt="eth"
                    src="https://s2.coinmarketcap.com/static/img/coins/64x64/1027.png"
                  ></img>
                  <h2>Ethereum</h2>
                </div>
                <div className="f_funddetails-holdings-content-section">
                  <h1>${lastPrices?.BTC}</h1>
                  <img
                    alt="btc"
                    src="https://s2.coinmarketcap.com/static/img/coins/64x64/1.png"
                  ></img>
                  <h2>Bitcoin</h2>
                </div>
                <div className="f_funddetails-holdings-content-section">
                  <h1>${lastPrices?.BNB}</h1>
                  <img
                    alt="bnb"
                    src="https://s2.coinmarketcap.com/static/img/coins/64x64/1839.png"
                  ></img>
                  <h2>Binance Coin</h2>
                </div>
                <div className="f_funddetails-holdings-content-section">
                  <h1>${lastPrices?.ADA?.toFixed(4)}</h1>
                  <img
                    alt="ada"
                    src="https://s2.coinmarketcap.com/static/img/coins/64x64/2010.png"
                  ></img>
                  <h2>Cardano</h2>
                </div>
                <div className="f_funddetails-holdings-content-section">
                  <h1>${lastPrices?.DOT}</h1>
                  <img
                    alt="dot"
                    src="https://s2.coinmarketcap.com/static/img/coins/64x64/6636.png"
                  ></img>
                  <h2>Polkadot</h2>
                </div>
              </div>
            </div>
          </div>
          <div className="bg-white">
            <div className="f_funddetails-fundfor">
              <div className="f_funddetails-fundfor-title">
                {t("c5_who_for")}
              </div>
              <div className="f_funddetails-fundfor-content">
                <div className="f_funddetails-fundfor-content-section">
                  <div style={{ flex: "1 1 300px" }}>
                    <h1>{t("c5_risk")}</h1>
                    <h2>{t("c5_crypto_market")}</h2>
                  </div>
                  <div style={{ flex: "1" }}>
                    <h1>{t("c5_timeframe")}</h1>
                    <h2>{t("c5_timeframe_desc")}</h2>
                  </div>
                </div>
              </div>
              <div className="f_funddetails-fundfor-image">
                <img src={graphc5} />
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default C5Index;
