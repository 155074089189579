import i18next from "i18next";
import React, { useEffect, useState } from "react";
import { Button, Card, Container, Form, Spinner } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import FIcon from "../../../../components/FIcon/FIcon";
import FTextInput from "../../../../components/FTextInput/FTextInput";
import {
  buyFundByIdService,
  getFundByIdService,
  getFundFeeByIdService,
  getFundPriceByIdService,
  withdrawFundByIdService,
} from "../../../../services/fund/fundService";
import {
  getBalanceByIdRequest,
  getWalletByIdRequest,
} from "../../../../services/wallet/walletService";
import {
  checkUserTubuWallet,
  checkWithdrawWalletId,
} from "../../../../utils/util";
import "./Withdraw.scss";

const Withdraw = ({ theme }) => {
  const fundId = window.location.href.split("withdraw?id=")[1];
  const user = JSON.parse(localStorage.getItem("user"));
  let navigate = useNavigate();

  const { t } = useTranslation();

  const getLanguage = i18next.language || window.localStorage.i18nextLng;

  const dispatch = useDispatch();
  const {
    txList,
    loadingTxList,
    fundFee,
    withdrawFundResult,
    withdrawFundStatus,
    loading: loadingFund,
  } = useSelector((state) => state.fund);
  const { wallet, balances, loading } = useSelector((state) => state.wallet);
  const [error, setError] = useState("");
  const [amount, setAmount] = useState();
  const [disabled, setDisabled] = useState(true);
  const [submitted, setSubmitted] = useState(false);

  const [estimatedDow, setEstimatedDow] = useState(0.0);

  const initialFilters = {
    id: fundId,
  };

  const initialFeeFilters = {
    id: fundId,
    amount: 0,
  };

  const walletFilters = {
    id: user.id,
  };

  const balanceFilters = {
    wallet: checkUserTubuWallet(wallet),
    id: user.id,
  };

  useEffect(() => {
    setDisabled(false);
    dispatch(
      getFundFeeByIdService({
        amount: amount,
        walletId: checkWithdrawWalletId(wallet),
      })
    );
    if (
      parseFloat(amount) > parseFloat(balances?.TUSD?.amount_usd).toFixed(6)
    ) {
      setError(t("withdraw_insuff_err"));
    } else {
      if (parseFloat(amount) <= fundFee) {
        setDisabled(true);
        setError(t("withdraw_network_err"));
      } else {
        setDisabled(false);
        setError("");
      }
    }

    if (estimatedDow <= 0) {
      setDisabled(true);
      setEstimatedDow(0);
    }
  }, [amount]);

  useEffect(() => {
    dispatch(getFundByIdService(initialFilters));
  }, [fundId]);

  useEffect(() => {
    dispatch(getWalletByIdRequest(walletFilters));
  }, []);

  useEffect(() => {
    dispatch(getFundPriceByIdService(initialFilters));
  }, []);

  useEffect(() => {
    dispatch(getFundFeeByIdService(initialFeeFilters));
  }, []);

  useEffect(() => {
    if (wallet.length !== 0) {
      dispatch(getBalanceByIdRequest(balanceFilters));
    }
  }, [wallet]);

  useEffect(() => {
    if (
      (withdrawFundResult != null || withdrawFundResult != undefined) &&
      submitted
    ) {
      if (withdrawFundStatus < 400) {
        let timerInterval;
        Swal.fire({
          title: t("withdraw_invest_succ"),
          icon: "success",
          text: `${withdrawFundResult}`,
        }).then((result) => {
          navigate("/wallets");
          setSubmitted(false);
        });
      } else {
        let timerInterval;
        Swal.fire({
          title: t("withdraw_err"),
          icon: "error",
          text: `${withdrawFundResult}`,
        }).then((result) => {
          navigate("/wallets");
          setSubmitted(false);
        });
      }
    }
  }, [withdrawFundResult, withdrawFundStatus]);

  function handleSubmit(e) {
    e.preventDefault();
    setSubmitted(true);
    let formData = {
      account_id: user?.id,
      amount: amount,
      amount_usd: amount,
      walletId: checkWithdrawWalletId(wallet),
      transaction_fee: parseFloat(fundFee)?.toFixed(2),
    };
    dispatch(withdrawFundByIdService(formData));
  }

  return (
    <Container className="withdraw-container">
      <div className="back" onClick={() => navigate("/wallets")}>
        <FIcon
          name="back"
          color={theme === "dark" ? "white" : "black"}
          size="20"
        />
        {t("withdraw_back")}
      </div>
      <div className="withdraw">
        <h1>{t("withdraw_invest_fund")}</h1>
        <Card>
          <Card.Header>Dow Digital Capital</Card.Header>
          <Card.Body>
            <div className="withdraw-info">
              <div className="withdraw-info-item">
                <h2>{t("withdraw_invest")}</h2>
                <h3>{t("withdraw_invest_fund")}</h3>
              </div>
              <div className="withdraw-info-right">
                <h2>{t("withdraw_usdc_")}</h2>
                <h3>
                  {loading || balances?.TUSD?.amount_usd == undefined ? (
                    <Spinner animation="grow" size="sm" />
                  ) : balances?.length !== 0 ? (
                    parseFloat(balances?.TUSD?.amount_usd)?.toFixed(6)
                  ) : (
                    "0"
                  )}{" "}
                  USDC
                </h3>
              </div>
            </div>

            <div className="withdraw-form">
              <h4
                onClick={() => {
                  setAmount(parseFloat(balances?.TUSD?.amount_usd).toFixed(6));
                  setEstimatedDow(
                    parseFloat(balances?.TUSD?.amount_usd - fundFee).toFixed(6)
                  );
                }}
              >
                {t("withdraw_all")}
              </h4>
              <Form>
                <FTextInput label={t("withdraw_usd_type")} id="amount">
                  <Form.Control
                    onChange={(event) => {
                      setAmount(event.target.value);
                      setEstimatedDow(
                        parseFloat(event.target.value - fundFee).toFixed(6)
                      );
                    }}
                    type="number"
                    placeholder="100"
                    step={0.01}
                    value={amount}
                  />
                </FTextInput>
                <div
                  style={{
                    height: "10px",
                  }}
                >
                  <Form.Text className="text-danger">{error}</Form.Text>
                </div>
                <div
                  className="withdraw-info"
                  style={{ paddingBottom: "10px", paddingTop: "20px" }}
                >
                  <div className="withdraw-info-item">
                    <h3 style={{ fontSize: "12px", opacity: 0.5 }}>
                      {t("withdraw_fee")}: {parseFloat(fundFee)?.toFixed(4)}{" "}
                      USDC
                    </h3>
                  </div>
                </div>
                <div>
                  <Button
                    onClick={handleSubmit}
                    className="withdraw_button d-flex justify-content-center align-items-center gap-2"
                    type="submit"
                    disabled={
                      error !== "" || disabled || loadingFund || loading
                    }
                  >
                    {t("withdraw_fee2")}
                  </Button>
                </div>
              </Form>
            </div>
          </Card.Body>
        </Card>
      </div>
    </Container>
  );
};

export default Withdraw;
