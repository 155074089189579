// Public Layout
import Dashboard from "./PublicLayout/Dashboard/Dashboard";
import Invest from "./PublicLayout/Invest/Invest";
import Performance from "./PublicLayout/Performance/Performance";
import Wallets from "./PublicLayout/Wallets/Wallets";
import Withdraw from "./PublicLayout/Wallets/Withdraw/Withdraw";
import FundDetails from "./PublicLayout/Invest/FundDetails/FundDetails";
import Arbitrage from "./PublicLayout/Invest/Arbitrage/Arbitrage";
import BitcoinProtected from "./PublicLayout/Invest/BitcoinProtected/BitcoinProtected";
import C5Index from "./PublicLayout/Invest/C5Index/C5Index";
import CryptoTrading from "./PublicLayout/Invest/CryptoTrading/CryptoTrading";
import AccountSettings from "./PublicLayout/AccountSettings/AccountSettings";
import Buy from "./PublicLayout/Invest/Buy/Buy";
import Redeem from "./PublicLayout/Invest/Redeem/Redeem";

// Main Layout
import Error from "./MainLayout/Error/Error";
import Login from "./MainLayout/Login/Login";
import LandingPage from "./MainLayout/LandingPage/LandingPage";
import SignUp from "./MainLayout/SignUp/SignUp";
import About from "./MainLayout/About/About";
import Funds from "./MainLayout/Funds/Funds";
import News from "./MainLayout/News/News";
import Confirmation from "./MainLayout/Confirmation/Confirmation";
import Terms from "./MainLayout/Terms/Terms";
import Yield from "./MainLayout/Yield/Yield";
import FAQ from "./MainLayout/FAQ/FAQ";
import ForgotPassword from "./MainLayout/ForgotPassword/ForgotPassword";
import ResetPassword from "./MainLayout/ResetPassword/ResetPassword";
import OTP from "./MainLayout/OTP/OTP";

import AboutDowTokens from "./MainLayout/AboutDowTokens/AboutDowTokens";
import NewsDetails from "./MainLayout/News/NewsDetails/NewsDetails";
import OptionStrategies from "./MainLayout/Yield/OptionStrategies/OptionStrategies";
import LiquidtyProviding from "./MainLayout/Yield/LiquidtyProviding/LiquidtyProviding";
import LeveragedStaking from "./MainLayout/Yield/LeveragedStaking/LeveragedStaking";

//yield pages
import YieldLandingPage from "./YieldLayout/YieldLandingPage/YieldLandingPage";
import YieldListPage from "./YieldLayout/YieldListPage/YieldListPage";
import YieldStakingPage from "./YieldLayout/YieldStakingPage/YieldStakingPage";
import YieldLiquidityPage from "./YieldLayout/YieldLiquidityPage/YieldLiquidityPage";
import YieldOptionPage from "./YieldLayout/YieldOptionPage/YieldOptionPage";
import YieldDashboardPage from "./YieldLayout/YieldDashboardPage/YieldDashboardPage";

export {
  Dashboard,
  Invest,
  Performance,
  Wallets,
  Error,
  Login,
  LandingPage,
  SignUp,
  About,
  Funds,
  News,
  FundDetails,
  Confirmation,
  Terms,
  Arbitrage,
  BitcoinProtected,
  C5Index,
  CryptoTrading,
  Yield,
  AboutDowTokens,
  AccountSettings,
  NewsDetails,
  Buy,
  Redeem,
  LeveragedStaking,
  LiquidtyProviding,
  OptionStrategies,
  YieldLandingPage,
  YieldListPage,
  YieldStakingPage,
  YieldLiquidityPage,
  YieldOptionPage,
  FAQ,
  Withdraw,
  ForgotPassword,
  ResetPassword,
  OTP,
  YieldDashboardPage,
};
