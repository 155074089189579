import { convertFromRaw, EditorState } from "draft-js";
import i18next from "i18next";
import React, { useEffect } from "react";
import { Card, Container, Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getNewsRequest } from "../../../services/news/newsService";
import "./News.scss";

const News = () => {
  const dispatch = useDispatch();
  let navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { newsList, loading } = useSelector((state) => state.news);
  const initialFilters = {
    filter: {},
  };

  useEffect(() => {
    dispatch(getNewsRequest(initialFilters));
  }, []);

  const getLanguage = i18next.language || window.localStorage.i18nextLng;

  return (
    <>
      <Container className="new_container">
        <div className="news">
          <h1>Blog</h1>
          <div className="news-cards">
            {loading ? (
              <Spinner animation="grow" />
            ) : (
              newsList?.map(function (item, index) {
                return (
                  <div
                    className="news-card"
                    key={index}
                    onClick={() => navigate("newsDetails?id=" + item?.id)}
                  >
                    {item?.image && (
                      <img
                        src={
                          process.env.REACT_APP_DOWNLOAD_IMAGE_BASE_URL +
                          item?.image
                        }
                      />
                    )}
                    <div className="news-card-desc">
                      <div className="news-card-desc-title">{item?.title}</div>
                      <div className="news-card-desc-content">
                        {item?.content !== undefined &&
                          EditorState.createWithContent(
                            convertFromRaw(JSON.parse(item?.content))
                          )
                            .getCurrentContent()
                            .getPlainText()
                            .substring(0, 200) + "..."}
                      </div>
                    </div>
                  </div>
                );
              })
            )}
          </div>
        </div>
      </Container>
    </>
  );
};

export default News;

/* <div className="news-others">
            {loading ? (
              <Spinner animation="grow" />
            ) : (
              newsList?.map(function (item, index) {
                return index === 0 ? (
                  ''
                ) : (
                  <Card
                    key={index}
                    onClick={() => navigate('newsDetails?id=' + item?.id)}
                  >
                    <Card.Body>
                      <div className="card_head">
                        {item?.image && (
                          <img
                            alt="others"
                            src={
                              process.env.REACT_APP_DOWNLOAD_IMAGE_BASE_URL +
                              item?.image
                            }
                          ></img>
                        )}
                        <div className="card_subhead">
                          <Card.Title>{item?.title}</Card.Title>
                          <Card.Subtitle>
                            {getLanguage === 'tr'
                              ? new Date(item?.c_date).toLocaleDateString(
                                  'tr-TR',
                                  {
                                    year: 'numeric',
                                    month: 'long',
                                    day: 'numeric',
                                  }
                                )
                              : new Date(item?.c_date).toLocaleDateString(
                                  'en-EN',
                                  {
                                    year: 'numeric',
                                    month: 'long',
                                    day: 'numeric',
                                  }
                                )}
                          </Card.Subtitle>
                        </div>
                      </div>
                      <Card.Text>
                        {item?.content !== undefined &&
                          EditorState.createWithContent(
                            convertFromRaw(JSON.parse(item?.content))
                          )
                            .getCurrentContent()
                            .getPlainText()
                            .substring(0, 200) + '...'}
                      </Card.Text>
                    </Card.Body>
                  </Card>
                );
              })
            )}
          </div>*/
