import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { logout } from "../../services/authentication/authenticationService";
import FIcon from "../FIcon/FIcon";
import "./FSidebar.scss";
import { useGlobalContext } from "../../contexts/sidebarContext";
import { getApproveCountRequest } from "../../services/admin/adminService";

function FSidebar({ activeTab, setActiveTab, theme }) {
  let navigate = useNavigate();
  const dispatch = useDispatch();

  const logOut = () => {
    dispatch(logout());
    navigate("/login");
  };
  const { t } = useTranslation();
  const [width, setWidth] = useState(0);
  const ref = useRef(null);

  useLayoutEffect(() => {
    setWidth(window.innerWidth);
  }, [window.innerWidth]);

  const { approveCount } = useSelector((state) => state.admin);

  useEffect(() => {
    dispatch(getApproveCountRequest());
  }, [dispatch]);

  const { isSidebarOpen, closeSidebar } = useGlobalContext();
  const handleCloseSidebar = () => {
    if (window.innerWidth < 992) {
      closeSidebar();
    }
  };
  return (
    <div>
      <div
        className="f_sidebar"
        style={{
          display: isSidebarOpen ? "flex" : "none",
        }}
      >
        <div className="f_sidebar-items_top">
          <div
            className="close_sidebar"
            onClick={closeSidebar}
            style={{ cursor: "pointer" }}
          >
            <svg
              width="12"
              height="12"
              viewBox="0 0 12 12"
              fill="black"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0.994464 10.1181L4.70491 6.05818L0.645028 2.34774L1.88184 0.994449L5.94172 4.70489L9.65216 0.645013L11.0055 1.88183L7.29501 5.94171L11.3549 9.65215L10.1181 11.0054L6.0582 7.295L2.34776 11.3549L0.994464 10.1181Z"
                fill="black"
              />
            </svg>
          </div>

          <div
            className="navbar_logo"
            style={{
              width: "265px",
              justifyContent: "center",
              textAlign: "center",
              alignItems: "center",
              color: "#4169e1",
            }}
          >
            <h1>DOW</h1>
          </div>
          <div
            className={
              activeTab === 0
                ? "f_sidebar-items_top-item aktif"
                : "f_sidebar-items_top-item"
            }
            onClick={() => {
              setActiveTab(0);
              localStorage.setItem("activeTab", 0);
              handleCloseSidebar();
            }}
          >
            <FIcon name="home" color="#4169E1" size="18"></FIcon>
            {t("sidebar_home")}
          </div>
          <hr />
          <div
            className={
              activeTab === 1
                ? "f_sidebar-items_top-item aktif"
                : "f_sidebar-items_top-item"
            }
            onClick={() => {
              setActiveTab(1);
              localStorage.setItem("activeTab", 1);
              handleCloseSidebar();
            }}
          >
            <FIcon name="plus" color="#4169E1" size="18"></FIcon>
            {t("sidebar_invest")}
            {approveCount?.invest !== 0 && (
              <div
                style={{
                  width: "24px",
                  height: "24px",
                  borderRadius: "100%",
                  background: "#4169E1",
                  color: "white",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  fontSize: "11px",
                }}
              >
                {approveCount?.invest}
              </div>
            )}
          </div>
          <hr />
          <div
            className={
              activeTab === 2
                ? "f_sidebar-items_top-item aktif"
                : "f_sidebar-items_top-item"
            }
            onClick={() => {
              setActiveTab(2);
              localStorage.setItem("activeTab", 2);
              handleCloseSidebar();
            }}
          >
            <FIcon name="minus" color="#4169E1" size="18"></FIcon>
            {t("sidebar_redeem")}
            {approveCount?.redeem !== 0 && (
              <div
                style={{
                  width: "24px",
                  height: "24px",
                  borderRadius: "100%",
                  background: "#4169E1",
                  color: "white",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  fontSize: "11px",
                }}
              >
                {approveCount?.redeem}
              </div>
            )}
          </div>
          <hr />
          <div
            className={
              activeTab === 8
                ? "f_sidebar-items_top-item aktif"
                : "f_sidebar-items_top-item"
            }
            onClick={() => {
              setActiveTab(8);
              localStorage.setItem("activeTab", 8);
              handleCloseSidebar();
            }}
          >
            <FIcon name="deposit" color="#4169E1" size="18"></FIcon>
            {t("sidebar_deposit")}
          </div>
          <hr />
          <div
            className={
              activeTab === 3
                ? "f_sidebar-items_top-item aktif"
                : "f_sidebar-items_top-item"
            }
            onClick={() => {
              setActiveTab(3);
              localStorage.setItem("activeTab", 3);
              handleCloseSidebar();
            }}
          >
            <FIcon name="withdraw" color="#4169E1" size="18"></FIcon>
            {t("sidebar_withdraw")}
          </div>
          <hr />

          <div
            className={
              activeTab === 10
                ? "f_sidebar-items_top-item aktif"
                : "f_sidebar-items_top-item"
            }
            onClick={() => {
              setActiveTab(10);
              localStorage.setItem("activeTab", 10);
              handleCloseSidebar();
            }}
          >
            <FIcon name="bnbtrader" color="#4169E1" size="18"></FIcon>
            Binance Trader
          </div>
          <hr />
          <div
            className={
              activeTab === 4
                ? "f_sidebar-items_top-item aktif"
                : "f_sidebar-items_top-item"
            }
            onClick={() => {
              setActiveTab(4);
              localStorage.setItem("activeTab", 4);
              handleCloseSidebar();
            }}
          >
            <FIcon name="profile" color="#4169E1" size="18"></FIcon>
            {t("sidebar_users")}
          </div>
          <hr />
          <div
            className={
              activeTab === 7
                ? "f_sidebar-items_top-item aktif"
                : "f_sidebar-items_top-item"
            }
            onClick={() => {
              setActiveTab(7);
              localStorage.setItem("activeTab", 7);
              handleCloseSidebar();
            }}
          >
            <FIcon name="partner" color="#4169E1" size="18"></FIcon>
            {t("sidebar_partners")}
          </div>
          <hr />
          <div
            className={
              activeTab === 9
                ? "f_sidebar-items_top-item aktif"
                : "f_sidebar-items_top-item"
            }
            onClick={() => {
              setActiveTab(9);
              localStorage.setItem("activeTab", 9);
              handleCloseSidebar();
            }}
          >
            <FIcon name="fee" color="#4169E1" size="18"></FIcon>
            {t("sidebar_fees")}
          </div>
          <hr />
          <div
            className={
              activeTab === 5
                ? "f_sidebar-items_top-item aktif"
                : "f_sidebar-items_top-item"
            }
            onClick={() => {
              setActiveTab(5);
              localStorage.setItem("activeTab", 5);
              handleCloseSidebar();
            }}
          >
            <FIcon name="funds" color="#4169E1" size="18"></FIcon>
            {t("sidebar_funds")}
          </div>
          <hr />
          <div
            className={
              activeTab === 6
                ? "f_sidebar-items_top-item aktif"
                : "f_sidebar-items_top-item"
            }
            onClick={() => {
              setActiveTab(6);
              localStorage.setItem("activeTab", 6);
              handleCloseSidebar();
            }}
          >
            <FIcon name="news" color="#4169E1" size="18"></FIcon>
            {t("sidebar_news")}
          </div>
          <hr />

          <div
            className={
              activeTab === 11
                ? "f_sidebar-items_top-item aktif"
                : "f_sidebar-items_top-item"
            }
            onClick={() => {
              setActiveTab(11);
              localStorage.setItem("activeTab", 11);
              handleCloseSidebar();
            }}
          >
            <FIcon name="profile" color="#4169E1" size="18"></FIcon>
            Emergency Contacts
          </div>
        </div>
      </div>
    </div>
  );
}

export default FSidebar;
