import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getLastPricesService } from '../../../../services/fund/fundService';
import FundDetails from '../../../PublicLayout/Invest/FundDetails/FundDetails';
import './LiquidtyProviding.scss';
import likidite1 from '../../../../assets/images/likidite1.svg';
import likidite2 from '../../../../assets/images/likidite2.png';
import { useTranslation } from 'react-i18next';

const LiquidtyProviding = ({ from, theme }) => {
  const dispatch = useDispatch();
  const { lastPrices, loading } = useSelector((state) => state.fund);

  const { t } = useTranslation();

  const initialFilters = {
    filter: {},
  };

  useEffect(() => {
    dispatch(getLastPricesService(initialFilters));
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <FundDetails location={'liquidty-providing'} from={from} theme={theme} />

      {loading ? (
        ''
      ) : (
        <div className="f_funddetailswrapperr">
          <div className="f_funddetails-fundforr">
            <div className="f_funddetails-fundforr-content">
              <div className="f_funddetails-fundforr-content-section">
                <img alt="for" src={likidite1}></img>
              </div>
              <div className="f_funddetails-fundforr-content-section">
                <h1>{t('liquidity_title1')}</h1>
                <h2>{t('liquidity_item1')}</h2>
              </div>
            </div>
          </div>
          <div className="f_funddetails-fundforr">
            <div className="f_funddetails-fundforr-content">
              <div className="f_funddetails-fundforr-content-section">
                <h1>{t('liquidity_title2')}</h1>
                <h2>{t('liquidity_item2')}</h2>
              </div>
              <div className="f_funddetails-fundforr-content-section">
                <img alt="for" src={likidite2}></img>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default LiquidtyProviding;
