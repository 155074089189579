import React from 'react';
import './Confirmation.scss';
import { useNavigate } from 'react-router-dom';
import { Button } from 'react-bootstrap';

const Confirmation = () => {
  let navigate = useNavigate();

  return (
    <div className="f_confirmation text-center">
      <h1>You have successfully signed up.</h1>
      <h3 className="by mt-2">
        You can now invest in funds created by{' '}
        <div className="logo">Dow Digital Capital.</div>
      </h3>
      <h6 className="mt-2">
        The road to profit and wealth lies ahead. Our team will help you
        navigate and increase your wealth through many strategies. We are here
        for you.
      </h6>
      <div className="mt-4 pb-4">
        <Button
          className="launch"
          onClick={() => navigate('/login')}
          style={{
            fontSize: '26px',
            fontWeight: 700,
            backgroundColor: 'var(--text-secondary)',
            border: 'none',
            width: '180px',
            height: '50px',
          }}
        >
          Log in
        </Button>
      </div>
    </div>
  );
};

export default Confirmation;
