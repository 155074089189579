import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { Spinner } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { fundsWalletColumns } from "../../../constants/columnConfigurations";
import {
  getBalanceByIdRequest,
  getFundTableDataRequest,
  getWalletByIdRequest,
} from "../../../services/wallet/walletService";
import {
  checkNull,
  checkUserTubuWallet,
  numberWithCommas,
} from "../../../utils/util";
import FTable from "../../FTable/FTable";
import { useNavigate } from "react-router-dom";

const WFunds = () => {
  const ref = useRef(null);
  const [width, setWidth] = useState(0);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const fundWalletConfigs = fundsWalletColumns({
    translateFunction: t,
    navigate: navigate,
  });
  function createData(
    name,
    logo,
    qty,
    c_price,
    b_price,
    return_d,
    pl,
    mark_to_market
  ) {
    return {
      assets: { logo, name },
      qty,
      c_price,
      b_price,
      return_d,
      pl,
      mark_to_market,
    };
  }
  const [fakeData, setFakeData] = useState([]);

  const user = JSON.parse(localStorage.getItem("user"));

  const dispatch = useDispatch();
  const { wallet, loadingBalance, balances, fundTableData } = useSelector(
    (state) => state.wallet
  );

  const walletFilters = {
    id: user.id,
  };

  const balanceFilters = {
    wallet: checkUserTubuWallet(wallet),
    id: user.id,
  };

  useEffect(() => {
    setFakeData([]);
  }, [balances]);

  useEffect(() => {
    dispatch(getWalletByIdRequest(walletFilters));
  }, []);

  useEffect(() => {
    if (wallet.length !== 0) {
      setFakeData([]);
      dispatch(getBalanceByIdRequest(balanceFilters));
      dispatch(getFundTableDataRequest(balanceFilters));
    }
  }, [wallet]);

  useLayoutEffect(() => {
    setWidth(ref.current.offsetWidth);
  }, []);

  const logos = [
    "https://logowik.com/content/uploads/images/usd-coin-usdc4695.jpg",
    "https://dow.capital/static/media/c5icon.a4fc3d13b355bfc93b0f.png",
    "https://dow.capital/static/media/btcicon.f369eb135dd8bed28e0d.png",
    "https://dow.capital/static/media/tradeicon.300070758f7cd253ff5f.png",
    "https://dow.capital/static/media/arbitrageicon.b534faf6f8c6f6c48dd5.png",
  ];

  useEffect(() => {
    if (balances && fundTableData) {
      let total = 0;
      setFakeData((oldArray) => [
        ...oldArray,
        createData(
          "USDC",
          logos[0],
          balances[Object.keys(balances)[0]].amount,
          "-",
          "-",
          "-",
          "-",
          parseFloat(balances[Object.keys(balances)[0]].amount_usd)?.toFixed(2)
        ),
      ]);
      total = total + balances[Object.keys(balances)[0]].amount;
      for (let i = 1; i < Object.keys(balances)?.length; i++) {
        total = total + balances[Object.keys(balances)[i]].amount_usd;

        setFakeData((oldArray) => [
          ...oldArray,
          createData(
            Object.keys(balances)[i],
            logos[i],
            parseFloat(balances[Object.keys(balances)[i]]?.amount)?.toFixed(4),
            Object.keys(balances)[i] == "BTCp"
              ? parseFloat(fundTableData?.currentPrice["BTCp"]).toFixed(4)
              : parseFloat(
                  fundTableData?.currentPrice[Object.keys(balances)[i]]
                ).toFixed(4),
            checkNull(
              Object.keys(balances)[i] == "BTCp"
                ? parseFloat(fundTableData?.buyPrice["BTCp"]).toFixed(4)
                : parseFloat(
                    fundTableData?.buyPrice[Object.keys(balances)[i]]
                  ).toFixed(4)
            ),
            checkNull(
              Object.keys(balances)[i] == "BTCp"
                ? parseFloat(fundTableData?.returnValue["BTCp"]).toFixed(4)
                : parseFloat(
                    fundTableData?.returnValue[Object.keys(balances)[i]]
                  ).toFixed(4)
            ),
            Object.keys(balances)[i] == "BTCp"
              ? parseFloat(fundTableData?.PL["BTCp"]).toFixed(4)
              : parseFloat(fundTableData?.PL[Object.keys(balances)[i]]).toFixed(
                  4
                ),
            parseFloat(balances[Object.keys(balances)[i]].amount_usd)?.toFixed(
              2
            )
          ),
        ]);
      }

      setFakeData((oldArray) => [
        ...oldArray,
        createData(
          "TOTAL",
          "",
          "",
          "",
          "",
          "",
          "",
          parseFloat(total)?.toFixed(2)
        ),
      ]);
    }
  }, [balances, fundTableData]);

  useEffect(() => {
    console.log(fakeData);
  }, [fakeData]);

  return (
    <div ref={ref}>
      {loadingBalance ? (
        <Spinner animation="grow" size="sm" />
      ) : (
        <div className="f_wallet-bg">
          {width > 550 ? (
            <div>
              {
                <FTable
                  columns={fundWalletConfigs}
                  data={fakeData}
                  type="fund"
                  total={true}
                />
              }
            </div>
          ) : (
            <div>
              <div
                className="d-flex justify-content-between align-items-center pb-2"
                style={{ borderBottom: "0.5px solid #5E718D" }}
              >
                <h4>{t("funds")}</h4>
                <div className="d-flex align-items-center">
                  <h6 style={{ fontSize: "14px" }}>{t("total")}</h6>
                  <div style={{ width: "10px" }}> </div>
                  <span style={{ fontSize: "14px", color: "#4169E1" }}>
                    ${fakeData.at(-1)?.mark_to_market}
                  </span>
                </div>
              </div>
              {fakeData?.slice(0, -1)?.map((value, index) => (
                <>
                  <div
                    className="funds-item pb-2 pt-2"
                    style={{ borderBottom: "0.5px solid #5E718D" }}
                  >
                    <div className="d-flex mt-2 mb-2 align-items-center">
                      <img src={value?.assets?.logo} height="22" />
                      <h6
                        style={{
                          fontSize: "14px !important",
                          fontWeight: "500",
                        }}
                      >
                        {value?.assets?.name} {t("balance")}
                      </h6>
                    </div>

                    <div className="w_transaction-acc-desc">
                      <div>
                        <h4>MARK TO MARKET</h4>
                        <span>
                          $
                          {numberWithCommas(
                            parseFloat(value?.mark_to_market)?.toFixed(2)
                          )}
                        </span>
                      </div>
                      <div>
                        <h4>{t("quantity")}</h4>
                        <span>
                          {numberWithCommas(parseFloat(value?.qty)?.toFixed(2))}
                        </span>
                      </div>
                      <div>
                        <h4>{t("current_price")}</h4>
                        <span>
                          {numberWithCommas(
                            parseFloat(value?.c_price)?.toFixed(2)
                          )}
                        </span>
                      </div>
                      <div>
                        <h4>{t("buy_price")}</h4>
                        <span>-</span>
                      </div>
                      <div>
                        <h4>{t("return_dollar")}</h4>
                        <span>
                          {numberWithCommas(
                            parseFloat(value?.return_d)?.toFixed(2)
                          )}
                        </span>
                      </div>
                      <div>
                        <h4>P/L</h4>
                        <span>-</span>
                      </div>
                    </div>
                  </div>
                </>
              ))}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default WFunds;
