// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.faq-title {
  font-size: 24px;
  font-weight: 700;
  line-height: 29px;
  margin-bottom: 78px !important;
}
.faq .accordion-button,
.faq .accordion-item,
.faq .accordion-header {
  border: 0 !important;
  background-color: #fafafa !important;
}
.faq .accordion-button {
  padding: 1.25rem 1rem;
  color: #252525 !important;
}
.faq .accordion-header {
  font-size: 13px;
  font-weight: 500;
  line-height: 16px;
}
.faq .accordion-body {
  font-size: 13px;
  font-weight: 400;
  line-height: 16px;
  padding-top: 0;
}
.faq .accordion-item {
  margin-bottom: 11px;
}

.faq-wrapper {
  background-color: white;
}`, "",{"version":3,"sources":["webpack://./src/pages/MainLayout/FAQ/FAQ.scss"],"names":[],"mappings":"AACE;EACE,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,8BAAA;AAAJ;AAEE;;;EAGE,oBAAA;EACA,oCAAA;AAAJ;AAEE;EACE,qBAAA;EACA,yBAAA;AAAJ;AAEE;EACE,eAAA;EACA,gBAAA;EACA,iBAAA;AAAJ;AAEE;EACE,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,cAAA;AAAJ;AAEE;EACE,mBAAA;AAAJ;;AAIA;EACE,uBAAA;AADF","sourcesContent":[".faq {\n  &-title {\n    font-size: 24px;\n    font-weight: 700;\n    line-height: 29px;\n    margin-bottom: 78px !important;\n  }\n  .accordion-button,\n  .accordion-item,\n  .accordion-header {\n    border: 0 !important;\n    background-color: #fafafa !important;\n  }\n  .accordion-button {\n    padding: 1.25rem 1rem;\n    color: #252525 !important;\n  }\n  .accordion-header {\n    font-size: 13px;\n    font-weight: 500;\n    line-height: 16px;\n  }\n  .accordion-body {\n    font-size: 13px;\n    font-weight: 400;\n    line-height: 16px;\n    padding-top: 0;\n  }\n  .accordion-item {\n    margin-bottom: 11px;\n  }\n}\n\n.faq-wrapper {\n  background-color: white;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
