import axios from "axios";
const API_URL = process.env.REACT_APP_API_URL;
const FUND_API_URL = process.env.REACT_APP_FUND_API_URL;

export const currencyIcon = (name) => {
  if (name === "ETH") {
  }
};
export const sortData = (data, selector, direction) => {
  const ASC = "asc";
  const DESC = "desc";
  if (!direction) {
    return data;
  }

  const customSort = (first, second) => {
    if (first[selector] > second[selector]) {
      return 1;
    } else if (first[selector] < second[selector]) {
      return -1;
    } else {
      return 0;
    }
  };

  const sortedData = [...data].sort(customSort);

  if (direction === DESC) {
    sortedData.reverse();
  }

  return sortedData;
};

const getToken = () => {
  const token = localStorage.getItem("token");
  if (token) {
    return token;
  }
  return "";
};

axios.interceptors.request.use(
  (config) => {
    const token = getToken();
    if (token) {
      config.headers["Authorization"] = "Bearer " + token;
    }
    config.headers["Content-Type"] = "application/json";

    return config;
  },
  (err) => Promise.reject(err)
);

axios.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
  },
  function (error) {
    console.log(error);

    if (error?.response?.status == 401) {
      window.location.href = "https://dow.capital/login";
      localStorage.clear();
    }

    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    return Promise.reject(error);
  }
);

export const handleRedirect = (history, path, state) =>
  history.push(path, { ...state });

/**
 * @param {string} method the HTTP verb you want to use
 * @param {string} path the route path / endpoint
 * @param {object} data (optional) data in JSON form for POST requests
 * @param {object} params (optional) data in the url for GET requests
 */
export const apiCall = async (method, path, data, params, responseType) => {
  const res = await axios({
    method,
    url: API_URL + path,
    data,
    params,
    responseType,
  });
  return res;
};

export const multiApiCall = async (
  method,
  path,
  data,
  params,
  responseType
) => {
  const res = await axios({
    method,
    url: API_URL + path,
    data,
    params,
    responseType,
  });
  return res;
};

export const fundApiCall = async (method, path, data, params, responseType) => {
  const res = await axios({
    method,
    url: FUND_API_URL + path,
    data,
    params,
    responseType,
  });
  return res;
};

export const objectToQueryString = (obj, prefix) => {
  var str = [],
    p;
  for (p in obj) {
    if (obj.hasOwnProperty(p)) {
      var k = prefix ? prefix + "[" + p + "]" : p,
        v = obj[p];
      str.push(
        v !== null && typeof v === "object"
          ? objectToQueryString(v, k)
          : encodeURIComponent(k) + "=" + encodeURIComponent(v)
      );
    }
  }
  return str.join("&");
};

export const dateTimeNameConverter = (name) => {
  if (name.startsWith("beginCreatedDate")) {
    return "createdDate[gte]";
  }
  if (name.startsWith("endCreatedDate")) {
    return "createdDate[lte]";
  }
  return name;
};

export function parseDate(str) {
  if (str) {
    var mdy = str.split("/");
    return new Date(mdy[2], mdy[0] - 1, mdy[1]);
  }
  return 0;
}

export function datediff(first, second) {
  return Math.round((second - first) / (1000 * 60 * 60 * 24));
}

export const checkDepositWallet = (object) => {
  for (let i = 0; i < object.length; i++) {
    if (object[i].network === "DEPOSIT") {
      return object[i].address;
    }
  }
  return "";
};

export const checkUserDepositWallet = (object) => {
  for (let i = 0; i < object.length; i++) {
    if (object[i].network === "DEPOSIT") {
      return object[i].user_address;
    }
  }
  return "";
};

export const checkUserWithdrawWallet = (object) => {
  for (let i = 0; i < object.length; i++) {
    if (object[i].network === "WITHDRAW") {
      return object[i].user_address;
    }
  }
  return "";
};

export const checkUserWithdrawWalletAndGetLastWallet = (object) => {
  if (object?.at(-1)?.network === "WITHDRAW") {
    return object?.at(-1)?.user_address;
  }
  return "";
};

export const checkUserTubuWallet = (object) => {
  for (let i = 0; i < object.length; i++) {
    if (object[i].network === "TUBU") {
      return object[i].user_address;
    }
  }
  return "";
};

export const checkWithdrawWallet = (object) => {
  for (let i = 0; i < object.length; i++) {
    if (object[i].network === "WITHDRAW") {
      return object[i].address;
    }
  }
  return "";
};

export const checkWithdrawWalletId = (object) => {
  for (let i = 0; i < object.length; i++) {
    if (object[i].network === "DEPOSIT") {
      return object[i].wallet_id_str;
    }
  }
  return "";
};

export const numberWithCommas = (x) => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const timeAgo = (input, language) => {
  const date = input instanceof Date ? input : new Date(input);
  const formatter = new Intl.RelativeTimeFormat(language);
  const ranges = {
    years: 3600 * 24 * 365,
    months: 3600 * 24 * 30,
    weeks: 3600 * 24 * 7,
    days: 3600 * 24,
    hours: 3600,
    minutes: 60,
    seconds: 1,
  };
  const secondsElapsed = (date.getTime() - Date.now()) / 1000;
  for (let key in ranges) {
    if (ranges[key] < Math.abs(secondsElapsed)) {
      const delta = secondsElapsed / ranges[key];
      return formatter.format(Math.round(delta), key);
    }
  }
};

export const addDays = (date = new Date(), day) => {
  date.setDate(date.getDate() - day);

  return new Date(date).toLocaleDateString("fr-CA", {
    year: "numeric",
    month: "numeric",
    day: "numeric",
  });
};
export const checkNull = (data) => {
  if (data === null || "NaN") {
    return 0.0;
  }
  return data;
};
