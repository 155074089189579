// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.f_modal-internal {
  display: flex;
  flex-direction: row;
  gap: 20px;
}

.modal-content {
  background-color: var(--background-secondary) !important;
}`, "",{"version":3,"sources":["webpack://./src/components/DashboardComponents/DInternalModal/DInternalModal.scss"],"names":[],"mappings":"AACE;EACE,aAAA;EACA,mBAAA;EACA,SAAA;AAAJ;;AAIA;EACE,wDAAA;AADF","sourcesContent":[".f_modal {\n  &-internal {\n    display: flex;\n    flex-direction: row;\n    gap: 20px;\n  }\n}\n\n.modal-content {\n  background-color: var(--background-secondary) !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
