import * as actions from "../../actions";

const initialState = {
  user: {},
  userInfoResult: null,
  twoFaActivateInfoResult: null,
  forgotPasswordInfoResult: null,
  updateUserResult: [],
  loading: false,
  error: false,
  isLogged: null,
  isOtp: false,
  isTwoFaLogin: false,
  otpUrl: null,
  emergencyContactCreateResult: null,
  emergencyContactUpdateResult: null,
  emergencyContactDeleteResult: null,
  emergencyContactResult: null,
};

const authentication = (state = initialState, action) => {
  switch (action.type) {
    case actions.LOGIN_REQUEST:
      return {
        ...state,
        user: {},
        loading: true,
        error: false,
        isLogged: null,
        isOtp: false,
        isTwoFaLogin: false,
      };
    case actions.LOGIN_SUCCEEDED:
      return {
        ...state,
        user: { ...action.payload },
        loading: false,
        error: false,
        isLogged: true,
        isOtp: false,
        isTwoFaLogin: false,
      };
    case actions.LOGIN_FAILED:
      return {
        ...state,
        user: {},
        loading: false,
        error: true,
        isLogged: false,
        isOtp: false,
        isTwoFaLogin: false,
      };
    case actions.NAVIGATE_OTP:
      return {
        ...state,
        user: { ...action.payload },
        loading: false,
        error: false,
        isOtp: true,
        isLogged: false,
        isTwoFaLogin: false,
      };
    case actions.FORGOT_PASSWORD_REQUEST:
      return {
        ...state,
        forgotPasswordInfoResult: null,
        loading: true,
        error: false,
      };
    case actions.FORGOT_PASSWORD_SUCCEEDED:
      return {
        ...state,
        forgotPasswordInfoResult: action.payload,
        loading: false,
        error: false,
      };
    case actions.FORGOT_PASSWORD_FAILED:
      return {
        ...state,
        forgotPasswordInfoResult: null,
        loading: false,
        error: true,
      };

    case actions.RESET_PASSWORD_REQUEST:
      return {
        ...state,
        resetPasswordInfoResult: null,
        loading: true,
        error: false,
      };
    case actions.RESET_PASSWORD_SUCCEEDED:
      return {
        ...state,
        resetPasswordInfoResult: action.payload,
        loading: false,
        error: false,
      };
    case actions.RESET_PASSWORD_FAILED:
      return {
        ...state,
        resetPasswordInfoResult: null,
        loading: false,
        error: true,
      };

    case actions.TWOFA_ACTIVATE_REQUEST:
      return {
        ...state,
        twoFaActivateInfoResult: null,
        loading: true,
        error: false,
      };
    case actions.TWOFA_ACTIVATE_SUCCEEDED:
      return {
        ...state,
        twoFaActivateInfoResult: action.payload,
        loading: false,
        error: false,
      };
    case actions.TWOFA_ACTIVATE_FAILED:
      return {
        ...state,
        twoFaActivateInfoResult: null,
        loading: false,
        error: true,
      };

    case actions.TWOFA_LOGIN_REQUEST:
      return {
        ...state,
        isTwoFaLogin: false,
        user: {},
        loading: true,
        error: false,
        isLogged: false,
        isOtp: false,
      };
    case actions.TWOFA_LOGIN_SUCCEEDED:
      return {
        ...state,
        isTwoFaLogin: true,
        user: { ...action.payload },
        loading: false,
        error: false,
        isLogged: false,
        isOtp: false,
      };
    case actions.TWOFA_LOGIN_FAILED:
      return {
        ...state,
        isTwoFaLogin: false,
        user: {},
        loading: false,
        error: true,
        isLogged: false,
        isOtp: false,
      };
    case actions.TWOFA_GENERATE_REQUEST:
      return {
        ...state,
        loading: true,
        error: false,
        otpUrl: null,
      };
    case actions.TWOFA_GENERATE_SUCCEEDED:
      return {
        ...state,
        loading: false,
        error: false,
        otpUrl: action.payload.otp_auth_url,
      };
    case actions.TWOFA_GENERATE_FAILED:
      return {
        ...state,
        loading: false,
        error: true,
        otpUrl: null,
      };
    case actions.LOGOUT:
      localStorage.clear();
      return { ...initialState };
    case actions.USER_INFO_REQUEST:
      return {
        ...state,
        userInfoResult: null,
        loading: true,
        error: false,
      };
    case actions.USER_INFO_SUCCEEDED:
      return {
        ...state,
        userInfoResult: action.payload,
        loading: false,
        error: false,
      };
    case actions.USER_INFO_FAILED:
      return {
        ...state,
        userInfoResult: null,
        loading: false,
        error: action.payload,
      };
    case actions.SIGNUP_REQUEST:
      return {
        ...state,
        user: {},
        loading: true,
        error: false,
        isLogged: false,
      };
    case actions.SIGNUP_SUCCEEDED:
      return {
        ...state,
        user: { ...action.payload },
        loading: false,
        error: false,
        isLogged: false,
      };
    case actions.SIGNUP_FAILED:
      return {
        ...state,
        user: {},
        loading: false,
        error: action.payload,
        isLogged: false,
      };
    case actions.UPDATE_USER_BY_ID_REQUEST:
      return {
        ...state,
        updateUserResult: [],
        loading: true,
        error: false,
        isLogged: false,
      };
    case actions.UPDATE_USER_BY_ID_SUCCESS:
      return {
        ...state,
        updateUserResult: [action.payload],
        loading: false,
        error: false,
        isLogged: false,
      };
    case actions.UPDATE_USER_BY_ID_FAIL:
      return {
        ...state,
        updateUserResult: [],
        loading: false,
        error: action.payload,
        isLogged: false,
      };
    case actions.EMERGENCY_CONTACT_CREATE_BY_ID_REQUEST:
      return {
        ...state,
        emergencyContactCreateResult: null,
        loading: true,
        error: false,
      };
    case actions.EMERGENCY_CONTACT_CREATE_BY_ID_SUCCESS:
      return {
        ...state,
        emergencyContactCreateResult: action.payload,
        loading: false,
        error: false,
      };
    case actions.EMERGENCY_CONTACT_CREATE_BY_ID_FAIL:
      return {
        ...state,
        emergencyContactCreateResult: null,
        loading: false,
        error: true,
      };
    case actions.EMERGENCY_CONTACT_UPDATE_BY_ID_REQUEST:
      return {
        ...state,
        emergencyContactUpdateResult: null,
        loading: true,
        error: false,
      };
    case actions.EMERGENCY_CONTACT_UPDATE_BY_ID_SUCCESS:
      return {
        ...state,
        emergencyContactUpdateResult: action.payload,
        loading: false,
        error: false,
      };
    case actions.EMERGENCY_CONTACT_UPDATE_BY_ID_FAIL:
      return {
        ...state,
        emergencyContactUpdateResult: null,
        loading: false,
        error: true,
      };
    case actions.EMERGENCY_CONTACT_DELETE_BY_ID_REQUEST:
      return {
        ...state,
        emergencyContactDeleteResult: null,
        loading: true,
        error: false,
      };
    case actions.EMERGENCY_CONTACT_DELETE_BY_ID_SUCCESS:
      return {
        ...state,
        emergencyContactDeleteResult: action.payload,
        loading: false,
        error: false,
      };
    case actions.EMERGENCY_CONTACT_DELETE_BY_ID_FAIL:
      return {
        ...state,
        emergencyContactDeleteResult: null,
        loading: false,
        error: true,
      };
    case actions.EMERGENCY_CONTACT_READ_BY_ID_REQUEST:
      return {
        ...state,
        emergencyContactResult: null,
        loading: true,
        error: false,
      };
    case actions.EMERGENCY_CONTACT_READ_BY_ID_SUCCESS:
      return {
        ...state,
        emergencyContactResult: action.payload.data,
        loading: false,
        error: false,
      };
    case actions.EMERGENCY_CONTACT_READ_BY_ID_FAIL:
      return {
        ...state,
        emergencyContactResult: null,
        loading: false,
        error: true,
      };
    default:
      return state;
  }
};

export default authentication;
