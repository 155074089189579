import * as services from "../actions";
export const createNewWalletRequest = (reqParams) => ({
  type: services.CREATE_NEW_WALLET_REQUEST,
  payload: reqParams,
});

export const createNewWalletSuccess = (reqParams) => ({
  type: services.CREATE_NEW_WALLET_SUCCESS,
  payload: reqParams,
});

export const createNewWalletFail = (error) => ({
  type: services.CREATE_NEW_WALLET_FAIL,
  payload: error,
});

export const getBalanceByIdRequest = (reqParams) => ({
  type: services.GET_BALANCE_BY_ID_REQUEST,
  payload: reqParams,
});

export const getBalanceByIdSuccess = ({ id, data }) => ({
  type: services.GET_BALANCE_BY_ID_SUCCESS,
  payload: {
    id,
    data,
  },
});

export const getBalanceByIdFail = (error) => ({
  type: services.GET_BALANCE_BY_ID_FAIL,
  payload: error,
});

export const getWalletByIdRequest = (reqParams) => ({
  type: services.GET_WALLET_BY_ID_REQUEST,
  payload: reqParams,
});

export const getWalletByIdSuccess = ({ id, data }) => ({
  type: services.GET_WALLET_BY_ID_SUCCESS,
  payload: {
    id,
    data,
  },
});

export const getWalletByIdFail = (error) => ({
  type: services.GET_WALLET_BY_ID_FAIL,
  payload: error,
});

export const postDepositWalletRequest = (reqParams) => ({
  type: services.POST_DEPOSIT_WALLET_REQUEST,
  payload: reqParams,
});

export const postDepositWalletSuccess = ({ id, data }) => ({
  type: services.POST_DEPOSIT_WALLET_SUCCESS,
  payload: {
    id,
    data,
  },
});

export const postDepositWalletFail = (error) => ({
  type: services.POST_DEPOSIT_WALLET_FAIL,
  payload: error,
});

export const postWithdrawWalletRequest = (reqParams) => ({
  type: services.POST_WITHDRAW_WALLET_REQUEST,
  payload: reqParams,
});

export const postWithdrawWalletSuccess = ({ id, data }) => ({
  type: services.POST_WITHDRAW_WALLET_SUCCESS,
  payload: {
    id,
    data,
  },
});

export const postWithdrawWalletFail = (error) => ({
  type: services.POST_WITHDRAW_WALLET_FAIL,
  payload: error,
});

export const getFundTableDataRequest = (reqParams) => ({
  type: services.GET_FUND_TABLE_DATA_REQUEST,
  payload: reqParams,
});

export const getFundTableDataSuccess = (reqParams) => ({
  type: services.GET_FUND_TABLE_DATA_SUCCESS,
  payload: reqParams,
});

export const getFundTableDataFail = (error) => ({
  type: services.GET_FUND_TABLE_DATA_FAIL,
  payload: error,
});
